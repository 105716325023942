import React from "react";
import { IoEyeOff, IoFingerPrintOutline, IoTicketSharp } from "react-icons/io5";
import {FaWhatsapp,FaTwitter,FaBars,FaSearch,FaUserPlus,FaUserAlt,  FaFacebookF, FaLinkedinIn} from "react-icons/fa"
import { BiUser, BiCompass, BiCog,BiPencil, BiCheckCircle, BiErrorCircle,BiBadgeCheck, BiUserPlus} from "react-icons/bi";
import { AiFillInstagram, AiOutlinePhone} from "react-icons/ai";

import { FiCalendar, FiCamera, FiClock,FiMinus,FiPlus } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";

import {
  MdDashboard,
  MdLogout,
  MdLocationPin,
  MdOutlineTextFields,
  MdLocationOn,
  MdDelete,
  MdClose,
  MdShare,
  MdArrowBackIos,
  MdMailOutline,
  MdHelp,
  MdSignalWifiStatusbarConnectedNoInternet4,
  
} from "react-icons/md";
import { BsBell, BsCalendar3RangeFill,BsHeart,BsFileEarmarkImage, BsCalendar2Check,BsFillBookmarkFill } from "react-icons/bs";
import { IoIosAddCircle, IoIosArrowBack,IoIosArrowDown,IoIosArrowRoundBack, IoIosArrowUp, IoIosEye } from "react-icons/io";
import {GrRevert, GrUserManager} from "react-icons/gr"
import {ImMagicWand} from "react-icons/im"

export const DashboardIcon = ({ size, color, onClick }) => {
  return <MdDashboard size={size} color={color} onClick={onClick} />;
};
export const TickectIcon = ({ size, color, onClick }) => {
  return <IoTicketSharp size={size} color={color} onClick={onClick} />;
};
export const CalendarIcon = ({ size, color, onClick,style }) => {
  return <FiCalendar size={size} color={color} onClick={onClick} style={style} />;
};

export const UserIcon = ({ size, color, onClick }) => {
  return <BiUser size={size} color={color} onClick={onClick} />;
};
export const AddUserIcon = ({ size, color, onClick,style }) => {
  return <BiUserPlus size={size} color={color} onClick={onClick} style={style} />;
};
export const CompassIcon = ({ size, color, onClick }) => {
  return <BiCompass size={size} color={color} onClick={onClick} />;
};
export const SettingsIcon = ({ size, color, onClick }) => {
  return <BiCog size={size} color={color} onClick={onClick} />;
};
export const LogOutIcon = ({ size, color, onClick }) => {
  return <MdLogout size={size} color={color} onClick={onClick} />;
};
export const HelpIcon = ({ size, color, onClick }) => {
  return <MdHelp size={size} color={color} onClick={onClick} />;
};
export const SearchIcon = ({ size, color, onClick }) => {
  return <FaSearch size={size} color={color} onClick={onClick} />;
};
export const BellIcon = ({ size, color, onClick }) => {
  return <BsBell size={size} color={color} onClick={onClick} />;
};
export const GoogleIcon = ({ size, color, onClick,style }) => {
  return <FcGoogle size={size} color={color} onClick={onClick} style={style} />;
};
export const AddIcon = ({ size, color, onClick,style,cursor }) => {
  return <FiPlus size={size} color={color} cursor={cursor} onClick={onClick} style={style} />;
};
export const MinusIcon = ({ size, color, onClick,style }) => {
  return <FiMinus size={size} color={color} onClick={onClick} style={style} />;
};
export const CircleAddIcon = ({ size, color, onClick,style }) => {
  return <IoIosAddCircle size={size} color={color} onClick={onClick} style={style} />;
};
export const BadgeIcon = ({ size, color, onClick,style }) => {
  return <BiBadgeCheck size={size} color={color} onClick={onClick} style={style} />;
};

export const LocattionIcon = ({ size, color, onClick }) => {
  return <MdLocationPin size={size} color={color} onClick={onClick} />;
};
export const DropDownIcon = ({ size, color, onClick }) => {
  return (
    <IoIosArrowDown
      cursor={"pointer"}
      size={size}
      color={color}
      onClick={onClick}
    />
  );
};
export const DropUpIcon = ({ size, color, onClick }) => {
  return (
    <IoIosArrowUp
      cursor={"pointer"}
      size={size}
      color={color}
      onClick={onClick}
    />
  );
};
export const GoBackIcon = ({ size, color, onClick }) => {
  return (
    <IoIosArrowBack
      cursor={"pointer"}
      size={size}
      color={color}
      onClick={onClick}
    />
  );
};
export const TextIcon = ({ size, color, onClick }) => {
  return <MdOutlineTextFields size={size} color={color} onClick={onClick} />;
};
export const EditIcon = ({ size, color, onClick }) => {
  return <BiPencil size={size} color={color} onClick={onClick} />;
};
export const LocationIcon = ({ size, color, onClick,style }) => {
  return <MdLocationOn size={size} color={color} onClick={onClick} style={style} />;
};
export const Calendar2Icon = ({ size, color, onClick,style }) => {
  return <BsCalendar3RangeFill size={size} color={color} style={style} onClick={onClick} />;
};
export const ClockIcon = ({ size, color, onClick }) => {
  return <FiClock size={size} color={color} onClick={onClick} />;
};
export const CameraIcon = ({ size, color, onClick }) => {
  return <FiCamera size={size} color={color} onClick={onClick} />;
};
export const ImageIcon = ({ size, color, onClick }) => {
 return <BsFileEarmarkImage  size={size} color={color} onClick={onClick} />;
};
export const DeleteIcon = ({ size, color, onClick }) => {
  return <MdDelete size={size} color={color} onClick={onClick} />;
};
export const CloseIcon = ({ size, color, onClick ,style}) => {
  return <MdClose size={size} style={style} color={color} onClick={onClick} />;
};
export const ShareIcon = ({ size, color, onClick ,style}) => {
  return <MdShare size={size} style={style} color={color} onClick={onClick} />;
};
export const LikeIcon = ({ size, color, onClick ,style}) => {
  return <BsHeart size={size} style={style} color={color} onClick={onClick} />;
};
export const BackIcon = ({ size, color, onClick ,style}) => {
  return <MdArrowBackIos size={size} style={style} color={color} onClick={onClick} />;
};
export const MenuIcon = ({ size, color, onClick ,style}) => {
  return <FaBars size={size} style={style} color={color} onClick={onClick} />;
};
export const OrganizerIcon = ({ size, color, onClick ,style}) => {
  return <GrUserManager size={size} style={style} color={color} onClick={onClick}  />;
};
export const WandIcon = ({ size, color, onClick ,style}) => {
  return <ImMagicWand size={size} style={style} color={color} onClick={onClick}  />;
};
export const WatchlistIcon = ({ size, color, onClick ,style}) => {
  return <BsFillBookmarkFill size={size} style={style} color={color} onClick={onClick}  />;
};
export const IosArrowIcon = ({ size, color, onClick ,style}) => {
  return <IoIosArrowRoundBack size={size} style={style} color={color} onClick={onClick}  />;
};
export const UserAddIcon = ({ size, color, onClick ,style}) => {
  return <FaUserPlus size={size} style={style} color={color} onClick={onClick}  />;
};
export const EventsIcon = ({ size, color, onClick ,style}) => {
  return <BsCalendar2Check size={size} style={style} color={color} onClick={onClick}  />;
};
export const CheckIcon = ({ size, color, onClick ,style}) => {
  return <BiCheckCircle size={size} style={style} color={color} onClick={onClick}  />;
};
export const ErrorIcon = ({ size, color, onClick ,style}) => {
  return <BiErrorCircle size={size} style={style} color={color} onClick={onClick}  />;
};
export const UserAltIcon = ({ size, color, onClick ,style}) => {
  return <FaUserAlt size={size} style={style} color={color} onClick={onClick}  />;
};
export const MailIcon = ({ size, color, onClick ,style}) => {
  return < MdMailOutline size={size} style={style} color={color} onClick={onClick}  />;
};
export const PhoneIcon = ({ size, color, onClick ,style}) => {
  return <AiOutlinePhone size={size} style={style} color={color} onClick={onClick}  />;
};


// SOCAIL ICONS
export const FacebookIcon = ({ size, color, onClick ,style}) => {
  return <FaFacebookF size={size} style={style} color={color} onClick={onClick} />;
};
export const WhatsAppIcon = ({ size, color, onClick ,style}) => {
  return <FaWhatsapp size={size} style={style} color={color} onClick={onClick} />;
};
export const InstagramIcon = ({ size, color, onClick ,style}) => {
  return <AiFillInstagram size={size} style={style} color={color} onClick={onClick} />;
};
export const TwiterIcon = ({ size, color, onClick ,style}) => {
  return <FaTwitter size={size} style={style} color={color} onClick={onClick} />;
};
export const LinkedInIcon = ({ size, color, onClick ,style}) => {
  return <FaLinkedinIn size={size} style={style} color={color} onClick={onClick} />;
};

export const NoNetWorkIcon = ({ size, color, onClick ,style}) => {
  return <MdSignalWifiStatusbarConnectedNoInternet4 size={size} style={style} color={color} onClick={onClick} />;
};
export const RevertIcon = ({ size, color, onClick ,style}) => {
  return <GrRevert size={size} style={style} color={color} onClick={onClick} />;
};
export const FingerPrintIcon = ({ size, color, onClick ,style}) => {
  return <IoFingerPrintOutline size={size} style={style} color={color} onClick={onClick} />;
};
export const EyeOpenIcon = ({ size, color, onClick ,style,cursor}) => {
  return <IoIosEye size={size} cursor={cursor} style={style} color={color} onClick={onClick} />;
};
export const EyeCloseIcon = ({ size, color, onClick ,style,cursor}) => {
  return <IoEyeOff size={size} cursor={cursor} style={style} color={color} onClick={onClick} />;
};
