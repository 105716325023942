import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../primaryComponents/image/image";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import no_image from "../../../assets/images/no_user.png";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { useSelector } from "react-redux";
import { dateDateFormatter } from "../../../utilities/functions/formatDate";
import { CONSTANTS } from "../../../utilities/constants";
const MetricsHead = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const startDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.startDate
  );
  const endDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.endDate
  );
  const attendees = useSelector(
    ({ event_details }) => event_details?.data?.event?.attendees
  );
  const { first, second } = dateDateFormatter(startDate, endDate);
  return (
    <>
      <FlexibleDiv direction={"column"}>
        <FlexibleDiv
          margin={"10px 0px"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <FlexibleDiv
            width={"50%"}
            justifyContent={"flex-start"}
            lineHeight={"12px"}
          >
            <HeaderText
              margin={"0px"}
              fontSize={"26px"}
              resFontSize={"18px"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            >
              Event Metrics
            </HeaderText>
          </FlexibleDiv>
          <FlexibleDiv
            width={"50%"}
            justifyContent={"flex-start"}
            gap={"30px"}
            breakPoint={"680px"}
            breakPointJustifyContent={"center"}
          >
            <FlexibleDiv width={"auto"}>
              {attendees?.slice(0,5).map(({ user, id }) => (
                <Image
                  width={"35px"}
                  radius={"50px"}
                  src={
                    user?.profile_image
                      ? `${CONSTANTS.imgbaseurl}${user?.profile_image}`
                      : no_image
                  }
                  style={styles.imgStyles}
                  key={id}
                />
              ))}
            </FlexibleDiv>
            <Text
              margin={"0px"}
              color={textColor}
              fontSize={"13px"}
              resFontSize={"13px"}
            >
              +{attendees?.length}
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv
          justifyContent={"flex-start"}
          nowrap={"nowrap"}
          lineHeight={"12px"}
        >
          <Text
            margin={"0px"}
            color={colorTheme.textColor}
            fontSize={"15px"}
            resFontSize={"13.8px"}
          >
            From {first?.formattedDate} to {second?.formattedDate}{" "}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default MetricsHead;

const styles = {
  imgStyles: {
    position: "relative",
    margin: "0px -6px",
    left: "2px",
    zIndex: "1",
    objectFit: "cover"
  }
};
