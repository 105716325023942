import React from "react";
import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { TagBlob } from "../../eventDetails.styles";
import EventImage from "./eventImage";
import Skeleton from "react-loading-skeleton";
import { removeHtmlTags } from "../../../../utilities/functions/removeHtmlTags";
import ExtraImages from "./extraImages";

const AboutEvent = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const description = useSelector(
    ({ event_details }) => event_details?.data?.event?.description
  );
  const tags = useSelector(
    ({ event_details }) => event_details?.data?.event?.tags
  );
  const cover_image = useSelector(
    ({ event_details }) => event_details?.data?.event?.cover_image
  );
  const extra_images = useSelector(
    ({ event_details }) => event_details?.data?.event?.extra_images
  );
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const eventTags =  tags ? JSON.parse(tags) : null
  return (
    <>
      <FlexibleDiv width={"80%"} breakPoint={"790px"} breakPointwidth={"100%"} breakPointPadding={'0px 25px'} >
        {/* Details  */}

        {/* <FlexibleDiv justifyContent={"flex-start"}>
          <Text
            fontSize={fontSizes.mobile_details}
            color={darkMode ? colorTheme.white.white :colorTheme.textColor}
            fontWeight={"500"}
            lineHeight={"1.7rem"}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </Text>
        </FlexibleDiv> */}

        {/* About */}

        <FlexibleDiv direction={"column"} alignItems={"flex-start"}>
          <FlexibleDiv justifyContent={"flex-start"} padding={"8px 0px"}>
            <Text
              fontSize={fontSizes.mobile_subtitle}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              fontWeight={"800"}
            >
            About Event
            </Text>
          </FlexibleDiv>

          {/* Info */}

          <FlexibleDiv
            justifyContent={"flex-start"}
            top={"-30px"}
            alignItems={!loading ? "flex-start" : "center"}
            position={"relative"}
            direction={!loading ? "column" : "row"}
          >
            {loading ? (
              <Skeleton
                width={500}
                height={23}
                style={{ marginTop: "34px", left: 0, position: "relative" }}
              />
            ) : (
              // <Text
              //   fontSize={fontSizes.mobile_details}
              //   color={darkMode ? colorTheme.white.white : colorTheme.textColor}
              //   fontWeight={"500"}
              //   lineHeight={"1.8rem"}
              // >
              //   {removeHtmlTags(description)}
              // </Text>
              <div style={{
                fontSize:fontSizes.mobile_details,
                color:darkMode ? colorTheme.white.white : colorTheme.textColor,
                fontWeight:"500",
                lineHeight:"1.8rem"
              }} dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </FlexibleDiv>

          {/* Tags */}
          {!loading && (
            <>
              {tags !== null && eventTags?.length > 0 && (
                <FlexibleDiv direction={"column"} padding={"20px 0px"}>
                  <FlexibleDiv
                    justifyContent={"flex-start"}
                    padding={"8px 0px"}
                  >
                    <Text
                      fontSize={fontSizes.mobile_subtitle}
                      color={
                        darkMode
                          ? colorTheme.white.white
                          : colorTheme.black.strong
                      }
                      fontWeight={"800"}
                    >
                      Tags
                    </Text>
                  </FlexibleDiv>
                  <FlexibleDiv justifyContent={"flex-start"} gap={"22px"}>
                    {eventTags?.map((item) => (
                      <TagBlob key={item}>
                        <Text
                          fontSize={"x-small"}
                          fontWeight={"600"}
                          resFontSize={"x-small"}
                          color={
                            darkMode
                              ? colorTheme.white.white
                              : colorTheme.black.strong
                          }
                        >
                         {item}
                        </Text>
                      </TagBlob>
                    ))}
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </>
          )}
          {/* Event Image */}

          <FlexibleDiv direction={"column"}>
            <FlexibleDiv justifyContent={"flex-start"} padding={"8px 0px"}>
              <Text
                fontSize={fontSizes.mobile_subtitle}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
                fontWeight={"800"}
              >
                Event Photos
              </Text>
            </FlexibleDiv>
            <FlexibleDiv justifyContent={"flex-start"}>
              <EventImage height={"300px"} width={"80%"} src={cover_image} />
            </FlexibleDiv>
            {extra_images !== "[]" && <ExtraImages extraImages={[0, 1, 2]} />}
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default AboutEvent;
