import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import SaveEdit from "../editProfile/saveEdit.component";
import ConfirmPassword from "./confirmNewPassword.component";
import CurrentPassword from "./currentPassword.component";
import NewPassword from "./newPassword.component";
import { useFormik } from "formik";
import { changePasswordSchema } from "../../../infrastructure/schemas/change_password";
import { useEffect } from "react";
import {
  editProfilePassword,
  start_edit_handler
} from "../../../state management/Redux/slices/user_slice/edit_profile_slice";

const PasswordSetting = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values) => {
      dispatch(
        editProfilePassword({
          token: token,
          password: values?.password,
          previous_password: values?.currentPassword,
          password_confirmation: values?.confirmPassword
        })
      );
    }
  });
  useEffect(() => {
    if (formik.values.confirmPassword === formik.values.password) {
      dispatch(start_edit_handler(true));
    }
  }, [dispatch, formik.values.confirmPassword]);
  return (
    <FlexibleDiv direction={"column"} padding={"40px 0px"} breakPoint={"670px"} breakPointPadding={'40px 23px'} >
      <FlexibleDiv
        alignItems={"flex-start"}
        direction={"column"}
        lineHeight={"1.9rem"}
      >
        <Text
          margin={"0px"}
          fontWeight={"600"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          Password
        </Text>
        <Text
          margin={"0px"}
          fontSize={"14px"}
          resFontSize={"13px"}
          color={colorTheme.textColor}
          fontWeight={"500"}
        >
          Please enter your current password to change your password.
        </Text>
      </FlexibleDiv>

      <MyForm formik={formik} />
      <FlexibleDiv margin={"40px 0px"}>
        <SaveEdit formik={formik} />
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default PasswordSetting;

const MyForm = ({ formik }) => {
  return (
    <form style={{ width: "100%"}} onSubmit={formik?.handleSubmit}>
      <CurrentPassword
        onChange={formik?.handleChange}
        value={formik?.values?.currentPassword}
        error={formik?.errors?.currentPassword}
        name="currentPassword"
        touched={formik?.touched?.currentPassword}
      />
      <NewPassword
        onChange={formik?.handleChange}
        value={formik?.values?.password}
        error={formik?.errors?.password}
        name="password"
        touched={formik?.touched?.password}
      />
      <ConfirmPassword
        onChange={formik?.handleChange}
        name="confirmPassword"
        value={formik?.values?.confirmPassword}
        error={formik?.errors?.confirmPassword}
        touched={formik?.touched?.confirmPassword}
      />
    </form>
  );
};

// export default MyForm;
