import axios from "axios";
import { CONSTANTS } from "../../../../utilities/constants";
import { toast } from "react-toastify";
import { ImageToFile } from "../../../../utilities/functions/imageToFile";
import { v4 as uuidv4 } from "uuid";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const editEventHandler = createAsyncThunk(
  "user/edit_event",
  async (
    {
      title,
      description,
      startDate,
      location,
      endDate,
      cover_image,
      category,
      visibility,
      id,
      token,
      tags,
      type
    },
    { rejectWithValue }
  ) => {
    try {
      const formattedImage = ImageToFile(
        `${CONSTANTS.imgbaseurl}${cover_image}`
      );
      // function createImageFile(fileInfo) {
      //   const {
      //     lastModified,
      //     lastModifiedDate,
      //     name,
      //     size,
      //     type,
      //     webkitRelativePath
      //   } = fileInfo;

      //   const blob = new Blob([], { type });
      //   const imageFile = new File([blob], name, {
      //     lastModified: lastModified || new Date(),
      //     lastModifiedDate: lastModifiedDate
      //       ? new Date(lastModifiedDate)
      //       : new Date(),
      //     type,
      //     size,
      //     webkitRelativePath
      //   });

      //   return imageFile;
      // }

      const formData = new FormData();
      if (cover_image !== null) {
        // const file = createImageFile(JSON.parse(cover_image));
        formData.append("cover_image", cover_image);
      }

      formData.append("title", title);
      formData.append("description", description);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);
      // if (cover_image !== null) {
      //   formData.append("cover_image", file);
      // }
      formData.append("visibility", visibility);
      formData.append("type", type);
      formData.append("categories", null);
      formData.append("tags", tags);
      formData.append("location", location);
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.post(
        CONSTANTS.baseURL + `api/event/edit/${id}`,
        formData,
        config
      );

      if (response.status === 200) {
        toast.success(response.data?.success);
        setInterval(() => {
          window.location.reload();
        }, [2000]);
      }
    } catch (error) {
      console.log("Edit event error", error);
      return rejectWithValue(error?.data?.error);
    }
  }
);

const initialState = {
  loading: false,
  isSuccess: false,
  isError: false,
  error: null,
  start_editing: false,
  title: "",
  description: "",
  startDate: "",
  endDate: "",
  cover_image: null,
  location: "",
  visibility: "",
  categories: null,
  type: "",
  tickets: [],
  tags: null
};

const Edit_EventSlice = createSlice({
  name: "edit_event_slice",
  initialState,
  reducers: {
    titleHandler: (state, { payload }) => {
      state.title = payload;
      state.start_editing = true;
    },
    descriptionHandler: (state, { payload }) => {
      state.description = payload;
      state.start_editing = true;
    },
    startDateHandler: (state, { payload }) => {
      state.startDate = payload;
      state.start_editing = true;
    },
    endDateHandler: (state, { payload }) => {
      state.endDate = payload;
      state.start_editing = true;
    },
    cover_imageHandler: (state, { payload }) => {
      state.cover_image = payload;
      state.start_editing = true;
    },
    visibilityHandler: (state, { payload }) => {
      state.visibility = payload;
      state.start_editing = true;
    },
    categoryHandler: (state, { payload }) => {
      state.categories = payload;
      state.start_editing = true;
    },
    typeHandler: (state, { payload }) => {
      state.type = payload;
      state.start_editing = true;
    },
    tagsHandler: (state, { payload }) => {
      state.tags = payload;
      state.start_editing = true;
    },
    locationHandler: (state, { payload }) => {
      state.location = payload;
      state.start_editing = true;
    },
    addTicket: (state, action) => {
      // state.tickets.push(action.payload);

      const newTicket = {
        id: uuidv4(), // Generate a unique ID
        ...action.payload
      };
      state.tickets.push(newTicket);
    },
    deleteTicketHandler: (state, action) => {
      state.tickets = state.tickets.filter(
        (ticket) => ticket.id !== action.payload
      );
    },
    editTicketHandler: (state, action) => {
      const { id, updatedTicket } = action.payload;
      const index = state.tickets.findIndex((ticket) => ticket.id === id);
      if (index !== -1) {
        state.tickets[index] = {
          ...state.tickets[index],
          ...updatedTicket
        };
      }
    },
    setTickets: (state, action) => {
      state.tickets = action.payload;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(editEventHandler.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(editEventHandler.fulfilled, (state) => {
        state.loading = false;
        state.isSuccess = true;
      })
      .addCase(editEventHandler.rejected, (state) => {
        state.loading = false;
        state.isError = true;
      });
  }
});

export const {
  titleHandler,
  descriptionHandler,
  startDateHandler,
  cover_imageHandler,
  visibilityHandler,
  endDateHandler,
  typeHandler,
  categoryHandler,
  tagsHandler,
  addTicket,
  deleteTicketHandler,
  setTickets,
  editTicketHandler,
  locationHandler
} = Edit_EventSlice.actions;

export default Edit_EventSlice.reducer;
