import React from "react";
import styled from "styled-components";


const InputStyles = styled.input`
  width: ${({ width }) => (width ? width : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  box-shadow: ${({ shadow }) => (shadow ? shadow : "")};
  background: ${({ bg }) => (bg ? bg : "none")};
  font-size:${({fontSize})=>fontSize ? fontSize : '0.8rem'} ;
  border: ${({ border }) => (border ? border : "none")};
  border-radius: ${({ radius }) => (radius ? radius : "")};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : "")};
  color: ${({color})=>color ? color : '#000'};
  outline: ${({ outline }) => (outline ? outline : "none")};
  ::placeholder {
    color: #b3b3b3;
    font-weight: 500;
    font-size: ${({placeholderSize})=>placeholderSize ? placeholderSize :''};
  }
`;
const Input = ({
  className,
  style,
  id,
  width,
  padding,
  shadow,
  radius,
  borderBottom,
  bg,
  color,
  onChange,
  onClick,
  onBlur,
  placeHolder,
  placeholderSize,
  border,
  outline,
  required,
  fontSize,
  value,
  type,
  disabled,
  checked,
  readonly ,
  minLength,
  maxLength,
  defaultValue,
  name,
  security
}) => {
  return (
    <InputStyles
    name={name}
    className={className}
    style={style}
    color={color}
    onBlur={onBlur}
    id={id}
    defaultValue={defaultValue}
      width={width}
      padding={padding}
      shadow={shadow}
      radius={radius}
      outline={outline}
      border={border}
      minLength={minLength}
      maxLength={maxLength}
      bg={bg}
      placeholder={placeHolder}
      placeholderSize={placeholderSize}
      required={required}
      onChange={onChange}
      value={value}
      type={type}
      onClick={ onClick}
      disabled={disabled}
      checked={checked}
      fontSize={fontSize}
      borderBottom={borderBottom}
      readOnly={readonly }
    />
  );
};

export default Input;



export const TextArea = styled.textarea`
  width: ${({ width }) => (width ? width : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  box-shadow: ${({ shadow }) => (shadow ? shadow : "")};
  background: ${({ bg }) => (bg ? bg : "none")};
  color: ${({color})=>color ? color : '#000'};
  font-size: 0.8rem;
  border-radius: ${({ radius }) => (radius ? radius : "")};
  border: ${({ border }) => (border ? border : "none")};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : "none")};
  outline: ${({ outline }) => (outline ? outline : "none")};
  ::placeholder {
    color: #b3b3b3;
    font-weight: 500;
  }
`