import styled from "styled-components";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { fontSizes } from "../../infrastructure/Theme/fontSize";

export const AuthLayout = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background:${({bg})=>bg ? bg : ""};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
`;

export const InputFieldColumn = styled.div`
  width: 60%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 1105px) {
    padding: 25px 0px;
    width: 100%;
    height: 100%;
  }
`;

export const ImageColumn = styled.div`
  width: 40%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: ${({justifyContent})=>justifyContent ? justifyContent :"flex-end"};
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(${({ image }) => (image ? image : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 1105px) {
    display: none;
  }
`;

export const LogoDiv = styled.div`
  position: absolute;
  top: 0px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: ${({ margin }) => (margin ? margin : "")};
  @media screen and (max-width: 680px) {
    width: 90%;
  }
`;
export const InputContainer = styled(FlexibleDiv)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;

export const Label = styled.label`
  font-size: ${({labelSize})=>labelSize ? labelSize : fontSizes.mobile_details};
  font-weight: 600;
  color:${({color})=>color ? color : colorTheme.black.strong}
`;

export const PoweredBy = styled.div`
  position: absolute;
  bottom: 0px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;




export const IndicatorOuterCirlce= styled.div`
width: 10px;
height: 10px;
border-radius: 50%;
padding: 12px;
background-color: ${({bg})=>bg ? bg :colorTheme.primary_color};
display: flex;
align-items: center;
justify-content: center;
position: relative;
border: ${({border})=>border ? border :'none'};
`

export const IndicatorInnerCircle = styled.div`
width: 3px;
height: 3px;
border-radius: 50%;
padding: 2px;
background-color: ${({bg})=>bg ? bg :'white'};
position: relative;
`