import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import FaqQuestion from "./faqQuestion.component";
import { questions } from "./questions";

const FAQ = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv direction={"column"}>
        <FlexibleDiv direction={"column"} alignItems={"flex-start"}>
          <HeaderText
            margin={"0px"}
            fontSize={fontSizes.desktop_subtitle}
            lineHeight={"34.73px"}
            resFontSize={"16px"}
            fontWeight={"600"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Frequently Asked Questions
          </HeaderText>
          <Text
            fontSize={"16px"}
            resFontSize={"13px"}
            lineHeight={"21.78px"}
            color="#A1A1A1"
            fontWeight={"500"}
          >
            Have questions?We’re here to help.
          </Text>
        </FlexibleDiv>

        <FlexibleDiv direction={"column"} alignItems={"flex-start"}>
          {questions?.map(({ question, answer }) => (
            <FaqQuestion answer={answer} question={question} key={question} />
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default FAQ;
