import { useRef } from "react";
import { useState, useEffect } from "react";
import {
  RiArrowDropDownLine as DropDownIcon,
  RiArrowDropUpLine as DropUpIcon,
} from "react-icons/ri";
import {
  DropDownWrapper,
  Option,
  OptionsContaniner,
  ValueContainer,
} from "./dropDown.styles";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../primaryComponents/input/input.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
const DropDown = ({
  components,
  label,
  labelSize,
  width,
  padding,
  setTypeOption,
  border,
  reduxControlled,
  action,
  value,
  array_of_values,
  reduxValue,
  icon,
  formikhandleChange,
  formikValue,
}) => {
  const dispatch = useDispatch();
  // REFS AND STATES
  const ref = useRef();
  const [toggle, setToggle] = useState(false);
  const [, setRemoveText] = useState(false);
  const [dropDownValue, setDropDownValue] = useState(label || components[0]);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  //   FUNCTIONS
  const ValueHandler = (e) => {
    // THIS FUNCTION HANDLES THE VALUE OF THE DROP DOWN
    const newValue = typeof e === "object" ? e.target.innerHTML : e;
    const mappedList = components?.find((item) => {
      return item[1] === newValue;
    });
    setDropDownValue(mappedList ? mappedList[0] : newValue);
    setToggle(false);
    setRemoveText(true);
    if (reduxControlled) {
      dispatch(action(newValue));
      formikhandleChange(formikValue)(e.toString());
    } else {
      // setTypeOption(newValue);
      formikhandleChange(formikValue)(newValue?.toString());
    }
  };

  useEffect(() => {
    let handler = (event) => {
      if (!ref.current.contains(event.target)) {
        setToggle(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <div style={{ width: width ? width : "100%" }} ref={ref}>
        <DropDownWrapper
          border={toggle ? "none" : border}
          borderTop={toggle ? "1px solid lightgrey" : ""}
          borderRight={toggle ? "1px solid lightgrey" : ""}
          borderLeft={toggle ? "1px solid lightgrey" : ""}
          bottomLeftRd={toggle ? "0px" : ""}
          bottomRightRd={toggle ? "0px" : ""}
          width={"100%"}
          bg={darkMode ? colorTheme.black.fadingBlack : "#FFF"}
          padding={padding}
        >
          <ValueContainer
            paddingVertical={"10px"}
            padding={icon ? "0px 1px 0px 8px " : "0px 1px 0px 15px"}
            gap={icon ? "5px" : null}
          >
            {icon ? icon : null}
            {reduxControlled ? (
              <Input
                readonly
                fontSize={labelSize}
                color={darkMode ? "#FFF" : "#000"}
                value={dropDownValue}
                onChange={(e) => {
                  ValueHandler(e);
                }}
              />
            ) : (
              <Input
                width={"93%"}
                color={darkMode ? "#FFF" : "#000"}
                readonly
                value={dropDownValue}
                fontSize={labelSize}
                onChange={(e) => {
                  ValueHandler(e);
                }}
              />
            )}

            {!toggle ? (
              <DropDownIcon
                color={darkMode ? "#fff" : "#000"}
                size={30}
                cursor={"pointer"}
                onClick={() => {
                  setToggle(true);
                }}
              />
            ) : (
              <DropUpIcon
                color={darkMode ? "#fff" : "#000"}
                size={30}
                cursor={"pointer"}
                onClick={() => {
                  setToggle(false);
                }}
              />
            )}
          </ValueContainer>
          <OptionsContaniner
            display={toggle ? "block" : "none"}
            borderBottom={toggle ? "1px solid lightgrey" : ""}
            borderRight={toggle ? "1px solid lightgrey" : ""}
            borderLeft={toggle ? "1px solid lightgrey" : ""}
            bottomLeftRd={toggle ? "5px" : ""}
            bottomRightRd={toggle ? "5px" : ""}
            bg={darkMode ? colorTheme.black.fadingBlack : "#FFF"}
          >
            {!label ? null : (
              <Option
                background={"#f7f7f7"}
                onClick={(e) => {
                  ValueHandler(e);
                }}
              >
                {label}
              </Option>
            )}
            {components?.map((item, index) => {
              return (
                <Option
                  onClick={(e) => {
                    ValueHandler(typeof item !== "string" ? item[1] : e);
                  }}
                  color={darkMode ? "#FFF" : colorTheme.black.strong}
                  hoverColor={darkMode ? colorTheme.black.strong : "#000"}
                  key={Math.random()}
                >
                  {typeof item !== "string" ? item[0] : item}
                </Option>
              );
            })}
          </OptionsContaniner>
        </DropDownWrapper>
      </div>
    </>
  );
};

export default DropDown;
