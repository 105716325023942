import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import AnalyticsHead from "../../../components/secondaryComponents/eventAnalytics/analyticsHead";
import EventMetrics from "../../../components/secondaryComponents/eventAnalytics/eventMetrics.component";
import EventTransaction from "../../../components/secondaryComponents/eventAnalytics/eventTransaction.component";


const Analytics = () => {
    return ( 
        <>
        <FlexibleDiv>
            <AnalyticsHead/>
            <EventMetrics/>
            <EventTransaction/>
        </FlexibleDiv>
        </>
     );
}
 
export default Analytics;