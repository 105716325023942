import { colorTheme } from "../../../infrastructure/Theme/color";
import { CalendarIcon } from "../../primaryComponents/icons/icons";
import { Text } from "../../primaryComponents/typography/typography.component";
import { ExtractDateTime } from "../../../utilities/functions/dateExtractor";

const CardTime = ({ startDate }) => {
  const { time, day, dayOfMonth, month } = ExtractDateTime(startDate);
  return (
    <>
      <Text
        resFontSize={"13px"}
        lineHeight={"23px"}
        fontSize={"13px"}
        fontWeight={"500"}
        color={colorTheme.white.fadingWhite}
        style={{ margin: "9px 0px 0px 0px" }}
      >
        <CalendarIcon color={colorTheme.black.soft} /> {day}, {month}{" "}
        {dayOfMonth}, {time}
      </Text>
    </>
  );
};

export default CardTime;
