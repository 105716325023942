import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  MailIcon,
  PhoneIcon
} from "../../../components/primaryComponents/icons/icons";
import { Image } from "../../../components/primaryComponents/image/image";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import no_image from "../../../assets/images/no_user.png";
import { LeftSide, RightSide } from "../styles";
import About from "../components/about";
// import { AccountUserType } from "..";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../utilities/constants";
import { useEffect, useState } from "react";
import { calculateDaysLeft } from "../../../services/up_coming_events";
import { dateDateFormatter } from "../../../utilities/functions/formatDate";
import Title from "../components/title.component";

const ProfileSection = ({ searchedAccount, data }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const organizers = useSelector(
    ({ organizers_following }) => organizers_following?.data
  );
  const upcomingEvents = useSelector(
    ({ user_ticket }) => user_ticket?.users_tickets?.upcoming_events
  );
  const userStatus = data?.[0]?.userStatus;
  const email = data?.[0]?.email;
  const phoneNumber = data?.[0]?.phone;
  const bio = data?.[0]?.bio;
  return (
    <>
      <FlexibleDiv
        padding={"20px 0px"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        {userStatus === 1 || upcomingEvents?.length > 0 ? (
          <FlexibleDiv
            width={"65%"}
            resPoint={"780px"}
            reswidth={"100%"}
            breakPoint={"1024px"}
            breakPointwidth={"100%"}
          >
            <FlexibleDiv
              margin={"0px 0px 30px 0px"}
              padding={"13px 23px"}
              bg={
                darkMode ? colorTheme.black.fadingBlack : colorTheme.white.white
              }
              shadow={"0px 0px 18px rgba(0, 0, 0, 0.1)"}
            >
              <About
                bio={bio}
                showBorderBottom={upcomingEvents?.length > 0 ? true : false}
                display={
                  searchedAccount
                    ? "flex"
                    : "none" && userStatus === 0
                    ? "none"
                    : "flex"
                }
              />
              {upcomingEvents?.length > 0 && (
                <FlexibleDiv
                  direction={"column"}
                  padding={userStatus === 0 ? "none" : "50px 0px"}
                >
                  <Title
                    title={"Up Coming Events"}
                    color={darkMode ? colorTheme.white.white : "#000"}
                    size={"15px"}
                  />

                  <FlexibleDiv direction={"column"}>
                    {upcomingEvents.map(({ title, startDate }) => (
                      <UPCOMING_EVENT_CARD
                        title={title}
                        startDate={startDate}
                        key={Math.random() * 100}
                      />
                    ))}
                  </FlexibleDiv>
                </FlexibleDiv>
              )}
            </FlexibleDiv>
          </FlexibleDiv>
        ) : null}
        {/* Details */}
        <FlexibleDiv
          direction={"column"}
          width={"35%"}
          resPoint={"780px"}
          reswidth={"100%"}
          breakPoint={"1024px"}
          breakPointwidth={"100%"}
        >
          <FlexibleDiv
            width={"100%"}
            margin={"0px 0px 30px 0px"}
            padding={" 15px 7px"}
            resPoint={"780px"}
            reswidth={"100%"}
            breakPoint={"1024px"}
            breakPointwidth={"100%"}
            bg={colorTheme.black.fadingBlack}
          >
            <FlexibleDiv padding={"0px 30px"}>
              <Title
                title={"Contact & Personal Info"}
                color={darkMode ? colorTheme.white.white : "#000"}
                size={"15px"}
              />
            </FlexibleDiv>
            {/* Email */}
            <Email email={email} />
            <MobilePhone phoneNumber={phoneNumber} />
          </FlexibleDiv>
          {organizers?.length > 0 && <Following />}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default ProfileSection;

const Email = ({ email }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv
      padding={"0px 30px 20px 30px"}
      lineHeight={"1.5rem"}
      breakPoint={"780px"}
      breakPointPadding={"30px 30px 20px 30px"}
      resPoint={"680px"}
      resPadding={"20px 15px 20px 15px"}
    >
      <FlexibleDiv justifyContent={"flex-start"} gap={"7px"}>
        <MailIcon
          size={19}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
        <Text
          margin={"0px"}
          fontSize={"15px"}
          fontWeight={"700"}
          resFontSize={"14px"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          Email:
        </Text>
      </FlexibleDiv>
      <FlexibleDiv justifyContent={"flex-start"} gap={"7px"}>
        <Text
          margin={"0px 0px 0px 22px"}
          fontSize={fontSizes.mobile_details}
          color={"skyblue"}
          fontWeight={"500"}
          resFontSize={"14px"}
        >
          {email}
        </Text>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};
const MobilePhone = ({ phoneNumber }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );

  return (
    <FlexibleDiv
      padding={"0px 30px 50px 30px"}
      lineHeight={"1.5rem"}
      breakPoint={"780px"}
      breakPointPadding={"30px 30px 20px 30px"}
      resPoint={"680px"}
      resPadding={"20px 15px 20px 15px"}
    >
      <FlexibleDiv justifyContent={"flex-start"} gap={"7px"}>
        <PhoneIcon
          size={19}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
        <Text
          margin={"0px"}
          fontSize={"15px"}
          fontWeight={"700"}
          resFontSize={"14px"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          Mobile Number:
        </Text>
      </FlexibleDiv>
      <FlexibleDiv justifyContent={"flex-start"} gap={"7px"}>
        <Text
          margin={"0px 0px 0px 22px"}
          fontSize={fontSizes.mobile_details}
          color={"skyblue"}
          fontWeight={"500"}
          resFontSize={"14px"}
        >
          {phoneNumber}
        </Text>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export const Following = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const organizers = useSelector(
    ({ organizers_following }) => organizers_following?.data
  );
  const loading = useSelector(
    ({ organizers_following }) => organizers_following?.loading
  );
  return (
    <FlexibleDiv
      direction={"column"}
      padding={"0px 30px"}
      breakPoint={"680px"}
      breakPointPadding={"0px 15px"}
    >
      {/* Top */}
      <FlexibleDiv justifyContent={"flex-start"}>
        <FlexibleDiv width="50%" justifyContent={"flex-start"}>
          <Text
            margin={"0px"}
            fontSize={"15px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            fontWeight={"700"}
            resFontSize={"16px"}
          >
            Subscribed
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>

      {/* List of following */}
      {organizers.map(
        ({ username, id, firstname, lastname, profile_image }) => (
          <Followers
            profile_image={profile_image}
            name={`${firstname} ${lastname}`}
            key={id}
            username={username}
          />
        )
      )}
    </FlexibleDiv>
  );
};

export const Followers = ({ name, profile_image, id, username }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv
      padding={"20px 0px"}
      justifyContent={"flex-start"}
      gap={"22px"}
      breakPoint={"680px"}
      breakPointgap={"12px"}
    >
      {/* Image */}
      <FlexibleDiv width={"10%"} justifyContent={"flex-start"}>
        <Image
          width={"40px"}
          height={"40px"}
          radius={"50%"}
          src={
            profile_image
              ? `${CONSTANTS.profileImageUrl}${profile_image}`
              : no_image
          }
        />
      </FlexibleDiv>
      {/* Name */}
      <FlexibleDiv width={"80%"} direction={"column"} alignItems={"flex-start"}>
        <Text
          margin={"0px"}
          fontSize={fontSizes.mobile_details}
          fontWeight={"700"}
          resFontSize={"12px"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          {name}
        </Text>
        <Text
          margin={"0px"}
          fontSize={fontSizes.mobile_smallest}
          color={colorTheme.textColor}
          fontWeight={"500"}
          resFontSize={"12px"}
        >
          @{username}
        </Text>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

const UPCOMING_EVENT_CARD = ({ startDate, title }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const daysLeft = calculateDaysLeft(startDate);
  const {
    formattedDate,
    formattedTime,
    formattedStartDay,
    formattedStartMonth
  } = dateDateFormatter(startDate).first;

  return (
    <FlexibleDiv
      direction={"row"}
      justifyContent={"flex-start"}
      wrap={"no-wrap"}
      padding={"12px 0px"}
    >
      <LeftSide />
      <RightSide
        bg={darkMode ? colorTheme.black.fadeMini : colorTheme.black.light}
      >
        {/* Date */}
        <FlexibleDiv width={"20%"} direction={"column"} lineHeight={"1.5rem"}>
          <Text
            margin={"0px"}
            fontWeight={"600"}
            color={colorTheme.textColor}
            fontSize={fontSizes.mobile_details}
            resFontSize={"12px"}
          >
            {formattedStartMonth}
          </Text>
          <Text
            margin={"0px"}
            fontWeight={"600"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            fontSize={fontSizes.desktop_body_text}
            resFontSize={"15px"}
          >
            {formattedStartDay}
          </Text>
        </FlexibleDiv>
        {/* Location */}
        <FlexibleDiv
          width={"50%"}
          direction={"column"}
          lineHeight={"1.5rem"}
          alignItems={"flex-start"}
        >
          <Text
            margin={"0px"}
            fontWeight={"600"}
            color={darkMode ? colorTheme.white.white : colorTheme.grey}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"13px"}
          >
            {title}
          </Text>
          <Text
            margin={"0px"}
            fontWeight={"500"}
            color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
            fontSize={fontSizes.mobile_smallest}
            resFontSize={"11px"}
          >
            {formattedDate} , {formattedTime}
          </Text>
        </FlexibleDiv>
        {/* Time Left */}
        <FlexibleDiv width={"30%"} direction={"column"}>
          <Text
            margin={"0px"}
            fontWeight={"600"}
            color={colorTheme.textColor}
            fontSize={fontSizes.mobile_smallest}
            resFontSize={"9.9px"}
          >
            {daysLeft}
          </Text>
        </FlexibleDiv>
      </RightSide>
    </FlexibleDiv>
  );
};
