import Skeleton from "react-loading-skeleton";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { LocationIcon } from "../../primaryComponents/icons/icons";
import { Text } from "../../primaryComponents/typography/typography.component";

const CardLocation = ({ location, loading }) => {
  return (
    <>
   
        <Text
          fontSize={"13px !important"}
          fontWeight={"500"}
          color={colorTheme.black.soft}
          style={{ margin: "5px 0px 0px 0px" }}
        >
          <LocationIcon /> {location}
        </Text>
   
    </>
  );
};

export default CardLocation;
