import { createSlice } from "@reduxjs/toolkit";



const ThemeSlice = createSlice({
    name:'theme',
    initialState:{
        darkmode:true
    },
    reducers:{
        toggleThemeHandler(state){
            state.darkmode = !state.darkmode
        }
    }
})

export const {toggleThemeHandler} = ThemeSlice.actions
export default ThemeSlice.reducer