import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
// import DeleteAccount from "../deleteAccount.component";
import LeftColumn from "./leftColumn.component";
import RightColumn from "./rightSideColumn.component";

const ColumnSection = ({ formik }) => {
  return (
    <FlexibleDiv margin={"40px 0px"} alignItems={"baseline"} breakPoint={'670px'} breakPointPadding={'40px 20px'} >
      <LeftColumn formik={formik} />
      <RightColumn formik={formik} />
      <FlexibleDiv
        display={"none"}
        breakPoint={"780px"}
        breakPointDisplay={"flex"}
      >
        {/* <DeleteAccount/> */}
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default ColumnSection;
