import { useState } from "react";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import TabMenu from "./tabMenu.component";
import { TabSwitch } from "./tabSwitch";

const TabView = ({ searchedAccount }) => {
    const [tabName, setTabName] = useState("Edit Profile");
    return (
      <>
        <FlexibleDiv padding={"40px 0px"}>
          <TabMenu
            tabName={tabName}
            setTabName={setTabName}
          />
  
          {TabSwitch(tabName)}
        </FlexibleDiv>
      </>
    );
  };
  
  export default TabView;