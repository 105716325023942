import React, { useEffect, useState } from "react";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { useSelector, useDispatch } from "react-redux";
import { colorTheme } from "../../infrastructure/Theme/color";
import {
  HeaderText,
  Text
} from "../../components/primaryComponents/typography/typography.component";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Image } from "../../components/primaryComponents/image/image";
import { CONSTANTS } from "../../utilities/constants";
import { dateDateFormatter } from "../../utilities/functions/formatDate";
import Ticket_Order_Summary from "../../components/secondaryComponents/tickets_page/order_summary.component";
import Order_Ticket from "../../components/secondaryComponents/tickets_page/order_ticket.component";
import PoweredBy from "../../components/secondaryComponents/tickets_page/powereBy.component";
import Ticket_Checkout from "../../components/secondaryComponents/tickets_page/ticket_checkout.component";
import { selectTicketHandler } from "../../state management/Redux/slices/payment_slice/buy_ticket_slice";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";
import "./style.css";
import {
  CloseIcon,
  DropDownIcon,
  DropUpIcon
} from "../../components/primaryComponents/icons/icons";
const Tickets_Page = ({ show, setShowModal }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const title = useSelector(
    ({ event_details }) => event_details.data?.event?.title
  );
  const cover_image = useSelector(
    ({ event_details }) => event_details.data?.event?.cover_image
  );
  const endDate = useSelector(
    ({ event_details }) => event_details.data?.event?.endDate
  );
  const startDate = useSelector(
    ({ event_details }) => event_details.data?.event?.startDate
  );
  const tickets = useSelector(
    ({ event_details }) => event_details.data?.event?.tickets
  );
  const selected_ticket = useSelector(
    ({ buy_ticket }) => buy_ticket.selected_ticket
  );
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const timeRange = dateDateFormatter(startDate, endDate).timeRange;
  const { formattedDate, dayOfWeek } = dateDateFormatter(startDate).first;
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  useEffect(() => {
    return () => {
      if (!show) {
        dispatch(selectTicketHandler(null));
      }
    };
  }, [show]);


  console.log("tickets",tickets)
  return (
    <>
      <PureModal
        width="100%"
        scrollable={true}
        isOpen={show}
        closeButton={<CloseIcon size={16} />}
        closeButtonPosition="header"
        onClose={() => {
          setShowModal(false);
          return true;
        }}
        // scroll={true}
        // showExitBtn={true}
        // show={show}
        // setShowModal={setShowModal}
      >
        {/* <FlexibleDiv
          height={"100%"}
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        > */}
        {/* <FlexibleDiv
            bg={darkMode ? colorTheme.black.fadeMini : colorTheme.white.white}
            width={"70%"}
            breakPoint={"780px"}
            breakPointwidth={"100%"}
            alignItems={"flex-start"}
            style={{ alignSelf: "center", overflowY: "hidden" }}
          > */}
        <FlexibleDiv
          padding={"23px 0px"}
          width={"55%"}
          direction={"column"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
        >
          <FlexibleDiv display={"block"}>
            <HeaderText
              color={textColor}
              lineHeight={"23px"}
              fontSize={"16px"}
              resFontSize={"16px"}
              margin={"0px"}
              style={{ textAlign: "center" }}
            >
              {title}
            </HeaderText>
            <Text
              lineHeight={"43px"}
              color={textColor}
              fontSize={"14px"}
              resFontSize={"14px"}
              margin={"0px"}
              style={{ textAlign: "center" }}
            >
              {dayOfWeek}, {formattedDate}, {timeRange}
            </Text>
          </FlexibleDiv>
          <FlexibleDiv
            margin={"0px 0px 23px 0px"}
            padding={tickets?.length > 1 ? "23px 0px" : "0px"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ overflow: "auto", display: "flex" }}
            position={"relative"}
            height={"400px"}
            breakPoint={"780px"}
            breakPointHeight={"300px"}
          >
            {tickets?.map((ticket) => {
              const { id, name, price, capacity, description,color } = ticket;
              return (
                <Order_Ticket
                  key={id}
                  ticket={ticket}
                  name={name}
                  cover_image={cover_image}
                  price={price}
                  capacity={capacity}
                  description={description}
                />
              );
            })}

            <PoweredBy />
          </FlexibleDiv>

          <FlexibleDiv breakPoint={"780px"} breakPointDisplay={"none"}>
            <Ticket_Checkout />
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv
          width={"45%"}
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          style={{ overflow: "auto" }}
          position={"relative"}
          breakPointDisplay={"none"}
          height={"600px"}
        >
          {/* <FlexibleDiv breakPoint={"780px"} breakPointDisplay={"none"}>
            <Image
              width={"100%"}
              height={"230px"}
              src={
                selected_ticket
                  ? `${CONSTANTS.imgbaseurl}${selected_ticket?.cover_image}`
                  : `${CONSTANTS.imgbaseurl}${cover_image}`
              }
            />
          </FlexibleDiv> */}
          <Ticket_Order_Summary />
        </FlexibleDiv>
        {/* <FlexibleDiv
          breakPoint={"780px"}
          breakPointDisplay={"block"}
          display={"none"}
          direction={"column"}
          style={{ overflowY: "hidden" }}
        >
          <DrawerSummary
            toggleDrawer={toggleDrawer}
            setToggleDrawer={setToggleDrawer}
          />
        </FlexibleDiv> */}
        <FlexibleDiv
          direction={"column"}
          position={"relative"}
          zIndex={"999"}
          bg={colorTheme.black.fadeMini}
          breakPoint={"780px"}
          breakPointDisplay={"block"}
          display={"none"}
        >
          <FlexibleDiv
            justifyContent={"flex-end"}
            margin={"0px 0px 12px 0px"}
            alignItems={"center"}
            gap={"15px"}
          >
            {/* {toggleDrawer ? (
              <DropUpIcon
                onClick={() => {
                  setToggleDrawer(false);
                }}
                size={14}
                color={"white"}
              />
            ) : (
              <DropDownIcon
                onClick={() => {
                  setToggleDrawer(true);
                }}
                size={14}
                color={"white"}
              />
            )} */}
            <Text
              color={textColor}
              style={{ paddingRight: "23px" }}
              resFontSize={"15.4px"}
            >
              Ticket Price: N{selected_ticket?.price}
            </Text>
          </FlexibleDiv>
          <Ticket_Checkout />
        </FlexibleDiv>
        {/* </FlexibleDiv> */}
        {/* </FlexibleDiv> */}
      </PureModal>
    </>
  );
};

export default Tickets_Page;

const DrawerSummary = ({ toggleDrawer, setToggleDrawer }) => {
  return (
    <Drawer
      style={{
        width: "100%",
        height: "auto",

        background: colorTheme.black.fadeMini
      }}
      open={toggleDrawer}
      onClose={() => {
        setToggleDrawer((prevState) => !prevState);
      }}
      direction="bottom"
    >
      <Ticket_Order_Summary />
    </Drawer>
  );
};
