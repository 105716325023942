import { BiDotsHorizontalRounded, BiQrScan } from "react-icons/bi";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { ShareIcon } from "../../components/primaryComponents/icons/icons";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { useSelector } from "react-redux";
import Share_Event_Scanner from "./share_event_scanner.component";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ShareEvent = () => {
  const username = useSelector(
    ({ event_details }) => event_details?.data?.organizer?.username
  );
  const authkey = useSelector(
    ({ event_details }) => event_details?.data?.event?.auth_key
  );
  const [showScanner, setShowScanner] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <FlexibleDiv
        justifyContent={"flex-end"}
        gap={"20px"}
        flexBasis={"50%"}
        breakPoint={"880px"}
        breakPointJustifyContent={"flex-start"}
        breakPointflexBasis={"100%"}
      >
        <Text
          fontWeight={"500"}
          color={colorTheme.textColor}
          fontSize={"13px"}
          resFontSize={"13px"}
        >
          @{username}
        </Text>

        <FlexibleDiv
          width={"20px"}
          height={"20px"}
          radius={"100%"}
          border={`.1px solid #C2BABA `}
          padding={"3px"}
        >
          <ShareIcon
            cursor={"pointer"}
            onClick={() => {
              setShowScanner(true);
            }}
            color={colorTheme.textColor}
            size={12}
          />
        </FlexibleDiv>
        <FlexibleDiv
          width={"20px"}
          height={"20px"}
          bg={colorTheme.white.white}
          radius={"100%"}
          // border={`.1px solid ${colorTheme.primary_color} `}
          padding={"3px"}
        >
          <Link
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
            target="_blank"
            to={`${window.location.href}/qr-scanner/${authkey}`}
          >
            <BiQrScan
              cursor={"pointer"}
              onClick={() => {}}
              color={colorTheme.blue}
              size={16}
            />
          </Link>
        </FlexibleDiv>
      </FlexibleDiv>
      <Share_Event_Scanner
        setShowModal={setShowScanner}
        showModal={showScanner}
      />
    </>
  );
};

export default ShareEvent;
