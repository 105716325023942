import * as yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
export const signUpSchema = yup.object().shape({
  firstName: yup.string().required("Fill in your first name"),
  lastName: yup.string().required("Fill in your last name"),
  username: yup.string().required("Fill in your username"),
  phoneNumber: yup.mixed().required("Fill in your phone number"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),
  password: yup
    .string()
    .min(5)
    // .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Password must at least be five characters"),
  gender: yup.string().required("Select gender"),
  readTerms: yup.boolean().oneOf([true], "You must agree to the terms"),
  agreeTerms: yup.boolean().oneOf([true], "You must agree to the terms")
});
