import React, { useEffect } from "react";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import LowerSection from "./components/lowerSection";
import TopSection from "./components/topSection";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getUsersTickets } from "../../state management/Redux/slices/user_slice/user_ticket_slice";
const LoggedInUser = () => {
  const userInfo = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo
  );
  const firstName = userInfo?.firstname;
  const lastName = userInfo?.lastname;
  const name = `${firstName} ${lastName}`;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsersTickets());
  }, []);
  return (
    <>
      <FlexibleDiv>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Profile </title>
        </Helmet>
        <FlexibleDiv width={"98%"} padding={"12px 0px 40px 0px"}>
          <TopSection
            name={name}
            followers={userInfo?.followersCount}
            following={userInfo?.followingCount}
            profileImage={userInfo?.profile_image}
            tickets={userInfo?.tickets}
            userStatus={userInfo?.organizer}
          />
          <LowerSection
            data={[
              {
                email: userInfo?.email,
                userStatus: userInfo?.organizer,
                tickets: userInfo?.tickets,
                phone: userInfo?.phone,
                bio: userInfo?.bio,
              },
            ]}
          />
        </FlexibleDiv>
        <Footer />
      </FlexibleDiv>
    </>
  );
};

export default LoggedInUser;
