import { useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";

const PopularTags = () => {
  // const tags = [
  //   {
  //     name: "Food Fast",
  //   },
  //   {
  //     name: "Dinner Night",
  //   },
  //   {
  //     name: "Health",
  //   },
  //   {
  //     name: "Party",
  //   },
  //   {
  //     name: "Tea",
  //   },
  //   {
  //     name: "Baking",
  //   },
  //   {
  //     name: "Cooking",
  //   },
  //   {
  //     name: "Soda",
  //   },
  // ];
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const events = useSelector(
    ({ single_category }) => single_category?.data?.events
  );

  const eventTags = events?.map((item) => {
    const parsedTag = JSON.parse(item?.tags);
    return parsedTag;
  });
  const tags = events ?  [].concat(...eventTags) : null;

  return (
    <>
      <FlexibleDiv margin={'23px 0px'} >
        <FlexibleDiv
          breakPoint={"780px"}
          justifyContent={'flex-start'}
          gap={'23px'}
          breakPointJustifyContent={"flex-start"}
          breakPointgap={"22px"}
        >
          {/* Tags */}
          {tags?.map((item, index) => (
            <FlexibleDiv
              flexBasis={"10%"}
              bg={colorTheme.black.light}
              radius={"5px"}
              padding={"7px"}
              breakPoint={"780px"}
              key={index}
              breakPointflexBasis={"15%"}
            >
              <Text
                color={darkMode ? colorTheme.white.white : "#000"}
                margin={"0px"}
                fontSize={"11px"}
                resFontSize={"10px"}
              >
                {item}
              </Text>
            </FlexibleDiv>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default PopularTags;
