import styled from "styled-components";
import { colorTheme } from "../../../infrastructure/Theme/color";

export const ConversationFormStyle = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const FormLabel = styled.label`
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${({ color }) => (color ? color : colorTheme.black.strong)};
  text-align: left;
  @media screen and (max-width: 780px) {
    font-size: 13.5px;
  }
`;
