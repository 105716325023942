// AuthRoute.js
import { useNavigate } from "react-router-dom";
import { TokenChecker } from "../../utilities/tokenChecker";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AuthRoute = ({ children }) => {
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.token
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/"); // Redirect to homepage if token exists
    }
  }, [token, navigate]);

  return children;
};

export default AuthRoute;
