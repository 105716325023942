import styled from "styled-components";

export const CoverImageStyle = styled.img`
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    url(${({ image }) => (image ? image : "")});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  height: 155px;
  width: 100%;
  object-fit: cover;
  border-top-right-radius: ${({ RdTopRight }) =>
    RdTopRight ? RdTopRight : "15px"};
  border-top-left-radius: ${({ RdTopLeft }) =>
    RdTopLeft ? RdTopLeft : "15px"};
  @media screen and (max-width: 680px) {
    border-radius: 0px;
  }
`;
