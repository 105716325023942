import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import TabComponent from "../../components/secondaryComponents/tab/tab.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import EditEventHead from "./editEventHead.component";
import Analytics from "./tabs/analytics.tab";
import OverView from "./tabs/overView.tab";
import { getEventDetails } from "../../state management/Redux/slices/events/get_event_details_slice";
import { useParams } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";
import {
  categoryHandler,
  cover_imageHandler,
  descriptionHandler,
  endDateHandler,
  locationHandler,
  startDateHandler,
  tagsHandler,
  titleHandler,
  typeHandler,
  visibilityHandler
} from "../../state management/Redux/slices/editEvent/edit_details_slice";
import { Helmet } from "react-helmet";

const EditEvent = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const Options = [
    { name: "Overview", active: true },
    { name: "Analytics", active: false }
  ];

  const [tabName, setTabName] = useState("Overview");
  const [views, setViews] = useState(0);
  const [noOfViews] = useState(1);
  const loading_editDetails = useSelector(
    ({ event_details }) => event_details?.isSuccess
  );
  const Components = [<OverView />, <Analytics />];
  // Event details state
  const eventTitle = useSelector(
    ({ event_details }) => event_details?.data?.event?.title
  );
  const eventDescription = useSelector(
    ({ event_details }) => event_details?.data?.event?.description
  );
  const eventStartDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.startDate
  );
  const eventEndDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.endDate
  );

  const eventCoverImage = useSelector(
    ({ event_details }) => event_details?.data?.event?.cover_image
  );
  const eventType = useSelector(
    ({ event_details }) => event_details?.data?.event?.type
  );
  const eventVisibility = useSelector(
    ({ event_details }) => event_details?.data?.event?.visibility
  );
  const eventTags = useSelector(
    ({ event_details }) => event_details?.data?.event?.tags
  );
  const eventLocation = useSelector(
    ({ event_details }) => event_details?.data?.event?.location
  );
  const eventCategory = useSelector(
    ({ event_details }) => event_details?.data?.event?.category
  );

  useEffect(() => {
    dispatch(getEventDetails({ token: token, event_id: eventId }));
    window.scrollTo(0, 0);
  }, [eventId, dispatch, token]);

  useEffect(() => {
    if (loading_editDetails) {
      dispatch(titleHandler(eventTitle));
      dispatch(descriptionHandler(eventDescription));
      dispatch(startDateHandler(eventStartDate));
      dispatch(endDateHandler(eventEndDate));
      dispatch(typeHandler(eventType));
      dispatch(categoryHandler(eventCategory));
      dispatch(visibilityHandler(eventVisibility));
      dispatch(tagsHandler(eventTags));
      dispatch(locationHandler(eventLocation));
    }
  }, [
    eventId,
    loading_editDetails,
    eventCategory,
    eventTitle,
    eventVisibility,
    eventType,
    eventStartDate,
    eventEndDate,
    eventDescription,
    eventCoverImage,
    eventTags
  ]);
  return (
    <>
      <SkeletonTheme
        baseColor={colorTheme.black.fadingBlack}
        highlightColor={"#444444"}
      >
        <FlexibleDiv
          style={{ overflowX: "hidden" }}
          bg={darkMode ? colorTheme.black.fadingBlack : colorTheme.white.white}
          direction={"column"}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>Edit event-{String(eventTitle)}</title>
          </Helmet>
          <EditEventHead />

          <FlexibleDiv margin={"50px auto"} width={"97%"}>
            <TabComponent
              Options={Options}
              tabName={tabName}
              setTabName={setTabName}
              Components={Components}
              view={views}
              setView={setViews}
              noOfViews={noOfViews}
            />
          </FlexibleDiv>
        </FlexibleDiv>
        <Footer />
      </SkeletonTheme>
    </>
  );
};

export default EditEvent;
