import axios from "axios";
import { CONSTANTS } from "../utilities/constants";
const ClientRequest = axios.create({
  baseURL: CONSTANTS.baseURL
});

ClientRequest.interceptors.request.use(
  async (config) => {
    try {
      const token = localStorage.getItem("token");
      config.headers.Accept = "application/json";
      config.headers["Content-Type"] = "application/json";
      config.withCredentials = true;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    } catch (error) {
      console.error("Error retrieving token:", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default ClientRequest;
