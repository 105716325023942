import styled from "styled-components";
import { colorTheme } from "../../../infrastructure/Theme/color";

export const FooterContainer = styled.div`
  background: #fafafb;
  position: ${({position})=>position ? position :"relative"};
  left: ${({left})=>left ? left :""};
  bottom: ${({bottom})=>bottom ? bottom :''};
  color: #3d3d4e;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  flex-shrink: 0;
  width: 100%;
  margin-top: ${({marginTop})=>marginTop ? marginTop : '120px'};
`;

export const FooterStyle = styled.footer`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;

  @media (min-width: 768px) {
    padding-right: 32px;
    padding-left: 32px;
  }
  @media (min-width: 768px) {
    max-width: 1680px;
  }
`;

export const FooterMain = styled.div`
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  align-items: baseline;
  padding: 48px 0 32px;
  border-bottom: 1px solid #e7e7e9;
  @media screen and (max-width: 1160px) {
    padding: 72px 0;
  }
  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
  }
`;

export const FooterColumns = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
`;
export const FooterUl = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 12px;
  padding: 0px;
`;
export const FooterLi = styled.li`
  list-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 14px;
  transition: color 0.34s ease-in-out;
  &:hover {
    color: ${colorTheme.secondary_color};
  }
`;

export const LowerSection = styled.div`
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 12px 0;
  color: #6e6d7a;
`;
