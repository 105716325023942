import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { formatDateToDatetimeLocal } from "../../../../utilities/functions/formatDate";
import { CONSTANTS } from "../../../../utilities/constants";

export const createEvent = createAsyncThunk(
  "event/createEvent",
  async (
    {
      title,
      location_name,
      category,
      description,
      type,
      cover_image,
      tags,
      endDate,
      startDate,
      visibility,
      extraImages,
      token
    },
    { rejectWithValue }
  ) => {
    const categories = [category];
    const jsonArray = JSON.stringify(categories);
    const extractedImages = extraImages?.map(({ image }) => {
      return image;
    });
    const formData = new FormData();
    formData.append("title", title);
    formData.append("startDate", startDate);
    formData.append("description", description);
    formData.append("type", type);
    formData.append("location", location_name);
    formData.append("endDate", endDate);
    formData.append("categories", jsonArray);
    formData.append("tags", JSON.stringify(tags));
    formData.append("visibility", visibility);
    formData.append("cover_image", cover_image);
    formData.append("extra_images", JSON.stringify(extractedImages));

    try {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": true,
          Authorization: `Bearer ${token}`,
          Referer: CONSTANTS.baseURL
        }
      };
      const response = await axios.post(
        CONSTANTS.baseURL + "api/event/create/test",
        formData,
        config
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const { response } = error;
      console.error("Create Event error:", error);
      console.error("Create Event error:", response);
      return rejectWithValue(response?.data?.error);
    }
  }
);

const now = new Date();
const formattedDate = formatDateToDatetimeLocal(now);

const initialState = {
  createdEvent: [],
  nameOfEvent: "",
  eventStart: formattedDate,
  eventEnd: formattedDate,
  category: "",
  privacy: "",
  description: "",
  nameOfLocation: "",
  locationAddress: "",
  mainImage: null,
  formOfLocation: "",
  extraImages: [
    { image: null, id: 0 },
    { image: null, id: 1 },
    { image: null, id: 2 },
    { image: null, id: 3 }
  ],
  tickets: [],
  tags: [],
  success: false,
  responseData: null,
  isError: false,
  error: null,
  loading: false
};
const CreateEventSlice = createSlice({
  name: "create_event",
  initialState,
  reducers: {
    nameOfEventValue(state, action) {
      state.nameOfEvent = action.payload;
    },
    eventStartValue(state, { payload }) {
      state.eventStart = payload;
    },
    eventEndValue(state, { payload }) {
      state.eventEnd = payload;
    },
    categoryValue(state, action) {
      state.category = action.payload;
    },
    privacyValue(state, action) {
      state.privacy = action.payload;
    },
    formOfLocationValue(state, action) {
      state.formOfLocation = action.payload;
    },
    nameOfLocationValue(state, action) {
      state.nameOfLocation = action.payload;
    },
    addressOfLocationValue(state, action) {
      state.locationAddress = action.payload;
    },
    descriptionValue(state, action) {
      state.description = action.payload;
    },
    addMainImage(state, { payload }) {
      state.mainImage = payload;
    },
    addExtraImages(state, action) {
      let Img = action.payload.file;
      let id = action.payload.ID;
      state.extraImages[id].image = Img;
    },
    addTickets(state, action) {
      state.tickets = action.payload;
    },
    addTags: (state, action) => {
      const tag = action.payload;
      state.tags = tag;
    },
    removeTag: (state, action) => {
      const tagToRemove = action.payload;
      const tagIndex = state.tags.findIndex((tag) => tag === tagToRemove);

      if (tagIndex !== -1) {
        state.tags.splice(tagIndex, 1);
      }
    },
    resetCreateEventState: (state) => {
      state.createdEvent = [];
      state.nameOfEvent = "";
      state.eventStart = formattedDate;
      state.eventEnd = formattedDate;
      state.category = "";
      state.privacy = "";
      state.description = "";
      state.nameOfLocation = "";
      state.locationAddress = "";
      state.mainImage = null;
      state.formOfLocation = "";
      state.extraImages = [
        { image: "", id: 0 },
        { image: "", id: 1 },
        { image: "", id: 2 },
        { image: "", id: 3 }
      ];
      state.tickets = [];
      state.tags = [];
      state.success = false;
      state.responseData = null;
      state.isError = false;
      state.error = null;
      state.loading = false;
    }
  },

  extraReducers: (builder) => {
    builder.addCase(createEvent.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
      state.isError = false;
    });
    builder.addCase(createEvent.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.isError = false;
      state.responseData = payload;
    });
    builder.addCase(createEvent.rejected, (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.isError = true;
      state.error = payload;
    });
  }
});

export const {
  nameOfEventValue,
  eventStartValue,
  eventEndValue,
  categoryValue,
  privacyValue,
  formOfLocationValue,
  descriptionValue,
  nameOfLocationValue,
  addressOfLocationValue,
  addMainImage,
  addExtraImages,
  addTickets,
  addTags,
  typeValueHandler,
  resetCreateEventState,
  removeTag
} = CreateEventSlice.actions;
export default CreateEventSlice.reducer;
