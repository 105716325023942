import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { DropDownIcon, DropUpIcon } from "../../primaryComponents/icons/icons";
import { Image } from "../../primaryComponents/image/image";
import { Text } from "../../primaryComponents/typography/typography.component";
import badge from "../../../assets/images/verifiedTick.png";
import ProfileDropDown from "./profiledropDown.component";
import { useState } from "react";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Info = () => {
  const [toggle, setToggle] = useState(false);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const Toggle = () => {
    setToggle(!toggle);
  };
  const userInfo = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo
  );
  const loading = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.loading
  );
  const isOrganizer = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.organizer
  );
  const firstName = userInfo?.firstname;
  const lastName = userInfo?.lastname;
  const name = `${firstName} ${lastName}`;
  return (
    <>
      <FlexibleDiv width={"70%"} position={"relative"} overFlow={"hidden"}>
        <FlexibleDiv direction={"column"}>
          <FlexibleDiv justifyContent={"flex-end"}>
            {loading ? (
              <Text
                color={colorTheme.white.fadingWhite}
                fontSize={"14px"}
                margin={"0px"}
                resFontSize={"14px"}
              >
                *****
              </Text>
            ) : (
              <Text
                fontSize={"14px"}
                resFontSize={"14px"}
                color={darkMode ? "#FFF" : "#121212"}
                margin={"0px"}
                lineHeight={"21.78px"}
              >
                {name}
              </Text>
            )}
          </FlexibleDiv>
          <FlexibleDiv justifyContent={"flex-end"} gap={"15px"}>
            {toggle ? (
              <DropUpIcon
                color={darkMode ? "#FFFF" : colorTheme.black.fadingBlack}
                size={13}
                onClick={() => Toggle()}
              />
            ) : (
              <DropDownIcon
                color={darkMode ? "#FFFF" : colorTheme.black.fadingBlack}
                size={13}
                onClick={() => Toggle()}
              />
            )}
            {isOrganizer === 1 ? (
              <Image
                src={badge}
                height={"16px"}
                width={"16px"}
                alt={"attend-badge"}
              />
            ) : null}
          </FlexibleDiv>
        </FlexibleDiv>
        <ProfileDropDown
          toggleHandler={Toggle}
          display={toggle ? "flex" : "none"}
        />
      </FlexibleDiv>
    </>
  );
};

export default Info;
