import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ClientRequest from "../../../../api/api";
export const getSingleCategory = createAsyncThunk(
  "user/single_category",
  async ({ slug }, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get(`api/category/events/${slug}`);
      return response.data;
    } catch (error) {
      const { response } = error;
      return rejectWithValue(response);
    }
  }
);
const initialState = {
  loading: false,
  isError: false,
  isSuccess: false,
  data: [],
  error: null
};

const SingleCategorySlice = createSlice({
  name: "single_category_slice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSingleCategory.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(getSingleCategory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
        state.isSuccess = true;
      })
      .addCase(getSingleCategory.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.error = payload;
      });
  }
});


export default SingleCategorySlice.reducer