import { BiStar } from "react-icons/bi";
import { colorTheme } from "../../../infrastructure/Theme/color";
import no_pic from "../../../assets/images/no_user.png";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { CONSTANTS } from "../../../utilities/constants";

const CardOrganizerInfo = ({
  loading,
  organizername,
  organizer_profilepic,
  userName,
}) => {
  return (
    <>
      <FlexibleDiv
        margin={"0px"}
        flex={"1 0 auto"}
        padding={"10px 0px 0px 4px"}
        width={"94%"}
        alignItems={"center"}
      >
        <FlexibleDiv
          width={"100%"}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={"7px"}
          wrap={"nowrap"}
        >
          <FlexibleDiv width={"15%"} justifyContent={"flex-start"}>
            <img
              src={
                organizer_profilepic
                  ? `${CONSTANTS.profileImageUrl}${organizer_profilepic}`
                  : no_pic
              }
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              alt={"organizer-profile-img"}
            />
          </FlexibleDiv>
          <FlexibleDiv
            width={"85%"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            {userName && (
              <Text
                fontSize={"14px !important"}
                resFontSize={"13px !important"}
                fontWeight={"600"}
                color={colorTheme.primary_color}
                style={{ margin: 0 }}
              >
                {userName}
              </Text>
            )}
            {/* <Text
              fontSize={"14px !important"}
              resFontSize={"10px !important"}
              fontWeight={"500"}
              color={colorTheme.black.soft}
              style={{ margin: "2px 0px 0px 0px" }}
            >
              583 followers
            </Text> */}
          </FlexibleDiv>
        </FlexibleDiv>
        {/* <FlexibleDiv
          width={"40%"}
          direction={"row"}
          justifyContent={"flex-end"}
        >
          <BiStar color={colorTheme.textColor} />
        </FlexibleDiv> */}
      </FlexibleDiv>
    </>
  );
};

export default CardOrganizerInfo;
