import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../../utilities/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { editTicket } from "./edit_ticket_slice";
import { deleteTicket } from "./delete_ticket_slice";

export const createTicket = createAsyncThunk(
  "user/event/create-ticket",
  async (
    {
      token,
      event_id,
      name,
      price,
      capacity,
      cover_image,
      description,
      setIsOpen,
      formAction,
      addTicket,
      dispatch
    },
    { rejectWithValue }
  ) => {
    const formData = new FormData();
    formData.append("event_id", event_id);
    formData.append("name", name);
    formData.append("price", price);
    formData.append("capacity", capacity);
    formData.append("cover_image", cover_image);
    formData.append("description", description);
    try {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.post(
        CONSTANTS.baseURL + "api/event/tickets/create",
        formData,
        config
      );

      if (response.status === 200) {
        dispatch(
          addTicket({
            name: name,
            price: price,
            capacity: capacity,
            cover_image: cover_image,
            description: description
          })
        );
        toast.success(response?.data?.success);
        setIsOpen(false);
        formAction?.resetForm();
      }
      return response.data;
    } catch (error) {
      const { response } = error;
      console.error("Create ticket error:", error);
      console.error("Create ticket error:", response);
      toast.error(response.data?.error);
      return rejectWithValue(response?.data?.error);
    }
  }
);
const initialState = {
  loading: false,
  isError: false,
  isSuccessful: false,
  errorMessage: null,
  deletingTicket: false
};

const Create_Ticket_Slice = createSlice({
  name: "create_ticket_slice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createTicket.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.isSuccessful = false;
        state.errorMessage = null;
      })
      .addCase(createTicket.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccessful = true;
      })
      .addCase(createTicket.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(editTicket.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.isSuccessful = false;
        state.errorMessage = null;
      })
      .addCase(editTicket.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccessful = true;
      })
      .addCase(editTicket.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = false;
        state.errorMessage = payload;
      });
    builder
      .addCase(deleteTicket.pending, (state) => {
        state.deletingTicket = true;
        state.isError = false;
        state.isSuccessful = false;
        state.errorMessage = null;
      })
      .addCase(deleteTicket.fulfilled, (state, { payload }) => {
        state.deletingTicket = false;
        state.isSuccessful = true;
      })
      .addCase(deleteTicket.rejected, (state, { payload }) => {
        state.deletingTicket = false;
        state.isError = false;
        state.errorMessage = payload;
      });
  }
});

export default Create_Ticket_Slice.reducer;
