import React, { useEffect, useState } from "react";
import { AuthLayout, ImageColumn, InputFieldColumn } from "./styles";
import LoginImage from "../../assets/images/Login.jpg";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text,
} from "../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../infrastructure/Theme/color";
import ErrorMsg from "./components/errorMsg";
import LoginForm from "./components/loginForm";
import Logo from "../../components/secondaryComponents/logo/logo.component";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import GoogleCallBackModal from "../../components/secondaryComponents/modal/google-callback-modal";
const Login = () => {
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [, setSlides] = useState(0);
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (location.search) {
      const url = new URLSearchParams(location.search);
      const token = url.get("token");
      localStorage.setItem("token", token);
      setShowModal(true);
    }
  }, [location.search]);

  return (
    <AuthLayout
      bg={darkMode ? colorTheme.black.darkmode1 : colorTheme.white.white}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Login</title>
      </Helmet>
      <ImageColumn image={LoginImage}>
        <FlexibleDiv direction={"column"} padding={"0rem 0rem 5rem 0rem"}>
          <FlexibleDiv justifyContent={"flex-start"} padding={"0px 22px"}>
            <HeaderText
              lineHeight={"2.3rem"}
              fontSize={fontSizes.desktop_subtitle}
              color={"white"}
            >
              Discover awesome events
              <br /> around you
            </HeaderText>
          </FlexibleDiv>
        </FlexibleDiv>
      </ImageColumn>
      <InputFieldColumn>
        <FlexibleDiv>
          <Logo darkMode={darkMode} />
        </FlexibleDiv>
        {/* Sign Up Box */}
        <FlexibleDiv
          width={"100%"}
          direction={"column"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          margin={"20px 0px 0px 0px"}
        >
          {/* Title */}
          <FlexibleDiv
            width={"65%"}
            direction={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            padding={"0px 0px 12px 0px"}
            breakPoint={"680px"}
            breakPointwidth={"90%"}
            breakPointdirection={"column"}
            breakPointAlign={"flex-start"}
          >
            <HeaderText
              margin={"0px"}
              fontSize={"40px"}
              resFontSize={"18px"}
              smallScreenFontSize={fontSizes.mobile_title}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            >
              Welcome back
            </HeaderText>
            <Text
              margin={"10px 0px 0px 0px"}
              color={colorTheme.textColor}
              fontSize={"16px"}
              fontWeight={"500"}
              resFontSize={"13px"}
            >
              Welcome back. Please enter your details
            </Text>
          </FlexibleDiv>

          {/* Form */}
          <ErrorMsg showErrMsg={showErrMsg} setShowErrMsg={setShowErrMsg} />
          <LoginForm />
        </FlexibleDiv>
      </InputFieldColumn>
      <GoogleCallBackModal setShowModal={setShowModal} showModal={showModal} />
      {/* <AuthResponse showExitBtn={true} setSlides={setSlides} loadingMessage={"Logging In...."} successMessage={"Login Successful"} /> */}
    </AuthLayout>
  );
};

export default Login;
// https://attend.org.ng/login?success=true&token=608|LSoCylqIKVIdhDU7nUTOgBID8T3TLT8Qt296K8Z7
