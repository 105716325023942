import React, { useEffect } from "react";
import { CloseIcon } from "../icons/icons";
import { Exit, ModalStyle } from "./modalStyle";
const Modal = ({ children, show, setShowModal, scroll, showExitBtn,direction }) => {
  useEffect(() => {
    if (show === true) {
      document.body.style.overflow = `${scroll ? "hidden" : "hidden"}`;
    }

    if (show === false) {
      document.body.style.overflow = `${scroll ? "unset" : "unset"}`;
    }
  }, [show, scroll]);

  const modalHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      <ModalStyle
        scroll={scroll ? "scroll" : "hidden"}
        display={show ? "flex" : "none"}
        direction={direction}
      >
        {!showExitBtn ? null : (
          <Exit>
            <CloseIcon
              onClick={() => modalHandler()}
              color="white"
              size={"1.6rem"}
              style={{ cursor: "pointer",background:"#FCAE1E",borderRadius:'50px',padding:'5px' }}
            />
          </Exit>
        )}
        {children}
      </ModalStyle>
    </>
  );
};

export default Modal;
