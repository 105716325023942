import styled from "styled-components";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { HorizontalLine } from "../../../components/primaryComponents/horizontalLine/horizontalLine";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { IndicatorInnerCircle, IndicatorOuterCirlce } from "../styles";

const Line = styled(HorizontalLine)`
@media  screen and (max-width:1354px){
width:75%;
}
@media  screen and (max-width:1084px){
width:80%;
}
@media  screen and (max-width:817px){
width:79%;
}
@media  screen and (max-width:697px){
width:78%;
}
@media  screen and (max-width:490px){
width:77%;
}
@media  screen and (max-width:459px){
width:74%;
}
@media  screen and (max-width:397px){
width:68%;
}
@media  screen and (max-width:267px){
width:58%;
}
@media  screen and (max-width:180px){
width:48%;
}
`

const Indicator = ({slides,setSlides}) => {

    
    return ( 
        <>
        <FlexibleDiv  justifyContent={'flex-start'} padding={'12px 0px'} >
        <IndicatorOuterCirlce id={slides} onClick={()=>{
                    setSlides(0)
           
        }} >
            <IndicatorInnerCircle />
        </IndicatorOuterCirlce>

        <Line bg={slides>0 ? colorTheme.primary_color :colorTheme.black.light}   height={"0.1px"} width={"80%"} resWidth={"80%"} />

        <IndicatorOuterCirlce   bg={slides>0 ? colorTheme.primary_color :'transparent'} border={slides>0 ?'none': `1px solid ${colorTheme.primary_color}`} onClick={()=>{
         if(slides===0)
      {
        return slides;
      }
         else{
            setSlides(1)
         }
           
        }}  > 
            <IndicatorInnerCircle  bg={slides>0 ? 'white' : colorTheme.black.soft } />
        </IndicatorOuterCirlce>
        </FlexibleDiv>
        </>
     );
}
 
export default Indicator;