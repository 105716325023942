import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Modal from "../../primaryComponents/modal/modal";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { Text } from "../../primaryComponents/typography/typography.component";
import img from "../../../assets/images/concert-photo.jpg";
import { Image } from "../../primaryComponents/image/image";
import Input from "../../primaryComponents/input/input.component";
import Button from "../../primaryComponents/button/button.component";
import { useEffect, useMemo, useState } from "react";
import Loader from "../loader/loader.component";
import { editPhoneNumber } from "../../../state management/Redux/slices/user_slice/edit_profile_slice";
import { getUserInfo } from "../../../state management/Redux/slices/user_slice";

const WhatsappNumberModal = ({ showModal, setShowModal }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );

  const dispatch = useDispatch();
  const bg = darkMode
    ? colorTheme.black.fadeMini
    : colorTheme.white.fadingWhite;
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const [phone, setPhone] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const loading = useSelector(
    ({ edit_profile }) => edit_profile?.editPhoneNoLoading
  );
  const isSuccessful = useSelector(
    ({ edit_profile }) => edit_profile?.editPhoneNoIsSuccessful
  );
  const isError = useSelector(
    ({ edit_profile }) => edit_profile?.editPhoneNoIsError
  );
  const error = useSelector(
    ({ edit_profile }) => edit_profile?.editPhoneNoError
  );

  const submitHandler = () => {
    if (validatePhoneNumber(phone)) {
      dispatch(editPhoneNumber({ phone: phone }));
    } else {
      setValidationMessage("Please enter a valid Nigerian phone number.");
    }
  };

  const retryHandler = () => {
    setValidationMessage("");
    setShowError(false);
  };

  const btnDisabled = loading || phone === "";
  const phoneNumberHandler = useMemo(
    () => (value) => {
      setPhone(value);
      setValidationMessage(""); // Clear validation message on input change
    },
    []
  );

  const validatePhoneNumber = (number) => {
    const nigerianPhoneRegex =
      /^(070|080|081|090|091|070|071|072|073|074|075|076|077|078|079)\d{8}$/;
    return nigerianPhoneRegex.test(number);
  };

  useEffect(() => {
    if (isSuccessful) {
      setShowModal(false);
      setPhone("");
      dispatch(getUserInfo());
    }
    if (isError) {
      setShowError(true);
    }
  }, [isSuccessful, isError]);

  const exitButtonFunction = () => {
    localStorage.setItem("whatsapp-modal-closed", true);
  };

  return (
    <>
      <Modal
        scroll={true}
        show={showModal}
        setShowModal={setShowModal}
      >
        {showError ? (
          <FlexibleDiv
            width={"60%"}
            direction={"column"}
            bg={bg}
            padding={"23px"}
            alignItems={"center"}
            height={"140px"}
            justifyContent={"center"}
            breakPoint={"880px"}
            breakPointwidth={"80%"}
            breakPointAlign={"stretch"}
          >
            <Text
              margin={"0px"}
              fontSize={"16px"}
              textAlign={"center"}
              color={colorTheme.red}
            >
              {error || "Something went wrong. Please try again."}
            </Text>
            <Button
              width={"45%"}
              onClick={retryHandler}
              padding={"12px"}
              height={"35px"}
              color={colorTheme.white.white}
              radius={"5px"}
              background={colorTheme.primary_color}
              hoverBorder={`0.9px solid ${colorTheme.primary_color}`}
              hoverBackground={"white"}
              hoverColor={colorTheme.primary_color}
              fontSize={"14px"}
              style={{ marginTop: "10px" }}
            >
              Retry
            </Button>
          </FlexibleDiv>
        ) : (
          <FlexibleDiv
            width={"60%"}
            bg={bg}
            padding={"0px"}
            alignItems={"stretch"}
            breakPoint={"880px"}
            breakPointwidth={"80%"}
            breakPointAlign={"stretch"}
          >
            <FlexibleDiv
              flexBasis={"40%"}
              padding={"0px"}
              breakPoint={"680px"}
              breakPointDisplay={"none"}
            >
              <Image src={img} width={"100%"} height={"100%"} />
            </FlexibleDiv>

            <FlexibleDiv
              margin={"0px"}
              flexBasis={"60%"}
              padding={"23px 0px"}
              breakPoint={"680px"}
              breakPointflexBasis={"100%"}
            >
              <FlexibleDiv margin={"0px"}>
                <Text
                  margin={"0px"}
                  fontSize={"24px"}
                  color={textColor}
                  resFontSize={"18px"}
                >
                  Welcome to{" "}
                  <span style={{ color: colorTheme.primary_color }}>
                    Attend🎉
                  </span>
                </Text>
              </FlexibleDiv>
              <FlexibleDiv margin={"5px 0px"} padding={"0px 23px"}>
                <Text
                  margin={"0px"}
                  fontSize={"12px"}
                  textAlign={"center"}
                  lineHeight={"23px"}
                  resFontSize={"12px"}
                  color={textColor}
                >
                  Get event updates instantly! Enter your WhatsApp number for
                  notifications.
                </Text>
              </FlexibleDiv>
              <FlexibleDiv margin={"5px 0px"} padding={"0px 23px"}>
                <Input
                  value={phone}
                  onChange={(e) => {
                    phoneNumberHandler(e.target.value);
                  }}
                  placeHolder={"Your WhatsApp number"}
                  width={"100%"}
                  bg={colorTheme.black.fadingBlack}
                  padding={"13px"}
                  radius={"5px"}
                  type={"tel"}
                  style={{ textAlign: "center" }}
                  placeholderSize={"13px"}
                  color={textColor}
                />
                {validationMessage && (
                  <Text
                    color={colorTheme.red}
                    fontSize={"12px"}
                    margin={"5px 0 0 0"}
                  >
                    {validationMessage}
                  </Text>
                )}
              </FlexibleDiv>
              <FlexibleDiv margin={"20px 0px"} padding={"0px 23px"}>
                <Button
                  width={"45%"}
                  onClick={submitHandler}
                  disabled={btnDisabled}
                  padding={"12px"}
                  height={"35px"}
                  color={colorTheme.white.white}
                  radius={"5px"}
                  background={
                    phone === ""
                      ? colorTheme.lightgrey
                      : loading
                      ? colorTheme.white.white
                      : colorTheme.primary_color
                  }
                  hoverBorder={
                    phone === ""
                      ? colorTheme.lightgrey
                      : `0.9px solid ${colorTheme.primary_color}`
                  }
                  hoverBackground={
                    phone === "" ? colorTheme.lightgrey : "white"
                  }
                  hoverColor={
                    phone === ""
                      ? colorTheme.white.white
                      : colorTheme.primary_color
                  }
                  type={"submit"}
                  fontSize={"14px"}
                >
                  {loading ? <Loader width={32} /> : " Get Updates"}
                </Button>
              </FlexibleDiv>
              <FlexibleDiv margin={"5px 0px"} padding={"0px 23px"}>
                <Text
                  margin={"0px"}
                  fontSize={"11px"}
                  textAlign={"center"}
                  lineHeight={"20px"}
                  resFontSize={"11px"}
                  style={{ fontStyle: "italic" }}
                  color={colorTheme.lightgrey}
                >
                  *Your privacy is important to us. We only use your number for
                  notifications.*
                </Text>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        )}
      </Modal>
    </>
  );
};

export default WhatsappNumberModal;
