import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { Text } from "../../primaryComponents/typography/typography.component";
import { QuantityPicker } from "react-qty-picker";
import { Controller } from "./order_ticket.component";
import Button from "../../primaryComponents/button/button.component";
import { generate_payment_link } from "../../../services/ticket_payment";
import { selectTicketHandler } from "../../../state management/Redux/slices/payment_slice/buy_ticket_slice";

const TicketSelector = () => {
    const dispatch = useDispatch();
    const darkMode = useSelector(
        ({ persistedReducer }) => persistedReducer.theme.darkmode
    )
    const token = useSelector(
        ({ persistedReducer }) => persistedReducer.user.token
    );
    const tickets = useSelector(
        ({ event_details }) => event_details?.data?.event?.tickets
    );
    const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
    const selected_tickets = useSelector(
        ({ buy_ticket }) => buy_ticket.selected_tickets
    );
    const selected_ticket = useSelector(
        ({ buy_ticket }) => buy_ticket.selected_ticket
    );
    const firstname = useSelector(
        ({ persistedReducer }) => persistedReducer?.user?.userInfo?.firstname
    );
    const lastname = useSelector(
        ({ persistedReducer }) => persistedReducer?.user?.userInfo?.lastname
    );
    const loading = useSelector(({ buy_ticket }) => buy_ticket.loading);
    const ticket_to_be_bought = selected_tickets.filter((ticket) => ticket?.id === selected_ticket?.id);
    const total = ticket_to_be_bought?.[0]?.quantity * ticket_to_be_bought?.[0]?.price;
    const bg =
        !ticket_to_be_bought?.[0]?.quantity > 0
            ? colorTheme.lightgrey
            : loading
                ? colorTheme.white.white
                : colorTheme.primary_color;
    const name = `${firstname} ${lastname}`;



    return (
        <>
            <FlexibleDiv direction={'column'} padding={'16px'} bg={darkMode ? colorTheme.black.fadeMini : colorTheme.white.white}
                shadow={darkMode ? "0px 0px 18px rgba(0, 0, 0, 0.1)" : ""} radius={'5px'} >
                <FlexibleDiv justifyContent={'flex-start'} margin={'10px 0px'} >
                    <Text color={textColor} fontSize={"16px"} resFontSize={"16px"} style={{ margin: "0px" }} >
                        Tickets
                    </Text>
                </FlexibleDiv>
                {tickets?.map((ticket) => {
                    const isAvailable = ticket?.capacity > 0;
                    return (
                        <FlexibleDiv style={{ cursor: "pointer" }} alignItems={'center'} onClick={() => {
                            dispatch(selectTicketHandler(ticket));
                        }} bg={
                            selected_ticket?.id === ticket?.id
                                ? `${colorTheme.sideBarBg}`
                                : `transparent`
                        } borderBottom={'.8px solid #d2d2d23a'} margin={'0px 0px 23px 0px'} padding={'0px 0px 12px 0px'}  >
                            <FlexibleDiv padding={'0px 12px 0px 12px'} flex={2} justifyContent={'space-between'} breakPoint={'680px'} breakPointdirection={'column'} breakPointFlex={1} breakPointAlign={'flex-start'} >
                                <Text color={isAvailable ? textColor : colorTheme.lightgrey} fontSize={"12px"} resFontSize={"12px"} >{ticket?.name}</Text>
                                <FlexibleDiv width={'auto'} direction={'column'} breakPoint={'680px'} breakPointAlign={'flex-start'} breakPointdirection={'column'} >
                                    <Text style={{ margin: '0px' }} color={isAvailable ? textColor : colorTheme.lightgrey} fontSize={"12px"} resFontSize={"12px"}>₦{parseFloat(ticket?.price?.replace(/[^\d.]/g, '')).toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text>
                                    <Text style={{ margin: '0px' }} color={colorTheme.lightgrey} fontSize={"12px"} resFontSize={"12px"} >{ticket?.capacity} available</Text>
                                </FlexibleDiv>
                            </FlexibleDiv>
                            <FlexibleDiv flex={1} breakPoint={'680px'} breakPointFlex={1} >
                                {isAvailable ? <Controller ticket={ticket} /> : <Text fontWeight={"600"} color={colorTheme.lightgrey} fontSize={"12px"} resFontSize={"12px"} >Sold Out</Text>}
                            </FlexibleDiv>

                        </FlexibleDiv>
                    )
                })}


                <FlexibleDiv justifyContent={'space-between'} gap={'12px'} breakPoint={'680px'} breakPointdirection={'column-reverse'} >
                    <FlexibleDiv flex={1} >
                        <Text color={textColor} fontSize={"12px"} resFontSize={"12px"} >Select the ticket you want to purchase</Text>
                    </FlexibleDiv>
                    <FlexibleDiv flex={1} >
                        <Text color={textColor} fontSize={"12px"} resFontSize={"12px"} >Quantity:  {ticket_to_be_bought?.length > 0 ? ticket_to_be_bought?.[0]?.quantity : 0}</Text>
                        <Text color={textColor} fontSize={"12px"} resFontSize={"12px"} > Total:  ₦{total ? total?.toLocaleString('en-us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0.00} </Text>
                        <Button disabled={!ticket_to_be_bought?.[0]?.quantity > 0}
                            type={"submit"}
                            onClick={() => {
                                dispatch(
                                    generate_payment_link({
                                        token: token,
                                        event_ticket_id: ticket_to_be_bought?.[0]?.id,
                                        quantity: ticket_to_be_bought?.[0]?.quantity,
                                        name: name
                                    })
                                );
                            }}
                            hoverBorder={"none"}
                            color={colorTheme.white.white}
                            background={bg}
                            hoverBackground={bg}
                            hoverColor={colorTheme.white.white}
                            width={"100px"}
                            radius={"5px"}
                            padding={"13px"}>Buy Tickets</Button>
                    </FlexibleDiv>

                </FlexibleDiv>
            </FlexibleDiv>
        </>
    );
}

export default TicketSelector;