import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import { Text } from "../../primaryComponents/typography/typography.component";

const NewPassword = ({ error, value, touched, onChange, onBlur, name }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv
        padding={"30px 0px"}
        direction={"column"}
        alignItems={"flex-start"}
      >
        <FlexibleDiv
          justifyContent={"space-between"}
          padding={"0px 0px 20px 0px"}
          width={"600px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          breakPointdirection={"column"}
          breakPointAlign={"flex-start"}
          style={{ alignSelf: "flex-start" }}
        >
          <Text
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontSize={"14px"}
            resFontSize={"14px"}
            fontWeight={"600"}
          >
            New Password
          </Text>
          {touched && error && (
            <Text
              lineHeight="43px"
              color={colorTheme.red}
              margin={"0px"}
              fontSize={"13px"}
              resFontSize="13px"
              fontWeight={"400"}
            >
              {error}
            </Text>
          )}
        </FlexibleDiv>
        <FlexibleDiv
          justifyContent={"flex-start"}
          width={"600px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
        >
          <Input
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            name={name}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            type={"password"}
            width={"100%"}
            border={"1px solid lightgray"}
            borderBottom={"1px solid lightgray"}
            radius={"5px"}
            padding={"10px"}
          />
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text
            color={colorTheme.textColor}
            cursor={"pointer"}
            fontSize={"13px"}
            resFontSize={"13px"}
            fontWeight={"500"}
          >
            *Your new password must be more than 8 characters.
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default NewPassword;
