import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import { colorTheme } from "../../infrastructure/Theme/color";
import Head from "./BlockCodes/header";
import {
  CreateEventStateProvider,
  useCreateEvent
} from "./createEvent Context";
import { CreateEventStyle, MainSection } from "./createEvent.style";
import BasicInfo from "./sections/basicInfo/basicInfo";
import Create from "./sections/create/create.component";
import Description from "./sections/description/description";
import Location from "./sections/location/location";
import Upload from "./sections/upload/upload";
import Modal from "../../components/primaryComponents/modal/modal";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import CreateEventLoader from "../../components/secondaryComponents/createEventLoader/createEventLoader.component";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { resetCreateEventState } from "../../state management/Redux/slices/create_event_slice";
import { Helmet } from "react-helmet";

const CreateEvent = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const success = useSelector(({ createEvent }) => createEvent.success);
  const title = useSelector(
    ({ createEvent }) => createEvent.responseData?.event?.title
  );
  const id = useSelector(
    ({ createEvent }) => createEvent.responseData?.event?.id
  );
  const isSubmitting = useCreateEvent()?.[1];
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (success) {
      const navigationHandler = () => {
        setTimeout(() => {
          setShowModal(false);
          navigate(`/manage/edit/${id}/${encodeURIComponent(title)}`);
          dispatch(resetCreateEventState());
        }, 3000);
      };

      navigationHandler();
      return () => {
        clearTimeout(navigationHandler);
      };
    }
  }, [success]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Create a new event</title>
      </Helmet>
      <CreateEventStateProvider>
        <CreateEventStyle>
          {/* <Header /> */}

          <FlexibleDiv height={"100%"} justifyContent={"center"}>
            <Head
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              title={"Create Event"}
              padding={"23px 0px"}
            />

            <MainSection
              bg={
                darkMode ? colorTheme.black.fadingBlack : colorTheme.white.white
              }
              shadow={darkMode ? "0px 0px 20px rgba(0, 0, 0, 0.05)" : ""}
            >
              <BasicInfo />
              <Description />
              <Upload />
              <Location />
              <Create setShowModal={setShowModal} />
              <CreateEventLoader show={showModal} setShowModal={setShowModal} />
            </MainSection>
          </FlexibleDiv>
        </CreateEventStyle>

        <Footer />
      </CreateEventStateProvider>
    </>
  );
};

export default CreateEvent;
