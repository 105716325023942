import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { TextArea } from "../../primaryComponents/input/input.component";
import {
  HeaderText,
  Text,
} from "../../primaryComponents/typography/typography.component";
import { removeHtmlTags } from "../../../utilities/functions/removeHtmlTags";
import Skeleton from "react-loading-skeleton";
import { descriptionHandler } from "../../../state management/Redux/slices/editEvent/edit_details_slice";

const EditEventDescription = React.memo(() => {
  const [readOnly, setReadOnly] = useState(true);
  const dispatch = useDispatch();
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const inputColor = darkMode
    ? colorTheme.white.fadingWhite
    : colorTheme.black.strong;
  const btnBgColor = darkMode
    ? colorTheme.primary_color
    : colorTheme.white.white;
  const btnColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;

  const description = useSelector(({ edit_event }) => edit_event.description);
  const loading = useSelector(({ event_details }) => event_details?.loading);
  return (
    <>
      <FlexibleDiv margin={"40px 0px"}>
        <FlexibleDiv
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"14.5px"}
            color={titleColor}
          >
            Event Description
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"15px"}
            color={colorTheme.textColor}
            resFontSize={"13.8px"}
          >
            This event description shows on your registration page. Tell us more
            about your event.
          </Text>
        </FlexibleDiv>

        <FlexibleDiv direction={"column"}>
          <FlexibleDiv justifyContent={"flex-start"}>
            {loading ? (
              <Skeleton width={700} height={200} />
            ) : (
              <FlexibleDiv
                justifyContent={"flex-start"}
                width={"850px"}
                breakPoint={"880px"}
                breakPointwidth={"100%"}
              >
                <TextArea
                  autoFocus={!readOnly}
                  onChange={(e) => {
                    dispatch(descriptionHandler(e.target.value));
                  }}
                  style={{
                    boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    padding: "12px",
                    height: "142px",
                  }}
                  placeholder="No description added yet"
                  readOnly={readOnly}
                  color={inputColor}
                  value={removeHtmlTags(description)}
                />
              </FlexibleDiv>
            )}
          </FlexibleDiv>

          <FlexibleDiv margin={"12px 0px"} justifyContent={"flex-start"}>
            <Button
              width={"870px"}
              padding={"12px"}
              children={
                description === "" ? "+ Add Description" : "Edit Description"
              }
              background={btnBgColor}
              color={btnColor}
              hoverBackground={"#F2F3F7"}
              hoverColor={"#000"}
              hoverBorder={"none"}
              onClick={() => {
                setReadOnly(false);
              }}
            />
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
});

export default EditEventDescription;
