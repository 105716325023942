import styled from "styled-components";
export const ModalStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  z-index: 90001;
  position: fixed;
  bottom: 0;
  top: 0;
  background: #000000c9;
  left: 0px;
  right: 0px;
  display: ${({ display }) => (display ? display : "flex")};
  align-items: center;
  justify-content: center;
  transform: translateY(0);
  transition: 1.5s ease-in-out;
  flex-direction:${({ direction }) => (direction ? direction : "row")} ;
  overflow-y: scroll !important;
  overflow-x: hidden;

  @media only screen and (max-width: 680px) {
    overflow-y: ${({ scroll }) => (scroll ? scroll : "scroll")};
    overflow-x: hidden;
  }
`;

export const Exit = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 98%;
  padding: 15px;
  display: flex;
  justify-content:flex-end;
  z-index: 90005;
  @media screen and (max-width:680px) {
    width: 90%;
  }
`;

