import {
    DashboardIcon,
    LogOutIcon,

    HelpIcon,
    TickectIcon,
  } from "../../../primaryComponents/icons/icons";
  import { colorTheme } from "../../../../infrastructure/Theme/color";
export const is_not_loggedIn_links = [
    {
      name: "Home",
      link: "/",
      icon: <DashboardIcon size={16} color={colorTheme.primary_color} />,
    },

    {
      name: "Help",
      link: "/contact-us",
      icon: <HelpIcon size={16} color={colorTheme.primary_color} />,
    },
    {
      name: "Become An Organizer",
      link: "/signup",
      icon: <TickectIcon size={16} color={colorTheme.primary_color} />,
    },
    {
      name: "Login",
      link: "/login",
      icon: <LogOutIcon size={16} color={colorTheme.primary_color} />,
    },
    {
      name: "Sign Up",
      link: "/signup",
      icon: <LogOutIcon size={16} color={colorTheme.primary_color} />,
    },
  ];
export const is_loggedIn_links = [
    {
        name: "Home",
        link: "/",
        icon: <DashboardIcon size={16} color={colorTheme.primary_color} />,
      },
  
      {
        name: "Help",
        link: "/contact-us",
        icon: <HelpIcon size={16} color={colorTheme.primary_color} />,
      },
  ];