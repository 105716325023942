import { TICKET_IMAGE_SECTION } from "./styles";
import { Image } from "../../primaryComponents/image/image";
import { CONSTANTS } from "../../../utilities/constants";
const TicketImage = ({ eventImage }) => {
  return (
    <>
      <TICKET_IMAGE_SECTION>
        <Image
          src={CONSTANTS.imgbaseurl + eventImage}
          width={"100%"}
          height={"100%"}
          objectFit={"cover"}
        />
      </TICKET_IMAGE_SECTION>
    </>
  );
};

export default TicketImage;
