import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import naira_icon from "../../../assets/images/naira_icon2.png";
import { Image } from "../../primaryComponents/image/image";
const Ticket_Order_Summary = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const selected_tickets = useSelector(
    ({ buy_ticket }) => buy_ticket.selected_tickets
  );
  const selected_ticket = useSelector(
    ({ buy_ticket }) => buy_ticket.selected_ticket
  );
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const total = selected_tickets?.reduce((total, ticket) => {
    return total + ticket.price * ticket.quantity;
  }, 0);
  return (
    <FlexibleDiv direction={"column"} padding={"23px 0px"}>
      {/* {selected_tickets?.length > 0 && (
        <>
          <FlexibleDiv display={"block"}>
            <Text
              style={{ textAlign: "left" }}
              fontSize={"16px"}
              resFontSize={"16px"}
              fontWeight={"600"}
              color={textColor}
            >
              Order Summary
            </Text>
          </FlexibleDiv>
          {selected_tickets?.map(({ name, quantity, price }) => (
            <FlexibleDiv key={name} justifyContent={"space-between"}>
              <Text
                style={{ textAlign: "left" }}
                fontSize={"16px"}
                resFontSize={"16px"}
                fontWeight={"400"}
                color={textColor}
              >
                {quantity} x{name}
              </Text>
              <Text
                style={{ textAlign: "left" }}
                fontSize={"16px"}
                resFontSize={"16px"}
                fontWeight={"500"}
                color={textColor}
              >
                N{price * quantity}
              </Text>
            </FlexibleDiv>
          ))}
          <FlexibleDiv justifyContent={"space-between"}>
            <Text
              style={{ textAlign: "left" }}
              fontSize={"18px"}
              resFontSize={"18px"}
              fontWeight={"500"}
              color={textColor}
            >
              Total
            </Text>
            <Text
              style={{ textAlign: "left" }}
              fontSize={"18px"}
              resFontSize={"18px"}
              fontWeight={"500"}
              color={textColor}
            >
              {`N${total}`}
            </Text>
          </FlexibleDiv>
        </>
      )} */}
      <FlexibleDiv display={"block"}>
        <Text
          style={{ textAlign: "left" }}
          fontSize={"16px"}
          resFontSize={"16px"}
          fontWeight={"600"}
          color={textColor}
        >
          Order Summary
        </Text>
      </FlexibleDiv>
      <FlexibleDiv justifyContent={"space-between"}>
        <Text
          style={{ textAlign: "left" }}
          fontSize={"16px"}
          resFontSize={"16px"}
          fontWeight={"400"}
          color={textColor}
        >
          {selected_ticket?.name}
        </Text>
        <Text
          style={{ textAlign: "left", padding: "0px 23px" }}
          fontSize={"16px"}
          resFontSize={"16px"}
          fontWeight={"500"}
          color={textColor}
        >
          <Image
            style={{ marginRight: "5px" }}
            src={naira_icon}
            width={"17px"}
            height={"17px"}
          />
          {selected_ticket?.price}
        </Text>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default Ticket_Order_Summary;
