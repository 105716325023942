import React from 'react';
import FlexibleDiv from '../../../components/primaryComponents/flexibleDiv/flexibleDiv.component';
import { CloseIcon } from '../../../components/primaryComponents/icons/icons';
import { Text } from '../../../components/primaryComponents/typography/typography.component';
import { fontSizes } from '../../../infrastructure/Theme/fontSize';


const ErrorMsg = ({msg,showErrMsg,setShowErrMsg}) => {
    return ( 
        <>
        <FlexibleDiv display={showErrMsg ? 'flex' :'none'} width={'60%'} bg={'#ff5f5fa6'} radius={'5px'} padding={'1px 12px 1px 12px'} justifyContent={'space-between'} >
        <Text color={'white'} fontSize={fontSizes.mobile_smallest} resFontSize={'12px'}  >
         404. Wrong password.
        </Text>
        <CloseIcon color={'white'} style={{cursor:"pointer"}} onClick={()=>{setShowErrMsg(false)}} />
        </FlexibleDiv>
        </>
     );
}
 
export default ErrorMsg;