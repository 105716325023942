import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import Head from "../../BlockCodes/header";
import MainImage from "./mainImage.component";
import SecondaryMedia from "./secondaryMedia.component";
import { useCreateEvent } from "../../createEvent Context";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";


const Upload = () => {
  const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
  const errors = useCreateEvent()?.[2];
  const touched = useCreateEvent()?.[3];
    return ( 
        <>
        <FlexibleDiv direction={'column'} margin={"70px 0px"} >
      {/* Title */}
      <FlexibleDiv  lineHeight={"1.9em"}  alignItems={'center'} margin={"0px 0px 23px 0px"} >
      <FlexibleDiv width={errors.main_image && touched.main_image ?'30%' : '100%'} breakPoint={'680px'} breakPointwidth={'100%'}>
        <Head color={darkMode ? colorTheme.white.white : colorTheme.black.strong} fontSize={fontSizes.desktop_body_text} width={'100%'} resFontSize={"18px"} title={"Upload Photos and Videos"} />
        </FlexibleDiv>
        <FlexibleDiv justifyContent={'flex-start'} width={'70%'} display={errors.main_image && touched.main_image ? 'flex' :'none'} breakPoint={'680px'} breakPointwidth={'100%'} >
        <Text margin={'0px'} color={colorTheme.red} fontSize={fontSizes.mobile_smallest} resFontSize={fontSizes.mobile_smallest} >
           ({errors.main_image})
           </Text> 
        </FlexibleDiv>
      </FlexibleDiv>
     
     
      
      <MainImage/>
      {/* <SecondaryMedia/> */}
    </FlexibleDiv>
        </>
     );
}
 
export default Upload;