import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    openModal:false,
    paymentMethods:[]
}

const PaymentMethodSlice = createSlice({
    name:"create_payment_method",
    initialState,
    reducers:{
        createPaymentMethod(state,action){
            state.paymentMethods.push(action.payload)
        },
        openModal(state,action){
            state.openModal =action.payload
        }
    }
})


export const {createPaymentMethod,openModal} = PaymentMethodSlice.actions;

export default PaymentMethodSlice.reducer;