import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  EventsIcon,
  TickectIcon,
  UserAddIcon,
  UserAltIcon,
} from "../../../components/primaryComponents/icons/icons";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { useEffect } from "react";
// import { get_user_events } from "../../../state management/Redux/slices/userAccountSlice/user_events_slice";
// import { getEventAttended } from "../../../services/eventsAttended";

const TICKET_FOLLOWERS = ({
  searchedAccount,
  followers,
  following,
  tickets,
  userStatus,
}) => {
  const isOrganizer = userStatus === 1 ? true : false;
  return (
    <>
      <FlexibleDiv padding={"30px 0px"}>
        <FlexibleDiv width={"75%"} breakPoint={"680px"} breakPointwidth={"90%"}>
          {searchedAccount || isOrganizer ? (
            <NO_OF_EVENTS />
          ) : (
            <NO_OF_TICKETS no_of_tickets={tickets?.length} />
          )}
          {isOrganizer && <NO_OF_FOLLOWERS no_of_followers={followers} />}
          {!isOrganizer && <Events_Attended />}
          <NO_OF_FOLLOWING no_of_following={following} />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default TICKET_FOLLOWERS;

const NO_OF_TICKETS = ({ no_of_tickets }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv
      width={"30%"}
      breakPointwidth={"auto"}
      breakPoint={"780px"}
      breakPointJustifyContent={"center"}
      breakPointAlign={"flex-start"}
      justifyContent={"flex-start"}
    >
      {/* Icons */}
      <FlexibleDiv
        width={"15%"}
        justifyContent={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"30%"}
        breakPointDisplay={"none"}
      >
        <TickectIcon
          size={23}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </FlexibleDiv>
      {/* Value */}
      <FlexibleDiv
        width={"85%"}
        direction={"column"}
        alignItems={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"70%"}
      >
        {/* Title */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            margin={"0px"}
            color={colorTheme.black.soft}
            fontWeight={"500"}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"14px"}
          >
            Tickets
          </Text>
        </FlexibleDiv>
        {/* Value */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontWeight={"700"}
            fontSize={fontSizes.desktop_details}
            resFontSize={"14px"}
          >
            {no_of_tickets}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

const NO_OF_FOLLOWERS = ({ no_of_followers }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv
      width={"30%"}
      breakPointwidth={"auto"}
      justifyContent={"flex-start"}
      breakPoint={"780px"}
      breakPointAlign={"flex-start"}
      breakPointJustifyContent={"center"}
    >
      {/* Icons */}
      <FlexibleDiv
        width={"15%"}
        justifyContent={"flex-start"}
        breakPointJustifyContent={"center"}
        breakPoint={"680px"}
        breakPointwidth={"30%"}
        breakPointDisplay={"none"}
      >
        <UserAltIcon
          size={18}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </FlexibleDiv>
      {/* Value */}
      <FlexibleDiv
        width={"85%"}
        direction={"column"}
        alignItems={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"70%"}
      >
        {/* Title */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            margin={"0px"}
            color={colorTheme.black.soft}
            fontWeight={"500"}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"14px"}
          >
            Followers
          </Text>
        </FlexibleDiv>
        {/* Value */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontWeight={"700"}
            fontSize={fontSizes.desktop_details}
            resFontSize={"14px"}
          >
            {no_of_followers}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};
const NO_OF_FOLLOWING = ({ no_of_following }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv
      width={"30%"}
      breakPointwidth={"auto"}
      justifyContent={"flex-start"}
      breakPoint={"780px"}
      breakPointAlign={"flex-start"}
      breakPointJustifyContent={"center"}
    >
      {/* Icons */}
      <FlexibleDiv
        width={"15%"}
        breakPointJustifyContent={"center"}
        justifyContent={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"30%"}
        breakPointDisplay={"none"}
      >
        <UserAddIcon
          size={23}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </FlexibleDiv>
      {/* Value */}
      <FlexibleDiv
        width={"85%"}
        direction={"column"}
        alignItems={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"70%"}
      >
        {/* Title */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            margin={"0px"}
            color={colorTheme.black.soft}
            fontWeight={"500"}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"14px"}
          >
            Following
          </Text>
        </FlexibleDiv>
        {/* Value */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontWeight={"700"}
            fontSize={fontSizes.desktop_details}
            resFontSize={"14px"}
          >
            {no_of_following}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};
const NO_OF_EVENTS = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const users_events = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.events
  );
  return (
    <FlexibleDiv
      width={"30%"}
      breakPointwidth={"auto"}
      justifyContent={"flex-start"}
      breakPoint={"780px"}
      breakPointAlign={"flex-start"}
      breakPointJustifyContent={"center"}
    >
      {/* Icons */}
      <FlexibleDiv
        width={"15%"}
        justifyContent={"flex-start"}
        breakPointJustifyContent={"center"}
        breakPoint={"680px"}
        breakPointwidth={"30%"}
        breakPointDisplay={"none"}
      >
        <EventsIcon
          size={23}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </FlexibleDiv>
      {/* Value */}
      <FlexibleDiv
        width={"85%"}
        direction={"column"}
        alignItems={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"70%"}
      >
        {/* Title */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            margin={"0px"}
            color={colorTheme.black.soft}
            fontWeight={"500"}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"14px"}
          >
            Events
          </Text>
        </FlexibleDiv>
        {/* Value */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"680px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontWeight={"700"}
            fontSize={fontSizes.desktop_details}
            resFontSize={"14px"}
          >
            {users_events?.length}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};
const Events_Attended = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const attendedEvents = useSelector(
    ({ user_ticket }) => user_ticket?.users_tickets?.events_attended
  );
  return (
    <FlexibleDiv
      width={"30%"}
      breakPointwidth={"auto"}
      justifyContent={"flex-start"}
      breakPoint={"780px"}
      breakPointAlign={"flex-start"}
      breakPointJustifyContent={"center"}
    >
      {/* Icons */}
      <FlexibleDiv
        width={"15%"}
        justifyContent={"flex-start"}
        breakPointJustifyContent={"center"}
        breakPoint={"680px"}
        breakPointwidth={"30%"}
        breakPointDisplay={"none"}
      >
        <EventsIcon
          size={23}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </FlexibleDiv>
      {/* Value */}
      <FlexibleDiv
        width={"85%"}
        direction={"column"}
        alignItems={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"70%"}
      >
        {/* Title */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            margin={"0px"}
            color={colorTheme.black.soft}
            fontWeight={"500"}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"14px"}
          >
            Events
          </Text>
        </FlexibleDiv>
        {/* Value */}
        <FlexibleDiv
          padding={"0px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"center"}
        >
          <Text
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontWeight={"700"}
            fontSize={fontSizes.desktop_details}
            resFontSize={"14px"}
          >
            {attendedEvents?.length}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};
