import styled from "styled-components";
import { Text } from "../../primaryComponents/typography/typography.component";

export const OrganizerCardStyle = styled.div`
  /* flex: ${({ flex }) => (flex ? flex : "0.3%")}; */
  width: 25%;
  margin: 10px;
  background-color: ${({ bg }) => (bg ? bg : "#FFF")};
  border-radius: 5px;
  border: ${({ border }) => (border ? border : ".8px solid lightgray")};
  padding: 10px;
  cursor: pointer;
  @media only screen and (max-width: 780px) {
    flex: inherit;
    width: 35%;
  }
  @media only screen and (max-width: 680px) {
    margin-top: 10px;
  }
`;

export const FollowerText = styled(Text)`
  @media only screen and (max-width: 680px) {
    margin: 0px;
  }
`;

export const UsernameText = styled(Text)`
  text-align: center;
  @media only screen and (max-width: 680px) {
    margin: 0px;
  }
`;
