import { useDispatch, useSelector } from "react-redux";
import { Label } from "../../../pages/Settings/styles";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import { useEffect } from "react";
import { colorTheme } from "../../../infrastructure/Theme/color";
import {
  edit_phoneNo_handler,
  start_edit_handler,
  toggleProfileEditted
} from "../../../state management/Redux/slices/user_slice/edit_profile_slice";

const EditMobileNo = ({ formik, value }) => {
  const dispatch = useDispatch();
  const darkmode = useSelector(
    ({ persistedReducer }) => persistedReducer?.theme?.darkmode
  );
  const phone = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.phone
  );

  const phone_no = useSelector(({ edit_profile }) => edit_profile?.phone_no);

  const editPhoneNoHandler = (e) => {
    const value = e.target.value;
    dispatch(edit_phoneNo_handler(value));
    formik?.handleChange("phone")(value);
  };

  useEffect(() => {
    if (phone_no === phone) {
      dispatch(start_edit_handler(false));
      dispatch(toggleProfileEditted(false));
    } else if (phone_no !== phone) {
      dispatch(start_edit_handler(true));
      dispatch(toggleProfileEditted(true));
    }
  }, [phone_no, dispatch, phone]);

  const inputColor = darkmode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  return (
    <>
      <FlexibleDiv margin={"0px 0px 20px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} padding={"7px 0px"}>
          <Label>Mobile Number</Label>
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Input
            value={value}
            color={inputColor}
            onChange={(e) => {
              editPhoneNoHandler(e);
            }}
            type={"tel"}
            borderBottom={"2.3px solid lightgrey"}
            fontSize={"14px"}
            width={"100%"}
            padding={"5px 5px 14px 5px"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default EditMobileNo;
