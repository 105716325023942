import { useSelector } from "react-redux";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import Title from "./title.component";
import { EditIcon } from "../../../components/primaryComponents/icons/icons";
import { useNavigate, useNavigation } from "react-router-dom";

const About = ({ display, bio, showBorderBottom }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const navigate = useNavigate();
  return (
    <>
      <FlexibleDiv
        display={display ? display : "flex"}
        padding={"0px 0px 30px 0px"}
        borderBottom={showBorderBottom ? "1px solid lightgrey" : "none"}
      >
        <FlexibleDiv
          padding={"0px 0px"}
          justifyContent={"flex-start"}
          gap={"12px"}
        >
          <FlexibleDiv width={"90%"}>
            <Title
              title={"About"}
              color={darkMode ? colorTheme.white.white : "#000"}
              size={"15px"}
            />
          </FlexibleDiv>
          <EditIcon
            size={18}
            color={colorTheme.primary_color}
            onClick={() => {
              navigate("/settings");
            }}
          />
        </FlexibleDiv>

        <FlexibleDiv justifyContent={"flex-start"} lineHeight={"1.3rem"}>
          <Text
            style={{ lineHeight: "30px" }}
            fontSize={fontSizes.mobile_details}
            fontWeight={"400"}
            resFontSize={fontSizes.mobile_details}
            title={"About"}
            color={
              darkMode
                ? bio
                  ? colorTheme.white.fadingWhite
                  : colorTheme.lightgrey
                : "#000"
            }
          >
            {bio
              ? bio
              : "Introduce yourself to the community! 🎉 Let everyone know who's behind the scenes. Share your passion for events, your expertise, or any exciting projects you're working on. Your bio is your stage – make it eventful! 📅✨"}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default About;
