import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../primaryComponents/typography/typography.component";
import Slider from "react-slick";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../infrastructure/Theme/color";
import "./homeCategories.css";
import { useEffect } from "react";
import { get_categories } from "../../state management/Redux/slices/categories/get_categories_slice";
import { CONSTANTS } from "../../utilities/constants";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Category_Card = styled.div`
  width: 421px !important;
  height: 231px;
  border-radius: 10px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
      ${({ bgColor }) =>
        bgColor ? bgColor : "rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)"}
    ),
    url(${({ image }) => (image ? image : "")});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 980px) {
    width: 350px !important;
    height: 200px !important;
  }
  @media only screen and (max-width: 780px) {
    width: 200px !important;
    height: 150px !important;
  }
`;

const HomeCategories = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const categories = useSelector(
    (state) => state.persistedReducer.categories.category_list
  );
  const loading = useSelector(
    (state) => state.persistedReducer.categories.loading
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (categories?.length < 1) {
      dispatch(get_categories({ token: token }));
    }
  }, [categories]);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      }
    ]
  };
  return (
    <FlexibleDiv margin={"20px 0px"}>
      <FlexibleDiv
        width={"96%"}
        justifyContent={"flex-start"}
        breakPoint={"680px"}
        breakPointwidth={"100%"}
        breakPointPadding={"0px 0px 0px 23px"}
      >
        <HeaderText
          fontSize={fontSizes.desktop_subtitle}
          resFontSize={"14.5px"}
          color={darkMode ? colorTheme.white.white : "#000"}
        >
          Browse the free events on Attend
        </HeaderText>
      </FlexibleDiv>

      <div className="categories-row-carousel">
        {/* {!loading ? (
          <FlexibleDiv>
            {Array.from({ length: 3 }).map(() => (
              <FlexibleDiv width={"230px"} bg={"red"} />
            ))}
          </FlexibleDiv>
        ) : categories?.length > 0 ? (
          <Slider {...settings}>
            {categories.map(({ name, placeholder, slug }) => (
              <Link to={`/category/${slug}`}>
                <Category_Card image={`${CONSTANTS.imgbaseurl}${placeholder}`}>
                  <Text
                    style={{ textAlign: "center" }}
                    fontWeight={"700"}
                    color={colorTheme.white.white}
                    resFontSize={"13.5px"}
                    fontSize={fontSizes.mobile_title - "4px"}
                  >
                    {name}
                  </Text>
                </Category_Card>
              </Link>
            ))}
          </Slider>
        ) : null} */}
        {loading ? (
          <FlexibleDiv
            width={"100%"}
            breakPoint={"670px"}
            breakPointPadding={"0px 0px"}
          >
            {Array.from({ length: 3 }).map(() => (
              <FlexibleDiv
                width={"30%"}
                radius={"10px"}
                breakPoint={"670px"}
                height={"231px"}
                breakPointwidth={"25%"}
                breakPointHeight={"100px"}
                bg={colorTheme.black.fadingBlack}
              />
            ))}
          </FlexibleDiv>
        ) : categories?.length > 0 ? (
          <Slider {...settings}>
            {categories.map(({ name, placeholder, slug }) => (
              <Link to={`/category/${slug}`}>
                <Category_Card image={`${CONSTANTS.imgbaseurl}${placeholder}`}>
                  <Text
                    style={{ textAlign: "center" }}
                    fontWeight={"700"}
                    color={colorTheme.white.white}
                    resFontSize={"13px"}
                    fontSize={"16px"}
                  >
                    {name}
                  </Text>
                </Category_Card>
              </Link>
            ))}
          </Slider>
        ) : null}
      </div>
    </FlexibleDiv>
  );
};

export default HomeCategories;
