import React from "react";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text,
} from "../../primaryComponents/typography/typography.component";
import EditEventCrew from "./editEventCrew.component";
import EventGuest from "./eventGuest.component";

const EditAdditionalInfo = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const visibility = useSelector(
    ({ event_details }) => event_details?.data?.event?.visibility
  );
  const bg = visibility === "PRIVATE";
  return (
    <FlexibleDiv margin={"40px 0px"}>
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        lineHeight={"25px"}
        padding={"23px 0px"}
      >
        <HeaderText
          margin={"0px"}
          fontSize={"20px"}
          fontWeight={"600"}
          resFontSize={"14.5px"}
          color={titleColor}
        >
          Additional information
        </HeaderText>
        <Text
          margin={"0px"}
          fontSize={"15px"}
          color={colorTheme.textColor}
          resFontSize={"14.8px"}
        >
          Is this a private or public event?
        </Text>
      </FlexibleDiv>

      <FlexibleDiv justifyContent={"flex-start"} gap={"12px"}>
        <FlexibleDiv
          width={"113px"}
          bg={bg ? colorTheme.primary_color : "#F2F3F7"}
          radius={"5px"}
          padding={"10px"}
          style={{ cursor: "pointer" }}
        >
          <Text
            color={bg ? colorTheme.white.white : colorTheme.black.strong}
            cursor={"pointer"}
            margin={"0px"}
            fontSize={"14px"}
            resFontSize={"14px"}
          >
            Private
          </Text>
        </FlexibleDiv>
        <FlexibleDiv
          width={"113px"}
          bg={bg ?  "#F2F3F7" :  colorTheme.primary_color}
          radius={"5px"}
          padding={"10px"}
          style={{ cursor: "pointer" }}
        >
          <Text
            cursor={"pointer"}
            margin={"0px"}
            fontSize={"14px"}
            color={bg ?  colorTheme.black.strong : colorTheme.white.white }
            resFontSize={"14px"}
          >
            Public
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>

      {/* <EventGuest />
      <EditEventCrew /> */}
    </FlexibleDiv>
  );
};

export default EditAdditionalInfo;
