import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../../components/primaryComponents/input/input.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { useDispatch,useSelector } from "react-redux";
import {  nameOfEventValue } from "../../../../state management/Redux/slices/create_event_slice";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { useCreateEvent } from "../../createEvent Context";

const NameOfEvent = ({}) => {
    const dispatch = useDispatch()
    const handleChange = useCreateEvent()?.[4]
    const errors = useCreateEvent()?.[2]
    const touched = useCreateEvent()?.[3]
    const values = useCreateEvent()?.[0]
    const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
    const InputHandler=(e)=>{
        let Value = e.target.value
        handleChange("title")(e);
        dispatch( nameOfEventValue(Value))
    }
    return ( 

        <>
        <FlexibleDiv direction={'column'} alignItems={'flex-start'} padding={"0px 0px"} lineHeight={"1.9em"} >
           <FlexibleDiv justifyContent={'flex-start'} gap={'12px'} >
           <Text fontSize={fontSizes.mobile_details} resFontSize={"14px"}  fontWeight={"500"}   color={darkMode ? colorTheme.textColor : colorTheme.black.strong} >
                Name Of Event
            </Text>
           {errors.title && touched.title &&  <Text color={colorTheme.red} fontSize={fontSizes.mobile_smallest} resFontSize={fontSizes.mobile_smallest} >
           ({errors.title})
           </Text> }
           </FlexibleDiv>

           <FlexibleDiv width={"95%"} breakPoint={"680px"} breakPointwidth="97%" >
           <Input  value={values.title} onChange={(e)=>{InputHandler(e)}} placeHolder={'Event title'} placeholderSize={'13px'} fontSize={'14px'} padding={'16px'} width={"100%"} border={`1px solid${colorTheme.lightgrey}`} radius={"5px"} color={darkMode ? colorTheme.white.white :colorTheme.black.strong} />
           </FlexibleDiv>
        </FlexibleDiv>
        </>
     );
}
 
export default NameOfEvent;