import { configureStore } from "@reduxjs/toolkit";
import createPaymentMethod from "../slices/createPaymentMethod";
import create_event_slice, { addMainImage } from "../slices/create_event_slice";
import organizerPageSlice from "../slices/organizerPageSlice";
import userAccountSlice from "../slices/userAccountSlice";
import SignUpSlice from "../slices/auth_slice/signUp";
import LoginSlice from "../slices/auth_slice/logIn";
import userSlice from "../slices/user_slice";
import storage from "redux-persist/lib/storage";
import EventSlice from "../slices/events/get_event_slice";
import {
  //   persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import { combineReducers } from "redux";
import themeSlice from "../slices/theme_slice";
import get_event_details_slice from "../slices/events/get_event_details_slice";
import get_categories_slice from "../slices/categories/get_categories_slice";
import user_events_slice from "../slices/userAccountSlice/user_events_slice";
import create_ticket_slice from "../slices/editEvent/create_ticket_slice";
import edit_details_slice from "../slices/editEvent/edit_details_slice";
import contact_us from "../slices/contact_us";
import forgot_password_slice from "../slices/forgot_password_slice";
import search_slice from "../slices/search/search_slice";
import edit_profile_slice from "../slices/user_slice/edit_profile_slice";
import buy_ticket_slice from "../slices/payment_slice/buy_ticket_slice";
import get_single_category_slice from "../slices/categories/get_single_category_slice";
import organizers_following from "../slices/organizers_following/organizers_following_slice";
import ticketValidation_slice from "../slices/payment_slice/ticketValidation_slice";
import user_ticket_slice from "../slices/user_slice/user_ticket_slice";
import list_of_organizers from "../slices/list_of_organizers";
import cover_image from "../slices/cover-image";
import routesSlice from "../slices/routes-slice";
const persistConfig = {
  key: "root",
  storage
};

const REDUCERS_TO_BE_PERSISTED = combineReducers({
  theme: themeSlice,
  user: userSlice,
  categories: get_categories_slice,
  routes:routesSlice
});
const persistedReducer = persistReducer(
  persistConfig,
  REDUCERS_TO_BE_PERSISTED
);
const Store = configureStore({
  reducer: {
    createEvent: create_event_slice,
    loggedInUser: userAccountSlice,
    organizerPage: organizerPageSlice,
    createPaymentMethod: createPaymentMethod,
    signUp: SignUpSlice,
    login: LoginSlice,
    events: EventSlice,
    user_events: user_events_slice,
    event_details: get_event_details_slice,
    create_ticket: create_ticket_slice,
    edit_event: edit_details_slice,
    contact_us: contact_us,
    forgot_password: forgot_password_slice,
    search: search_slice,
    edit_profile: edit_profile_slice,
    buy_ticket: buy_ticket_slice,
    single_category: get_single_category_slice,
    organizers_following: organizers_following,
    ticket_validation: ticketValidation_slice,
    user_ticket: user_ticket_slice,
    list_of_organizers: list_of_organizers,
    user_cover_image: cover_image,
    persistedReducer
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          addMainImage.type
        ]
      }
    })
});

export default Store;
