import styled from "styled-components";
import { colorTheme } from "../../infrastructure/Theme/color";
export const TagBlob = styled.div`
width:120px;
padding: 1px;
border-radius: 9px;
background-color: ${colorTheme.black.light};
text-align: center;
`

export const MainStyle = styled.main`
  /* background: white; */
  border-radius: 4px;
  position: relative;
  top: 30%;
  max-width: 2080px;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  margin-bottom: 30px;
  padding: 23px 0px;
  /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%); */

  @media screen and (max-width: 880px) {
    margin-top: 43px;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 796px) {
    top: auto;
  }
  @media screen and (max-width: 780px) {
   margin-top: auto;
  }
`;


export const EventDetailsWrapper = styled.div`
width: 100%;
height: 100%;
position: relative;
margin-top: 20px;
overflow-x: hidden;
`

export const Column = styled.div`
width:100%;
display: flex;
position: relative;
flex-direction:column;
align-items: flex-end;
justify-content: flex-start;

@media screen and (max-width:890px) {
display: none;
}
`