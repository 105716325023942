import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { FingerPrintIcon } from "../../components/primaryComponents/icons/icons";
import { colorTheme } from "../../infrastructure/Theme/color";
import Logo from "../../components/secondaryComponents/logo/logo.component";
import {
  HeaderText,
  Text
} from "../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import {
  AuthLayout,
  ImageColumn,
  InputFieldColumn
} from "../Login-SignUp/styles";
import LoginImage from "../../assets/images/Login.jpg";
import Button from "../../components/primaryComponents/button/button.component";
import OTPInput from "react-otp-input";
import { useEffect, useState } from "react";
import Loader from "../../components/secondaryComponents/loader/loader.component";
import { verify_otp } from "../../state management/Redux/slices/forgot_password_slice";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const OTP = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const verifyingOtp = useSelector(
    ({ forgot_password }) => forgot_password?.verifyingOtp
  );
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(false);

  const background = disable
    ? colorTheme.lightgrey
    : verifyingOtp
    ? colorTheme.white.white
    : colorTheme.primary_color;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (otp.length < 5) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [otp]);
  return (
    <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - OTP</title>
      </Helmet>
      <AuthLayout
        bg={darkMode ? colorTheme.black.darkmode1 : colorTheme.white.white}
      >
        <ImageColumn image={LoginImage}>
          <FlexibleDiv direction={"column"} padding={"0rem 0rem 5rem 0rem"}>
            <FlexibleDiv justifyContent={"flex-start"} padding={"0px 22px"}>
              <HeaderText
                lineHeight={"2.3rem"}
                fontSize={fontSizes.desktop_subtitle}
                color={"white"}
              >
                Discover awesome events
                <br /> around you
              </HeaderText>
            </FlexibleDiv>
          </FlexibleDiv>
        </ImageColumn>
        <InputFieldColumn style={{ position: "relative" }}>
          <FlexibleDiv
            position={"absolute"}
            top={"0px"}
            left={"0px"}
            right={"0px"}
            justifyContent={"space-evenly"}
            breakPoint={"780px"}
            breakPointJustifyContent={"space-between"}
            breakPointMargin={"auto"}
            breakPointwidth={"90%"}
            breakPointPadding={"43px 0px"}
            padding={"18px 0px"}
          >
            <Logo darkMode={darkMode} />
          </FlexibleDiv>
          <FlexibleDiv
            width={"100%"}
            direction={"column"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            margin={"20px 0px 0px 0px"}
          >
            {/* Title */}
            <FlexibleDiv
              width={"65%"}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"0px 0px 12px 0px"}
              breakPoint={"680px"}
              breakPointwidth={"90%"}
              breakPointdirection={"column"}
              breakPointAlign={"center"}
            >
              <FlexibleDiv
                margin={"0px 0px 25px 0px"}
                justifyContent={"center"}
                breakPoint={"680px"}
                breakPointJustifyContent={"center"}
              >
                <FlexibleDiv
                  width={"35px"}
                  height={"30px"}
                  border={`1.4px solid ${colorTheme.black.light}`}
                  padding={"8px"}
                  radius={"7px"}
                >
                  <FingerPrintIcon
                    size={23}
                    color={
                      darkMode
                        ? colorTheme.white.white
                        : colorTheme.black.strong
                    }
                  />
                </FlexibleDiv>
              </FlexibleDiv>

              <HeaderText
                margin={"0px"}
                fontSize={fontSizes.mobile_title}
                resFontSize={fontSizes.mobile_title}
                smallScreenFontSize={fontSizes.mobile_title}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              >
                OTP Code
              </HeaderText>
              <Text
                margin={"0px"}
                lineHeight={"43px"}
                color={colorTheme.textColor}
                fontSize={"13px"}
                fontWeight={"500"}
                resFontSize={"13px"}
              >
                Fill in the code you got from your email.
              </Text>
            </FlexibleDiv>

            <FlexibleDiv direction={"column"}>
              <FlexibleDiv
                direction={"column"}
                width={"70%"}
                breakPoint={"780px"}
                breakPointwidth={"90%"}
                breakPointJustifyContent={"center"}
              >
                <FlexibleDiv
                  justifyContent={"center"}
                  alignItems={"center"}
                  breakPoint={"680px"}
                  breakPointJustifyContent={"center"}
                >
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    inputStyle={{
                      width: "2em",
                      height: "2em",
                      outline: "none",
                      border: "none",
                      color: darkMode
                        ? colorTheme.white.white
                        : colorTheme.black.strong,
                      backgroundColor: darkMode
                        ? colorTheme.black.soft
                        : colorTheme.lightgrey,
                      fontSize: 18
                    }}
                    numInputs={5}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input typeof="number" itemType="number" {...props} />
                    )}
                  />
                </FlexibleDiv>
                <Button
                  onClick={() => {
                    dispatch(verify_otp({ code: otp, navigate: navigate }));
                  }}
                  disabled={disable}
                  background={background}
                  hoverBackground={
                    verifyingOtp
                      ? colorTheme.white.white
                      : colorTheme.primary_color
                  }
                  hoverBorder={"none"}
                  hoverColor={"#FFF"}
                  type={"submit"}
                  radius={"5px"}
                  style={{ marginTop: "25px" }}
                  padding={"14px"}
                  width={"60%"}
                >
                  {verifyingOtp ? (
                    <Loader width={"30px"} height={"30px"} />
                  ) : (
                    "Verify Otp"
                  )}
                </Button>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </InputFieldColumn>
      </AuthLayout>
    </>
  );
};

export default OTP;
