import { useSelector } from "react-redux";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import CoverImage from "../../../components/secondaryComponents/coverImage/coverImage.component";
import TICKET_FOLLOWERS from "./ticket_followers";
import UserInfo from "./userInfo";
// import { getEventAttended } from "../../../services/eventsAttended";
// import { useEffect } from "react";
import { CONSTANTS } from "../../../utilities/constants";
import no_cover_image from "../../../assets/images/no_cover_image.png";
const TopSection = ({
  searchedAccount,
  name,
  following,
  followers,
  tickets,
  profileImage,
  userStatus,
}) => {
  const cover_image = useSelector(
    (state) => state.persistedReducer.user.userInfo?.cover_image
  );
  const organizerStatus = useSelector(
    (state) => state.persistedReducer.user.userInfo?.organizer
  );

  const is_organizer = organizerStatus === 1 ? true : false;
  return (
    <>
      <FlexibleDiv>
        {is_organizer && (
          <CoverImage
            img={
              cover_image !== null
                ? `${CONSTANTS.imgbaseurl}${cover_image}`
                : no_cover_image
            }
            type={searchedAccount ? "organizer" : "user"}
          />
        )}
        <UserInfo
          name={name}
          searchedAccount={searchedAccount}
          userStatus={userStatus}
          profileImage={profileImage}
        />
        <TICKET_FOLLOWERS
          searchedAccount={searchedAccount}
          userStatus={userStatus}
          followers={followers}
          following={following}
          tickets={tickets}
        />
      </FlexibleDiv>
    </>
  );
};

export default TopSection;
