import { Text } from "../../primaryComponents/typography/typography.component";
import { Image } from "../../primaryComponents/image/image";
import { FollowerText, OrganizerCardStyle, UsernameText } from "./style";
import no_img from "../../../assets/images/no_user.png";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../utilities/constants";
import { unfollowOrganizer } from "../../../services/unfollow_organizer";
import { followOrganizer } from "../../../services/follow_organizer";
import { setFollowing } from "../../../state management/Redux/slices/events/get_event_details_slice";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const OrganizerCard = ({ name, username, id, profile_image, flex }) => {
  const [subscribeMessage, setSubscribedMessage] = useState("Follow");
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.token
  );
  const organizersFollowed = useSelector(
    ({ organizers_following }) => organizers_following?.data
  );
  const [isFollowing, setIsFollowing] = useState(false);
  const navigate = useNavigate();
  const subscribeHandler = () => {
    if (!token) {
      navigate("/login");
    } else {
      if (isFollowing) {
        dispatch(
          unfollowOrganizer({
            organizerId: id,
          })
        );
        dispatch(setFollowing(false));
        setSubscribedMessage("Follow");
        setIsFollowing(false);
      } else {
        dispatch(
          followOrganizer({
            organizerId: id,
          })
        );
        dispatch(setFollowing(true));
        setSubscribedMessage("Following");
        setIsFollowing(true);
      }
    }
  };
  useEffect(() => {
    if (isFollowing) {
      setSubscribedMessage("Following");
    }
  }, []);
  useEffect(() => {
    const check = organizersFollowed?.find((organizer) => organizer?.id === id);
    console.log("check", check);
    if (check) {
      setIsFollowing(true);
      setSubscribedMessage("Following");
    } else {
      setIsFollowing(false);
      setSubscribedMessage("Follow");
    }
  }, []);
  return (
    <>
      <OrganizerCardStyle
        flex={flex}
        bg={darkMode ? colorTheme.black.fadingBlack : ""}
        border={darkMode ? ".3px solid #131313" : ""}
      >
        {/* Image */}
        <FlexibleDiv justifyContent={"center"}>
          <Image
            width={"60px"}
            height={"60px"}
            radius={"60px"}
            src={
              profile_image !== null
                ? `${CONSTANTS.profileImageUrl}${profile_image}`
                : no_img
            }
          />
        </FlexibleDiv>
        {/* Name of Organizer */}
        <FlexibleDiv
          direction={"column"}
          justifyContent={"center"}
          margin={"12px 0px"}
        >
          {/* <Text
            margin={"5px 0px 0px 0px"}
            fontSize={fontSizes.mobile_details}
            fontWeight={"600"}
            resFontSize={"13px"}
            color={darkMode ? colorTheme.white.white : "#000"}
          >
            {username}
          </Text> */}
          <UsernameText
            margin={"4px 0px"}
            fontSize={fontSizes.mobile_details}
            color={darkMode ? "#FFF" : colorTheme.lightgrey}
            fontWeight={"500"}
            resFontSize={"9px"}
          >
            {username}
          </UsernameText>
        </FlexibleDiv>

        {/* Followers */}
        {/* <FlexibleDiv
          direction={"column"}
          justifyContent={"center"}
          lineHeight={"30px"}
        >
          <FollowerText
            margin={"0px"}
            fontSize={fontSizes.mobile_details}
            fontWeight={"500"}
            color={ darkMode ? '#FFF' :colorTheme.lightgrey}
            resFontSize={'13px'}
          >
            {5000} followers
          </FollowerText>
        </FlexibleDiv> */}
        {/* Follow Btn */}

        <FlexibleDiv margin={"0px 0px 20px 0px"}>
          <Button
            onClick={() => {
              subscribeHandler();
            }}
            width={"100px"}
            hoverColor={isFollowing ? "none" : "white"}
            hoverBackground={isFollowing ? "transparent" : colorTheme.blue}
            background={isFollowing ? "transparent" : colorTheme.blue}
            hoverBorder={
              isFollowing
                ? `0.3px solid ${colorTheme.blue}`
                : "0.9px solid skyblue"
            }
            border={darkMode ? "0.2px solid #6F767E" : "2px solid grey"}
            radius={"10px"}
            padding={"4px"}
            color={darkMode ? colorTheme.white.white : "#000"}
          >
            {subscribeMessage}
          </Button>
        </FlexibleDiv>
      </OrganizerCardStyle>
    </>
  );
};

export default OrganizerCard;

// color={"white"}
// border={isFollowing ? `0.3px solid ${colorTheme.blue}` : "none"}

// width={"90px"}
// radius={"5px"}
// padding={"8px"}
// >
