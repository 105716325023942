

import React from 'react'
import FlexibleDiv from '../../primaryComponents/flexibleDiv/flexibleDiv.component'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './styles.css'

ChartJS.register(ArcElement, Tooltip, Legend);
export const data = {
    labels: ['Registered','Viewed'],
    datasets: [
      {
        label: ['analytics'],
        data: [80,20],
        backgroundColor: [
          '#407BFF',
          '#FCAE1E',
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 1,
      },
    ],
   Option:{
    responsiveness:true
   },
  };
const  EventChart=()=> {
  return (
    <FlexibleDiv width={'30%'} breakPoint={"780px"} breakPointDisplay={"none"}  >
           <Doughnut width={'200px !important'} height={'200px !important'} data={data} />
        </FlexibleDiv>
  )
}

export default EventChart