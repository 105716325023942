import EditProfile from "../../components/secondaryComponents/editProfile/editProfile.component";
import ManageBilling from "../../components/secondaryComponents/manageBilling/manageBilling.component";
import PasswordSetting from "../../components/secondaryComponents/passwordSetting/passwordSetting.component";

export const TabSwitch = (tabName) => {
  switch (tabName) {
    case "Edit Profile":
      return <EditProfile />;
    case "Password":
      return <PasswordSetting />;
    default:
      return <EditProfile />;
  }
};
// export const TabSwitch = (tabName) => {
//   switch (tabName) {
//     case "Password":
//       return <PasswordSetting />;
//     default:
//       return <PasswordSetting />;
//   }
// };
