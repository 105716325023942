import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "../../../../components/primaryComponents/image/image";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { DropZone } from "./style";
import React, { useState } from "react";
import { ImageIcon } from "../../../../components/primaryComponents/icons/icons";
import { addExtraImages } from "../../../../state management/Redux/slices/create_event_slice";
import { useCreateEvent } from "../../createEvent Context";

const MediaText = styled(Text)`
  text-align: center;
  @media only screen and (max-width: 780px) {
    font-size: small;
  }
`;

const MediaCard = ({ item, id }) => {
  const extraImages = useSelector((state) => state.createEvent.extraImages);
  const [imageFile, setImageFile] = useState(null);
  const dispatch = useDispatch();
  const [imgSelected, setImgSelected] = useState(false);
  const handleChange = useCreateEvent()?.[4];
  const InputHandler = (e) => {
    const [file] = e.target.files;
    const ID = e.target.id;
    if (e.target.files && e.target.files[0]) {
      setImgSelected(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        const fileData = {
          name: file.name,
          size: file.size,
          type: file.type
        };

        dispatch(addExtraImages({ ID, file: fileData }));
        setImageFile(base64String);
        // dispatch(addExtraImages({ ID, file: base64String }));
      };
    } else {
      setImgSelected(false);
      return;
    }
  };

  let singleImage = extraImages.map((state) => {
    return state.image;
  });
  return (
    <>
      <DropZone
        width={"25%"}
        htmlFor={id}
        breakPoint={"680px"}
        breakPointwidth={"45%"}
        breakPointdirection={"column"}
        backgroundHover={singleImage[id] ? "" : "#0000000a"}
        padding={singleImage[id] ? "0px" : "23px 0px"}
      >
        <Image
          src={`data:image/png;base64,${imageFile}`}
          // src={singleImage[id]}
          height={singleImage[id] ? "150px" : ""}
          width={singleImage[id] ? "100%" : ""}
          style={{ display: imgSelected ? "block" : "none" }}
        />
        <input
          onChange={(e) => {
            InputHandler(e);
          }}
          required
          id={id}
          type={"file"}
          style={{ display: "none" }}
        />

        {singleImage[id] ? null : (
          <ImageIcon color={colorTheme.primary_color} size={50} />
        )}

        {singleImage[id] ? null : (
          <MediaText
            cursor={"pointer"}
            fontSize={fontSizes.mobile_details}
            fontWeight={"500"}
            resFontSize={"12px"}
          >
            {" "}
            <span style={{ color: colorTheme.primary_color }}>
              Browse Images
            </span>
          </MediaText>
        )}
      </DropZone>
    </>
  );
};

export default MediaCard;
