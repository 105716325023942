import axios from "axios";
import { CONSTANTS } from "../../../../utilities/constants";
import { toast } from "react-toastify";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const contactUsHandler = createAsyncThunk(
  "user/contact-us",
  async ({ name, email, subject, message,action }, { rejectWithValue }) => {
    try {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Credentials": true,
        },
      };

      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("subject", subject);
      formData.append("message", message);

      const response = await axios.post(
        `${CONSTANTS.baseURL}api/contact`,
        formData,
        config,
      );
      if (response?.status === 200) {
        toast.success(response?.data?.success)
        action?.resetForm()
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorObject = error.response.data.error;
        for (const field in errorObject) {
          if (errorObject.hasOwnProperty(field)) {
            const errorMessage = errorObject[field];
            toast.error(`${field}: ${errorMessage}`);
          }
        }
      } else {
        toast.error("An error occurred during sending message.");
      }

      return rejectWithValue(error?.response?.data?.error);
    }
  }
);
const initialState = {
  loading: false,
  isError: false,
  isSuccess: false,
  error: null,
};

const Contact_Us_Slice = createSlice({
  name: "contact_us_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(contactUsHandler.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
        state.isError = false;
      })
      .addCase(contactUsHandler.fulfilled, (state) => {
        state.loading = false;
        state.isSuccess = true;
      })
      .addCase(contactUsHandler.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.error = payload;
      });
  },
});

export default Contact_Us_Slice.reducer;
