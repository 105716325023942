import moment from "moment";
export const formatDateToDatetimeLocal = (date) => {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1)?.padStart(2, "0");
  const day = String(date?.getDate())?.padStart(2, "0");
  const hours = String(date?.getHours())?.padStart(2, "0");
  const minutes = String(date?.getMinutes())?.padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
  return {formattedDate,year,month,day,hours,minutes};
};

export const dateDateFormatter = (startDate, endDate) => {
  const parsedStartDate = moment(startDate);
  const parsedEndDate = moment(endDate);

  const formattedStartDate = parsedStartDate.format("MMM D, YYYY");
  const dayOfWeekStart = parsedStartDate.format("ddd");
  const formattedStartTime = parsedStartDate.format("h:mm A");
  const formattedStartDay = parsedStartDate.format("D")
  const formattedStartMonth = parsedStartDate.format("MMM")

  const formattedEndDate = parsedEndDate.format("MMM D, YYYY");
  const dayOfWeekEnd = parsedEndDate.format("ddd");
  const formattedEndTime = parsedEndDate.format("h:mm A");
  const formattedEndDay = parsedEndDate.format("D")
  const formattedEndMonth = parsedEndDate.format("MMM")
  let timeRange = `${formattedStartTime} - ${formattedEndTime}`;

  let isRecurring = false;
  if (parsedStartDate.format("h:mm A") === parsedEndDate.format("h:mm A")) {
    isRecurring = true;
    timeRange = formattedStartTime;
  }

  return {
    first: {
      dayOfWeek: dayOfWeekStart,
      formattedTime: formattedStartTime,
      formattedDate: formattedStartDate,
      formattedStartDay:formattedStartDay,
      formattedStartMonth:formattedStartMonth
    },
    second: {
      dayOfWeek: dayOfWeekEnd,
      formattedTime: formattedEndTime,
      formattedDate: formattedEndDate,
      formattedEndDay:formattedEndDay,
      formattedEndMonth:formattedEndMonth
    },
    timeRange: timeRange,
    isRecurring: isRecurring,
  };
};
