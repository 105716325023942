import { useDispatch, useSelector } from "react-redux";
import { AccountUserType } from "..";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { AddIcon } from "../../../components/primaryComponents/icons/icons";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import EventCard from "../../../components/secondaryComponents/eventCard";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { useEffect } from "react";
import { get_user_events } from "../../../state management/Redux/slices/userAccountSlice/user_events_slice";
import EventLoaderCard from "../../../components/secondaryComponents/skeleton/eventCard_loader.component";
import { useNavigate } from "react-router-dom";
import { createUrlFriendlyString } from "../../../utilities/create-url-friendly-string.js";

const Events = () => {
  const [userType] = AccountUserType();
  const dispatch = useDispatch();
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const user_events = useSelector(
    ({ user_events }) => user_events?.user_events?.events
  );
  const loading_events = useSelector(
    ({ user_events }) => user_events?.loading_user_events
  );
  useEffect(() => {
    dispatch(get_user_events({ token: token }));
  }, []);
  return (
    <>
      <FlexibleDiv padding={"30px 0px"} justifyContent={"center"}>
        <FlexibleDiv
          justifyContent={"flex-start"}
          width={"85%"}
          breakPoint={"1024px"}
          breakPointJustifyContent={"center"}
          wrap={"wrap"}
          gap={"12px"}
        >
          {userType === "searched_organizer" ? null : <CREATE_NEW_EVENT />}

          {loading_events ? (
            <>
              {Array.from({ length: 12 }).map((index) => (
                <EventLoaderCard
                  width={"250px"}
                  breakPoint={"680px"}
                  breakPointwidth={"90%"}
                  key={Math.random()}
                />
              ))}
            </>
          ) : (
            user_events?.map(
              ({ id, startDate, cover_image, title, category, user }) => (
                <EventCard
                  link={`/manage/edit/${id}/${createUrlFriendlyString(title)}`}
                  key={id}
                  category={category && JSON?.parse(category)}
                  startDate={startDate}
                  organizer={user ? user : null}
                  title={title}
                  cover_image={cover_image}
                  width={"250px"}
                  breakPoint={"680px"}
                  breakPointwidth={"90%"}
                />
              )
            )
          )}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Events;

const CREATE_NEW_EVENT = () => {
  const navigate = useNavigate();
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv
        onClick={() => {
          navigate("/create");
        }}
        width={"250px"}
        shadow={"0 1px 2px 0 rgb(0 0 0 / 15%)"}
        radius={"10px"}
        border={`1.1px solid ${colorTheme.primary_color}`}
        borderStyle={"dotted"}
        breakPoint={"680px"}
        breakPointwidth={"90%"}
      >
        <FlexibleDiv
          bg={darkMode ? colorTheme.black.fadeMini : "#efefef"}
          justifyContent={"center"}
          padding={"23px"}
        >
          <FlexibleDiv
            justifyContent={"center"}
            shadow={"0 1px 2px 0 rgb(0 0 0 / 15%)"}
            radius={"50%"}
            width={"50px"}
            height={"50px"}
            padding={"3px"}
            bg={colorTheme.primary_color}
          >
            <AddIcon
              onClick={() => {
                navigate("/create");
              }}
              cursor={"pointer"}
              size={50}
              color={"white"}
            />
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          justifyContent={"center"}
          bg={darkMode ? colorTheme.black.fadingBlack : "#fff"}
          padding={"50px 0px"}
          breakPoint={"780px"}
          breakPointPadding={"10px 0px"}
        >
          <Text
            onClick={() => {
              navigate("/create");
            }}
            fontWeight={"500"}
            cursor={"pointer"}
            color={colorTheme.primary_color}
            fontSize={fontSizes.mobile_details}
            resFontSize={"13px"}
          >
            Create New Event
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};
