import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import ConversationForm from "./conversationForm.component";

const Conversation = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv direction={"column"}>
      <FlexibleDiv direction={"column"} alignItems={"flex-start"}>
        <HeaderText
          margin={"0px"}
          fontSize={fontSizes.desktop_subtitle}
          lineHeight={"34.73px"}
          resFontSize={"16px"}
          fontWeight={"600"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          Let’s start a conversation
        </HeaderText>
        <Text
          fontSize={"16px"}
          resFontSize={"14px"}
          lineHeight={"21.78px"}
          color="#A1A1A1"
          fontWeight={"500"}
        >
          Send us a message and we will be in touch within one business day.
        </Text>
      </FlexibleDiv>

      <ConversationForm />
    </FlexibleDiv>
  );
};

export default Conversation;
