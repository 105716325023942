import React, { useContext, createContext, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createEventSchema } from "../../../infrastructure/schemas/create_event_schema";
import { createEvent } from "../../../state management/Redux/slices/create_event_slice";
const CreateEventContext = createContext();

export const CreateEventStateProvider = ({ children }) => {
  const title = useSelector(({ createEvent }) => createEvent.nameOfEvent);
  const description = useSelector(({ createEvent }) => createEvent.description);
  const startDate= useSelector(({ createEvent }) => createEvent.eventStart);
  const endDate = useSelector(({ createEvent }) => createEvent.eventEnd);
  const category = useSelector(({ createEvent }) => createEvent.category);
  const main_image = useSelector(({ createEvent }) => createEvent.mainImage);
  const visibility = useSelector(({ createEvent }) => createEvent.privacy);
  const tags = useSelector(({ createEvent }) => createEvent.tags);
  const type = useSelector(({ createEvent }) => createEvent.formOfLocation);
  const [cover_image,setCoverImage] = useState(null)
  const location_name = useSelector(
    ({ createEvent }) => createEvent.nameOfLocation
  );
  const  extraImages= useSelector(
    ({ createEvent }) => createEvent.extraImages
  );

  const token = useSelector(({persistedReducer})=>persistedReducer?.user?.token);
  const data = {title,description,category,cover_image:cover_image,type,location_name,startDate,endDate,tags,visibility,token:token,extraImages}
  const dispatch = useDispatch();
  const onSubmit =async(values,action) => {
    // action.resetForm()
  dispatch(createEvent(data))
  };

  const { values,isSubmitting,errors,touched ,handleChange, handleSubmit } = useFormik({
    initialValues: {
      title,
      description,
      category,
      main_image:cover_image,
      type,
      visibility,
      location_name,
      extra_images:extraImages,
    },
    validationSchema: createEventSchema,
    onSubmit
  });
  return (
    <CreateEventContext.Provider
      value={[values, isSubmitting,errors,touched, handleChange, handleSubmit,setCoverImage,cover_image]}
    >
      {children}
    </CreateEventContext.Provider>
  );
};

export const useCreateEvent = () => {
  return useContext(CreateEventContext);
};
