import styled from "styled-components";

export const DropDownWrapper = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  padding: ${({ padding }) => (padding ? padding : "")};
  height: ${({ height }) => (height ? height : "")};
  background-color: ${({ bg }) => (bg ? bg : "#fff")};
  border-radius: ${({ radius }) => (radius ? radius : "5px")};
  border: ${({ border }) => (border ? border : "1px solid lightgrey")};
  position: relative;
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : "")};
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : "")};
  border-right: ${({ borderRight }) => (borderRight ? borderRight : "")};
  border-top: ${({ borderTop }) => (borderTop ? borderTop : "")};
  border-bottom-left-radius: ${({ bottomLeftRd }) =>
    bottomLeftRd ? bottomLeftRd : ""};
  border-bottom-right-radius: ${({ bottomRightRd }) =>
    bottomRightRd ? bottomRightRd : ""};
`;

export const ValueContainer = styled.div`
  padding-left: 15px;
  padding-right: 1px;
  padding: ${({ padding }) => (padding ? padding : "")};
  gap:${({gap})=>gap ? gap :''};
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 880px) {
    flex-direction: row;
  }
`;

export const OptionsContaniner = styled.div`
  width: 100%;
  background-color: ${({ bg }) => (bg ? bg : "#fff")};
  padding: 0px 0px;
  z-index: 999;
  position: absolute;
  left: -1px;
  right: 0px;
  top: 102%;
  display: ${({ display }) => (display ? display : "")};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : "")};
  border-left: ${({ borderLeft }) => (borderLeft ? borderLeft : "")};
  border-right: ${({ borderRight }) => (borderRight ? borderRight : "")};
  border-top: ${({ borderTop }) => (borderTop ? borderTop : "")};
  border-bottom-left-radius: ${({ bottomLeftRd }) =>
    bottomLeftRd ? bottomLeftRd : ""};
  border-bottom-right-radius: ${({ bottomRightRd }) =>
    bottomRightRd ? bottomRightRd : ""};
  overflow-x: hidden;
`;

export const Option = styled.option`
  font-size: 12px;
  line-height: 2.5rem;
  padding: 0px 15px 0px 15px;
  color: ${({color})=>color ?color :'#000'};
  font-weight: 500;
  position: relative;
  border-bottom: 0.1px solid #f7f7f7;
  background: ${({ background }) => (background ? background : "")};
  cursor: pointer;
  width: 100%;
  transition: background 0.5s ease-in-out;
  &:hover {
    background: ${({hoverBg})=>hoverBg ? hoverBg : '#f7f7f7'};
    color:${({hoverColor})=>hoverColor ? hoverColor : ''};
  }
`;
