import * as yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
export const start_Forgot_Password_Schema = yup.object().shape({
  email: yup.string().email("Invalid email").required()
});
export const complete_Forgot_Password_Schema = yup.object().shape({
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Password must at least be five characters")
});
