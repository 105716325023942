import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CONSTANTS } from "../../../../utilities/constants";
export const getEventDetails = createAsyncThunk(
  "user/get_event_details",
  async ({ token, event_id }, { rejectWithValue }) => {
    try {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${token}` // include token here
        }
      };
      const response = await axios.get(
        CONSTANTS.baseURL + `api/event/get/${event_id}`,
        config
      );
      // console.log("response", response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log("get event details error", error);
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: false,
  isSuccess: false,
  isError: false,
  data: [],
  error: null,
  isFollowing: false
};

const Event_Details_Slice = createSlice({
  name: "event_details_slice",
  initialState,
  reducers: {
    clearDetailsData: (state) => {
      state.loading = false;
      // state.isSuccess = false;
      // state.isError = false;
      // state.error = null;
      // state.data = [];
    },
    setFollowing: (state, { payload }) => {
      state.isFollowing = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventDetails.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getEventDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
        state.isFollowing = payload?.following;
      })
      .addCase(getEventDetails.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = payload;
      });
  }
});

export const { clearDetailsData, setFollowing } = Event_Details_Slice.actions;
export default Event_Details_Slice.reducer;
