import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ClientRequest from "../../../../api/api";

export const getUsersTickets = createAsyncThunk(
  "user/get-users-tickets",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/user/get/tickets");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const { response } = error;
      console.log("getting user ticket error", response);
      toast.error("Error occured while getting your tickets");
      return rejectWithValue(response?.message);
    }
  }
);

const initialState = {
  loading: false,
  status: "",
  error: null,
  users_tickets: []
};

const User_Ticket_Slice = createSlice({
  name: "user_ticket_slice",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getUsersTickets.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getUsersTickets.fulfilled, (state, { payload }) => {
        state.status = "fulfilled";
        state.users_tickets = payload;
      })
      .addCase(getUsersTickets.rejected, (state, { payload }) => {
        state.status = "rejected";
        state.error = payload;
      });
  }
});

export default User_Ticket_Slice.reducer;
