import Button from "../../../components/primaryComponents/button/button.component";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../components/primaryComponents/input/input.component";
import {
  HeaderText,
  Text
} from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { InputContainer, Label } from "../styles";
import { useSelector } from "react-redux";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./dropdown.css";

import { useFormContext } from "../../../state management/context/formContext";
import Loader from "../../../components/secondaryComponents/loader/loader.component";
const Step2 = ({ setFinishedStep2 }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const loading = useSelector(
    ({ persistedReducer }) => persistedReducer.user.signUpLoading
  );

  const Continue = () => {
    handleSubmit();
    setFinishedStep2(true);
  };

  const { values, handleChange, errors, handleSubmit, touched, setFieldValue } =
    useFormContext();
  return (
    <>
      <FlexibleDiv>
        <FlexibleDiv
          lineHeight={"2.7rem"}
          breakPointineHeight={"2rem"}
          direction={"column"}
          width={"100%"}
          alignItems={"flex-start"}
          breakPoint={"1105px"}
          breakPointwidth={"100%"}
          breakPointdirection={"column"}
          breakPointAlign={"flex-start"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={fontSizes.desktop_title}
            color={darkMode ? colorTheme.white.white : colorTheme.textColor}
            fontWeight={"600"}
            resFontSize={"18px"}
          >
            Tell us more
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"14px"}
            color={colorTheme.textColor}
            fontWeight={"500"}
            resFontSize={"12px"}
          >
            Create an account to build your personal review.
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>

      {/* Name */}
      <InputContainer margin={"12px 0px 12px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <span
            style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
          >
            *
          </span>

          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            First name{" "}
            {errors?.firstName && touched?.firstName && (
              <span
                style={{
                  color: "red",
                  fontSize: "11.5px",
                  fontWeight: "400",
                  margin: "0px 0px 0px 12px"
                }}
              >
                {errors?.firstName}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        <Input
          required
          value={values?.firstName}
          onChange={(e) => {
            handleChange("firstName")(e.target.value);
          }}
          type={"name"}
          placeHolder={"Your first name"}
          placeholderSize={"13px"}
          fontSize={"13px"}
          padding={"13px"}
          border={`1.4px solid ${colorTheme.black.light}`}
          radius={"5px"}
          width={"100%"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </InputContainer>
      {/* last name*/}
      <InputContainer margin={"12px 0px 12px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <span
            style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
          >
            *
          </span>

          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Last name{" "}
            {errors?.lastName && touched?.lastName && (
              <span
                style={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: "11.5px",
                  margin: "0px 0px 0px 12px"
                }}
              >
                {errors?.lastName}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        <Input
          required
          value={values?.lastName}
          onChange={(e) => {
            handleChange("lastName")(e.target.value);
          }}
          type={"text"}
          placeHolder={"Your last name"}
          placeholderSize={"13px"}
          fontSize={"13px"}
          padding={"13px"}
          border={`1.4px solid ${colorTheme.black.light}`}
          radius={"5px"}
          width={"100%"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </InputContainer>

      <InputContainer margin={"12px 0px 12px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <span
            style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
          >
            *
          </span>

          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Username{" "}
            {errors?.username && touched?.username && (
              <span
                style={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: "11.5px",
                  margin: "0px 0px 0px 12px"
                }}
              >
                {errors?.username}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        <Input
          required
          value={values?.username}
          onChange={(e) => {
            handleChange("username")(e.target.value);
          }}
          type={"text"}
          placeHolder={"Your username"}
          placeholderSize={"13px"}
          fontSize={"13px"}
          padding={"13px"}
          border={`1.4px solid ${colorTheme.black.light}`}
          radius={"5px"}
          width={"100%"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        />
      </InputContainer>
      <InputContainer margin={"12px 0px 12px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <span
            style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
          >
            *
          </span>

          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Gender{" "}
            {errors?.gender && touched?.gender && (
              <span
                style={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: "11.5px",
                  margin: "0px 0px 0px 12px"
                }}
              >
                {errors?.gender}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        <Dropdown
          style={{ width: "100%" }}
          options={["Male", "Female"]}
          onChange={(e) => {
            handleChange("gender")(e.value);
          }}
          value={values?.gender}
          placeholder="Choose Gender"
        />
        ;
      </InputContainer>
      {/* Terms and Services */}
      <FlexibleDiv justifyContent={"flex-start"} gap={"10px"} wrap={"no-wrap"}>
        <span style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}>
          *
        </span>
        <Input
          type={"checkbox"}
          required
          checked={values?.agreeTerms}
          onChange={() => {
            setFieldValue("agreeTerms", !values.agreeTerms);
          }}
        />

        <Text
          fontSize={fontSizes.mobile_smallest}
          fontWeight={"600"}
          lineHeight={"17px"}
          resFontSize={"12px"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          I agree with Attend's{" "}
          <span
            style={{
              color: darkMode ? colorTheme.blue : "#0fb3b3",
              cursor: "pointer",
              fontWeight: "500"
            }}
          >
            Terms of service
          </span>
          {errors?.agreeTerms && touched?.agreeTerms && (
            <span
              style={{
                color: "red",
                fontSize: "11.5px",
                fontWeight: "400",
                margin: "0px 0px 0px 12px"
              }}
            >
              {errors?.agreeTerms}
            </span>
          )}
        </Text>
      </FlexibleDiv>
      <FlexibleDiv padding={"12px 0px"}>
        <Button
          type={"submit"}
          padding={"12px"}
          radius={"5px"}
          width={"100%"}
          background={
            loading ? colorTheme.white.white : colorTheme.primary_color
          }
          fontSize={"14px"}
          hoverColor={colorTheme.primary_color}
          hoverBorder={"none"}
          onClick={() => {
            Continue();
          }}
        >
          {loading ? <Loader width={32} /> : "  Register"}
        </Button>
      </FlexibleDiv>
    </>
  );
};

export default Step2;
