import { createSlice } from "@reduxjs/toolkit";
import { generate_payment_link } from "../../../../services/ticket_payment";

const initialState = {
  loading: false,
  error: null,
  isError: false,
  isSuccess: false,
  selected_tickets: [],
  selected_ticket: null,
  total_amount: null
};

const Buy_Ticket = createSlice({
  name: "buy_ticket_slice",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { id, name, price, quantity, capacity } = action.payload;
      const existingTicket = state.selected_tickets.find(
        (ticket) => ticket.id === id
      );

      if (existingTicket) {
        // Check if adding more would exceed the maximum quantity limit
        const newQuantity = existingTicket.quantity + quantity;
        existingTicket.quantity = Math.min(newQuantity, capacity);
      } else {
        // Check if adding the ticket would exceed the maximum quantity limit
        const newQuantity = Math.min(quantity, capacity);
        state.selected_tickets.push({ id, name, price, quantity: newQuantity });
      }
    },
    reduceQuantity: (state, action) => {
      const ticketIndex = state.selected_tickets.findIndex(
        (t) => t.id === action.payload
      );

      if (ticketIndex !== -1) {
        if (state.selected_tickets[ticketIndex].quantity === 1) {
          state.selected_tickets.splice(ticketIndex, 1);
        } else {
          state.selected_tickets[ticketIndex].quantity = Math.max(
            state.selected_tickets[ticketIndex].quantity - 1,
            0
          );
        }
      }
    },
    selectTicketHandler: (state, { payload }) => {
      state.selected_tickets.forEach((ticket) => {
        if (ticket.id !== payload.id) {
          ticket.quantity = 0;
        }
      });

      // Set the selected ticket
      state.selected_ticket = payload;
    
    }
    // purchaseTickets: (state) => {
    //   state.cart = [];
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(generate_payment_link.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(generate_payment_link.fulfilled, (state) => {
        state.loading = false;
        state.isSuccess = true;
      })
      .addCase(generate_payment_link.rejected, (state) => {
        state.loading = false;
        state.isError = true;
      });
  }
});

export const {
  addToCart,
  removeFromCart,
  reduceQuantity,
  selectTicketHandler
} = Buy_Ticket.actions;
export default Buy_Ticket.reducer;
