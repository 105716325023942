import React from "react";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../primaryComponents/image/image";
import image from "../../../assets/images/banner2.png";
import { Text } from "../../primaryComponents/typography/typography.component";
import Button from "../../primaryComponents/button/button.component";
import { useNavigate } from "react-router-dom";
// import { colorTheme } from "../../../infrastructure/Theme/color";
const Banner2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <FlexibleDiv
        margin={"90px 0px 0px 0px "}
        position={"relative"}
        style={{ overflowX: "hidden" }}
      >
        <FlexibleDiv position={"relative"}>
          <Image
            src={image}
            alt={"banner-img"}
            width={"100%"}
            height={"180px"}
          />

          <FlexibleDiv
            position={"absolute"}
            zIndex={"1"}
            height={"100%"}
            direction={"column"}
            alignItems={"flex-start"}
            style={{ oveflowX: "hidden" }}
          >
            <FlexibleDiv padding={"0px 50px"} justifyContent={"flex-start"}>
              <Text
                fontWeight={"600"}
                margin={"0px"}
                fontSize={"24px"}
                resFontSize={"18px"}
              >
                Events with{" "}
                <span
                  style={{ background: "teal", padding: "3px", color: "white" }}
                >
                  Attend
                </span>
              </Text>
            </FlexibleDiv>
            <FlexibleDiv padding={"0px 50px"} justifyContent={"flex-start"}>
              <Text margin={"0px"} fontSize={"14px"} resFontSize={"13px"}>
                Let's help you organize your first event
              </Text>
            </FlexibleDiv>
            <FlexibleDiv padding={"15px 50px"} justifyContent={"flex-start"}>
              <Button
                onClick={() => {
                  navigate("/create");
                }}
                width={"100px"}
                radius={"5px"}
                background={"black"}
                color={"white"}
                hoverBackground={"white"}
                hoverColor={"black"}
                hoverBorder={"none"}
                padding={"10px"}
                fontSize={"13px"}
              >
                Get Started
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Banner2;
