import React from "react";
import styled from "styled-components";
import Image from "../../../../assets/images/Party.jpg";
import { CONSTANTS } from "../../../../utilities/constants";
const Img = styled.img`
  width: ${({ width }) => (width ? width : "100%")};
  object-fit: cover;
  height: ${({ height }) => (height ? height : "80vh")};
  @media only screen and (max-width: 680px) {
    width: 100%;
    height: auto;
    object-fit: initial;
  }
`;
const EventImage = ({ height, width, src }) => {
  return (
    <>
      <Img
        alt="event image"
        height={height}
        width={width}
        src={`${CONSTANTS.imgbaseurl}${src}`}
      />
    </>
  );
};

export default EventImage;
