import { useState } from "react";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
// import { Text } from "../../primaryComponents/typography/typography.component";
import ColumnSection from "./columnSections/columnSections.component";
import ImageSection from "./imageSection/imageSection.component";
import SaveEdit from "./saveEdit.component";
import { Formik, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { editUserProfile } from "../../../state management/Redux/slices/user_slice/edit_profile_slice";

const EditProfile = () => {
  const [profile_image_file, set_profile_image_file] = useState(null);
  const gender = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.gender
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const email = useSelector(({ edit_profile }) => edit_profile?.email);
  const fullname = useSelector(({ edit_profile }) => edit_profile?.fullname);
  const phone_no = useSelector(({ edit_profile }) => edit_profile?.phone_no);
  const bio = useSelector(({ edit_profile }) => edit_profile?.bio);
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(
      editUserProfile({ values: values, token: token, dispatch: dispatch })
    );
  };
  const formik = useFormik({
    initialValues: {
      fullname: fullname || "",
      gender: gender || "",
      email: email || "",
      phone: phone_no || "",
      bio: bio || "",
    },
    onSubmit,
  });
  return (
    <Formik>
      <FlexibleDiv display={"flex"}>
        <ImageSection
          profile_image_file={profile_image_file}
          set_profile_image_file={set_profile_image_file}
        />
        <ColumnSection formik={formik} />
        <FlexibleDiv breakPoint={"670px"} breakPointPadding={"0px 20px"}>
          <SaveEdit
            formik={formik}
            set_profile_image_file={set_profile_image_file}
            profile_image_file={profile_image_file}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </Formik>
  );
};

export default EditProfile;
