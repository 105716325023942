import styled from "styled-components";

export const DropZone = styled.label`
  width: ${({width})=>width ? width :'100%'};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid grey;
  border-radius: 5px;
  border-style: dotted;
  padding:${({padding})=>padding ? padding :'63px 0px'};
  justify-content: center;
  cursor: pointer;
  &:hover{
    background:${({backgroundHover})=>backgroundHover ? backgroundHover : ''}
  }


  @media screen and (max-width: ${({breakPoint})=>breakPoint ? breakPoint :''} ) {
    width: ${({ breakPointwidth }) => (breakPointwidth ? breakPointwidth : "")};
    display: ${({ breakPointDisplay }) =>
      breakPointDisplay ? breakPointDisplay : ""};
    flex-basis: ${({ breakPointflexBasis }) => (breakPointflexBasis ? breakPointflexBasis : "")};
    justify-content: ${({ breakPointJustifyContent }) => (breakPointJustifyContent ? breakPointJustifyContent : "")};
    flex-wrap: ${({breakPointWrap})=>breakPointWrap ? breakPointWrap : 'wrap'};
    align-items: ${({ breakPointAlign }) => (breakPointAlign ? breakPointAlign : "center")};
    margin:${({breakPointMargin})=>breakPointMargin ? breakPointMargin : ''};
    gap: ${({breakPointgap})=>breakPointgap ?breakPointgap :''};
    flex-direction: ${({ breakPointdirection }) => (breakPointdirection ? breakPointdirection : "row")};
    padding: ${({breakPointPadding})=>breakPointPadding ? breakPointPadding :""};
    left: ${({breakPointLeft})=>breakPointLeft ? breakPointLeft :""};
 }
`;
