import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { useDispatch, useSelector } from "react-redux"
import { getEventDetails } from "../../state management/Redux/slices/events/get_event_details_slice";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useParams, useHref, useNavigate } from "react-router-dom";
import { HeaderText } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { Image } from "../../components/primaryComponents/image/image";
import { CONSTANTS } from "../../utilities/constants";
import TicketSelector from "../../components/secondaryComponents/tickets_page/ticket_selector.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
const TicketPage = () => {
    const dispatch = useDispatch();
    const { eventId } = useParams();
    const href = useHref()
    const navigate = useNavigate()
    const darkMode = useSelector(
        ({ persistedReducer }) => persistedReducer.theme.darkmode
    );

    const token = useSelector(
        ({ persistedReducer }) => persistedReducer.user.token
    );
    const title = useSelector(
        ({ event_details }) => event_details?.data?.event?.title
    );
    const event_cover_image = useSelector(
        ({ event_details }) => event_details?.data?.event?.cover_image
    );
    const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
    useEffect(() => {
        dispatch(getEventDetails({ token: token, event_id: eventId }));
    }, [eventId]);
    useEffect(() => {
        const decodedUrl = decodeURIComponent(href).toLowerCase();
        const modifiedUrl = decodedUrl.replace(/\s+/g, '_')
        href.replace(modifiedUrl)
        navigate(modifiedUrl, { replace: true })
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Tickets - ${title}`}</title>
            </Helmet>
            <FlexibleDiv height={'100%'} display={'block'} >
                <FlexibleDiv margin={'30px 0px 40px 0px'} justifyContent={'center'} alignItems={'center'} >
                    <HeaderText fontSize={"28px"} resFontSize={"18px"} color={textColor} >Tickets</HeaderText>
                </FlexibleDiv>

                <FlexibleDiv justifyContent={'space-between'} alignItems={'flex-start'} breakPoint={'680px'} breakPointdirection={'column'}   >
                    <FlexibleDiv padding={'0px 23px'} width={'45%'} breakPoint={'680px'} breakPointwidth={'90%'} breakPointPadding={'0px'} breakPointMargin={'0px 0px 30px 0px'}  >
                        <Image width={'100%'} height={'auto'} src={`${CONSTANTS.imgbaseurl}${event_cover_image}`} />
                    </FlexibleDiv>
                    <FlexibleDiv padding={'0px 23px'} width={'45%'} breakPoint={'680px'} breakPointwidth={'90%'} breakPointPadding={'0px'}  breakPointMargin={'0px 0px 30px 0px'}  >
                        <TicketSelector />
                    </FlexibleDiv>
                </FlexibleDiv>
            </FlexibleDiv>
            <Footer/>
        </>
    );
}

export default TicketPage;