

import { colorTheme } from "../../../infrastructure/Theme/color"
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component"
import { Text } from "../../primaryComponents/typography/typography.component"

const PaymentDate =({date})=>{
    return(
        <>
     
      {/* {date.map(({date})=>(
         <FlexibleDiv justifyContent={'flex-start'} >
          <Text fontSize={"14px"} color={colorTheme.ash} resFontSize={'small'}>{date}</Text>
          </FlexibleDiv>
      ))} */}
      <FlexibleDiv justifyContent={'flex-start'}  >
          <Text fontSize={"14px"} color={colorTheme.textColor} resFontSize={'small'}>{date}</Text>
          </FlexibleDiv>

        </>
    )
}

export  default PaymentDate