import { colorTheme } from "../../../infrastructure/Theme/color";
import { Text } from "../../primaryComponents/typography/typography.component";

const CardCategory = ({ category }) => {
  return (
    <Text
      fontSize={"10px !important"}
      fontWeight={"500"}
      color={colorTheme.black.soft}
      style={{ margin: "5px 0px 0px 2px" }}
    >
      {category && category[0]}
    </Text>
  );
};

export default CardCategory;
