import styled from "styled-components";
import { colorTheme } from "../../../../infrastructure/Theme/color";


export const MobileNavStyle = styled.div`
width: 100%;
right: 0px;
top: 55px;
padding: 0px 0px 32px 0px;
position: fixed;
height: 100vh;
overflow: hidden;
background: ${({bg})=>bg ? bg : colorTheme.white.white};
z-index: 999;
display: none;
@media only screen and (max-width:1180px) {
    display: ${({breakPointDisplay})=>      breakPointDisplay ?       breakPointDisplay : ''};
}
`

export const MobileMenuStyle= styled.div`
    /* display: none; */
    position: absolute;
    z-index: 10;
    width: 100%;
    height: calc(100vh - 60px);
    padding: 23px;
    overflow: scroll;
    -webkit-box-shadow: 0px 20px 5px rgb(13 12 34 / 5%);
    box-shadow: 0px 20px 35px rgb(13 12 34 / 5%);


`