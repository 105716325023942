import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input, { TextArea } from "../../primaryComponents/input/input.component";
import { ConversationFormStyle, FormLabel } from "./styles";
import { useFormik } from "formik";
import { contactUsSchema } from "../../../infrastructure/schemas/contact_us_schema";
import { Text } from "../../primaryComponents/typography/typography.component";
import Loader from "../loader/loader.component";
import { contactUsHandler } from "../../../state management/Redux/slices/contact_us";

const ConversationForm = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const loading = useSelector(({ contact_us }) => contact_us?.loading);
  const dispatch = useDispatch();
  const onSubmit = (values,action) => {
    const { name, email, subject, message } = values;
    dispatch(contactUsHandler({ name, email, subject, message,action }));
  };

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: contactUsSchema,
    onSubmit,
  });
  return (
    <ConversationFormStyle
      onSubmit={() => {
        onSubmit();
      }}
    >
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        margin={" 0px 0px 40px 0px"}
      >
        <FlexibleDiv justifyContent={"space-between"}>
          <FormLabel
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            htmlFor="name"
          >
            Name
          </FormLabel>
          {errors?.name && touched?.name && (
            <Text fontSize={"14px"} resFontSize={"13.5px"} color={colorTheme.red}>
              {errors?.name}
            </Text>
          )}
        </FlexibleDiv>
        <Input
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          required
          value={values.name}
          onChange={(e) => {
            handleChange("name")(e.target.value);
          }}
          id={"name"}
          width={"98%"}
          padding={"10px"}
          borderBottom={"0.5px solid  #4B4B4B"}
        />
      </FlexibleDiv>
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        margin={" 0px 0px 40px 0px"}
      >
        <FlexibleDiv justifyContent={"space-between"}>
          <FormLabel
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            htmlFor="name"
          >
            Email
          </FormLabel>
          {errors?.email && touched?.email && (
            <Text fontSize={"14px"} color={colorTheme.red}>
              {errors?.email}
            </Text>
          )}
        </FlexibleDiv>
        <Input
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          required
          value={values.email}
          onChange={(e) => {
            handleChange("email")(e.target.value);
          }}
          id={"email"}
          width={"98%"}
          padding={"10px"}
          borderBottom={"0.5px solid  #4B4B4B"}
        />
      </FlexibleDiv>
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        margin={" 0px 0px 40px 0px"}
      >
        <FlexibleDiv justifyContent={"space-between"}>
          <FormLabel
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            htmlFor="name"
          >
            Subject
          </FormLabel>
          {errors?.subject && touched?.subject && (
            <Text fontSize={"14px"} color={colorTheme.red}>
              {errors?.subject}
            </Text>
          )}
        </FlexibleDiv>
        <Input
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          required
          value={values.subject}
          onChange={(e) => {
            handleChange("subject")(e.target.value);
          }}
          type={"text"}
          id={"subject"}
          width={"98%"}
          padding={"10px"}
          borderBottom={"0.5px solid  #4B4B4B"}
        />
      </FlexibleDiv>
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        margin={" 0px 0px 40px 0px"}
      >
        <FlexibleDiv justifyContent={"space-between"}>
          <FormLabel
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            htmlFor="name"
          >
            Message
          </FormLabel>
          {errors?.message && touched?.message && (
            <Text fontSize={"14px"} color={colorTheme.red}>
              {errors?.message}
            </Text>
          )}
        </FlexibleDiv>
        <TextArea
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          required
          value={values.message}
          onChange={(e) => {
            handleChange("message")(e.target.value);
          }}
          type={"text"}
          id={"message"}
          width={"98%"}
          padding={"12px 0px"}
          borderBottom={"0.5px solid  #4B4B4B"}
        />
      </FlexibleDiv>
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        margin={" 0px 0px 40px 0px"}
      >
        <Button
          onClick={handleSubmit}
          background={
            loading ? colorTheme.white.white : colorTheme.primary_color
          }
          type={"submit"}
          padding={"13px"}
          radius={"4.20px"}
          width={"168px"}
          children={loading ? <Loader width={'30px'} /> : "Send"}
          hoverBackground={
            loading ? colorTheme.white.white : colorTheme.primary_color
          }
          hoverBorder={"none"}
          hoverColor={"#FFF"}
        />
      </FlexibleDiv>
    </ConversationFormStyle>
  );
};

export default ConversationForm;
