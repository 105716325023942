import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CONSTANTS } from "../../../../utilities/constants";
import ClientRequest from "../../../../api/api";
// import { CONSTANTS } from "../../../../utilities/constants";

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "attend_inc_session";
axios.defaults.xsrfHeaderName = "attend_inc_session";

// REGISTRATION FUNCTION
export const registerUser = createAsyncThunk(
  "user/register",
  async (
    {
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
      gender,
      username,
      navigate
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await ClientRequest.post("api/user/register", {
        email: email,
        firstname: firstName,
        lastname: lastName,
        phone: phoneNumber,
        password: password,
        gender: gender,
        username: username
      });
      if (response.status === 201) {
        toast.success(response.data?.success);
        localStorage.setItem("token", response.data?.token);
        navigate("/");
      }
      return response.data;
    } catch (error) {
      console.error("Sign up error", error);
      if (error.response && error.response.data && error.response.data.error) {
        const errorObject = error.response.data.error;
        for (const field in errorObject) {
          if (errorObject.hasOwnProperty(field)) {
            const errorMessage = errorObject[field];
            toast.error(`${field}: ${errorMessage}`);
          }
        }
      } else {
        toast.error("An error occurred during registration.");
      }

      return rejectWithValue(error?.response?.data?.error);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async ({ email, password, navigate }, { rejectWithValue }) => {
    try {
      let loginData = { email: email, password: password };
      const csrf = await ClientRequest.get("sanctum/csrf-cookie");
      if (csrf.status === 204) {
        const loginRes = await ClientRequest.post("api/user/login", loginData);
        if (loginRes.data?.success) {
          localStorage.setItem("token", loginRes.data?.token);
          toast.success(loginRes.data?.success);
          navigate("/");
          return loginRes?.data;
        } else if (loginRes?.data?.error) {
          toast.error(loginRes.data?.error);
        }
      } else {
        console.error("CSRF Token not set");
      }
    } catch (error) {
      toast.error("An error occurred during logging in.");

      return rejectWithValue(error?.response?.data?.error);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  "user/get-info",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/user/get");

      if (response.status === 200) {
        return response.data;
      } else {
        console.error("Auth Error", response);
      }
    } catch (error) {
      console.error("Error", error);
      return rejectWithValue(error);
    }
  }
);
const initialState = {
  userInfo: null,
  signUpLoading: false,
  signUpError: false,
  signUpSuccess: false,
  loginLoading: false,
  loginError: false,
  loginSuccess: false,
  loading: false,
  error: null,
  success: false,
  token: ""
};

const UserSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    clearAuthServiceState(state) {
      state.error = null;
      state.success = false;
      state.loading = false;
    },
    logOut(state) {
      state.token = null;
      state.userInfo = null;
      state.error = null;
    },
  setToken:(state,{payload})=>{
    state.token =payload
  }
  },
  extraReducers: (builder) => {
    // Register User
    builder
      .addCase(registerUser.pending, (state) => {
        state.signUpLoading = true;
        state.signUpError = false;
        state.signUpSuccess = false;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        state.signUpLoading = false;
        state.signUpSuccess = false;
        state.token = payload?.token;
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.signUpLoading = false;
        state.signUpError = true;
        state.error = payload;
      });

    // Login User
    builder
      .addCase(loginUser.pending, (state) => {
        state.loginLoading = true;
        state.loginError = false;
        state.loginSuccess = false;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loginLoading = false;
        state.loginSuccess = true;
        state.token = payload?.token;
      })
      .addCase(loginUser.rejected, (state, { payload }) => {
        state.loginLoading = false;
        state.loginError = true;
        state.error = payload;
      });

    // // Get User Information
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        if (!payload.success) {
          state.loading = false;
          state.error = payload;
        } else {
          state.loading = false;
          state.userInfo = payload.user;
          state.success = true;
        }
      })
      .addCase(getUserInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  }
});

export const { clearAuthServiceState, logOut,setToken } = UserSlice.actions;

export default UserSlice.reducer;
