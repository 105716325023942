import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { RightSideStyle } from "../../../../pages/Settings/styles";
import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../primaryComponents/typography/typography.component";
import EditEmail from "../email.component";
import EditMobileNo from "../mobileNo.component";
import { useEffect } from "react";
import { edit_email_handler, edit_phoneNo_handler } from "../../../../state management/Redux/slices/user_slice/edit_profile_slice";
// import EditSocials from "../socials.component";
// import EditWebpage from "../webpage.component";

const RightColumn = ({ formik }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const email = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.email
  );
  const phone = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.phone
  );

  const phone_no = useSelector(({ edit_profile }) => edit_profile?.phone_no);
  useEffect(() => {
    dispatch(edit_email_handler(email));
    dispatch(edit_phoneNo_handler(phone));
    formik?.handleChange("phone")(phone);
    formik?.handleChange("email")(email);
  }, []);

  const edit_email = useSelector(({ edit_profile }) => edit_profile?.email);
  return (
    <RightSideStyle>
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
          fontWeight={"600"}
          fontSize={"16px"}
        >
          Contact & Personal Info
        </Text>
      </FlexibleDiv>
      <EditMobileNo value={phone_no} formik={formik} />
      <EditEmail  value={edit_email} formik={formik} />
      {/* <EditWebpage/>
          <EditSocials/> */}
    </RightSideStyle>
  );
};

export default RightColumn;
