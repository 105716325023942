import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Modal from "../../../../components/primaryComponents/modal/modal";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
// import {
//   WhatsappShareButton,
//   FacebookShareButton,
//   TwitterShareButton,
//   InstapaperShareButton,
//   FacebookIcon,
//   InstapaperIcon,
//   TwitterIcon,
//   WhatsappIcon
// } from "react-share";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import Input from "../../../../components/primaryComponents/input/input.component";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import { FacebookShare, TwitterShare, WhatsappShare } from "react-share-lite";
import { Helmet } from "react-helmet";
import { CONSTANTS } from "../../../../utilities/constants";

const Share_Modal = ({ showModal, setShowModal }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const title = useSelector(
    ({ event_details }) => event_details.data?.event?.title
  );
  const description = useSelector(
    ({ event_details }) => event_details?.data?.event?.description
  );
  const cover_image = useSelector(
    ({ event_details }) => event_details?.data?.event?.cover_image
  );

  const [copy, setCopy] = useState(false);
  const bg = darkMode
    ? colorTheme.black.fadeMini
    : colorTheme.white.fadingWhite;
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const socialIcons = [
    {
      icon: (
        <FacebookShare size={23} url={window.location.href} title={title} />
      ),
      // icon: <FacebookShareButton />
    },
    {
      icon: (
        <WhatsappShare size={23} url={window.location.href} title={title} />
      ),
      // icon: <WhatsappShareButton />
    },
    {
      icon: <TwitterShare size={23} url={window.location.href} title={title} />,
    },
  ];

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);
  return (
    <>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${CONSTANTS.imgbaseurl}${cover_image}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Your Site Name" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={`${CONSTANTS.imgbaseurl}${cover_image}`}
        />

        {/* Pinterest */}
        <meta name="pinterest-rich-pin" content="true" />
      </Helmet>
      <Modal
        scroll={true}
        showExitBtn={true}
        show={showModal}
        setShowModal={setShowModal}
      >
        <FlexibleDiv
          display={"block"}
          width={"40%"}
          bg={bg}
          padding={"40px"}
          breakPoint={"780px"}
          breakPointwidth={"60%"}
        >
          <FlexibleDiv>
            <Text
              style={{ margin: "0px", textAlign: "center" }}
              color={textColor}
              fontSize={"16px"}
            >
              Share with friends
            </Text>
          </FlexibleDiv>

          <FlexibleDiv direction={"column"} padding={"0px 0px 23px 0px"}>
            <FlexibleDiv
              justifyContent={"center"}
              gap={"20px"}
              margin={"23px 0px"}
              position={"relative"}
              top={"-12px"}
            >
              {socialIcons.map(({ icon }, index) => (
                <Text
                  key={index}
                  cursor={"pointer"}
                  fontSize={fontSizes.desktop_body_text}
                  hoverColor={colorTheme.primary_color}
                  fontWeight={"900"}
                  color={colorTheme.textColor}
                >
                  {icon}
                </Text>
              ))}
            </FlexibleDiv>
            <FlexibleDiv>
              <FlexibleDiv
                width={"60%"}
                border={"1px solid lightgrey"}
                padding={"10px"}
              >
                <FlexibleDiv>
                  <Input
                    readonly={true}
                    width={"70%"}
                    color={textColor}
                    value={window.location.href}
                  />
                  <FlexibleDiv width={"20%"}>
                    <CopyToClipboard
                      onCopy={() => {
                        setCopy(true);
                      }}
                      text={window.location.href}
                    >
                      <BiCopy
                        style={{ cursor: "pointer" }}
                        color={
                          copy
                            ? colorTheme.secondary_color
                            : colorTheme.white.white
                        }
                        size={18}
                      />
                    </CopyToClipboard>
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </Modal>
    </>
  );
};

export default Share_Modal;
