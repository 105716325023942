import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClientRequest from "../../../../api/api";
import { toast } from "react-toastify";
export const setCoverImageRequest = createAsyncThunk(
  "user/profile/edit-cover-image",
  async ({ cover_image }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("cover_image", cover_image);
      const response = await ClientRequest.post(
        "api/user/edit/cover-image",
        formData
      );
      console.log("list of organizers", response);
      if (response.status === 200) {
        toast.success("Cover Image uploaded successfully");
        return response.data;
      }
    } catch (error) {
      const { response } = error;
      console.log("List of organizers error", error);
      console.log("List of organizers error response", response);
      return rejectWithValue(response);
    }
  }
);
const initialState = {
  loading: false,
  isSuccessful: false,
  isError: false,
  cover_image: null,
  error: null
};

const CoverImageSlice = createSlice({
  name: "cover_image_slice",
  initialState,
  reducers: {
    setCoverImage: (state, { payload }) => {
      state.cover_image = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCoverImageRequest.pending, (state) => {
        state.loading = true;
        state.isSuccessful = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(setCoverImageRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccessful = true;
      })
      .addCase(setCoverImageRequest.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.error = payload;
      });
  }
});

export const { setCoverImage } = CoverImageSlice.actions;
export default CoverImageSlice.reducer;
