import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../../../components/primaryComponents/image/image";
import Img from "../../../../assets/images/Party.jpg"


const ExtraImages = ({extraImages}) => {
    return (
        <>
       <FlexibleDiv justifyContent={'flex-start'} gap={'12px'} margin={'30px 0px'} >
       {extraImages.map((item)=>(
            <Image src={Img} key={item} width={'100px'} height={'90px'} style={{borderRadius:'10px'}} />
        ))}
       </FlexibleDiv>
        </>
      );
}
 
export default ExtraImages;