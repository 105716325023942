import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../../components/primaryComponents/input/input.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { nameOfLocationValue } from "../../../../state management/Redux/slices/create_event_slice";
import Head from "../../BlockCodes/header";
import { useCreateEvent } from "../../createEvent Context";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";

function NAME_OF_LOCATION() {
  const dispatch = useDispatch();
  const handleChange = useCreateEvent()?.[4];
  const errors = useCreateEvent()?.[2];
  const touched = useCreateEvent()?.[3];
  const values = useCreateEvent()?.[0];
  const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
  const locationHandler =(e)=>{
    dispatch(nameOfLocationValue(e.target.value))
    handleChange('location_name')(e.target.value)
  }
  return (
    <>
      <FlexibleDiv direction={"column"} margin={"23px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"}>
          <FlexibleDiv width={errors.location_name && touched.location_name ?'30%' : '100%'} breakPoint={'680px'} breakPointwidth={'100%'} >
          <Head
            title={"Name of Location"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={"15px"}
            width={"100%"}
            smallScreenFontSize={"15px"}
          />
          </FlexibleDiv>
          <FlexibleDiv justifyContent={'flex-start'} width={'70%'} display={errors.location_name && touched.location_name ? 'flex' :'none'} breakPoint={'680px'} breakPointwidth={'100%'} >
        <Text color={colorTheme.red} fontSize={fontSizes.mobile_smallest} resFontSize={fontSizes.mobile_smallest} >
           ({errors.location_name})
           </Text> 
        </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv margin={"12px 0px"}>
          <Input
            value={values.location_name}
            placeHolder={'eg Transcorp Hilton'}
            placeholderSize={"13px"}
            fontSize={'14px'}
            onChange={locationHandler}
            padding={"16px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            width={"100%"}
            border={`1px solid ${colorTheme.lightgrey}`}
            radius={"5px"}
            placeholder={"Event Location"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
}

export default NAME_OF_LOCATION;
