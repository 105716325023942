import styled from "styled-components";


export const ModalContainer = styled.div`
width: 100%;
height: 100%;
display:${({display})=>display ? display :"none"};
transition: display 0.5s ease-in-out;
background-color: #0000006b;
position: fixed;
z-index: 999;
left: 0px;
right: 0px;
top:0px;
`