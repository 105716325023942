import React from 'react';
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Events from "../components/events";
import Filter from "../components/filter";

const EventSection = () => {
    return ( 
        <>
            <FlexibleDiv direction={'column'} padding={"12px 0px 32px 0px"} >
                {/* Filter */}
                {/* <Filter/> */}
                <Events/>
            </FlexibleDiv>
        </>
    );
}

export default React.memo(EventSection);
