import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Conversation from "../../components/secondaryComponents/conversation/conversation.component";
import Email from "./email.component";
import PhoneNumber from "./phoneNumber.component";
// import Socials from "./socials.component";



const ConversationColumn = () => {
    return ( 
        <>
       <FlexibleDiv flexBasis={'40%'} breakPoint={'780px'} breakPointflexBasis={'100%'} breakPointMargin={"40px 0px"}>
       <Conversation/>
       <PhoneNumber/>
       <Email/>
       {/* <Socials/> */}
       </FlexibleDiv>
        </>
     );
}
 
export default ConversationColumn;