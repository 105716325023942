import {
  // MenuItems,
  MenuItemTitle,
  NewEventCircleNot,
  ProfilePic,
  SubNav,
  SubNavItem
} from "../../../infrastructure/Layout/layoutStyles";
import { WandIcon } from "../../primaryComponents/icons/icons";
import no_profile_image from "../../../assets/images/no_user.png";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Skeleton from "react-loading-skeleton";
import { CONSTANTS } from "../../../utilities/constants";
import { useNavigate } from "react-router-dom";

const SideBarOrganizer = ({ Image, name }) => {
  const navigate = useNavigate()
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const organizers = useSelector(
    ({ organizers_following }) => organizers_following?.data
  );
  const loading = useSelector(
    ({ organizers_following }) => organizers_following?.loading
  );
  return (
    <>
      <div
        style={{
          marginBottom: "15px",
          width: "100%",
          top: "-55px",
          position: "relative"
        }}
      >
        <MenuItemTitle onClick={()=>{
          navigate("/organizers")
        }}  color={darkMode ? colorTheme.white.white : ""}>
          <span>{<WandIcon />}</span>Organizers
        </MenuItemTitle>

        {/* {loading ? (
          Array.from({ length: 3 }).map((index) => (
            <Skeleton
              style={{
                margin: "23px 0px"
              }}
              width={230}
              height={40}
              key={index}
            />
          ))
        ) : (
          <div style={{ height: "100px", overflowY: "scroll" }}>
            <SubNav left={"0px"}>
              {organizers
                ?.slice(0, 5)
                ?.map(({ username, id, profile_image }) => {
                  // <Link to={`/profile/organizer/${userName}`} key={Math.random()} >

                  return (
                    <SubNavItem
                      key={id}
                      color={darkMode ? colorTheme.textColor : ""}
                      hoverBg={darkMode ? colorTheme.black.darkmode1 : ""}
                    >
                      <ProfilePic
                        src={
                          profile_image
                            ? `${CONSTANTS.profileImageUrl}${profile_image}`
                            : no_profile_image
                        }
                      />
                      {username}
                      <NewEventCircleNot />
                    </SubNavItem>
                  );
                  // </Link>
                })}
            </SubNav>
          </div>
        )} */}
      </div>
    </>
  );
};

export default SideBarOrganizer;
