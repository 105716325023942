// src/features/navigation/NavigationListener.js

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPreviousRoute } from "../state management/Redux/slices/routes-slice";
import { createBrowserHistory } from "history";
const NavigationListener = ({ excludePaths }) => {
  const location = useLocation();
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" && !excludePaths.includes(location.pathname)) {
        dispatch(setPreviousRoute(location.pathname));
      }
    });

    return () => {
      unlisten();
    };
  }, [dispatch, history, excludePaths]);

  return null;
};

export default NavigationListener;
