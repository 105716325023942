import styled from "styled-components";

export const SearchContainer = styled.form`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 6px 8px 6px 8px;
  border-radius: 12x;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 0px 15px #cdc3c3;
  @media only screen and (max-width: 780px) {
    width: 60%;
    position: absolute;
    left: 0px;
    top: 78px;
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 680px) {
    width: 90%;
    position: absolute;
    left: 0px;
    top: 78px;
  }
`;
export const InputContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
`;
export const ButtonContainer = styled.div`
  width: ${({width})=>width ? width :"30%"};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

