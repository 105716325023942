import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CONSTANTS } from "../../../../utilities/constants";
import { toast } from "react-toastify";
import axios from "axios";
import ClientRequest from "../../../../api/api";

export const send_email_for_forgot_password = createAsyncThunk(
  "user/forgot-password/send-email",
  async ({ email, navigate, action }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("email", email);

      const response = await ClientRequest.post(
        `api/user/send-forgot-password`,
        formData
      );
      if (response.status === 200) {
        sessionStorage.setItem("forgot_password_email", email);
        navigate("/forgot-password/otp");
        toast.success(response?.data?.success);
        action?.resetForm();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorObject = error.response.data.error;
        for (const field in errorObject) {
          if (errorObject.hasOwnProperty(field)) {
            const errorMessage = errorObject[field];
            toast.error(`${field}: ${errorMessage}`);
          }
        }
      } else {
        toast.error("An error occurred during sending email.");
      }

      return rejectWithValue(error?.response?.data?.error);
    }
  }
);

export const verify_otp = createAsyncThunk(
  "user/forgot-password/verify_otp",
  async ({ code, navigate }, { rejectWithValue }) => {
    try {
      const email = sessionStorage.getItem("forgot_password_email");
      const formData = new FormData();
      formData.append("code", code);
      formData.append("email", email);

      const response = await ClientRequest.post(
        `api/user/verify-password-code`,
        formData
      );
      if (response.status === 200) {
        sessionStorage.setItem("verify_otp_token", response?.data?.auth_token);
        navigate("/forgot-password/reset-password");
        toast.success(response?.data?.success);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorObject = error.response.data.error;
        for (const field in errorObject) {
          if (errorObject.hasOwnProperty(field)) {
            const errorMessage = errorObject[field];
            toast.error(`${field}: ${errorMessage}`);
          }
        }
      } else {
        toast.error("An error occurred during verifying password.");
      }

      return rejectWithValue(error?.response?.data?.error);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "user/forgot-password/change_password",
  async ({ password, navigate, action }, { rejectWithValue }) => {
    try {
      const auth_token = sessionStorage.getItem("verify_otp_token");
      const formData = new FormData();
      formData.append("new_password", password);
      formData.append("auth_token", auth_token);

      const response = await ClientRequest.post(
        `api/user/change-password`,
        formData
      );
      if (response.status === 200) {
        navigate("/login");
        toast.success(response?.data?.success);
        action?.resetForm();
        sessionStorage.removeItem("auth_token");
        sessionStorage.removeItem("forgot_password_email");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const errorObject = error.response.data.error;
        for (const field in errorObject) {
          if (errorObject.hasOwnProperty(field)) {
            const errorMessage = errorObject[field];
            toast.error(`${field}: ${errorMessage}`);
          }
        }
      } else {
        toast.error("An error occurred during resetting password.");
      }

      return rejectWithValue(error?.response?.data?.error);
    }
  }
);
const initialState = {
  sendingEmail: false,
  verifyingOtp: false,
  resettingPassword: false,
  isSuccess: false,
  isError: false,
  error: null
};

const ForgotPassword_Slice = createSlice({
  name: "forgot_password_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(send_email_for_forgot_password.pending, (state) => {
        state.sendingEmail = true;
        state.error = null;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(send_email_for_forgot_password.fulfilled, (state) => {
        state.sendingEmail = false;
        state.error = null;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(
        send_email_for_forgot_password.rejected,
        (state, { payload }) => {
          state.sendingEmail = false;
          state.error = payload;
          state.isError = true;
          state.isSuccess = true;
        }
      );
    builder
      .addCase(verify_otp.pending, (state) => {
        state.verifyingOtp = true;
        state.error = null;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(verify_otp.fulfilled, (state) => {
        state.verifyingOtp = false;
        state.error = null;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(verify_otp.rejected, (state, { payload }) => {
        state.verifyingOtp = false;
        state.error = payload;
        state.isError = true;
        state.isSuccess = true;
      });
    builder
      .addCase(resetPassword.pending, (state) => {
        state.resettingPassword = true;
        state.error = null;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resettingPassword = false;
        state.error = null;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.resettingPassword = false;
        state.error = payload;
        state.isError = true;
        state.isSuccess = true;
      });
  }
});

export default ForgotPassword_Slice.reducer;
