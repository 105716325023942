import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { ImageIcon } from "../../../../components/primaryComponents/icons/icons";
import { Image as MainImageComponent } from "../../../../components/primaryComponents/image/image";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { addMainImage } from "../../../../state management/Redux/slices/create_event_slice";
import { DropZone } from "./style";
import { useCreateEvent } from "../../createEvent Context";

const MainImage = () => {
  const mainImage = useSelector((state) => state.createEvent.mainImage);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const handleChange = useCreateEvent()?.[4];
  const values = useCreateEvent()?.[0];
  const dispatch = useDispatch();
  const [imgSelected, setImgSelected] = useState(false);
  const setCoverImage = useCreateEvent()?.[6];
  const cover_image = useCreateEvent()?.[7]

  const HandleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0] && !mainImage) {
      setImgSelected(true);
      const reader = new FileReader();
      reader.readAsDataURL(e.dataTransfer.files[0]);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        dispatch(addMainImage(e.dataTransfer.files[0]));
      };

      handleChange("main_image")(URL.createObjectURL(e.dataTransfer.files[0]));
    } else if (mainImage) {
      dispatch(addMainImage(""));
      setImgSelected(true);
      const reader = new FileReader();
      reader.readAsDataURL(e.dataTransfer.files[0]);
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        dispatch(addMainImage(e.dataTransfer.files[0]));
      };
      handleChange("main_image")(URL.createObjectURL(e.dataTransfer.files[0]));
    } else {
      return mainImage;
    }
  };

  const HandleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const InputHandler = (e) => {
    const [file] = e.target.files;
    if (e.target.files && e.target.files[0]) {
      setImgSelected(true);
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = () => {
      //   const base64String = reader.result.split(",")[1];
      //   dispatch(addMainImage(file));
      // };
      // dispatch(addMainImage(file));
      setCoverImage(file)
      handleChange("main_image")(URL.createObjectURL(file));
    } else {
      setImgSelected(false);
      return mainImage;
    }
  };


  return (
    <>
      <DropZone
        htmlFor="file-input"
        onDragEnter={HandleDrag}
        onDragLeave={HandleDrag}
        onDragOver={HandleDrag}
        onDrop={HandleDrop}
        backgroundHover={mainImage ? "" : "#0000000a"}
        padding={mainImage ? "0px" : ""}
      >
        <MainImageComponent
          src={values.main_image}
          height={values.main_image ? "300px" : "300px"}
          width={values.main_image ? "100%" : "300px"}
          style={{ display: imgSelected ? "block" : "none" }}
        />
        <input
          onChange={(e) => {
            InputHandler(e);
          }}
          required
          id="file-input"
          type={"file"}
          style={{ display: "none" }}
        />
        {values.main_image ? null : (
          <ImageIcon color={colorTheme.primary_color} size={50} />
        )}
        {values.main_image ? null : (
          <FlexibleDiv>
            <Text
              cursor={"pointer"}
              fontSize={fontSizes.mobile_body_text}
              fontWeight={"600"}
              resFontSize={"14px"}
              color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
            >
              Drop an image or{" "}
              <span
                style={{ color: colorTheme.primary_color, fontSize: "16px" }}
              >
                Browse
              </span>
            </Text>
          </FlexibleDiv>
        )}
        {values.main_image ? null : (
          <FlexibleDiv
            margin={"30px 0px 12px 0px"}
            justifyContent={"center"}
            lineHeight={"1.8rem"}
            breakPoint={"680px"}
            breakPointMargin={"10px 0px 12px 0px"}
          >
            <Text
              style={{ textAlign: "center" }}
              cursor={"pointer"}
              fontSize={fontSizes.mobile_details}
              color={colorTheme.black.soft}
              fontWeight={"500"}
              resFontSize={"12px"}
            >
              This is the main photo that will be displayed in home page(Max
              10MB)
            </Text>
          </FlexibleDiv>
        )}
      </DropZone>
    </>
  );
};

export default MainImage;
