import styled from "styled-components";
import { colorTheme } from "../../../infrastructure/Theme/color";

export const TabOption= styled.div`
width: ${({width})=>width ? width :'5%'};
display: ${({display})=>display ? display :"block"};
justify-content:${({justifyContent})=>justifyContent ? justifyContent :""};
align-items: ${({alignItems})=>alignItems ? alignItems :""};
gap: ${({gap})=>gap ? gap :""};
position: relative;
&::after{
  content: '';
  background: ${({bg})=>bg ? bg : colorTheme.primary_color};
  height: 2px;
  width: ${({linewidth})=>linewidth ? linewidth :'45px'};
  top: 98%;
  position: absolute;
}
`