import React, { useState } from "react";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../primaryComponents/image/image";
// import Button from "../../primaryComponents/button/button.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { useSelector, useDispatch } from "react-redux";
import { CONSTANTS } from "../../../utilities/constants";

import styled from "styled-components";
import { cover_imageHandler } from "../../../state management/Redux/slices/editEvent/edit_details_slice";
const EditButton = styled.label`
  background: transparent;
  border-radius: 20px;
  padding: 10px;
  width: 209px;
  font-size: 12px;
  text-align: center;
  color: ${colorTheme.white.white};
  border: 1px solid #fff;
`;
const EditEventCoverImage = ({ setEditCoverImage,editCoverImage }) => {
  const cover_image = useSelector(
    ({ event_details }) => event_details?.data?.event?.cover_image
  );
  // const editImage = useSelector(({ edit_event }) => edit_event.cover_image);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const InputHandler = (e) => {
    const [file] = e.target.files;
    if (e.target.files && e.target.files[0]) {
      const data = {
        name: file.name,
        size: file.size,
        lastModified: file.lastModified,
        type: file.type,
        webkitRelativePath: file.webkitRelativePath,
        lastModifiedDate: file.lastModifiedDate
      };
      // dispatch(cover_imageHandler(file));
      setEditCoverImage(file);
      setImage(URL.createObjectURL(file));
    } else {
      return image;
    }
  };

  return (
    <>
      <FlexibleDiv height={"230px"} width={"850px"} position={"relative"}>
        <Image
          width={"100%"}
          height={"100%"}
          src={image ? image : `${CONSTANTS.imgbaseurl}${cover_image}`}
        />

        <FlexibleDiv
          position={"absolute"}
          height={"100%"}
          bg={"linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"}
        >
          <input
            onChange={(e) => {
              InputHandler(e);
            }}
            required
            id="file-input"
            type={"file"}
            style={{ display: "none" }}
          />
          <EditButton htmlFor="file-input">Change cover photo</EditButton>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default EditEventCoverImage;
