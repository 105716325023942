
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status:'User',
    activeTab:"Profile",
}

 const UserAccountSlice = createSlice({
    name:"User_Account",
    initialState,

    reducers:{
        changeAccountStatus(state,action){
            state.status = action.payload;
        },
        accountTabHandler(state,{payload}){
            state.activeTab=payload
        }
    }
})


export const {changeAccountStatus,accountTabHandler} = UserAccountSlice.actions;

export default  UserAccountSlice.reducer;