import styled from "styled-components";

export const DraftImageStyles = styled.div`
  display: flex;
  justify-content: inherit;
  position: relative;
  flex: 0.4;
  align-items: center;
  @media only screen and (max-width: 968px) {
    flex: 0.7;
  }
  @media only screen and (max-width: 884px) {
    flex: 1;
  }
  @media only screen and (max-width: 760px) {
    flex: 1.4;
  }
  @media only screen and (max-width: 680px) {
    flex: 3.1;
  }
`;

export const DraftDetailsStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  flex: 2;
  position: relative;
  height: 100%;
  max-height: 100%;
  background-color: ${({ bg }) => (bg ? bg : "#f7f7f7")};
  @media only screen and (max-width: 968px) {
    flex: 3;
  }
  @media only screen and (max-width: 884px) {
    flex: 3.6;
  }
  @media only screen and (max-width: 760px) {
    flex: 4.5;
  }
  @media only screen and (max-width: 680px) {
    flex: 5;
  }
`;
