import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  Text,
} from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import Head from "../../BlockCodes/header";
import FormOfEvent from "./formOfEvent.component";
import NameOfEvent from "./nameOfEvent.component";
import TimeOfEvent from "./timeOfEvent.component";

const BasicInfo = ({title,handleChange}) => {
  const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
  return (
    <>
      <FlexibleDiv direction={"column"}>
        {/* Title */}
        <FlexibleDiv
          direction={"column"}
          lineHeight={"1.9em"}
          alignItems={"flex-start"}
        >
          <Head
            title={"Basic Information"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            fontSize={fontSizes.desktop_body_text}
            resFontSize={"18px"}
            width={"100%"}
          />
          <Text
           color={darkMode ? colorTheme.textColor : colorTheme.ash} 
            margin={"0px"}
            fontSize={fontSizes.mobile_body_text}
            fontWeight={"400"}
            resFontSize={"16px"}
          >
            Please fill in the basic information about your event.
          </Text>
        </FlexibleDiv>

        {/* Name Of Event */}
        <NameOfEvent title={title} handleChange={handleChange} />

        {/* Time Of Event */}
        <TimeOfEvent />

        {/* Form Of Event */}
        <FormOfEvent />
      </FlexibleDiv>
    </>
  );
};

export default BasicInfo;
