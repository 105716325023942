import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import { TabOption } from "../account/styles";

const TabMenu = ({tabName,setTabName}) => {
    const [TabOptions, setTabOptions] = useState([
        { name: "Edit Profile", active: true },
        // { name: "Manage Billing Account", active: false },
        { name: "Password", active: false },
      ]);
    
      const ProfileTabStateHandler = () => {
          setTabOptions(() => {
            return [
                { name: "Edit Profile", active: true },
                // { name: "Manage Billing Account", active: false },
                { name: "Password", active: false },
            ];
          });
        };
    //     // } else if (!searchedAccount) {
    //     //   setTabOptions((e) => e.filter((e) => e.name !== "Events"));
    //     //   setTabOptions((e) => e.filter((e) => e.name !== "Draft"));
    //     // } else if (searchedAccount) {
    //     //   setTabOptions((e) => e.filter((e) => e.name !== "Draft"));
    //     //   setTabOptions((e) => e.filter((e) => e.name !== "Tickets"));
    //     // }
    //   };
      // useEffect(() => {
      //   ProfileTabStateHandler();
      // },[TabOptions,setTabOptions]);
    
      const TabHandler = (e) => {
        let Value = e.target.innerHTML;
        setTabName(Value);
      };
      const darkMode = useSelector(({persistedReducer})=>persistedReducer.theme.darkmode)
    
  return (
    <>
      <FlexibleDiv
        justifyContent={"flex-start"}
        gap={"22px"}
        breakPoint={"1180px"}
        breakPointgap={"25%"}
        breakPointPadding={"0px 32px"}
        borderBottom={"0.9px solid lightgrey"}
      >
        {TabOptions.map(({ name, active }) => (
          <TabOption
            bg={name === tabName ? "" : "none"}
            linewidth={"100%"}
            key={name}
            // width={name === "Edit Profile"  ? '50%' : "20%" && name==="Password" ? '50%' : '20%'}
            width="auto"
          >
            <Text
              cursor={"pointer"}
              onClick={(e) => {
                TabHandler(e, active);
              }}
              color={  name === tabName  ? darkMode ? colorTheme.white.white :'' : colorTheme.black.soft}
              fontSize={fontSizes.mobile_details}
              fontWeight={name === tabName  ? "bold" :"500"}
              resFontSize={"14px"}
            >
              {name}
            </Text>
          </TabOption>
        ))}
      </FlexibleDiv>
    </>
  );
};

export default TabMenu;
