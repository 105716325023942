import styled from "styled-components";
import Modal from "../../primaryComponents/modal/modal";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { Text } from "../../primaryComponents/typography/typography.component";
import Loader from "../../../assets/images/loading.svg";
import { Image } from "../../primaryComponents/image/image";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorIcon, CheckIcon } from "../../primaryComponents/icons/icons";
const Container = styled.div`
  width: 55px;
  height: 55px;
  background: ${colorTheme.black.fadingBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
  border-radius: 10px;
  padding: 3px;
  overflow: hidden;
`;
const Content = styled.div`
  transform: rotate(-45deg);
`;
const CreateEventLoader = ({ show, setShowModal }) => {
  const [message, setMessage] = useState("Creating your event...");
  const [showBtn, setShowButton] = useState(false);
  const error = useSelector(({ createEvent }) => createEvent.error);
  const isError = useSelector(({ createEvent }) => createEvent.isError);
  const success = useSelector(({ createEvent }) => createEvent.success);
  const loading = useSelector(({ createEvent }) => createEvent.loading);

  useEffect(() => {
    if (success) {
      setMessage("Event created successfully");
    }
  }, [success]);
  return (
    <>
      <Modal
        show={show}
        direction={"column"}
        setShowModal={setShowModal}
        showExitBtn={isError}
      >
        <Container>
          <Content>
            {isError && <ErrorIcon size={"34px"} color={colorTheme.red} />}
            {success && <CheckIcon size={"34px"} color={"green"} />}
            {loading && <Image src={Loader} width={"35px"} />}
          </Content>
        </Container>
        <FlexibleDiv margin={"12px 0px 0px 0px"}>
          <Text fontSize={"14px"} color={"white"}>
            {loading ? "Creating your event..." : "Event created successfully"}
          </Text>

          {isError &&
            error !== undefined &&
            Object.keys(error).map((key) => (
              <Text key={key} fontSize={"14px"} color={colorTheme.red}>
                {error !== undefined ? error[key][0] : "Network Error"}
              </Text>
            ))}

          {isError && error === undefined && (
            <Text fontSize={"14px"} color={colorTheme.red}>
              Network Error
            </Text>
          )}
        </FlexibleDiv>
      </Modal>
    </>
  );
};

export default CreateEventLoader;
