import ClientRequest from "../../../../api/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const get_categories = createAsyncThunk(
  "user/get_categories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/category/all");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.log("category error", error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  loading: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  category_list: []
};

const Categories_Slice = createSlice({
  name: "categories_slice",
  initialState,
  reducers: {
    clearCategoriesState: (state) => {
      state.category_list = [];
      state.loading = false;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_categories.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(get_categories.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.category_list = payload?.categories;
      })
      .addCase(get_categories.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.errorMessage = payload;
      });
  }
});

export const {clearCategoriesState} = Categories_Slice.actions
export default Categories_Slice.reducer;
