import React, { useState, createContext, useContext } from "react";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import LowerSection from "./components/lowerSection";
import TopSection from "./components/topSection";
const AccountContext = createContext();
export const AccountProvider = ({ children, accountType }) => {
  const [userType, setUserType] = useState(accountType);
  // const [organizer,setOrganizer] = useState('user');

  // useEffect(()=>{
  //   if(accountType === "user_loggedIn"){
  //     setUserType('user_loggedIn')
  //   }
  //   else if (accountType === "searched_organizer"){
  //     setUserType('searched_organizer')
  //   }
  //   if(userType === "organizer"){
  //     setUserType('organizer')
  //   }
  // })
  return (
    <AccountContext.Provider value={[userType, setUserType]}>
      {children}
    </AccountContext.Provider>
  );
};

export const AccountUserType = () => {
  return useContext(AccountContext);
};

const AccountPage = ({ accountType }) => {
  // const [userType,setUserType] = AccountUserType()
  // useEffect(()=>{
  //  if(location.pathname === "/account/organizer"){
  //   setUserType('searched_organizer')
  //  }
  //  else if(location.pathname === "/account/my-profile"){
  //   setUserType('user_loggedIn')
  //  }

  // },[userType])

  return (
    <AccountProvider accountType={accountType}>
      <FlexibleDiv>
        <FlexibleDiv width={"98%"} padding={"12px 0px 40px 0px"}>
          <TopSection />
          <LowerSection />
        </FlexibleDiv>
        <Footer />
      </FlexibleDiv>
    </AccountProvider>
  );
};

export default AccountPage;
