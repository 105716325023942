import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../../components/primaryComponents/image/image";
import Profile_Image from "../../../assets/images/no_user.png";
import Button from "../../../components/primaryComponents/button/button.component";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { EditButton, ProfileImageView, Badge, ProfileName } from "../styles";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../../infrastructure/Theme/color";
import {
  EditIcon,
  LocationIcon,
} from "../../../components/primaryComponents/icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import badge from "../../../assets/images/verifiedTick.png";
import { CONSTANTS } from "../../../utilities/constants";
// import { getLocation } from "../../../utilities/functions/getUserLocation";
const UserInfo = ({ searchedAccount, name, userStatus, profileImage }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const userInfo = useSelector((state) => state.persistedReducer.user.userInfo);
  const organizerStatus = userInfo?.organizer;

  const is_organizer = organizerStatus === 1 ? true : false;
  const navigate = useNavigate();
  const CreateEventHandler = () => {
    navigate("/create");
  };
  return (
    <FlexibleDiv
      position={"relative"}
      padding={"0px 0px"}
      margin={`${is_organizer ? "0px" : "66px 0px 0px 0px"}`}
      breakPointJustifyContent={"space-between"}
      breakPoint={"780px"}
      breakPointPadding={"13px 0px"}
      breakPointMargin={`${is_organizer ? "0px" : "30px 0px 0px 0px"}`}
    >
      <FlexibleDiv
        breakPoint={"780px"}
        breakPointWrap="nowrap"
        width={searchedAccount ? "100%" : "60%"}
        breakPointgap={"23px"}
        position={"relative"}
      >
        {/* Profile Image */}
        <ProfileImageView
          top={`${is_organizer ? "-90px" : "0px"}`}
          left={searchedAccount ? "2%" : "5%"}
          bg={darkMode ? colorTheme.black.fadeMini : "#FFF"}
        >
          <Image
            width={"80%"}
            resPoint={"680px"}
            resWidth={"60px"}
            resHeight={"60px"}
            radius={"50%"}
            height={"80%"}
            src={
              profileImage
                ? `${CONSTANTS.profileImageUrl}${profileImage}`
                : Profile_Image
            }
          />
          {searchedAccount ? null : (
            <Link to="/settings">
              <EditButton>
                <EditIcon color={"white"} />
              </EditButton>{" "}
            </Link>
          )}
        </ProfileImageView>
        {/* User */}
        <FlexibleDiv
          width={"80%"}
          position={"relative"}
          justifyContent={"flex-start"}
          padding={"12px 0px"}
          left={searchedAccount ? "2%" : "80px"}
          breakPoint={"780px"}
          breakPointPadding={"20px 0px"}
          breakPointLeft={"0px"}
          resPoint={"680px"}
          reswidth={"70%"}
        >
          {/* User name and User type */}
          <FlexibleDiv
            justifyContent={"flex-start"}
            padding={"0px"}
            gap={"12px"}
          >
            <ProfileName
              fontSize={fontSizes.mobile_body_text}
              fontWeight={"600"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              margin={"0px"}
              lineHeight={"29px"}
              resFontSize={fontSizes.mobile_details}
            >
              {name}
            </ProfileName>
            {searchedAccount ? (
              <Badge
                src={badge}
                height={"16px"}
                width={"16px"}
                alt={"attend-badge"}
              />
            ) : userStatus === 1 ? (
              <Badge
                src={badge}
                height={"16px"}
                width={"16px"}
                alt={"attend-badge"}
              />
            ) : null}
          </FlexibleDiv>

          {/* Location */}
          <FlexibleDiv justifyContent={"flex-start"} padding={"0px"}>
            <Text
              fontSize={fontSizes.mobile_smallest}
              fontWeight={"600"}
              color={"grey"}
              margin={"0px"}
              resFontSize={fontSizes.mobile_smallest}
            >
              @{userInfo?.username}
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>

      {searchedAccount ? null : (
        <FlexibleDiv
          width={"40%"}
          justifyContent={"flex-end"}
          gap={"12px"}
          resPoint={"680px"}
          reswidth={"30%"}
        >
          <Button
            onClick={() => {
              CreateEventHandler();
            }}
            padding={"5px"}
            style={{ gap: "5px" }}
            radius={"5px"}
          >
            + Create Event
          </Button>
        </FlexibleDiv>
      )}
    </FlexibleDiv>
  );
};

export default UserInfo;
