import * as yup from "yup";
export const contactUsSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Email is required"),
  name: yup.string().required("Name required"),
  subject: yup.string().required("Subject required"),
  message: yup.string().required("Message required"),
});
