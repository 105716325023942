import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClientRequest from "../../../../api/api";
export const get_user_events = createAsyncThunk(
  "user/get_user_events",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/user/get/events");
      return response.data;
    } catch (error) {
      console.error("Create Event error:", error);
      const { response, message } = error;
      console.log("messgae", response.data);
      if (response?.data === undefined) {
        return rejectWithValue(message);
      } else {
        return rejectWithValue(response.data);
      }
    }
  }
);
const initialState = {
  user_events: [],
  loading_user_events: false,
  loading_user_events_success: false,
  loading_user_events_failed: false,
  errorMessage: null
};

const User_Event_Slice = createSlice({
  name: "user_event_slice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(get_user_events.pending, (state) => {
        state.loading_user_events = true;
        state.loading_user_events_failed = false;
        state.loading_user_events_success = false;
      })
      .addCase(get_user_events.fulfilled, (state, { payload }) => {
        state.loading_user_events = false;
        state.loading_user_events_success = true;
        state.user_events = payload;
      })
      .addCase(get_user_events.rejected, (state, { payload }) => {
        state.loading_user_events = false;
        state.loading_user_events_failed = true;
        state.errorMessage = payload;
      });
  }
});

export default User_Event_Slice.reducer;
