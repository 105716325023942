import React from 'react';
import { Image } from '../../primaryComponents/image/image';
import logoLightMode from '../../../assets/images/logo(lightmode).png'
import logoDarkMode from '../../../assets/images/logo(darkmode).png'
import styled from 'styled-components';
const LogoImage = styled(Image)`
@media only screen and (max-width:680px) {
    width:${({breakPointWidth})=>breakPointWidth ? breakPointWidth : '98px'};
}
`
const Logo = ({margin,width,breakPointWidth,darkMode}) => {
    return ( 
        <>
        
        {/* <Text resFontSize={'21px'} cursor={'pointer'}  margin={margin ? margin :'24px'} fontSize={fontSizes.desktop_subtitle} fontWeight={'900'} color={colorTheme.primary_color} >event<span style={{color:colorTheme.secondary_color}} >pay</span></Text> */}
        {darkMode ? <LogoImage breakPointWidth={breakPointWidth} src={logoDarkMode} width={width ? width : "110px"}  alt={'logo'} /> : <LogoImage breakPointWidth={breakPointWidth} src={logoLightMode} width={width ? width : "110px"}  alt={'logo'} /> }

        </>
     );
}
 
export default Logo;