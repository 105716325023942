import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    email:'',
    password:'',
    rememberMe:false,
    userData:{},
    loggedIn:true
}


const LoginSlice = createSlice({
    name:"login_state",
    initialState:initialState,
    reducers:{
        loginEmailHandler(state,{payload}){
            state.email=payload
        },
        loginPasswordHandler(state,{payload}){
            state.password=payload
        },
        rememberMeHandler(state,{payload}){
            state.rememberMe=payload
        },
        clearLogin(state){
            state.password= "";
            state.email= "";
            state.rememberMe=false
        }


    }
})


export const {
    loginEmailHandler,
    loginPasswordHandler,
    rememberMeHandler,
    clearLogin
}  = LoginSlice.actions

export default LoginSlice.reducer