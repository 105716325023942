import React from "react";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../components/primaryComponents/image/image";
import badge from "../../assets/images/verifiedTick.png";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { useSelector } from "react-redux";
import { dateDateFormatter } from "../../utilities/functions/formatDate";
import Skeleton from "react-loading-skeleton";
import { CONSTANTS } from "../../utilities/constants";
const EventInfo = React.memo(() => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const title = useSelector(
    ({ event_details }) => event_details?.data?.event?.title
  );
  const status = useSelector(
    ({ event_details }) => event_details?.data?.event?.status
  );
  const startDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.startDate
  );
  const endDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.endDate
  );
    const cover_image = useSelector(
      ({ event_details }) => event_details?.data?.event?.cover_image
    );
    const location = useSelector(
      ({ event_details }) => event_details?.data?.event?.location
    );

  const loading = useSelector(({ event_details }) => event_details?.loading);
  const { first } = dateDateFormatter(startDate, endDate);

  return (
    <>
      <FlexibleDiv
        flexBasis={"50%"}
        justifyContent={"flex-start"}
        gap={"20px"}
        wrap={"nowrap"}
        alignItems={"flex-start"}
        breakPoint={"880px"}
        breakPointdirection={"column"}
        breakPointAlign={'flex-start'}
        breakPointflexBasis={"100%"}
      >
        {/* image */}
        {loading ? (
          <Skeleton width={108} height={100} />
        ) : (
          <FlexibleDiv
            flexBasis={"30%"}
            position={"relative"}
            justifyContent={"flex-start"}
            breakPoint={"880px"}
            breakPointflexBasis={"100%"}
          >
            <Image
              src={`${CONSTANTS.imgbaseurl}${cover_image}`}
              width={"100%"}
              height={"100px"}
              radius={"5px"}
              style={{ position: "relative" }}
            />
            <FlexibleDiv
              position={"absolute"}
              zIndex={"1"}
              bottom={"0px"}
              right={"-40%"}
            >
              <Image
                src={badge}
                width={"22px"}
                height={"22px"}
                radius={"5px"}
                alt={"badge"}
              />
            </FlexibleDiv>
          </FlexibleDiv>
        )}

        <FlexibleDiv
          direction={"column"}
          flexBasis={"90%"}
          alignItems={"flex-start"}
          breakPoint={"880px"}
          breakPointflexBasis={"100%"}
        >
          {/* Event title */}
          {loading ? (
            <Skeleton width={200} height={28} />
          ) : (
            <FlexibleDiv
              justifyContent={"flex-start"}
              gap={"12px"}
              lineHeight={"24.2px"}
            >
              <Text
                margin={"5px 0px 10px 0px"}
                fontSize={"18px"}
                resFontSize={"17px"}
                fontWeight={"500"}
                color={titleColor}
              >
                {title}
              </Text>
              <Text
                margin={"5px 0px 10px 0px"}
                fontSize={"13px"}
                resFontSize={"13px"}
                color={colorTheme.primary_color}
              >
                -{status}
              </Text>
            </FlexibleDiv>
          )}
          {loading ? (
            <Skeleton width={103} height={28} style={{marginTop:12}} />
          ) : (
            <FlexibleDiv justifyContent={"flex-start"}>
              <Text
                margin={"0px"}
                fontWeight={"500"}
                lineHeight={"19px"}
                fontSize={"15px"}
                resFontSize={"15px"}
                color={colorTheme.textColor}
              >
                {first?.dayOfWeek},{first?.formattedDate},{" "}
                {first?.formattedTime} GMT +5:00{" "}
              </Text>
            </FlexibleDiv>
          )}
          <FlexibleDiv justifyContent={"flex-start"}>
            <Text
              fontWeight={"500"}
              fontSize={"13px"}
              resFontSize={"13px"}
              color={colorTheme.textColor}
            >
            {location}
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
});

export default EventInfo;
