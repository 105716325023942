import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { editProfileImageHandler } from "../../../state management/Redux/slices/user_slice/edit_profile_slice";
import Loader from "../loader/loader.component";

const SaveEdit = ({ set_profile_image_file, profile_image_file, formik }) => {
  const dispatch = useDispatch();
  const start_edit = useSelector(
    ({ edit_profile }) => edit_profile?.start_edit
  );
  const isProfileEditted = useSelector(
    ({ edit_profile }) => edit_profile?.isProfileEditted
  );
  const loading = useSelector(({ edit_profile }) => edit_profile?.loading);
  const is_profile_image = useSelector(
    ({ edit_profile }) => edit_profile?.is_profile_image
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const background = !start_edit
    ? colorTheme.lightgrey
    : loading
    ? colorTheme.white.white
    : colorTheme.primary_color;
  const saveEditHandler = () => {
    if (is_profile_image === true && isProfileEditted == true) {
      formik?.handleSubmit();
      dispatch(
        editProfileImageHandler({
          token: token,
          profile_image: profile_image_file,
        })
      );
    } else if (isProfileEditted && is_profile_image === false) {
      formik?.handleSubmit();
    } else if (is_profile_image && isProfileEditted === false) {
      dispatch(
        editProfileImageHandler({
          token: token,
          profile_image: profile_image_file,
        })
      );
    }
  };
  return (
    <>
      <FlexibleDiv justifyContent={"flex-start"} gap={"23px"}>
        <Button
          border={"1.5px solid lightgrey"}
          width={"80px"}
          fontSize={"14px"}
          padding={"5px"}
          radius={"5px"}
          color={"black"}
          background={"white"}
          hoverBackground={"white"}
          hoverColor={"black"}
          hoverBorder={"1.5px solid lightgrey"}
        >
          Cancel
        </Button>
        <Button
          type={"submit"}
          disabled={!start_edit}
          onClick={saveEditHandler}
          border={"none"}
          width={"80px"}
          padding={"5px"}
          radius={"5px"}
          fontSize={"14px"}
          color={"white"}
          background={background}
          hoverBackground={background}
          hoverColor={"white"}
          hoverBorder={"none"}
        >
          {loading ? <Loader width={"30px"} height={"30px"} /> : "Save"}
        </Button>
      </FlexibleDiv>
    </>
  );
};

export default SaveEdit;
