import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import EventAttendees from "./eventAttendees.component";
import EventChart from "./eventChart.component";
import MetricsHead from "./metricsHead.component";
import TicketsSold from "./ticketsSold.component";



const EventMetrics = () => {
    return ( 
        <>
        <FlexibleDiv display={'block'} margin={"20px 0px"} >
        <FlexibleDiv border={'1px solid #6F767E'} radius={'10px'} padding={"23px"}  width={"97%"} breakPoint={"780px"} breakPointborder={'none'} breakPointwidth={"100%"}  breakPointPadding={'12px 0px'}>
        <MetricsHead/>
        <FlexibleDiv alignItems={'stretch'} margin={"20px 0px 0px 0px"}  justifyContent={'flex-start'} gap={'12px'} wrap={"nowrap"} breakPoint={"780px"} breakPointgap={"12px"} breakPointWrap={"nowrap"} breakPointAlign={'stretch'} >
            <TicketsSold/>
            <EventAttendees/>
            {/* <EventChart/> */}
        </FlexibleDiv>
        </FlexibleDiv>
        </FlexibleDiv>
        </>
     );
}
 
export default EventMetrics;


