import styled from "styled-components";
import { colorTheme } from "../Theme/color";

export const LayoutStyle = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
`;
export const SideBarStyle = styled.div`
  width: 235px;
  padding-top: 2px;
  left: 0;
  top: 0;
  position: fixed;
  min-height: 100vh;
  z-index: 9;
  background: ${colorTheme.sideBarBg};
  transition: 0.5s ease-in;
  @media only screen and (max-width: 780px) {
    transform: translateX(-100%);
  }
`;
export const MainSection = styled.div`
  padding: 30px 0px 30px 30px;
  margin-left: 225px;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;

  @media only screen and (max-width: 780px) {
    padding: 10px;
    margin-left: 0px;
  }
`;
export const LogoContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
`;
export const LogOutContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0px;
  padding: 25px 0px 25px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    width: 100%;
    color: #000;
    font-weight: 600;
  }
  svg {
    color: ${colorTheme.ash};
  }
  &:hover {
    color: #fff;
  }
`;

export const SideBarStyle2 = styled.article`
  height: calc(100% - 62px);
  height: 100%;
  position: fixed;
  left: 0;
  top: 62px;
  width: 220px;
  overflow-y: scroll;
  background:${({bg})=>bg ? bg :colorTheme.sideBarBg} ;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
  @media screen and (max-width: 1180px) {
    display: none;
  }
`;

export const MainSection2 = styled.main`
  top: 62px;
  /* height: calc(100% - 118px); */
  width: calc(100% - 220px);
  position: absolute;
  left: ${({left})=>left ? left :'220px'};
  width: ${({width})=>width ? width : 'calc(100% - 220px)'};
  background-color: ${({bg})=>bg ? bg :'#FFF'};
  overflow-x: hidden;
  @media screen and (max-width: 1180px) {
    width: 100%;
    left: 0px;
  }
`;
// Menu Container
export const MenuItemsContainer = styled.ul`
  /* margin-top: 92px; */
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px;
  position: relative;
  a{
    width: 95%;
    text-decoration: none;
    color: black;
  }
`;
// Menu Items
export const MenuItems = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  position: relative;
  margin: 0px;
  top:-13px;
`;
// Menu Title
export const MenuItemTitle = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  font-weight: 500;
  cursor: pointer;
  margin: 0px;
  border-radius: 5px;
  padding: 9px;
  font-size: 14px;
  /* margin-bottom:33px; */
  width: 90%;
  color: ${({color})=>color ? color :colorTheme.white.white};
  transition: background 0.3 ease-in-out;
  &:hover {
    transition:background 0.5s ease-in-out;
    background: ${colorTheme.primary_color};
    padding: 9px;
    color: white;
  }
  span{
    font-size: 14px;
  }
`;
// Sub Nav
export const SubNav = styled.div`
  width: 90%;
  position: relative;
  display: flex;
  margin-top: 3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  left: ${({ left }) => (left ? left : "22px")};
`;
// SubNav Items
export const SubNavItem = styled.li`
  font-size: 12.5px;
  position: relative;
  list-style-type: none;
  cursor: pointer;
  font-weight: 300;
  width: 89%;
  display: flex;
  align-items: center;
  color: ${({color})=>color ? color :'#000'};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  line-height: 1.1rem;
  gap: 9px;
  /* padding: 2px 4.3px 2px 4.3px; */
  padding: 5px 9px;
  transition: background 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: ${({hoverBg})=>hoverBg ? hoverBg :'lightgrey'};
  }
`;
export const NumberBox = styled.div`
  padding: 4px;
  width: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorTheme.primary_color};
  font-size: 0.7rem;
  font-weight: 600;
`;
export const ProfilePic = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  object-fit: cover;
`;
export const NewEventCircleNot = styled.div`
  width: 8.4px;
  height: 8.4px;
  border-radius: 8.4px;
  background: #0ecf21;
  position: absolute;
  left: 23px;
  top: 9%;
  z-index: 1;
`;
