import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientRequest from "../api/api";
export const followOrganizer = createAsyncThunk(
  "user/follow-organizer",
  async ({ organizerId }, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.post("api/user/follow-user", {
        target_user: organizerId
      });
      if (response.status === 200) {
      return response?.success
      }
    } catch (error) {
      const { response } = error;
      console.log("Follower Organizer Error", response);
      return rejectWithValue(error);
    }
  }
);
