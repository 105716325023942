import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";

const Title = ({ title, color, size }) => {
  return (
    <>
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          color={color}
          fontSize={size ? size : fontSizes.mobile_details}
          fontWeight={"700"}
        >
          {title}
        </Text>
      </FlexibleDiv>
    </>
  );
};

export default Title;
