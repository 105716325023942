import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import TickerOrder from "../eventAnalytics/ticketOrder.component";
import Amount from "../manageBilling/amount.component";
import PaymentDate from "../manageBilling/paymentDate.component";
// import PaymentMethod from "../manageBilling/paymentMethod.component";
import PaymentStatus from "../manageBilling/paymentStatus.component";
import Users from "../manageBilling/users.component";
import "./styles.css";
import { dateDateFormatter } from "../../../utilities/functions/formatDate";
const TableComponent = ({ options, styles, components }) => {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            {options.map(({ option, index }) => (
              <Th style={styles} key={index}>
                {option}
              </Th>
            ))}
          </Tr>
        </Thead>
        <FlexibleDiv
          height={"1px"}
          bg={"lightgrey"}
          width={"94%"}
          position={"absolute"}
          margin={"20px 0px"}
          breakPoint={"40em"}
          breakPointDisplay={"none"}
        />
        <Tbody style={{ margin: "120px 0px", position: "relative" }}>
          {components.map(
            ({ amount_paid, user, status, updated_at, ticket_code }) => {
              const name = `${user?.firstname} ${user?.lastname}`;
              const { first } = dateDateFormatter(updated_at);
              return (
                <>
                  <Tr>
                    <Td>
                      {" "}
                      <Amount amount={amount_paid} />
                    </Td>
                    <Td>
                      <Users name={name} />
                    </Td>
                    <Td>
                      <PaymentStatus status={status} />
                    </Td>
                    <Td>
                      <PaymentDate date={first?.formattedDate} />
                    </Td>
                    {/* <Td><PaymentMethod method={method}/></Td> */}
                    {ticket_code ? (
                      <Td>
                        <TickerOrder ticketOrder={ticket_code} />
                      </Td>
                    ) : null}
                  </Tr>
                  <FlexibleDiv
                    height={"1px"}
                    bg={"lightgrey"}
                    width={"94%"}
                    position={"absolute"}
                  />
                </>
              );
            }
          )}
        </Tbody>
      </Table>
    </>
  );
};

export default TableComponent;

export const TestTable = () => {
  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>Event</Th>
            <Th>Date</Th>
            <Th>Location</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Tablescon</Td>
            <Td>9 April 2019</Td>
            <Td>East Annex</Td>
          </Tr>
          <Tr>
            <Td>Capstone</Td>
            <Td>9 April 2019</Td>
            <Td>East Annex</Td>
          </Tr>
          <Tr>
            <Td>Tuscaloosa</Td>
            <Td>9 April 2019</Td>
            <Td>East Annex</Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};
