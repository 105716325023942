import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colorTheme } from "../../../infrastructure/Theme/color";
import {
  clearAuthServiceState,
  logOut
} from "../../../state management/Redux/slices/user_slice";
import { Text } from "../../primaryComponents/typography/typography.component";
import { accountTabHandler } from "../../../state management/Redux/slices/userAccountSlice";
import { clearCategoriesState } from "../../../state management/Redux/slices/categories/get_categories_slice";

const ProfileDropDownStyle = styled.div`
  height: ${({ height }) => (height ? height : "auto")};
  display: ${({ display }) => (display ? display : "flex")};
  background: ${({ bg }) => (bg ? bg : "#FFF")};
  width: 200px;
  position: absolute;
  flex-direction: column;
  top: 50px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 0px 2px lightgrey;
  overflow-x: hidden;
`;

const Item = styled.div`
  width: 100%;
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : ` 0.5px solid ${colorTheme.black.soft}`};
  &:hover {
    background-color: ${colorTheme.black.light};
  }
`;

const ProfileDropDown = ({ display, toggleHandler }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const userInfo = useSelector((state) => state.persistedReducer.user.userInfo);
  const isOrganizer = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.organizer
  );
  const no_of_tickets = userInfo?.tickets?.length;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOutHandler = async () => {
    dispatch(logOut());
    dispatch(clearAuthServiceState());
    dispatch(clearCategoriesState());
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <ProfileDropDownStyle
        display={display}
        bg={darkMode ? colorTheme.black.fadingBlack : ""}
      >
        <Link
          to="/my-profile"
          onClick={() => {
            toggleHandler();
            dispatch(accountTabHandler("Tickets"));
          }}
        >
          <Item borderBottom={darkMode ? colorTheme.grey : ""}>
            <Text
              color={darkMode ? "#FFF" : ""}
              fontWeight={"600"}
              cursor={"pointer"}
              fontSize={"13px"}
              resFontSize="13px"
              margin={"0px"}
              lineHeight={"31.2px"}
              style={{ padding: "0px 50px" }}
            >
              Tickets({no_of_tickets})
            </Text>
          </Item>
        </Link>

        <Link to="/contact-us" onClick={() => toggleHandler()}>
          <Item borderBottom={darkMode ? colorTheme.black.fadingBlack : ""}>
            <Text
              color={darkMode ? "#FFF" : ""}
              fontWeight={"600"}
              cursor={"pointer"}
              fontSize={"13px"}
              resFontSize="13px"
              margin={"0px"}
              lineHeight={"31.2px"}
              style={{ padding: "0px 50px" }}
            >
              Contact Us
            </Text>
          </Item>
        </Link>

        {isOrganizer === 1 && (
          <Link
            to={"/my-profile"}
            onClick={() => {
              toggleHandler();
              dispatch(accountTabHandler("Events"));
            }}
          >
            <Item borderBottom={darkMode ? colorTheme.grey : "red"}>
              <Text
                color={darkMode ? "#FFF" : ""}
                fontWeight={"600"}
                cursor={"pointer"}
                fontSize={"13px"}
                resFontSize="13px"
                margin={"0px"}
                lineHeight={"31.2px"}
                style={{ padding: "0px 50px" }}
              >
                Manage Event
              </Text>
            </Item>
          </Link>
        )}

        <Item
          borderBottom={darkMode ? colorTheme.black.fadingBlack : ""}
          justifyContent={"flex-start"}
          onClick={() => toggleHandler()}
        >
          <Text
            onClick={() => {
              logOutHandler();
            }}
            color={darkMode ? "#FFF" : ""}
            fontWeight={"600"}
            cursor={"pointer"}
            fontSize={"13px"}
            resFontSize="13px"
            margin={"0px"}
            lineHeight={"31.2px"}
            style={{ padding: "0px 50px" }}
          >
            Logout
          </Text>
        </Item>
      </ProfileDropDownStyle>
    </>
  );
};

export default ProfileDropDown;
