import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import Head from "../../BlockCodes/header";
import Address from "./address.component";
import NAME_OF_LOCATION from "./NAME_OF_LOCATION.component";
import TagsComp from "./tags.component";
import TYPE_OF_LOCATION from "./TYPE_OF_LOCATION.component";

const Location = () => {
  const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
  return (
    <>
       <FlexibleDiv direction={'column'} margin={"30px 0px"} >
      {/* Title */}
      <FlexibleDiv direction={'column'} lineHeight={"1.9em"}  alignItems={'flex-start'} margin={"0px 0px 23px 0px"} >
      <Head color={darkMode ? colorTheme.white.white : colorTheme.black.strong}  fontSize={fontSizes.desktop_body_text} width={'100%'} resFontSize={"18px"} title={"Location"} />
      <Text color={colorTheme.textColor} margin={"0px"} fontSize={fontSizes.mobile_body_text} fontWeight={"400"} resFontSize={"16px"} >Please tell us more information about the location of your event.</Text>
      </FlexibleDiv>

      {/* TYPE OF LOCATION */}
      <TYPE_OF_LOCATION/>
      {/* Name Of Location */}
      <NAME_OF_LOCATION/>
      
      {/* Address and Tags */}
      <FlexibleDiv justifyContent={'space-between'} >
        {/* <Address/> */}
        <TagsComp/>
      </FlexibleDiv>
    </FlexibleDiv>
    
    </>
    );
}
 
export default Location;