import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { HeaderText, Text } from "../../primaryComponents/typography/typography.component";



const UnAuthorized = () => {
    const navigate = useNavigate()
    const darkMode = useSelector(({persistedReducer})=>persistedReducer.theme.darkmode)
    return ( 
        <>
        <FlexibleDiv height={'100%'} bg={darkMode ? colorTheme.black.darkmode1 : colorTheme.white.white} >
        <FlexibleDiv direction={'column'} lineHeight={'33px'} >
            <HeaderText fontSize={'120px'} resFontSize={"43px"} color={colorTheme.primary_color} >401</HeaderText>
            <Text margin={'0px'} resFontSize={"15px"} color={colorTheme.primary_color} >No Authorization Found</Text>
            <Text margin={'0px'} resFontSize={"13px"} fontSize={"14px"} color={darkMode ? colorTheme.white.white : colorTheme.black.strong} >This page is not publically available. </Text>
            <Text margin={'0px'} resFontSize={"13px"} fontSize={"14px"} color={darkMode ? colorTheme.white.white : colorTheme.black.strong} > To access it please login. </Text>
            <FlexibleDiv margin={'40px 0px'} justifyContent={'center'} gap={"12px"} >
                <Button onClick={()=>navigate('/')} width={'170px'} children={"Return Home"} border={`0.3px solid ${colorTheme.primary_color}`} background={"white"} color={colorTheme.primary_color} padding={"12px"} radius={"4px"} hoverBackground={colorTheme.primary_color} hoverColor={"#FFF"} hoverBorder={'none'} />
                <Button onClick={()=>navigate('/login')} width={'170px'} children={"Login"}  color={'#FFF'} padding={"12px"} radius={"4px"} hoverBackground={'#FFF'} hoverColor={colorTheme.primary_color} hoverBorder={`0.3px solid ${colorTheme.primary_color}`} />
            </FlexibleDiv>
        </FlexibleDiv>
        </FlexibleDiv>
        </>
     );
}
 
export default UnAuthorized;