import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
// import {  CalendarIcon } from "../../primaryComponents/icons/icons";
import { HeaderText, Text } from "../../primaryComponents/typography/typography.component";
// import DropDown from "../dropDown/dropDown.component";


const AnalyticsHead =()=>{
    const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
    const iconColor = darkMode ? colorTheme.white.white :colorTheme.black.strong
    return(
        <>
          <FlexibleDiv direction={'column'} margin={"20px 0px"} >
            <FlexibleDiv margin={'10px 0px'} justifyContent={"space-between"} direction={"row"}   >
            <HeaderText margin={"0px"} fontSize={"26px"} resFontSize={"18px"} color={darkMode ? colorTheme.white.white : colorTheme.black.strong} >Analytics</HeaderText>
            {/* <DropDown icon={<CalendarIcon color={iconColor} />} width={'183px'} label={'Last 3 Months'} components={['H','K']} /> */}
        </FlexibleDiv>
          <FlexibleDiv justifyContent={'flex-start'} nowrap={'nowrap'}  >
            <Text  color={colorTheme.textColor} margin={"0px"} fontSize={"15px"} resFontSize={"13.8px"} >Take a quick look how your event is going</Text>
          </FlexibleDiv>
        </FlexibleDiv>
        </>
    )
}

export default AnalyticsHead;