import { createSlice} from "@reduxjs/toolkit";
import { RegCategories } from "../../../../pages/Login-SignUp/components/categoriesData";

// INITIAL STATE
const signUpState = {
  firstName: "",
  lastName: "",
  password: "",
  email: "",
  phoneNumber:"",
  userName:"",
  gender: "",
  categories: [],
  errorMsg: "",
  readTerms: false,
  agreeTerms: false,
  selected: new Array(RegCategories.length).fill(false),
  isFetching:false,
  isSuccess:false,

};

// SLICE
const SignUpSlice = createSlice({
  name: "signup_slice",
  initialState: signUpState,
  reducers: {
    clearState(state){
     state.firstName= "";
  state.lastName= "";
  state.password= "";
  state.email= "";
  state.phoneNumber="";
  state.gender= "";
  state.readTerms= false;
  state.agreeTerms= false;
    }
    // selectMarkHandler(state, { payload }) {
    //   state.selected = state.selected;
    // },
  },

});

// ACTIONS
export const {
  firstNameHandler,
  lastNameHandler,
  passwordHandler,
  phoneNumberHandler,
  emailHandler,
  userNameHandler,
  genderHandler,
  categoriesValues,
  addToCategories,
  removeFromCategories,
  agreeTermsHandler,
  readTermsHandler,
  clearState,
} = SignUpSlice.actions;


// REDUCER
export default SignUpSlice.reducer;
