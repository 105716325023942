import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { dateDateFormatter } from "../../../utilities/functions/formatDate";

const TicketDate = ({ eventDate }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const { first } = dateDateFormatter(eventDate);
  return (
    <FlexibleDiv
      width={"10%"}
      direction={"column"}
      justifyContent={"flex-start"}
      alignItems={"flex-start"}
      lineHeight={"1.9rem"}
      breakPoint={"680px"}
      breakPointwidth={"100%"}
      breakPointdirection={"row"}
      breakPointJustifyContent={"flex-start"}
      breakPointgap={"20px"}
    >
      {/* Month */}
      <Text
        margin={"0px"}
        color={colorTheme.secondary_color}
        fontWeight={"600"}
        fontSize={"1rem"}
        resFontSize={".8rem"}
      >
        {first.dayOfWeek}
      </Text>
      {/* day */}
      <Text
        margin={"0px"}
        color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        fontWeight={"600"}
        fontSize={"1.6rem"}
        resFontSize={"1rem"}
      >
        {first.formattedStartDay}
      </Text>
    </FlexibleDiv>
  );
};

export default TicketDate;
