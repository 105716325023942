import { Link } from "react-router-dom";
import Button from "../../../components/primaryComponents/button/button.component";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { HorizontalLine } from "../../../components/primaryComponents/horizontalLine/horizontalLine";
import Input from "../../../components/primaryComponents/input/input.component";
import {
  HeaderText,
  Text,
} from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { InputContainer, Label } from "../styles";
import { useSelector } from "react-redux";
import { useFormContext } from "../../../state management/context/formContext";
import { Next } from "../../../components/secondaryComponents/stepVerification/stepVerification";
import {
  EyeCloseIcon,
  EyeOpenIcon,
  GoogleIcon,
} from "../../../components/primaryComponents/icons/icons";
import { useEffect, useState } from "react";
import { getGoogleUrl } from "../../../services/google_services";

const Step1 = ({ noOfSlides, slides, setSlides, setFinishedStep2 }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const [url, setUrl] = useState("");
  const [passwordSecurity, setPasswordSecurity] = useState(true);
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    touched,
    handleBlur,
    setFieldValue,
  } = useFormContext();
  const Continue = () => {
    if (
      values?.email === "" ||
      values?.password === "" ||
      values.phoneNumber === "" ||
      values?.readTerms === false
    ) {
      handleSubmit();
      setFinishedStep2(false);
    } else if (
      errors?.email ||
      errors?.password ||
      errors?.phoneNumber ||
      errors?.readTerms
    ) {
      handleSubmit();
      setFinishedStep2(true);
    } else {
      Next(slides, setSlides, noOfSlides);
    }
  };

  const getUrlHandler = async () => {
    const url = await getGoogleUrl();
    setUrl(url);
  };
  useEffect(() => {
    getUrlHandler();
  }, []);
  return (
    <>
      <FlexibleDiv>
        <FlexibleDiv
          lineHeight={"2.7rem"}
          direction={"column"}
          width={"100%"}
          padding={"2px 0px"}
          alignItems={"flex-start"}
          breakPoint={"1105px"}
          breakPointwidth={"100%"}
          breakPointdirection={"column"}
          breakPointAlign={"flex-start"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={fontSizes.desktop_title}
            color={darkMode ? colorTheme.white.white : colorTheme.textColor}
            fontWeight={"600"}
            resFontSize={"18px"}
          >
            Create an account
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"14px"}
            color={colorTheme.textColor}
            fontWeight={"500"}
            resFontSize={"12px"}
          >
            Create an account to build your personal review.
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>

      {/* Email */}
      <InputContainer margin={"2px 0px 2px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            <span
              style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
            >
              *
            </span>{" "}
            Email{" "}
            {errors?.email && touched.email && (
              <span
                style={{
                  fontSize: "11.5px",
                  color: "red",
                  fontWeight: "400",
                  margin: "0px 0px 0px 12px",
                }}
              >
                {errors?.email}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        <Input
          value={values?.email}
          onChange={(e) => {
            handleChange("email")(e);
          }}
          onBlur={handleBlur("email")}
          // required
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          type={"email"}
          placeHolder={"davidgreat123@gmail.com"}
          fontSize={"13px"}
          placeholderSize={"13px"}
          padding={"13px"}
          border={`1.4px solid ${colorTheme.black.light}`}
          radius={"5px"}
          width={"100%"}
        />
      </InputContainer>

      {/* phone number*/}
      <InputContainer margin={"12px 0px 12px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <span
            style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
          >
            *
          </span>

          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Phone number
            {errors?.phoneNumber && touched.phoneNumber && (
              <span
                style={{
                  color: "red",
                  fontSize: "11.5px",
                  fontWeight: "400",
                  margin: "0px 0px 0px 12px",
                }}
              >
                {errors.phoneNumber}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        <Input
          // required
          value={values?.phoneNumber}
          onChange={(e) => {
            handleChange("phoneNumber")(e);
          }}
          onBlur={handleBlur("phoneNumber")}
          type={"tel"}
          placeHolder={"Whatsapp number"}
          placeholderSize={"13px"}
          fontSize={"13px"}
          padding={"13px"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          border={`1.4px solid ${colorTheme.black.light}`}
          radius={"5px"}
          width={"100%"}
        />
      </InputContainer>
      {/* /Password */}
      <InputContainer margin={"12px 0px 12px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} margin={"0px 0px 12px 0px "}>
          <Label
            labelSize={"14px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            <span
              style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}
            >
              *
            </span>{" "}
            Password{" "}
            {errors?.password && touched?.password && (
              <span
                style={{
                  color: "red",
                  fontSize: "11.5px",
                  fontWeight: "400",
                  margin: "0px 0px 0px 12px",
                }}
              >
                {errors?.password}
              </span>
            )}
          </Label>
        </FlexibleDiv>
        {/* <Input
          // required
          value={values?.password}
          onBlur={handleBlur("password")}
          onChange={(e) => {
            handleChange("password")(e);
          }}
          type={"password"}
          placeHolder={"5+ Characters"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          fontSize={"13px"}
          placeholderSize={"13px"}
          padding={"13px"}
          border={`1.4px solid ${colorTheme.black.light}`}
          radius={"5px"}
          width={"100%"}
        /> */}
        <FlexibleDiv
          wrap={"nowrap"}
          justifyContent={"space-between"}
          padding={"13px"}
          radius={"5px"}
          border={`1.4px solid ${colorTheme.black.light}`}
        >
          <Input
            width={"90%"}
            placeholderSize={"13px"}
            value={values?.password}
            onBlur={handleBlur("password")}
            onChange={(e) => {
              handleChange("password")(e);
            }}
            required
            type={passwordSecurity ? "password" : "text"}
            placeHolder={"5+ Characters"}
            fontSize={"13px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          />
          <FlexibleDiv width={"10%"}>
            {passwordSecurity ? (
              <EyeCloseIcon
                onClick={() => {
                  setPasswordSecurity(false);
                }}
                cursor={"pointer"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            ) : (
              <EyeOpenIcon
                onClick={() => {
                  setPasswordSecurity(true);
                }}
                cursor={"pointer"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            )}
          </FlexibleDiv>
        </FlexibleDiv>
      </InputContainer>
      <FlexibleDiv justifyContent={"flex-start"} gap={"10px"} wrap={"no-wrap"}>
        <span style={{ fontWeight: "lighter", color: "red", fontSize: "12px" }}>
          *
        </span>
        <Input
          type={"checkbox"}
          // required
          checked={values?.readTerms}
          onChange={() => {
            // dispatch(readTermsHandler(!readTerms));
            setFieldValue("readTerms", !values.readTerms);
          }}
        />
        <Text
          fontSize={fontSizes.mobile_smallest}
          fontWeight={"600"}
          lineHeight={"17px"}
          resFontSize={"12px"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
        >
          I have read the Attend's{" "}
          <span
            style={{
              color: darkMode ? colorTheme.blue : "#0fb3b3",
              cursor: "pointer",
              fontWeight: "500",
            }}
          >
            Terms of services
          </span>{" "}
          and{" "}
          <span
            style={{
              color: darkMode ? colorTheme.blue : "#0fb3b3",
              cursor: "pointer",
              fontWeight: "500",
            }}
          >
            Privacy Policy.
          </span>
          {errors?.readTerms && touched?.readTerms && (
            <span
              style={{
                color: "red",
                fontWeight: "400",
                fontSize: "11.5px",
                margin: "0px 0px 0px 12px",
              }}
            >
              {errors?.readTerms}
            </span>
          )}
        </Text>
      </FlexibleDiv>
      <FlexibleDiv padding={"12px 0px"}>
        <Button
          padding={"12px"}
          radius={"5px"}
          width={"100%"}
          fontSize={"14px"}
          type={"submit"}
          hoverColor={colorTheme.primary_color}
          hoverBorder={"none"}
          onClick={() => {
            Continue();
          }}
        >
          Next
        </Button>
      </FlexibleDiv>
      {/* Or */}
      <FlexibleDiv>
        <HorizontalLine
          bg={colorTheme.black.light}
          height={"0.1px"}
          width={"45%"}
          resWidth={"40%"}
        />{" "}
        <Text
          color={colorTheme.black.soft}
          margin={"0px"}
          fontSize={"14px"}
          resFontSize={"12px"}
        >
          Or
        </Text>{" "}
        <HorizontalLine
          bg={colorTheme.black.light}
          height={"0.1px"}
          width={"45%"}
          resWidth={"40%"}
        />
      </FlexibleDiv>
      {/* Sign in with Google */}
      <Link to={url} style={{ width: "100%" }}>
        <FlexibleDiv padding={"25px 0px"}>
          <FlexibleDiv
            padding={"10px 0px"}
            radius={"9px"}
            border={`0.1px solid ${colorTheme.black.light}`}
          >
            <Text
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
              }}
              color={colorTheme.grey}
              fontWeight={"800"}
              margin={"0px"}
              fontSize={"14px"}
              resFontSize={"12px"}
            >
              <GoogleIcon size="22px" /> Sign up with Google
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </Link>
      <FlexibleDiv
        justifyContent={"center"}
        alignItems={"center"}
        margin={"10px 0px 0px 0px"}
        breakPoint={"1105px"}
        padding={'0px 0px 4px 0px'}
        breakPointDisplay={"flex"}
      >
        <Text
          color={colorTheme.black.soft}
          margin={"0px"}
          fontSize={fontSizes.mobile_details}
          resFontSize={"12px"}
          style={{ gap: "32px" }}
        >
          Already have an account?
          <Link to={"/login"}>
            <span
              style={{
                color: colorTheme.blue,
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Sign In
            </span>
          </Link>
        </Text>
      </FlexibleDiv>
    </>
  );
};

export default Step1;
