import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
// import {TokenChecker } from "../../../utilities/tokenChecker";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { CloseIcon, MenuIcon } from "../../primaryComponents/icons/icons";
import { Text } from "../../primaryComponents/typography/typography.component";
import Logo from "../logo/logo.component";
import NavProfile from "../navProfile/navProfile.component";
import Search2 from "../Search/search2.0";
import MobileNavMenu from "./Mobile Navigation Bar";
import { loggedInLinks, NavLinks } from "./Secondary Navigation Bar/links";
import { LinkBox } from "./Secondary Navigation Bar/secNavBar.style";

const NavStyle = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  height: 62px;
  width: 100%;
  /* background-color: ${colorTheme.test}; */
  background-color: ${({ bg }) => (bg ? bg : "#FFF")};
  /* box-shadow: 0px 0px 11px lightgrey; */
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : "0.3px solid #f6f6f6"};
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 11024px) {
    position: fixed;
  }
`;

const TestNav = () => {
  const [toggleNavBar, setToggleNavBar] = useState(false);
  const NavHandler = () => {
    setToggleNavBar(!toggleNavBar);
  };
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const isToken = useSelector((state) => state.persistedReducer.user.token);

  return (
    <>
      <NavStyle
        bg={darkMode ? colorTheme.black.fadingBlack : "#FFF"}
        borderBottom={darkMode ? colorTheme.ash : ""}
      >
        {/* Logo */}
        <FlexibleDiv
          width={"15%"}
          justifyContent={"flex-start"}
          breakPoint={"1105px"}
          breakPointwidth={"20%"}
          resPoint={"670px"}
          reswidth={"50%"}
          resPadding={'0px 0px 0px 15px'}
          padding={"0px 0px 0px 10px"}
        >
          <Link to={"/"}>
            <Logo darkMode={darkMode} />
          </Link>
        </FlexibleDiv>
        {/* Search */}
        <FlexibleDiv
          width={"45%"}
          breakPoint={"1180px"}
          breakPointwidth={"70%"}
          resPoint={"670px"}
          padding={'0px 0px 0px 25px'}
          resDisplay={"none"}
          justifyContent={"flex-start"}
        >
          <Link style={{ width: "auto" }} to={"/search"}>
            <Search2 color={darkMode ? "#FFFF" : ""} />
          </Link>
        </FlexibleDiv>
        {/* Links */}
        <FlexibleDiv
          width={"40%"}
          justifyContent={"flex-end"}
          gap={"32px"}
          wrap={"nowrap"}
          breakPoint={"1180px"}
          breakPointDisplay={"none"}
          // padding={"0rem 2rem 0rem 0rem"}
        >
          {!isToken ? (
            NavLinks.map(({ name, path, color }) => (
              <Link
                key={name}
                to={path}
                style={{
                  textDecoration: "none",
                  color: `${darkMode ? "#FFF" : "#000"}`
                }}
              >
                <LinkBox>
                  <Text
                    cursor={"pointer"}
                    color={color}
                    fontSize={fontSizes.mobile_smallest}
                  >
                    {name}
                  </Text>
                </LinkBox>
              </Link>
            ))
          ) : (
            <FlexibleDiv width={"55%"} justifyContent={"flex-end"}>
              {loggedInLinks.map(({ name, path, color }) => (
                <Link
                  key={name}
                  to={path}
                  style={{
                    textDecoration: "none",
                    color: `${darkMode ? "#FFF" : "#000"}`
                  }}
                >
                  <LinkBox>
                    <Text
                      cursor={"pointer"}
                      color={color}
                      fontSize={fontSizes.mobile_smallest}
                    >
                      {name}
                    </Text>
                  </LinkBox>
                </Link>
              ))}
            </FlexibleDiv>
          )}

          {isToken ? (
            <FlexibleDiv width={"45%"}>
              <NavProfile />
            </FlexibleDiv>
          ) : null}
        </FlexibleDiv>

        {/* Menu Bar */}

        <FlexibleDiv
          display={"none"}
          breakPoint={"1180px"}
          breakPointDisplay={"flex"}
          breakPointwidth={"10%"}
          breakPointJustifyContent={"flex-end"}
          padding={"0rem 2rem 0rem 0rem"}
          resPadding={"0rem 1rem 0rem 0rem"}
          resPoint={"670px"}
          reswidth={"50%"}
        >
          {toggleNavBar ? (
            <CloseIcon
              color={darkMode ? "#FFFF" : colorTheme.black.fadingBlack}
              size={"20px"}
              onClick={NavHandler}
            />
          ) : (
            <MenuIcon
              color={darkMode ? "#FFFF" : colorTheme.black.fadingBlack}
              onClick={NavHandler}
            />
          )}
        </FlexibleDiv>
      </NavStyle>
      <MobileNavMenu
        toggleNavBar={toggleNavBar}
        setToggleNavBar={setToggleNavBar}
      />
    </>
  );
};

export default TestNav;
