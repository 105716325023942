import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { separateName } from "../../../../utilities/functions/separateName";
import { getUserInfo } from ".";
import ClientRequest from "../../../../api/api";

export const editProfileImageHandler = createAsyncThunk(
  "user/edit-profile-image",
  async ({ profile_image, dispatch }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("profile_image", profile_image);
      const response = await ClientRequest.post(
        `api/user/edit/profile-image`,
        formData,
      );
      if (response.status === 200) {
        toast.success("Profile image uploaded successfully");
        return response.data;
      }
    } catch (error) {
      console.log("edit profile image error", error);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editProfilePassword = createAsyncThunk(
  "user/edit-profile-password",
  async (
    { token, password, previous_password, password_confirmation, dispatch },
    { rejectWithValue },
  ) => {
    try {
      const formData = new FormData();
      formData.append("previous_password", previous_password);
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);
      const response = await ClientRequest.post(
        `api/user/edit/change-password`,
        formData,
      );
      if (response.status === 200) {
        toast.success("Password changed successfully");
        return response.data;
      }
    } catch (error) {
      console.log("edit password error", error);
      toast.error(error?.response?.data?.error);
      return rejectWithValue(error.response?.data.error);
    }
  },
);

export const editUserProfile = createAsyncThunk(
  "user/edit-user-profile",
  async ({ token, values, dispatch }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      const { firstName, lastName } = separateName(values?.fullname);
      formData.append("firstname", firstName);
      formData.append("lastname", lastName);
      formData.append("email", values?.email);
      formData.append("gender", values?.gender);
      formData.append("phone", values?.phone);
      // formData?.append("bio",values?.bio)
      const response = await ClientRequest.post(`api/user/edit/profile`, {
        firstname: firstName,
        lastname: lastName,
        email: values?.email,
        gender: values?.gender,
        phone: values?.phone,
        bio: values?.bio,
      });
      if (response.status === 200) {
        dispatch(getUserInfo({ token: token }));
        toast.success("Profile changed successfully");
        return response.data;
      }
    } catch (error) {
      console.log("edit profile error", error);
      toast.error(error?.response?.data?.error);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editPhoneNumber = createAsyncThunk(
  "user/edit-user-phone-number",
  async ({ phone }, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.post(`api/user/edit/phone`, {
        phone: phone,
      });

      if (response.status === 200) {
        toast.success("Phone number updated successfully");
        return response.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
      return rejectWithValue(error.response?.data);
    }
  },
);

const initialState = {
  profile_image: null,
  edit_password: "",
  is_profile_image: false,
  loading: false,
  edit_profile_image_isSuccessful: false,
  edit_profile_image_isError: false,
  change_password_isSuccessful: false,
  change_password_isError: false,
  edit_profile_isSuccessful: false,
  edit_profile_isError: false,
  isProfileEditted: false,
  editPhoneNoLoading: false,
  editPhoneNoIsSuccessful: false,
  editPhoneNoIsError: false,
  editPhoneNoError: false,
  error: null,
  start_edit: false,
  fullname: "",
  gender: "",
  phone_no: "",
  email: "",
  bio: "",
};

const Edit_Profile_Slice = createSlice({
  name: "edit_profile_slice",
  initialState,
  reducers: {
    profile_image_handler: (state, { payload }) => {
      const image = payload.image;
      const imageState = payload?.imageState;
      state.profile_image = image;
      state.start_edit = true;
      state.is_profile_image = imageState;
    },
    edit_password_handler: (state, { payload }) => {
      state.edit_password = payload;
    },
    start_edit_handler: (state, { payload }) => {
      state.start_edit = payload;
    },
    toggleProfileEditted: (state, { payload }) => {
      state.isProfileEditted = payload;
    },
    edit_fullname_handler: (state, { payload }) => {
      state.fullname = payload;
    },
    edit_gender_handler: (state, { payload }) => {
      state.gender = payload;
    },
    edit_email_handler: (state, { payload }) => {
      state.email = payload;
    },
    edit_bio_handler: (state, { payload }) => {
      state.bio = payload;
    },
    edit_phoneNo_handler: (state, { payload }) => {
      state.phone_no = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(editProfileImageHandler.pending, (state) => {
        state.loading = true;
        state.edit_profile_image_isSuccessful = false;
        state.edit_profile_image_isError = false;
      })
      .addCase(editProfileImageHandler.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.edit_profile_image_isSuccessful = true;
      })
      .addCase(editProfileImageHandler.rejected, (state, { payload }) => {
        state.loading = false;
        state.edit_profile_image_isError = true;
        state.error = false;
      });

    builder
      .addCase(editProfilePassword.pending, (state) => {
        state.loading = true;
        state.change_password_isSuccessful = false;
        state.change_password_isError = false;
      })
      .addCase(editProfilePassword.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.change_password_isSuccessful = true;
      })
      .addCase(editProfilePassword.rejected, (state, { payload }) => {
        state.loading = false;
        state.change_password_isError = true;
        state.error = false;
      });
    builder
      .addCase(editUserProfile.pending, (state) => {
        state.loading = true;
        state.edit_profile_isError = false;
        state.edit_profile_isSuccessful = false;
      })
      .addCase(editUserProfile.fulfilled, (state) => {
        state.loading = false;
        state.edit_profile_isSuccessful = true;
        state.edit_profile_isError = false;
      })
      .addCase(editUserProfile.rejected, (state) => {
        state.loading = false;
        state.edit_profile_isError = true;
        state.edit_profile_isSuccessful = false;
      })
      .addCase(editPhoneNumber.pending, (state) => {
        state.editPhoneNoLoading = true;
        state.editPhoneNoIsError = false;
        state.editPhoneNoError = null;
        state.editPhoneNoIsSuccessful = false;
      })
      .addCase(editPhoneNumber.fulfilled, (state) => {
        state.editPhoneNoLoading = false;
        state.editPhoneNoIsSuccessful = true;
      })
      .addCase(editPhoneNumber.rejected, (state, { payload }) => {
        state.editPhoneNoLoading = false;
        state.editPhoneNoIsError = true;
        state.editPhoneNoError = payload;
      });
  },
});

export const {
  profile_image_handler,
  start_edit_handler,
  edit_password_handler,
  edit_fullname_handler,
  edit_email_handler,
  edit_gender_handler,
  edit_phoneNo_handler,
  edit_bio_handler,
  toggleProfileEditted,
} = Edit_Profile_Slice.actions;

export default Edit_Profile_Slice.reducer;
