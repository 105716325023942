import React from "react";
import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  CalendarIcon,
  LocationIcon,
} from "../../../../components/primaryComponents/icons/icons";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { dateDateFormatter } from "../../../../utilities/functions/formatDate";

const DateTime = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const endDate = useSelector(
    ({ event_details }) => event_details.data?.event?.endDate
  );
  const startDate = useSelector(
    ({ event_details }) => event_details.data?.event?.startDate
  );
  const location = useSelector(
    ({ event_details }) => event_details.data?.event?.location
  );
  const timeRange = dateDateFormatter(startDate, endDate).timeRange;
  const isRecurring = dateDateFormatter(startDate, endDate).isRecurring;
  const { formattedDate, dayOfWeek } = dateDateFormatter(startDate).first;
  return (
    <>
      <FlexibleDiv
        width={"70%"}
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        breakPoint={"790px"}
        breakPointwidth={"100%"}
        breakPointPadding={'0px 10px'}
      >
        {/* Date Time */}
        <FlexibleDiv alignItems={"baseline"}>
          {/* Icon */}
          <FlexibleDiv
            flexBasis={"10%"}
            breakPoint={"790px"}
            breakPointflexBasis={"10%"}
          >
            <CalendarIcon
              size={fontSizes.mobile_body_text}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            />
          </FlexibleDiv>
          <FlexibleDiv
            flexBasis={"90%"}
            direction={"column"}
            alignItems={"flex-start"}
            lineHeight={"1.4rm"}
            breakPoint={"790px"}
            breakPointflexBasis={"90%"}
            breakPointdirection={"column"}
            breakPointAlign={"baseline"}
          >
            <Text
              fontSize={fontSizes.mobile_body_text}
              fontWeight={"900"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            >
              Date and time
            </Text>

            <time
              style={{
                lineHeight: ".5rem",
                top: "-13px",
                position: "relative",
              }}
            >
              {/* Date */}
              <Text
                fontSize={fontSizes.mobile_details}
                fontWeight={"500"}
                lineHeight={"1.2rem"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              >
                {dayOfWeek}, {formattedDate}
              </Text>

              {/* Time */}
              <Text
                fontSize={fontSizes.mobile_details}
                fontWeight={"500"}
                lineHeight={"1.2rem"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              >
                {/* 11:00 - 6:00( WAT) */}
                {timeRange} (WAT) {isRecurring && "Recurring Event"}
              </Text>
            </time>
          </FlexibleDiv>
        </FlexibleDiv>

        {/* Location */}
        <FlexibleDiv alignItems={"baseline"} margin={"30px 0px 0px 0px"}>
     
          <FlexibleDiv
            flexBasis={"10%"}
            breakPoint={"790px"}
            breakPointflexBasis={"10%"}
          >
            <LocationIcon
              size={fontSizes.mobile_body_text}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            />
          </FlexibleDiv>
          <FlexibleDiv
            flexBasis={"90%"}
            direction={"column"}
            alignItems={"flex-start"}
            lineHeight={"1px"}
            breakPoint={"790px"}
            breakPointflexBasis={"90%"}
            breakPointAlign={"baseline"}
            breakPointdirection={"column"}
          >
            <Text
              fontSize={fontSizes.mobile_body_text}
              fontWeight={"900"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            >
              Location
            </Text>

     
            <Text
              fontSize={fontSizes.mobile_details}
              fontWeight={"500"}
              lineHeight={"1.4rem"}
              position={"relative"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              top={"-13px"}
            >
             {location}
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default DateTime;
