import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../primaryComponents/image/image";
import banner1 from "../../../assets/images/banner1.png";
import {
  HeaderText,
  Text,
} from "../../primaryComponents/typography/typography.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import { useNavigate } from "react-router-dom";
import { BlueBox, ImageContainer } from "./styles";

const BECOME_AN_ORGANIZER = () => {
  const navigate = useNavigate();
  return (
    <FlexibleDiv alignItems={"flex-start"}>
      <ImageContainer>
        <Image width={"100%"} height={"204px"} src={banner1} alt={"banner"} />
      </ImageContainer>

      <BlueBox>
        <FlexibleDiv
          bg={"cornflowerblue"}
          height={"100%"}
          padding={"44.8px 18px"}
        >
          <FlexibleDiv
            alignItems={"flex-start"}
            direction={"column"}
            lineHeight={"40px"}
          >
            <HeaderText
              margin={"0px"}
              fontSize={fontSizes.mobile_subtitle}
              fontWeight={"600"}
              color={"white"}
              resFontSize={"16px"}
            >
              Become an Organizer
            </HeaderText>
            <Text
              margin={"0px"}
              color={"white"}
              fontSize={"13px"}
              resFontSize={"10px"}
            >
              Be the first to create an event in your place
            </Text>
          </FlexibleDiv>
          <FlexibleDiv justifyContent={"flex-start"}>
            <Button
              onClick={() => {
                navigate("/create");
              }}
              hoverBackground={colorTheme.primary_color}
              hoverColor={"white"}
              hoverBorder={"none"}
              radius={"5px"}
              padding={"10px"}
            >
              Get Started
            </Button>
          </FlexibleDiv>
        </FlexibleDiv>
      </BlueBox>
    </FlexibleDiv>
  );
};

export default BECOME_AN_ORGANIZER;
