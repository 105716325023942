import React from "react";
import moment from "moment";

export const ExtractDateTime = (dateTimeString) => {
  const dateObj = moment(dateTimeString);
  const day = dateObj.format("ddd");
  const dayOfMonth = dateObj.date()
  const time = dateObj.format('hh:mm A')
  const month = dateObj.format("MMMM");

  return { day, time, month,dayOfMonth };
};
