import * as yup from "yup"


export const createEventSchema = yup.object().shape({
title: yup.string().required("Event must have a title"),
description: yup.string().required("Event must have a description"),
category: yup.string().required("Event category is required"),
main_image: yup.mixed().required("Event image is required"),
extra_images: yup.array().notRequired(),
visibility : yup.string().required("Select event visibility"),
type: yup.string().required("Select event type"),
location_name : yup.string().required("Name of location of required"),
})  


