import { useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { HeaderText } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import EventInfo from "./eventInfo.component";
import ShareEvent from "./shareEvent.component";


const EditEventHead = () => {
    const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
    return ( 
        <>
        <FlexibleDiv  >
            <FlexibleDiv margin={'10px 0px'} justifyContent={"flex-start"} padding={"0px 0px 0px 20px"} >
            <HeaderText fontSize={"26px"} resFontSize={"18px"} color={darkMode ? colorTheme.white.white : colorTheme.black.strong} >Edit Event</HeaderText>
        </FlexibleDiv>
          <FlexibleDiv justifyContent={'space-evenly'} nowrap={'nowrap'} padding={"0px 20px"} alignItems={'flex-start'} >
                    {/* Event */}
        <EventInfo/>
        {/* Share Event */}
        <ShareEvent/>
          </FlexibleDiv>
        </FlexibleDiv>
   </>
     );
}
 
export default EditEventHead;