import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
// import { Text } from "../../primaryComponents/typography/typography.component"

const PaymentStatus = ({ status }) => {
  return (
    <>
      {/*      
      {status.map(({status})=>(
         <FlexibleDiv width={'50px'} radius={"10px"} bg={'#9fefa6'} padding={'3px'} margin={'10px 0px'}  style={{color:colorTheme.secondary_color,textTransform:'capitalize',fontSize:'12px'}}  >
          {status}
          </FlexibleDiv>
      ))} */}
      <FlexibleDiv
        width={"50px"}
        radius={"10px"}
        bg={status === "USED"?"#9fefa6" : colorTheme.primary_color}
        padding={"3px"}
        margin={"10px 0px"}
        style={{
          color:status === "USED"?colorTheme.secondary_color : "#3d2802",
          textTransform: "capitalize",
          fontSize: "11.5px"
        }}
      >
        {status}
      </FlexibleDiv>
    </>
  );
};

export default PaymentStatus;
