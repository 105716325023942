import { useDispatch } from "react-redux";
import styled from "styled-components";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { openModal } from "../../../state management/Redux/slices/createPaymentMethod";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import DropDown from "../dropDown/dropDown.component";

const CardStyle = styled.div`
  width: 400px;
  border-radius: 5px;
  box-shadow: 0px 0px 2px lightgrey;
  padding: 10px;
  background: white;
`;
const InputBox = styled.input`
  display: flex;
  width: 10%;
  padding: 10px;
  border: 0.9px solid lightgrey;
  border-radius: 5px;
  outline: 0px;
  overflow-y: hidden;
  &[type="number"] {
    -moz-appearance: textfield;
  }
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0px;
  }
`;
const AddPaymentCard = () => {
  return (
    <>
      <FlexibleDiv height={"100%"}>
        <CardStyle>
          <FlexibleDiv>
            <Text
              fontSize={fontSizes.mobile_body_text}
              resFontSize={"15px"}
              fontWeight={"600"}
            >
              Add New Card
            </Text>
          </FlexibleDiv>
          {/* Card Number */}
          <FlexibleDiv width={"85%"} margin={"auto"}>
            <FlexibleDiv justifyContent={"flex-start"}>
              <Text
                fontSize={fontSizes.mobile_smallest}
                resFontSize={"13px"}
                fontWeight={"600"}
                color={colorTheme.grey}
              >
                Card Number
              </Text>
            </FlexibleDiv>
            <CardNumber />
          </FlexibleDiv>

          <FlexibleDiv margin={"20px 0px"} justifyContent={"space-around"}>
            <SelectMonth />
            <SelectYear />
          </FlexibleDiv>

          {/* Card Holder Name */}
          <FlexibleDiv
            width={"85%"}
            margin={"auto"}
            justifyContent={"space-between"}
            padding={"0px 0px 15px 0px"}
          >
            <FlexibleDiv width={"40%"}>
              <CardHolderName />
            </FlexibleDiv>
            <FlexibleDiv width={"40%"}>
              <CVcNumber />
            </FlexibleDiv>
          </FlexibleDiv>
          <AddCardButton />
        </CardStyle>
      </FlexibleDiv>
    </>
  );
};

export default AddPaymentCard;

const CardNumber = () => {
  return (
    <>
      <FlexibleDiv justifyContent={"space-between"}>
        <InputBox type={"number"} minLength={1} maxLength={4} />
        <InputBox type={"number"} minLength={1} maxLength={4}  />
        <InputBox type={"number"} minLength={1}  maxLength={4} />
        <InputBox type={"number"} minLength={1} maxLength={4}  />
      </FlexibleDiv>
    </>
  );
};

const SelectMonth = () => {
  return (
    <>
      <FlexibleDiv direction={"column"} width={"35%"}>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text fontSize={fontSizes.mobile_smallest} resFontSize={"10px"}>
            Select Exp Month
          </Text>
        </FlexibleDiv>
        <FlexibleDiv>
          <DropDown
            label={"Select Month"}
            components={["January", "Febuary", "March"]}
            padding={"5px"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};
const SelectYear = () => {
  return (
    <>
      <FlexibleDiv direction={"column"} width={"35%"}>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text fontSize={fontSizes.mobile_smallest} resFontSize={"10px"}>
            Select Exp Year
          </Text>
        </FlexibleDiv>
        <FlexibleDiv>
          <DropDown
            label={"Select Year"}
            components={["2022", "2021"]}
            padding={"5px"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

const CardHolderName = () => {
  return (
    <>
      <FlexibleDiv direction={"column"}>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text fontSize={fontSizes.mobile_smallest} resFontSize={"10px"}>
            Card Holder Name
          </Text>
        </FlexibleDiv>
        <FlexibleDiv>
          <Input
            width={"100%"}
            padding={"10px"}
            radius={"5px"}
            border={"1px solid lightgrey"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};
const CVcNumber = () => {
  return (
    <>
      <FlexibleDiv direction={"column"}>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text fontSize={fontSizes.mobile_smallest} resFontSize={"10px"}>
            CVC
          </Text>
        </FlexibleDiv>
        <FlexibleDiv>
          <Input
            width={"100%"}
            padding={"10px"}
            radius={"5px"}
            border={"1px solid lightgrey"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

const AddCardButton = () => {
  const dispatch = useDispatch();
  const AddCardHandler = () => {
    dispatch(openModal(false));
  };
  return (
    <>
      <FlexibleDiv padding={"15px 0px"}>
        <Button
          onClick={() => AddCardHandler()}
          width={"85%"}
          hoverBackground={colorTheme.primary_color}
          hoverBorder={"none"}
          hoverColor={"white"}
          radius={"5px"}
          padding={"13px"}
        >
          Add Card
        </Button>
      </FlexibleDiv>
    </>
  );
};
