import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import HomeBanner from "../../components/homeBanner/homeBanner.component";
import HomeCategories from "../../components/homeCategories";
import Banner2 from "../../components/secondaryComponents/banner/Banner2.component";
import EventRow from "../../components/secondaryComponents/EventRow/eventRow";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import { getEvents } from "../../state management/Redux/slices/events/get_event_slice";
import { NoNetWorkIcon } from "../../components/primaryComponents/icons/icons";
import { colorTheme } from "../../infrastructure/Theme/color";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import EventLoaderCard from "../../components/secondaryComponents/skeleton/eventCard_loader.component";
import { get_user_events } from "../../state management/Redux/slices/userAccountSlice/user_events_slice";
import { Helmet } from "react-helmet";

const Home = () => {
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const isOrganizer = useSelector(
    ({ persistedReducer }) => persistedReducer.user.userInfo?.organizer
  );
  const isError = useSelector(({ events }) => events.isError);
  // const errorMessage = useSelector(({ events }) => events.errorMessage);
  const loading = useSelector(({ events }) => events.fetching_events);
  const eventList = useSelector(({ events }) => events.event_list);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_user_events({ token: token }));
    dispatch(getEvents());
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Event Organizing and Management</title>
      </Helmet>
      <HomeBanner />
      <HomeCategories />
      <FlexibleDiv justifyContents={"center"} direction={"column"}>
        {loading && (
          <FlexibleDiv
            margin={"30px 0px"}
            gap={"23px"}
            justifyContent={"space-evenly"}
            breakPoint={"780px"}
            breakPointJustifyContent={"center"}
            alignItems={"center"}
            flexWrap={"no-wrap"}
          >
            {Array.from({ length: 4 }).map((index) => (
              <EventLoaderCard width={"230px"} key={index} />
            ))}
          </FlexibleDiv>
        )}
        {isError && (
          <FlexibleDiv
            justifyContents={"center"}
            direction={"column"}
            margin={"150px 0px"}
          >
            <NoNetWorkIcon
              color={darkMode ? colorTheme.ash : colorTheme.black.fadingBlack}
              size={120}
            />
            <Text
              color={
                darkMode ? colorTheme.white.fadingWhite : colorTheme.textColor
              }
            >
              Network error
              {/* {errorMessage} */}
            </Text>
          </FlexibleDiv>
        )}
        {!loading && !isError && (
          <FlexibleDiv
            justifyContents={"center"}
            direction={"column"}
            margin={"50px 0px"}
          >
            {eventList?.events?.length <= 0 ? (
              <Text
                fontSize={"28px"}
                color={
                  darkMode ? colorTheme.white.fadingWhite : colorTheme.textColor
                }
              >
                No Available Events
              </Text>
            ) : (
              <>
                <EventRow />
              </>
            )}
          </FlexibleDiv>
        )}

        <FlexibleDiv margin={"20px 0px"} width={"96%"}>
          <Banner2 />
        </FlexibleDiv>
        <Footer />
      </FlexibleDiv>
    </>
  );
};

export default Home;
