import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../../primaryComponents/image/image";
import ImageCompressor from "image-compressor.js";
import styled from "styled-components";
import { CameraIcon } from "../../../primaryComponents/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import image_placeholder from "../../../../assets/images/no_user.png";
import { useEffect, useState } from "react";
import { profile_image_handler } from "../../../../state management/Redux/slices/user_slice/edit_profile_slice";
import { CONSTANTS } from "../../../../utilities/constants";
const ChangeProfileImage = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0px;
  background-color: #3837376e;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const ProfileImageView = styled.div`
  width: 90px;
  background: ${({ bg }) => (bg ? bg : colorTheme.white.white)};
  border-radius: 50%;
  height: 90px;
  position: absolute;
  left: ${({ left }) => (left ? left : "5%")};
  z-index: 1;
  top: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 680px) {
    width: 85px;
    height: 85px;
  }
  /* @media screen and (max-width:480px) {
    width: 75px;
    height: 75px;
    top: -50%;
  } */
`;

const ProfileImageSection = ({
  profile_image_file,
  set_profile_image_file,
}) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const profileImage = useSelector(
    ({ persistedReducer }) => persistedReducer.user.userInfo?.profile_image
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const edit_profile_image = useSelector(
    ({ edit_profile }) => edit_profile?.profile_image
  );

  const image = edit_profile_image ? edit_profile_image : image_placeholder;

  const InputHandler = (e) => {
    const [file] = e.target.files;
    if (file) {
      new ImageCompressor(file, {
        quality: 0.6,
        success(result) {
          dispatch(
            profile_image_handler({
              image: URL.createObjectURL(result),
              imageState: true,
            })
          );
          set_profile_image_file(result);
        },
        error(e) {
          console.error(e.message);
        },
      });
    }
  };
  useEffect(() => {
    if (profileImage !== null) {
      dispatch(
        profile_image_handler({
          image: `${CONSTANTS.profileImageUrl}${profileImage}`,
          imageState: false,
        })
      );
    }
  }, [profileImage]);
  return (
    <FlexibleDiv position={"relative"}>
      <ProfileImageView
        bg={darkMode ? colorTheme.black.fadeMini : colorTheme.white.white}
      >
        <input
          onChange={(e) => {
            InputHandler(e);
          }}
          required
          id="profile-image-input"
          type={"file"}
          style={{ display: "none" }}
        />
        <Image width={"80%"} radius={"50%"} height={"80%"} src={image} />
        <ChangeProfileImage>
          <label htmlFor="profile-image-input">
            <CameraIcon color={"white"} size={23} cursor={"pointer"} />
          </label>
        </ChangeProfileImage>
      </ProfileImageView>
    </FlexibleDiv>
  );
};

export default ProfileImageSection;
