import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../../components/primaryComponents/input/input.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { useDispatch, useSelector } from "react-redux";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { useState } from "react";
import { useEffect } from "react";
import { formOfLocationValue } from "../../../../state management/Redux/slices/create_event_slice";
import { useCreateEvent } from "../../createEvent Context";

const TYPE_OF_LOCATION = () => {
  const dispatch = useDispatch();
  const handleChange = useCreateEvent()?.[4];
  const errors = useCreateEvent()?.[2];
  const touched = useCreateEvent()?.[3];
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const InputHandler = (e, action) => {
    let value = e.target.value;
    dispatch(action(value));
    handleChange("type")(value);
  };
  const formOfLocation = useSelector(
    (state) => state.createEvent.formOfLocation
  );
  const [physicalStateChecked, setPhysicalChecked] = useState(false);
  const [onlineChecked, setOnlineChecked] = useState(false);

  const CheckboxHandler = (e) => {
    InputHandler(e, formOfLocationValue);
  };

  useEffect(() => {
    if (formOfLocation === "Physical") {
      setPhysicalChecked(true);
      setOnlineChecked(false);
    } else if (formOfLocation === "Online") {
      setPhysicalChecked(false);
      setOnlineChecked(true);
    }
  }, [formOfLocation, physicalStateChecked, onlineChecked]);
  return (
    <>
      <FlexibleDiv
        margin={"10px 0px"}
        justifyContent={"flex-start"}
        gap={"12px"}
      >
        <Text
          color={darkMode ? colorTheme.white.white : colorTheme.textColor}
          margin={"0px"}
          fontSize={fontSizes.mobile_body_text}
          fontWeight={"500"}
          resFontSize={"16px"}
        >
          This event will be.
        </Text>
        {errors.type && touched.type && (
          <Text
            color={colorTheme.red}
            margin={"0px"}
            fontSize={fontSizes.mobile_smallest}
            resFontSize={fontSizes.mobile_smallest}
            fontWeight={"500"}
          >
            ({errors.type})
          </Text>
        )}
      </FlexibleDiv>

      <FlexibleDiv justifyContent={"flex-start"} gap={"22px"}>
        <FlexibleDiv width={"90px"} justifyContent={"flex-start"} gap={"8px"}>
          <Input
            checked={physicalStateChecked}
            value={"Physical"}
            type={"checkbox"}
            onChange={(e) => {
              CheckboxHandler(e);
            }}
          />
          <Text
            color={colorTheme.textColor}
            fontSize={"15px"}
            fontWeight={"500"}
            resFontSize={"14px"}
            margin={"0px"}
          >
            Physical
          </Text>
        </FlexibleDiv>
        <FlexibleDiv width={"90px"} justifyContent={"flex-start"} gap={"8px"}>
          <Input
            checked={onlineChecked}
            value={"Online"}
            type={"checkbox"}
            onChange={(e) => {
              CheckboxHandler(e);
            }}
          />
          <Text
            color={colorTheme.textColor}
            fontSize={"15px"}
            fontWeight={"500"}
            resFontSize={"14px"}
            margin={"0px"}
          >
            Online
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default TYPE_OF_LOCATION;
