import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { QR_CODE_SECTION } from "./styles";
import "./style.css";
import { Text } from "../../primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { useScreenshot } from "use-react-screenshot";
import { PhotoView } from "react-photo-view";
import { AwesomeQRCode } from "@awesome-qrcode/react";
import { useEffect, useRef } from "react";
const TicketQRCode = ({ eventQRCode }) => {
  const qrCode = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(qrCode.current);
  useEffect(() => {
    getImage();
  }, [eventQRCode]);
  return (
    <QR_CODE_SECTION>
      <FlexibleDiv padding={"0px 0px 12px 0px"}>
        <PhotoView src={image}>
          <div ref={qrCode} style={{ width: "100%" }}>
            <AwesomeQRCode
              value={eventQRCode}
              ecLevel="M"
              size={130}
              style={{ width: "30px !important", height: "30px !important" }}
              dataStyle="dots"
              eyeRadius={12}
              bgColor={colorTheme.white.white}
              fgColor="black"
              eyeColor={colorTheme.primary_color}
            />
          </div>
        </PhotoView>
      </FlexibleDiv>
      <FlexibleDiv
        padding={"30px 0px 12px 0px"}
        position={"absolute"}
        bottom={"-10px"}
        justifyContent={"flex-end"}
      >
        <Text
          resFontSize={"8px"}
          cursor={"pointer"}
          margin={"0px"}
          fontSize={"12px"}
          fontWeight={"900"}
          color={colorTheme.primary_color}
          style={{ paddingRight: "32px" }}
        >
          Attend
        </Text>
      </FlexibleDiv>
    </QR_CODE_SECTION>
  );
};

export default TicketQRCode;
