import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import Head from "../../BlockCodes/header";
import "react-toastify/dist/ReactToastify.css";
// import Tags from "@yaireo/tagify/dist/react.tagify";
// import "@yaireo/tagify/dist/tagify.css";
import "react-tagsinput/react-tagsinput.css";
import "./tags.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addTags,
  removeTag
} from "../../../../state management/Redux/slices/create_event_slice";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import TagsInput from "react-tagsinput";
import "./tags.css";
const TagsComp = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.createEvent.tags);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const tagsHandler = (e) => {
    dispatch(addTags(e));
  };


  return (
    <FlexibleDiv width={"45%"} breakPoint={"680px"} breakPointwidth={"100%"}>
      <FlexibleDiv justifyContent={"flex-start"}>
        <Head
          title={"Tags"}
          color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          fontSize={fontSizes.mobile_body_text}
          resFontSize={"15px"}
          width={"100%"}
          smallScreenFontSize={"15px"}
        />
      </FlexibleDiv>
      <FlexibleDiv margin={"12px 0px"}>
        {/* <Tags
          onRemove={onRemoveTag}
          value={tags}
          settings={settings}
          onChange={(e) => {
            tagsHandler(e);
          }}
          className={"tags"}
          showDropdown
          placeholder={"Add event tags"}
        /> */}
        <TagsInput
          maxTags={5}
          className={
            darkMode ? "darkmode-tag-container" : "lightmode-tag-container "
          }
          value={tags}
          onChange={(e) => {
            tagsHandler(e);
          }}
        />
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

export default TagsComp;
