import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import DraftSection from "../sections/draftSection";
import EventSection from "../sections/eventSection";
import ProfileSection from "../sections/profileSection";
import TickectSection from "../sections/ticketSection";
import { TabOption } from "../styles";

const TabSwitch = (tabName, searchedAccount, data) => {
  switch (tabName) {
    case "Profile":
      return (
        <ProfileSection
          data={data}
          searchedAccount={searchedAccount ? true : false}
        />
      );
    case "Events":
      return <EventSection />;
    case "Tickets":
      return <TickectSection data={data} />;
    // case "Draft":
    //   return <DraftSection />;
    default:
      return (
        <ProfileSection
          data={data}
          searchedAccount={searchedAccount ? true : false}
        />
      );
  }
};
const ProfileTab = ({ searchedAccount, data }) => {
  const activeTab = useSelector(({ loggedInUser }) => loggedInUser?.activeTab);
  const [tabName, setTabName] = useState("Profile");
  useEffect(() => {
    setTabName(activeTab);
  }, [activeTab]);
  return (
    <>
      <FlexibleDiv padding={"40p 0px"}>
        <PROFILE_TAB_HEAD
          tabName={tabName}
          setTabName={setTabName}
          searchedAccount={searchedAccount}
          data={data}
        />

        {TabSwitch(tabName, searchedAccount, data)}
      </FlexibleDiv>
    </>
  );
};

export default ProfileTab;

// Profile Tab Head

const PROFILE_TAB_HEAD = ({ setTabName, tabName, searchedAccount, data }) => {
  const activeTab = useSelector(({ loggedInUser }) => loggedInUser?.activeTab);
  const userStatus = data?.[0]?.userStatus;
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const [TabOptions, setTabOptions] = useState([
    { name: "Profile", active: true },
    { name: "Events", active: false },
    { name: "Tickets", active: false },
    // { name: "Draft", active: false }
  ]);

  const ProfileTabStateHandler = () => {
    if (userStatus === 1 && !searchedAccount) {
      setTabOptions(() => {
        return [
          { name: "Profile", active: true },
          { name: "Events", active: false },
          { name: "Tickets", active: false },
          // { name: "Draft", active: false }
        ];
      });
    } else if (userStatus !== 1) {
      setTabOptions((e) => e.filter((e) => e.name !== "Events"));
      // setTabOptions((e) => e.filter((e) => e.name !== "Draft"));
    } else if (searchedAccount) {
      // setTabOptions((e) => e.filter((e) => e.name !== "Draft"));
      setTabOptions((e) => e.filter((e) => e.name !== "Tickets"));
    }
  };
  useEffect(() => {
    ProfileTabStateHandler();
  }, [activeTab, userStatus, data]);

  const TabHandler = (e) => {
    let Value = e.target.innerHTML;
    setTabName(Value);
  };
  return (
    <FlexibleDiv
      justifyContent={"flex-start"}
      gap={"22px"}
      breakPoint={"1180px"}
      breakPointgap={"25%"}
      breakPointJustifyContent={"center"}
      breakPointPadding={"0px 32px"}
      borderBottom={"0.9px solid lightgrey"}
    >
      {TabOptions.map(({ name, active }) => (
        <TabOption
          bg={name === tabName ? "" : "none"}
          key={name}
          width={"auto"}
        >
          <Text
            cursor={"pointer"}
            onClick={(e) => {
              TabHandler(e, active);
            }}
            color={
              name === tabName
                ? darkMode
                  ? colorTheme.white.white
                  : "#000"
                : colorTheme.textColor
            }
            fontSize={fontSizes.mobile_details}
            fontWeight={"600"}
            resFontSize={"14px"}
          >
            {name}
          </Text>
        </TabOption>
      ))}
    </FlexibleDiv>
  );
};
