import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";

const CardName = ({ title, userName }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv
        margin={"0px"}
        padding={"0px"}
        direction={"column"}
        width={"100%"}
        alignItems={"flex-start"}
        breakPoint={"780px"}
        breakPointwidth={"100%"}
        breakPointJustifyContent={"flex-start"}
        breakPointdirection={"column"}
        breakPointAlign={"flex-start"}
      >
        <Text
          fontSize={"18px "}
          fontWeight={"600"}
          className="event_title"
          style={{
            margin: 0,
            // textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
            // overflow: "hidden",
            overflowWrap: " break-word"
            // width: "130px"
          }}
          color={darkMode ? colorTheme.white.white : "#000"}
          resFontSize={"15px"}
          lineHeight={"23px"}
        >
          {title}
        </Text>
        {/* {userName ?  <Text
        resFontSize={'13px'}
        lineHeight={'33px'}
          fontSize={"13px "}
          fontWeight={"500"}
          color={colorTheme.black.soft}
          style={{ margin: 0 }}
        >
          @{userName}
        </Text> : null} */}
      </FlexibleDiv>
    </>
  );
};

export default CardName;
