import { useSelector } from "react-redux";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";

const PoweredBy = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const textColor = darkMode ? colorTheme.white.fadingWhite : colorTheme.ash;
  return (
    <>
      <FlexibleDiv
        padding={"0px 23px 0px 23px"}
        display={"block"}
        margin={"43px 0px 0px 0px"}
      >
        <Text
          textAlign="left"
          color={textColor}
          resFontSize={"13px"}
          fontSize={"13px"}
          colo
        >
          Powered by{" "}
          <span style={{ color: colorTheme.primary_color }}>Attend</span>
        </Text>
      </FlexibleDiv>
    </>
  );
};

export default PoweredBy;
