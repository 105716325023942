import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Loader from "../loader/loader.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { CheckIcon } from "../../primaryComponents/icons/icons";
import Button from "../../primaryComponents/button/button.component";
import { getGoogleUrl } from "../../../services/google_services";
import {
  clearAuthServiceState,
  getUserInfo,
  setToken,
} from "../../../state management/Redux/slices/user_slice";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Modal from "../../primaryComponents/modal/modal";

const Status = ({ loading, isSuccessful, isError, bg, textColor }) => {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectRoute = useSelector(
    ({ persistedReducer }) => persistedReducer.routes.redirectRoute
  );

  const getUrlHandler = async () => {
    const url = await getGoogleUrl();
    setUrl(url);
  };
  useEffect(() => {
    getUrlHandler();
  }, []);

  useEffect(() => {
    if (isSuccessful) {
      const token = localStorage.getItem("token");
      dispatch(setToken(token));
      const timer = setTimeout(() => {
        if (redirectRoute) {
          navigate(redirectRoute);
        } else {
          navigate("/");
        }
      }, 3000); // 3 seconds delay to allow the user to read the text

      return () => {
        dispatch(clearAuthServiceState());

        clearTimeout(timer);
      }; // Cleanup the timer on component unmount
    }
  }, [isSuccessful, redirectRoute, navigate]);

  if (loading) {
    return (
      <FlexibleDiv
        width={"40%"}
        direction={"column"}
        bg={bg}
        padding={"40px"}
        breakPoint={"780px"}
        breakPointwidth={"60%"}
      >
        <Loader width={"43px"} />
        <Text
          color={textColor}
          textAlign={"center"}
          fontSize={"14px"}
          style={{ textAlign: "center" }}
          resFontSize={"12px"}
        >
          Attend is processing your account..
        </Text>
      </FlexibleDiv>
    );
  }

  if (isSuccessful) {
    return (
      <FlexibleDiv
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"40%"}
        bg={bg}
        padding={"40px"}
        breakPoint={"780px"}
        breakPointwidth={"60%"}
      >
        <CheckIcon size={"24px"} color={colorTheme.secondary_color} />
        <Text
          color={textColor}
          textAlign={"center"}
          fontSize={"14px"}
          style={{ marginLeft: "10px" }}
          resFontSize={"12px"}
        >
          Your account has been successfully linked with Google
        </Text>
      </FlexibleDiv>
    );
  }

  if (isError) {
    return (
      <FlexibleDiv
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"40%"}
        bg={bg}
        padding={"40px"}
        breakPoint={"780px"}
        breakPointwidth={"60%"}
      >
        <CheckIcon size={"24px"} color={colorTheme.red} />
        <Text
          color={colorTheme.red.error}
          fontSize={"14px"}
          style={{ marginLeft: "10px" }}
          textAlign={"center"}
          resFontSize={"12px"}
        >
          Failed to link your Google account. Please try again.
        </Text>
        <Button
          width={"230px"}
          onClick={() => {
            navigate(url);
          }}
        >
          Retry
        </Button>
      </FlexibleDiv>
    );
  }

  return null;
};

const GoogleCallBackModal = ({ showModal, setShowModal }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );

  const loading = useSelector(
    ({ persistedReducer }) => persistedReducer.user.loading
  );
  const isSuccessful = useSelector(
    ({ persistedReducer }) => persistedReducer.user.success
  );
  const isError = useSelector(
    ({ persistedReducer }) => persistedReducer.user.error
  );
  const bg = darkMode
    ? colorTheme.black.fadeMini
    : colorTheme.white.fadingWhite;
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const location = useLocation();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (location.search && token) {
      dispatch(getUserInfo());
    }
  }, [location.search, token]);

  return (
    <Modal scroll={true} show={showModal} setShowModal={setShowModal}>
      <Status
        loading={loading}
        isSuccessful={isSuccessful}
        isError={isError}
        bg={bg}
        textColor={textColor}
      />
    </Modal>
  );
};

export default GoogleCallBackModal;
