import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { TICKET_DETAILS_SECTION } from "./styles";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
const TicketDetails = ({
  eventName,
  eventLocation,
  eventDate,
  eventOrderNo,
  ticketStatus,
  ticketType,
}) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const formatInput = "YYYY-MM-DD HH:mm:ss";
  const outputFormat = "dddd, MMMM D, h:mma (z)";
  const [ticketStatusState, setTicketStatusState] = useState(true);
  const convertedDate = moment(eventDate, formatInput)
    .tz("Africa/Lagos")
    .format(outputFormat);
  const checkTicketStatus = useMemo(
    () => () => {
      if (ticketStatus === "UNUSED") {
        setTicketStatusState(false);
      } else {
        setTicketStatusState(true);
      }
    },
    [ticketStatus, ticketStatusState]
  );
  useEffect(() => {
    checkTicketStatus();
  }, [ticketStatus]);
  return (
    <TICKET_DETAILS_SECTION>
      {/* Title of Event */}
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          fontSize={"1.1rem"}
          margin={"0px"}
          fontWeight={"700"}
          resFontSize={"x-small"}
          color={darkMode ? colorTheme.white.white : "#000"}
        >
          {eventName}
          {`(${ticketType})`}
        </Text>
      </FlexibleDiv>

      {/* Location */}
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          fontSize={fontSizes.mobile_smallest}
          margin={"0px"}
          color={darkMode ? colorTheme.textColor : "#202020c7"}
          fontWeight={"600"}
          resFontSize={"xx-small"}
        >
          {eventLocation}
        </Text>
      </FlexibleDiv>

      {/* Date of Event */}
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          fontSize={fontSizes.mobile_smallest}
          margin={"0px"}
          color={darkMode ? colorTheme.textColor : "#202020c7"}
          fontWeight={"600"}
          resFontSize={"xx-small"}
        >
          <span style={{ color: colorTheme.lightgrey }}> Order:</span>{" "}
          {eventOrderNo}
        </Text>
      </FlexibleDiv>
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          fontSize={fontSizes.mobile_smallest}
          margin={"0px"}
          color={darkMode ? colorTheme.textColor : "#202020c7"}
          fontWeight={"600"}
          resFontSize={"xx-small"}
        >
          {convertedDate}
        </Text>
      </FlexibleDiv>

      {/* Edit ticket details */}
      <FlexibleDiv justifyContent={"flex-start"}>
        <Text
          fontSize={fontSizes.mobile_smallest}
          margin={"0px"}
          color={ticketStatusState ? "#028A0F" : colorTheme.primary_color}
          fontWeight={"600"}
          resFontSize={"xx-small"}
          cursor={"pointer"}
        >
          {ticketStatus}
        </Text>
      </FlexibleDiv>
    </TICKET_DETAILS_SECTION>
  );
};

export default TicketDetails;
