import React, { useEffect } from "react";
import {
  MenuItems,
  MenuItemsContainer,
  MenuItemTitle,
  // NumberBox,
  // NewEventCircleNot,
  // ProfilePic,
  SideBarStyle2,
  // SubNav,
  // SubNavItem,
} from "../../../infrastructure/Layout/layoutStyles";
import { MenuLinks } from "./menuLinks";
import { Link, useNavigate } from "react-router-dom";
// import { AccountUserType } from "../../../pages/account";
// import { Organizers } from "../../../infrastructure/Data/organizers";
import SideBarOrganizer from "./sideBarOrganizer";
import SideBarEventInfo from "./sideBarEventInfo";
import SideBarSetting from "./sideBarSettings";
import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
// import Button from "../../primaryComponents/button/button.component";
// import { AddIcon } from "../../primaryComponents/icons/icons";
import { get_list_of_organizers_following } from "../../../state management/Redux/slices/organizers_following/organizers_following_slice";
import { SkeletonTheme } from "react-loading-skeleton";
import { accountTabHandler } from "../../../state management/Redux/slices/userAccountSlice";
const SideBar2 = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const organizers = useSelector(
    ({ organizers_following }) => organizers_following?.data
  );
  useEffect(() => {
    dispatch(get_list_of_organizers_following());
  }, []);
  return (
    <>
      <SideBarStyle2
        bg={darkMode ? colorTheme.black.fadingBlack : colorTheme.sideBarBg}
      >
        <MenuItemsContainer>
          {MenuLinks.map(({ name, icon, subNav, path }, index) => (
            <div
              key={index}
              style={{
                width: "90%",
                display: "flex",
                alignItems: "flex-start",
                position: "relative",
                top: `${
                  name === "Profile" ? "-1px" : name === "Tickets" ? "90px" : ""
                } `,
                marginTop: `${name === "Settings" ? "35px" : ""}`,
              }}
            >
              <MenuItems key={Math.random() * 1000}>
                <Link
                  onClick={() => {
                    if (name === "Profile") {
                      dispatch(accountTabHandler("Profile"));
                    }
                  }}
                  to={path}
                  key={Math.random() * 1000}
                >
                  {" "}
                  <MenuItemTitle
                    key={Math.random()}
                    color={darkMode ? colorTheme.white.white : ""}
                  >
                    {" "}
                    <span>{icon}</span>
                    {name}
                  </MenuItemTitle>
                </Link>
                {/* {subNav ?  <SubNav left={name==="Organizers" ? '0px' :""}>
                            {subNav.map(({subnavlink,organizerName,path})=>(
                            <Link to={path} key={Math.random()*1000} ><SubNavItem  key={Math.random()} justifyContent={subnavlink ? 'space-between' :''} > {organizerName ?<ProfilePic src={Image} />:null}  {subnavlink || organizerName}    {subnavlink ? <NumberBox>5</NumberBox> : null} {organizerName ? <NewEventCircleNot /> : null} </SubNavItem></Link>
                            ))} 
                          </SubNav> : null} */}
              </MenuItems>
            </div>
          ))}

          <div
            style={{
              width: "83%",
              display: "flex",
              alignItems: "flex-start",
              position: "relative",
              flexDirection: "column",
            }}
          >
            <SideBarEventInfo />
            <SkeletonTheme
              baseColor={colorTheme.black.fadingBlack}
              highlightColor={"#444444"}
            >
              {organizers?.length > 0 && <SideBarOrganizer />}
            </SkeletonTheme>

            <SideBarSetting />
          </div>
        </MenuItemsContainer>
      </SideBarStyle2>
    </>
  );
};

export default SideBar2;

// 0.875rem

// onClick={()=> organizerName ? navigate('/account/organizer') : null}
