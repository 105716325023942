import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CONSTANTS } from "../../../../utilities/constants";
import ClientRequest from "../../../../api/api";
export const getEvents = createAsyncThunk(
  "user/get-events",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/event/list");
      return response.data;
    } catch (error) {
      console.error("Create Event error:", error);
      const { response, message } = error;
      console.log("messgae", response.data);
      if (response?.data === undefined) {
        return rejectWithValue(message);
      } else {
        return rejectWithValue(response.data);
      }
    }
  }
);

const initialState = {
  fetching_events: false,
  isError: false,
  isSuccess: false,
  errorMessage: null,
  event_list: []
};

const Event_Slice = createSlice({
  name: "event_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.pending, (state) => {
      state.fetching_events = true;
      state.isError = false;
    });
    builder.addCase(getEvents.fulfilled, (state, { payload }) => {
      state.fetching_events = false;
      state.event_list = payload;
      state.isSuccess = true;
    });
    builder.addCase(getEvents.rejected, (state, { payload }) => {
      state.fetching_events = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload;
    });
  }
});

export default Event_Slice.reducer;
