import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { TokenChecker } from "../../../../utilities/tokenChecker";
import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../primaryComponents/typography/typography.component";
import Search2 from "../../Search/search2.0";
import { is_loggedIn_links, is_not_loggedIn_links } from "./mobileLinks";
import MobileMenuProfile from "./mobileMenuProfile";
import { MobileMenuStyle, MobileNavStyle } from "./styles";
const MobileNavMenu = ({ toggleNavBar, setToggleNavBar }) => {
  const isToken = TokenChecker();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  return (
    <MobileNavStyle
      bg={darkMode ? colorTheme.black.fadingBlack : colorTheme.white.white}
      breakPointDisplay={toggleNavBar ? "flex" : "none"}
    >
      <MobileMenuStyle
        direction={"column"}
        alignItems={"flex-start"}
        padding={"0px 0px 0px 23px"}
      >
        <FlexibleDiv
          width={"90%"}
          margin={"0px 0px 32px 0px"}
          display={"none"}
          breakPoint={"680px"}
          breakPointDisplay={"flex"}
        >
          <Link onClick={()=>{setToggleNavBar(false)}} style={{ width: "100%" }} to={"/search"}>
            <Search2
              width={"100%"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            />
          </Link>
        </FlexibleDiv>

        {!isToken
          ? is_not_loggedIn_links.map(({ name, icon, link }, index) => (
              <Link
                to={link}
                onClick={() => {
                  setToggleNavBar(false);
                }}
                key={name}
              >
                <FlexibleDiv
                  key={index}
                  justifyContent={"flex-start"}
                  width={"90%"}
                  gap={"12px"}
                >
                  <Text>{icon}</Text>
                  <Text
                    color={
                      darkMode
                        ? colorTheme.white.white
                        : colorTheme.black.strong
                    }
                  >
                    {name}
                  </Text>
                </FlexibleDiv>
              </Link>
            ))
          : is_loggedIn_links.map(({ name, icon, link }, index) => (
              <Link
                to={link}
                onClick={() => {
                  setToggleNavBar(false);
                }}
                key={name}
              >
                <FlexibleDiv
                  key={index}
                  justifyContent={"flex-start"}
                  width={"90%"}
                  gap={"12px"}
                >
                  <Text>{icon}</Text>
                  <Text
                    color={
                      darkMode
                        ? colorTheme.white.white
                        : colorTheme.black.strong
                    }
                  >
                    {name}
                  </Text>
                </FlexibleDiv>
              </Link>
            ))}

        <FlexibleDiv
          height={"0.2px"}
          bg={colorTheme.black.light}
          width={"90%"}
        ></FlexibleDiv>

        {isToken ? (
          <MobileMenuProfile
            setToggleNavBar={setToggleNavBar}
            toggleNavBar={toggleNavBar}
          />
        ) : null}
      </MobileMenuStyle>
    </MobileNavStyle>
  );
};

export default MobileNavMenu;
