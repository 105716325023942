import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../../components/primaryComponents/input/input.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import {
  eventEndValue,
  eventStartValue,
} from "../../../../state management/Redux/slices/create_event_slice";
import { colorTheme } from "../../../../infrastructure/Theme/color";

const TimeOfEvent = () => {
  const dispatch = useDispatch();
  const InputHandler = (e, action) => {
    let value = e.target.value;
    dispatch(action(value));
  };
  return (
    <>
      <FlexibleDiv padding={"13px 0px"}>
        <EventStart InputHandler={InputHandler} />

        <EventEnd InputHandler={InputHandler} />
      </FlexibleDiv>
    </>
  );
};

export default TimeOfEvent;

const EventStart = ({ InputHandler }) => {
  const date = useSelector((state) => state.createEvent.eventStart);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );

  return (
    <>
      <FlexibleDiv
        flexBasis={"50%"}
        breakPoint={"680px"}
        breakPointflexBasis={"100%"}
      >
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text
            fontSize={fontSizes.mobile_details}
            resFontSize={"14px"}
            color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
          >
            Event Starts
          </Text>
        </FlexibleDiv>

        <FlexibleDiv
          justifyContent={"flex-start"}
          gap={"12px"}
          breakPoint={"680px"}
          breakPointgap={"0px"}
          breakPointJustifyContent={"space-between"}
        >
          <FlexibleDiv
            width={"90%"}
            breakPoint={"680px"}
            breakPointwidth={"97%"}
          >
            <Input
              className={darkMode ? "dateTimeInput" : ""}
              value={date}
              onChange={(e) => {
                InputHandler(e, eventStartValue);
              }}
              type={"datetime-local"}
              fontSize={"12px"}
              padding={"16px"}
              border={`1px solid ${colorTheme.lightgrey}`}
              radius={"5px"}
              width={"100%"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            />
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

const EventEnd = ({ InputHandler }) => {
  const date = useSelector((state) => state.createEvent.eventEnd);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv
        flexBasis={"50%"}
        breakPoint={"680px"}
        breakPointflexBasis={"100%"}
      >
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text
            fontSize={fontSizes.mobile_details}
            resFontSize={"14px"}
            color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
          >
            Event Ends
          </Text>
        </FlexibleDiv>

        <FlexibleDiv
          justifyContent={"flex-start"}
          gap={"12px"}
          breakPoint={"680px"}
          breakPointgap={"0px"}
          breakPointJustifyContent={"space-between"}
        >
          <FlexibleDiv
            width={"90%"}
            breakPoint={"680px"}
            breakPointwidth={"97%"}
          >
            <Input
              className={darkMode ? "dateTimeInput" : ""}
              value={date}
              onChange={(e) => {
                InputHandler(e, eventEndValue);
              }}
              type={"datetime-local"}
              fontSize={"13px"}
              padding={"16px"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              border={`1px solid ${colorTheme.lightgrey}`}
              radius={"5px"}
              width={"100%"}
            />
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};
