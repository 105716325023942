import Hero from "../../components/secondaryComponents/hero/hero.component"
import image from "../../assets/images/contactUs.png"

const ContactHero = ()=>{
    return(
        <>
        <Hero height={"440px"} image={image} title={"Contact Us"} backBtn alignItems={'center'} text={"If you need help or have a question, we’re here for you"} textContainerWidth={'100%'} textColor={'#FFFF'}  breakPoint={"780px"} breakPointHeight={'auto'}   breakPointwidth={"100%"} resTextAlign={"center"} />
        </>
    )
}

export default ContactHero