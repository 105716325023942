import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TestNav from "../../components/secondaryComponents/NavigationBar/testNav";
import SideBar2 from "../../components/secondaryComponents/sideBar/sidebar2.0";
import { TokenChecker } from "../../utilities/tokenChecker";
import { colorTheme } from "../Theme/color";
import { LayoutStyle, MainSection2 } from "./layoutStyles";
import WhatsappNumberModal from "../../components/secondaryComponents/whatsapp-number-modal/index.jsx";

const Layout3 = ({ children, sidebar }) => {
  const darkmode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const phone = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.phone
  );
  const isToken = TokenChecker();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if ((phone === "" || phone === undefined || phone === null) && isToken) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [isToken, phone]);

  return (
    <>
      <TestNav />
      <LayoutStyle>
        {sidebar && isToken ? <SideBar2 /> : null}
        <MainSection2
          bg={darkmode ? colorTheme.black.darkmode1 : ""}
          width={sidebar && isToken ? "calc(100% - 220px)" : "100%"}
          left={sidebar && isToken ? "" : "0px"}
        >
          {children}
        </MainSection2>
        <WhatsappNumberModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </LayoutStyle>
    </>
  );
};

export default Layout3;
