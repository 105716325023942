import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
import CoverImageSection from "./coverImageSection.component";
import ProfileImageSection from "./profileImageSection.component";


const ImageSection = ({profile_image_file,set_profile_image_file}) => {
    return ( 
        <FlexibleDiv display={'block'} position={'relative'}>
            <CoverImageSection/>
            <ProfileImageSection profile_image_file={profile_image_file} set_profile_image_file={set_profile_image_file}/>
        </FlexibleDiv>
     );
}
 
export default ImageSection;