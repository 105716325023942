import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { AddIcon, MinusIcon } from "../../primaryComponents/icons/icons";
import { Text } from "../../primaryComponents/typography/typography.component";
import { useState } from "react";
import {
  addToCart,
  reduceQuantity,
  selectTicketHandler
} from "../../../state management/Redux/slices/payment_slice/buy_ticket_slice";
import naira_icon from "../../../assets/images/naira_icon2.png"
import { useEffect } from "react";
import React from "react";
import { Image } from "../../primaryComponents/image/image";
import { CONSTANTS } from "../../../utilities/constants";

const Order_Ticket = ({ name, price, capacity, description,cover_image, ticket }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const selected_ticket = useSelector(
    ({ buy_ticket }) => buy_ticket.selected_ticket
  );
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  return (
    <>
      <FlexibleDiv
      style={{overflow:"hidden"}}
        onClick={() => {
          dispatch(selectTicketHandler(ticket));
        }}
        position={"relative"}
        padding={"23px 0px 0px 0px"}
        radius={"9px"}
        margin={"0px 0px 23px 0px"}
        width={"70%"}
        border={
          selected_ticket?.id === ticket?.id
            ? `1px solid ${colorTheme.primary_color}`
            : `1px solid ${colorTheme.black.light}`
        }
      >
        <FlexibleDiv>
          <FlexibleDiv width={"100%"} justifyContent={"flex-start"}     padding={"0px 23px"}>
            <Text margin={"0px"} color={textColor} fontSize={"14px"}>
              {name}
            </Text>
          </FlexibleDiv>
          {/* <Controller ticket={ticket} /> */}

          <FlexibleDiv display={"block"} margin={"20px 0px 0px 0px"} padding={"0px 23px"}>
            <Text
              margin={"0px"}
              textAlign="left"
              color={textColor}
              style={{display:"flex",alignItems:"center"}}
              fontSize={"18px"}
              fontWeight={"600"}
            >
              <Image style={{marginRight:'5px'}} src={naira_icon} width={'17px'} height={"17px"} />{price}
            </Text>
            <Text margin={"10px 0px"} color={textColor} fontSize={"14px"}>
              {description}
            </Text>
          </FlexibleDiv>
          <FlexibleDiv>
            <Image style={{borderBottomLeftRadius:5,borderBottomRightRadius:5}} src={`${CONSTANTS.imgbaseurl}${cover_image}`} height={'90px'} width={'100%'} />
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Order_Ticket;

export const Controller = React.memo(({ ticket }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const dispatch = useDispatch();

  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;

  const [reducebtnBg, setReduceBtnBg] = useState(colorTheme.lightgrey);
  const [addbtnBg, setAddBtnBg] = useState(colorTheme.primary_color);
  const [ticketQuantities, setTicketQuantities] = useState({});

  const selected_tickets = useSelector(
    ({ buy_ticket }) => buy_ticket?.selected_tickets
  );
  const single_ticket = selected_tickets?.filter(
    (item) => item?.name === ticket?.name
  );
  const handleAddToCart = (ticket) => {
    const quantity = ticketQuantities[ticket.id] || 1;
    dispatch(addToCart({ ...ticket, quantity }));
  };

  const handleReduceQuantity = (ticketId) => {
    dispatch(reduceQuantity(ticketId));
    setReduceBtnBg(
      ticketQuantities[ticketId] > 0
        ? colorTheme.primary_color
        : colorTheme.lightgrey
    );
  };

  useEffect(() => {
    setReduceBtnBg(
      single_ticket[0]?.quantity > 0
        ? colorTheme.primary_color
        : colorTheme.lightgrey
    );

    setAddBtnBg(
      ticket?.capacity === single_ticket[0]?.quantity
        ? colorTheme.lightgrey
        : colorTheme.primary_color
    );
  }, [single_ticket[0]?.quantity, ticket?.capacity]);
  return (
    <>
      <FlexibleDiv width={"50%"}>
        <Button
          onClick={() => handleReduceQuantity(ticket.id)}
          radius={"5px"}
          padding={"9px"}
          background={reducebtnBg}
          hoverBackground={reducebtnBg}
          hoverBorder={"none"}
          hoverColor={"none"}
        >
          <MinusIcon size={16} />
        </Button>
        <Text margin={"0px"} color={textColor} fontSize={"14px"}>
          {single_ticket[0]?.quantity || 0}
        </Text>
        <Button
          onClick={() => handleAddToCart(ticket)}
          radius={"5px"}
          hoverBackground={addbtnBg}
          hoverColor={"none"}
          hoverBorder={"none"}
          padding={"9px"}
          background={addbtnBg}
        >
          <AddIcon color={colorTheme.white.white} />
        </Button>
      </FlexibleDiv>
    </>
  );
});
