
import UnAuthorized from "../../components/secondaryComponents/unAuthorized/unAuthorized.component";
import { TokenChecker } from "../../utilities/tokenChecker";

const ProtectedRoute = ({children}) => {
    // const accessToken = localStorage.getItem("token")

     if (!TokenChecker()) {
      return <UnAuthorized/>
       }
     return children;
}
 
export default ProtectedRoute;