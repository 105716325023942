import React, { useEffect, useState } from "react";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import BECOME_AN_ORGANIZER from "../../components/secondaryComponents/banner/BECOME_AN_ORGANIZER.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import Hero from "../../components/secondaryComponents/hero/hero.component";
import TopOrganizers from "../../components/secondaryComponents/topOrganizers/topOrganizers.component";
import { colorTheme } from "../../infrastructure/Theme/color";
// import Filter from "./filter";
import FreeEvents from "./freeEvents";
import OnlineEvents from "./onlineEvents";
import PopularTags from "./popularTags";
import TrendingEvents from "./trendingEvents";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCategory } from "../../state management/Redux/slices/categories/get_single_category_slice";
import EventCard from "../../components/secondaryComponents/eventCard";
import { CONSTANTS } from "../../utilities/constants";
import { Helmet } from "react-helmet";
const Catergory = () => {
  // const [typeOption, setTypeOption] = useState("Tye");
  const { slug } = useParams();
  const dispatch = useDispatch();
  const events = useSelector(
    ({ single_category }) => single_category?.data?.events
  );
  const categories = useSelector(
    (state) => state.persistedReducer.categories.category_list
  );
  // const [sliceNo, setSliceNo] = useState();
  // useEffect(() => {
  //   setSliceNo(Infinity);
  // }, [typeOption]);

  const thisCategory = categories?.filter((item) => item?.slug === slug);
  useEffect(() => {
    dispatch(getSingleCategory({ slug: slug }));
  }, [slug]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Category - {slug}</title>
      </Helmet>
      <FlexibleDiv direction={"column"}>
        {/* <CategoryHero /> */}
        <Hero
          alignItems={"center"}
          image={`${CONSTANTS.imgbaseurl}${thisCategory[0]?.placeholder}`}
          textContainerWidth={"100%"}
          height={"200px"}
          backBtn
          breakPoint={"880px"}
          breakPointHeight={"200px"}
          title={thisCategory[0]?.name}
          text={`Get the best ${slug} events here.`}
          textColor={colorTheme.primary_color}
        />
        <FlexibleDiv width={"80%"} padding={"33px"} direction={"column"}>
          {/* <Filter setTypeOption={setTypeOption} typeOption={typeOption} /> */}
          <PopularTags />
          <FlexibleDiv
            margin={"20px 0px 0px 0px"}
            justifyContent={"flex-start"}
            gap={"22px"}
            breakPoint={"780px"}
            wrap={"wrap"}
            breakPointgap={"6px"}
          >
            {events?.map(
              (
                { cover_image, title, startDate, id, tickets, user, category },
                index
              ) => {
                return (
                  <EventCard
                    style={{ overflowX: "hidden" }}
                    breakPointwidth={events?.length === 1 ? "80%" : "100%"}
                    breakPoint={"780px"}
                    width={"30%"}
                    link={`/event-details/${id}/${encodeURIComponent(title)}`}
                    key={index}
                    
                    category={ category && JSON.parse(category)}
                    organizer={user ? user : null}
                    cover_image={cover_image}
                    event_id={id}
                    title={title}
                    startDate={startDate}
                    tickets={tickets}
                  />
                );
              }
            )}
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv width={"95%"}>
          <BECOME_AN_ORGANIZER />
        </FlexibleDiv>
        <Footer />
      </FlexibleDiv>
    </>
  );
};

export default Catergory;

const Switch = (typeOption, sliceNo) => {
  switch (typeOption) {
    case "Trending Events":
      return (
        <>
          <TrendingEvents sliceNo={sliceNo} />
          <TopOrganizers />
        </>
      );
    case "Online Events":
      return (
        <>
          <OnlineEvents sliceNo={sliceNo} />
          <TopOrganizers />
        </>
      );
    case "Free Events":
      return (
        <>
          <FreeEvents sliceNo={sliceNo} />
          <TopOrganizers />
        </>
      );

    default:
      return (
        <>
          <TrendingEvents />
          <OnlineEvents />
          <TopOrganizers />
          <FreeEvents />
        </>
      );
  }
};
