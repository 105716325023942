import { useDispatch, useSelector } from "react-redux";
import { Label } from "../../../pages/Settings/styles";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import { useEffect } from "react";
import {
  edit_fullname_handler,
  start_edit_handler,
  toggleProfileEditted
} from "../../../state management/Redux/slices/user_slice/edit_profile_slice";
import { colorTheme } from "../../../infrastructure/Theme/color";

const EditName = ({ formik }) => {
  const dispatch = useDispatch();
  const darkmode = useSelector(
    ({ persistedReducer }) => persistedReducer?.theme?.darkmode
  );
  const firstname = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.firstname
  );
  const lastname = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.lastname
  );

  const fullname = useSelector(({ edit_profile }) => edit_profile?.fullname);

  const name_of_user = `${firstname} ${lastname}`;
  const editNameHandler = (e) => {
    const value = e.target.value;
    dispatch(edit_fullname_handler(value));
    dispatch(start_edit_handler(true));
    formik?.handleChange("fullname")(value);
  };

  const inputColor = darkmode
    ? colorTheme.white.white
    : colorTheme.black.strong;

  // useEffect(() => {
  //   dispatch(edit_fullname_handler(name_of_user));
  //   formik?.handleChange("fullname")(name_of_user);
  // }, [dispatch, formik, name_of_user]);
  useEffect(() => {
    dispatch(edit_fullname_handler(name_of_user));
    formik?.handleChange("fullname")(name_of_user);
  }, []);

  useEffect(() => {
    if (fullname === name_of_user) {
      dispatch(start_edit_handler(false));
      dispatch(toggleProfileEditted(false));
    } else if (fullname !== name_of_user) {
      dispatch(start_edit_handler(true));
      dispatch(toggleProfileEditted(true));
    }
  }, [fullname, name_of_user, dispatch]);
  return (
    <>
      <FlexibleDiv margin={"0px 0px 20px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} padding={"7px 0px"}>
          <Label>Name</Label>
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Input
            onChange={(e) => {
              editNameHandler(e);
            }}
            color={inputColor}
            value={fullname}
            border={"none"}
            borderBottom={"2.3px solid lightgrey"}
            fontSize={"14px"}
            width={"100%"}
            padding={"5px 5px 14px 5px"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default EditName;
