import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import ProfileTab from "./profileTab";

const LowerSection = ({searchedAccount,data}) => {
    return ( 
        <FlexibleDiv padding={'20px 0px'} >
            <ProfileTab data={data} searchedAccount={searchedAccount} />
        </FlexibleDiv>
     );
}
 
export default LowerSection;