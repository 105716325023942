import styled from "styled-components";
import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
// import { CameraIcon } from "../../../primaryComponents/icons/icons";
import CoverImage from "../../coverImage/coverImage.component";
import { CameraIcon } from "../../../primaryComponents/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import ImageCompressor from "image-compressor.js";
import {
  setCoverImage,
  setCoverImageRequest,
} from "../../../../state management/Redux/slices/cover-image";
import { useState } from "react";
import { Text } from "../../../primaryComponents/typography/typography.component";
import { CONSTANTS } from "../../../../utilities/constants";

const ChangeCoverImage = styled.div`
  /* position:absolute; */
  width: 100%;
  /* z-index: 1; */
  top: 0px;
  background-color: #c3c3c3a8;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const CoverImageSection = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.persistedReducer.user.userInfo);
  //   const user_cover_image = useSelector(
  //     (state) => state?.user_cover_image?.cover_image
  //   );
  const [user_cover_image, setUserCoverImage] = useState(null);
  const cover_image = userInfo?.cover_image;
  const coverimageHandler = (e) => {
    const [file] = e.target.files;
    if (file) {
      new ImageCompressor(file, {
        quality: 0.6,
        success(result) {
          dispatch(setCoverImageRequest({ cover_image: result }));
          setUserCoverImage(URL.createObjectURL(result));
        },
        error(e) {
          console.error(e.message);
        },
      });
    }
  };
  return (
    <>
      <FlexibleDiv position={"relative"} padding={"20px 0px 0px 0px"}>
        <FlexibleDiv width={"100%"} position={"relative"}>
          <input
            onChange={(e) => {
              coverimageHandler(e);
            }}
            required
            id="file-input"
            type={"file"}
            style={{ display: "none" }}
          />
          {cover_image !== null || user_cover_image !== null ? (
            <label
              htmlFor="file-input"
              style={{ width: "100%", position: "relative" }}
            >
              <CoverImage
                type={"user"}
                RdTopLeft={"0px"}
                RdTopRight={"0px"}
                img={
                  user_cover_image || `${CONSTANTS.imgbaseurl}${cover_image}`
                }
              />
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  top: "50%",
                  zIndex: 1,
                  //   left: "50%",
                  //   right: "50%"
                }}
              >
                <CameraIcon color={"white"} size={23} cursor={"pointer"} />
                <Text
                  color={"white"}
                  style={{ left: 0 }}
                  fontSize={"14.5px"}
                  resFontSize={"13.5px"}
                >
                  Change cover image
                </Text>
              </div>
            </label>
          ) : (
            <label htmlFor="file-input" style={{ width: "100%" }}>
              <ChangeCoverImage>
                <CameraIcon color={"white"} size={23} cursor={"pointer"} />
                <Text
                  color={"white"}
                  style={{ left: 10 }}
                  fontSize={"14.5px"}
                  resFontSize={"13.5px"}
                >
                  Change cover image
                </Text>
              </ChangeCoverImage>
            </label>
          )}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default CoverImageSection;
