import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./style.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState } from "react";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { useCreateEvent } from "../../createEvent Context";
import { descriptionValue } from "../../../../state management/Redux/slices/create_event_slice";
const TextEditor = () => {
  const dispatch = useDispatch();
  const handleChange = useCreateEvent()?.[4];
  const values = useCreateEvent()?.[0];
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const textEditorHandler = (e) => {
    handleChange("description")(e);
    dispatch(descriptionValue(e));
  };
  return (
    <ReactQuill
      style={{
        width: "100%",
        height: "200px",
        marginBottom: 50,
        borderRadius: 50,
        color: darkMode ? colorTheme.white.white : colorTheme.black.strong,
      }}
      theme="snow"
      value={values.description}
      onChange={(e)=>textEditorHandler(e)}
    />
  );
};

export default TextEditor;
