import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import React, { useEffect, useState } from "react";
import Ticket_Creator from "../ticket_creator/ticket_creator";
import Ticket_Editor from "./ticketEditior.component";
import { deleteTicket } from "../../../state management/Redux/slices/editEvent/delete_ticket_slice";
import {
  deleteTicketHandler,
  setTickets
} from "../../../state management/Redux/slices/editEvent/edit_details_slice";

const EditEventPrice = React.memo(() => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const dispatch = useDispatch();
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const deletingTicket = useSelector(
    ({ create_ticket }) => create_ticket?.deletingTicket
  );
  const [isOpen, setIsOpen] = useState(false);
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const btnBgColor = darkMode
    ? colorTheme.primary_color
    : colorTheme.white.white;
  const btnColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const tickets = useSelector(
    ({ event_details }) => event_details?.data?.event?.tickets
  );
  const editTicket = useSelector(({ edit_event }) => edit_event?.tickets);
  useEffect(() => {
    dispatch(setTickets(tickets));
  }, [loading]);
  return (
    <>
      <FlexibleDiv margin={"40px 0px"}>
        <FlexibleDiv
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"14.5px"}
            color={titleColor}
          >
            Price of event
          </HeaderText>
        </FlexibleDiv>

        <FlexibleDiv justifyContent={"flex-start"} gap={"12px"}>
          <FlexibleDiv
            width={"113px"}
            bg={tickets?.length === 0 ? colorTheme.primary_color : "#F2F3F7"}
            radius={"5px"}
            padding={"10px"}
            style={{ cursor: "pointer" }}
          >
            <Text
              cursor={"pointer"}
              margin={"0px"}
              fontSize={"14px"}
              resFontSize={"13px"}
              color={
                tickets?.length === 0 ? "#F2F3F7" : colorTheme.black.strong
              }
            >
              Free
            </Text>
          </FlexibleDiv>
          <FlexibleDiv
            width={"113px"}
            bg={tickets?.length > 0 ? colorTheme.primary_color : "#F2F3F7"}
            radius={"5px"}
            padding={"10px"}
            style={{ cursor: "pointer" }}
          >
            <Text
              cursor={"pointer"}
              margin={"0px"}
              fontSize={"14px"}
              color={tickets?.length > 0 ? "#F2F3F7" : colorTheme.black.strong}
              resFontSize={"13px"}
            >
              Paid
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv margin={"12px 0px"} direction={"column"} wrap={"nowrap"}>
          {editTicket &&
            editTicket?.length > 0 &&
            editTicket?.map(
              ({ name, price, capacity, id, description, cover_image }) => (
                <SET_TICKET
                  key={id}
                  ticketId={id}
                  ticketName={name}
                  price={price}
                  cover_image={cover_image}
                  description={description}
                  quantity={capacity}
                />
              )
            )}
        </FlexibleDiv>
        <FlexibleDiv margin={"12px 0px"} justifyContent={"flex-start"}>
          <Button
            width={"878px"}
            padding={"12px"}
            onClick={() => {
              setIsOpen(true);
            }}
            children={"+ Add More Tickets"}
            background={btnBgColor}
            color={btnColor}
            hoverBackground={"#F2F3F7"}
            hoverColor={"#000"}
            hoverBorder={"none"}
          />
        </FlexibleDiv>
      </FlexibleDiv>

      <Ticket_Creator
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
});

export default EditEventPrice;

const SET_TICKET = ({
  ticketName,
  price,
  quantity,
  ticketId,
  description,
  cover_image
}) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const deletingTicket = useSelector(
    ({ create_ticket }) => create_ticket?.deletingTicket
  );
  const inputColor = darkMode
    ? colorTheme.white.fadingWhite
    : colorTheme.black.strong;
  return (
    <FlexibleDiv direction={"column"}>
      <FlexibleDiv
        wrap={"nowrap"}
        style={{ overFlow: "scroll" }}
        justifyContent={"flex-start"}
        gap={"12px"}
      >
        {/* Ticket Name */}
        <FlexibleDiv
          direction={"column"}
          flexBasis={"26.33%"}
          alignItems={"flex-start"}
        >
          <Text
            color={colorTheme.textColor}
            fontSize={"16px"}
            resFontSize={"13.8px"}
          >
            Ticket Name
          </Text>
          <Input
            width={"90%"}
            border={`0.5px solid #A1A1A1`}
            color={inputColor}
            padding={"10px"}
            radius={"5px"}
            defaultValue={ticketName}
          />
        </FlexibleDiv>
        {/* Ticket Price */}
        <FlexibleDiv
          direction={"column"}
          flexBasis={"26.33%"}
          alignItems={"flex-start"}
        >
          <Text
            color={colorTheme.textColor}
            fontSize={"16px"}
            resFontSize={"13.8px"}
          >
            Price
          </Text>
          <Input
            width={"90%"}
            border={`0.5px solid #A1A1A1`}
            padding={"10px"}
            color={inputColor}
            radius={"5px"}
            defaultValue={`N ${price}`}
          />
        </FlexibleDiv>
        {/* Ticket Name */}
        <FlexibleDiv
          direction={"column"}
          flexBasis={"26.33%"}
          alignItems={"flex-start"}
        >
          <Text
            color={colorTheme.textColor}
            fontSize={"16px"}
            resFontSize={"13.8px"}
          >
            Quantity
          </Text>
          <Input
            width={"90%"}
            border={`0.5px solid #A1A1A1`}
            padding={"10px"}
            color={inputColor}
            radius={"5px"}
            defaultValue={quantity}
            readonly
          />
        </FlexibleDiv>
      </FlexibleDiv>
      <FlexibleDiv
        justifyContent={"flex-start"}
        gap={"23px"}
        margin={"20px 0px"}
      >
        <Button
          onClick={() => {
            setIsOpen(true);
          }}
          radius={"5px"}
          width={"90px"}
          padding={"5px"}
        >
          Edit
        </Button>
        <Button
          background={colorTheme.red}
          hoverBackground={colorTheme.red}
          hoverColor={colorTheme.white.white}
          hoverBorder={"none"}
          color={colorTheme.white.white}
          onClick={() => {
            dispatch(
              deleteTicket({
                id: ticketId,
                dispatch: dispatch,
                deleteTicketHandler: deleteTicketHandler
              })
            );
          }}
          radius={"5px"}
          width={"90px"}
          padding={"5px"}
        >
          {deletingTicket ? "Deleting..." : "Delete"}
        </Button>
      </FlexibleDiv>
      <Ticket_Editor
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        ticketName={ticketName}
        ticketId={ticketId}
        price={price}
        description={description}
        cover_image={cover_image}
        quantity={quantity}
        // setEventTickets={setEventTickets}
      />
    </FlexibleDiv>
  );
};
