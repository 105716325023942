import { useNavigate } from "react-router-dom";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { GoBackIcon } from "../../primaryComponents/icons/icons";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import {
  BackButtonContainer,
  BackgroundImage,
  BackgroundImageContainer,
  HeroCol,
  TextSection
} from "./styles";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { useDispatch } from "react-redux";

const Hero = ({
  image,
  loading,
  typeOfHandler,
  goBackFunction,
  backBtn,
  title,
  text,
  width,
  height,
  alignItems,
  textColor,
  titleColor,
  resTextAlign,
  textContainerWidth,
  breakPoint,
  breakPointHeight,
  breakPointwidth,
  author
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <FlexibleDiv
        width={width ? width : "98%"}
        margin={"auto"}
        height={height}
        breakPoint={breakPoint}
        breakPointHeight={breakPointHeight}
        breakPointwidth={breakPointwidth}
      >
        <BackgroundImageContainer>
          <BackgroundImage image={image}>
            {backBtn ? (
              <BackButtonContainer>
                <GoBackIcon
                  color="white"
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (typeOfHandler === "dispatch") {
                      dispatch(goBackFunction());
                    }
                    navigate(-1);
                  }}
                />
                {/* <Text
                  color={titleColor ? titleColor :"silver"}
                  cursor={"pointer"}
                  fontSize={fontSizes.mobile_smallest}
                >
                  Back
                </Text> */}
              </BackButtonContainer>
            ) : null}

            <TextSection>
              <HeroCol alignItems={alignItems} flexBasis={textContainerWidth}>
                {loading ? (
                  <Skeleton
                    width={300}
                    height={65}
                    style={{ marginBottom: 23 }}
                  />
                ) : (
                  <HeaderText
                    fontSize={fontSizes.desktop_title}
                    color={"white"}
                    resFontSize={"23px"}
                  >
                    {title}
                  </HeaderText>
                )}
                {loading ? (
                  <Skeleton width={100} height={15} />
                ) : (
                  <>
                    {author ? (
                      <Text
                        color={"silver"}
                        fontSize={fontSizes.mobile_details}
                        top={"-20px"}
                        resFontSize={"14px"}
                      >
                        By {author}
                      </Text>
                    ) : null}
                  </>
                )}
                <Text
                  color={textColor ? textColor : "silver"}
                  fontSize={fontSizes.mobile_details}
                  top={"-25px"}
                  lineHeight={"23px"}
                  resFontSize={"13px"}
                  resTextAlign={resTextAlign}
                >
                  {text}
                </Text>
              </HeroCol>
            </TextSection>
          </BackgroundImage>
        </BackgroundImageContainer>
      </FlexibleDiv>
    </>
  );
};

export default Hero;
