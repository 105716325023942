import * as yup from "yup";

const isImageFile = (value) => {
  // Check if it's a File and has an image type
  return value instanceof File && value.type.startsWith("image/");
};


export const editTicketSchema = yup.object().shape({
  name: yup.string().required("Ticket name required"),
  description: yup.string().required("Description required"),
  price: yup.number().required("Price required"),
  capacity: yup.number().required("Capacity required"),
  // cover_image: yup.mixed().test('isImage', 'Cover image must be a valid image file', isImageFile)
});