import styled from "styled-components";


export const ImageContainer = styled.div`
flex:70%;
@media screen and (max-width: 1240px) {
    flex: 65%;
}
@media screen and (max-width: 1096px) {
    flex: 55%;
}
@media screen and (max-width: 880px) {
    flex: 50%;
}
@media screen and (max-width: 760px) {
    flex: 45%;
}
@media screen and (max-width: 680px) {
display: none;
}
`

export const BlueBox = styled.div`
flex: 30%;
display: flex;
@media screen and (max-width: 1240px) {
    flex:35%;
}
@media screen and (max-width: 1096px) {
    flex:45%;
}
@media screen and (max-width: 880px) {
    flex:50%;
}
@media screen and (max-width: 760px) {
    flex:55%;
}
@media screen and (max-width: 680px) {
    flex:100%;
}
`