export const questions = [
  {
    question: "What is Attend?",
    answer:
      "Attend is a comprehensive event management platform that allows you to create, manage, and promote events seamlessly."
  },
  {
    question: "How do I get started with Attend?",
    answer:
      "To get started, visit our website and sign up for an account. Once logged in, you can begin creating your events."
  },
  {
    question: "What types of events can I create on Attend?",
    answer:
      "Attend supports a wide range of events, including conferences, seminars, workshops, concerts, parties, and more."
  },
  {
    question: "Is Attend free to use?",
    answer:
      "Attend offers both free and premium plans. Basic features are available for free, and additional premium features are available for a subscription fee."
  },
  {
    question: "How do I create an event on Attend?",
    answer:
      "Log in to your account, navigate to the dashboard, and click on 'Create Event.' Follow the step-by-step instructions to set up your event."
  },
  {
    question: "Can I customize the look and feel of my event page?",
    answer:
      "Yes, you can customize your event page by adding images, logos, and choosing from different templates to match your branding."
  },
  {
    question: "What information should I include when creating an event?",
    answer:
      "Include details such as event name, date, time, venue, ticket types, and a brief description to provide attendees with all the necessary information."
  },
  {
    question: "How does the ticketing system work on Attend?",
    answer:
      "Attend provides a secure and easy-to-use ticketing system. You can set different ticket types, prices, and limits."
  },
  {
    question: "What payment options are available for ticket purchases?",
    answer:
      "Attend supports various payment methods, including credit/debit cards and other popular online payment options."
  },
  {
    question: "How do I track ticket sales and attendee information?",
    answer:
      "You can track ticket sales and access attendee information through the dashboard. Attend also provides reporting tools to help you analyze event data."
  },
  {
    question: "Can I promote my event on social media through Attend?",
    answer:
      "Yes, you can easily share your event on social media directly from the Attend platform."
  },
  {
    question:
      "What do I do if I encounter issues with my event page or ticketing?",
    answer:
      "If you experience any issues, contact our support team through the Help Center or submit a support ticket for assistance."
  }
];
