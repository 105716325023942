import { useState } from "react";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { DropDownIcon, DropUpIcon } from "../../primaryComponents/icons/icons";
import { Text } from "../../primaryComponents/typography/typography.component";
import { FaqQuestionContainer } from "./styles";

const FaqQuestion = ({ question, answer }) => {
  const [toggleQuestion, setToggleQuestion] = useState(false);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const ToggleHandler = () => {
    setToggleQuestion(!toggleQuestion);
  };
  return (
    <>
      <FaqQuestionContainer
        height={toggleQuestion ? "auto" : ""}
        paddingBottom={toggleQuestion ? "40px" : "12px"}
      >
        <FlexibleDiv justifyContent={"space-between"} position={"relative"}>
          <FlexibleDiv width={"80%"} justifyContent={'flex-start'} >
            <Text
              color={colorTheme.primary_color}
              fontSize={"18px"}
              resFontSize={"14px"}
              fontWeight={"500"}
            >
              {question}
            </Text>
          </FlexibleDiv>
          <FlexibleDiv width={"20%"}>
            {toggleQuestion ? (
              <DropUpIcon
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
                size={18}
                onClick={() => ToggleHandler()}
              />
            ) : (
              <DropDownIcon
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
                size={18}
                onClick={() => ToggleHandler()}
              />
            )}
          </FlexibleDiv>
        </FlexibleDiv>
        <FlexibleDiv width={"97%"} position={"relative"}>
          <Text
            lineHeight={"21.78px"}
            margin={"0px"}
            fontSize={"15px"}
            resFontSize={"13px"}
            color={"#A1A1A1"}
          >
            {answer}
          </Text>
        </FlexibleDiv>
      </FaqQuestionContainer>
    </>
  );
};

export default FaqQuestion;
