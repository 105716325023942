export const ImageToFile = (imgUrl) => {
  try {
    const blob = new Blob([imgUrl], { type: "image/jpeg" });
    const file = new File([blob], "image", { type: blob.type });
    return file;
  } catch (error) {
    console.error("Error converting URL to blob:", error);
    throw error;
  }
};
