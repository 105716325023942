import React from 'react';
import { UserImageStyle } from './userImage.styles';
// import Image from "../../../assets/images/no_user.png"

const UserImage = ({
    width,
    img,
    height,
    resWidth,
    resHeight,
    resborderRadius,
    objectFit,
    src,
    alt,
    onChange
}) => {
    return ( 
        <UserImageStyle objectFit={objectFit} resWidth={resWidth}resHeight={resHeight} resborderRadius={resborderRadius} onChange={onChange} width={width} height={height} src={img} alt={alt ? alt :'user-img'} />

        
     );
}
 
export default UserImage;