import { colorTheme } from "../../../../infrastructure/Theme/color";

export const NavLinks = [
    // {
    //     name:'Explore Events',
    //     path:'/',
    //     active:false,
    //     color:colorTheme.black
    // },
    {
        name:'Help',
        path:'/contact-us',
        active:false,
        color:colorTheme.black
    },
    {
        name:'Create Event',
        path:'/create',
        active:false,
        color:colorTheme.primary_color
    },
    {
        name:'Login',
        path:'/login',
        active:false,
        color:colorTheme.black
    },
    {
        name:'Sign Up',
        path:'/signup',
        active:false,
        color:colorTheme.black
    },
]


export const loggedInLinks =[
    {
        name:'Help',
        path:'/contact-us',
        active:false,
        color:colorTheme.black
    },
    {
        name:'Create Event',
        path:'/create',
        active:false,
        color:colorTheme.primary_color
    },
]