import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import Skeleton from "react-loading-skeleton";
import { locationHandler } from "../../../state management/Redux/slices/editEvent/edit_details_slice";

const EditEventLocation = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const location = useSelector(({ edit_event }) => edit_event?.location);
  const dispatch = useDispatch();
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const inputColor = darkMode
    ? colorTheme.white.fadingWhite
    : colorTheme.black.strong;
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const [readOnly, setReadOnly] = useState(true);
  return (
    <>
      <FlexibleDiv margin={"40px 0px"}>
        <FlexibleDiv
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"18.5px"}
            color={titleColor}
          >
            Location
          </HeaderText>
        </FlexibleDiv>

        <FlexibleDiv justifyContent={"flex-start"}>
          {loading ? (
            <Skeleton width={600} height={28} />
          ) : (
            <FlexibleDiv
              shadow={"0px 2px 7px rgba(0, 0, 0, 0.1)"}
              width={"850px"}
              padding={"0px 12px"}
              breakPoint={"680px"}
              breakPointwidth={"100%"}
            >
              <FlexibleDiv width={"50%"}>
                <Input
                  width={"100%"}
                  padding={"12px"}
                  onChange={(e) => {
                    const value = e.target.value;
                    dispatch(locationHandler(value));
                  }}
                  readonly={readOnly}
                  value={location}
                  color={inputColor}
                />
              </FlexibleDiv>
              <FlexibleDiv width="50%" justifyContent={"flex-end"}>
                <Text
                cursor={"pointer"}
                  onClick={() => {
                    setReadOnly(false);
                  }}
                  color={colorTheme.primary_color}
                  fontSize={"14px"}
                  resFontSize={"13px"}
                >
                  Change location
                </Text>
              </FlexibleDiv>
            </FlexibleDiv>
          )}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default EditEventLocation;
