import OrganizerCoverImage from "./organizerCoverImage";
import UserCoverImage from "./userCoverImage";

const CoverImage = ({ type, RdTopLeft, RdTopRight, img }) => {
  return (
    <>
      {type === "organizer" ? (
        <OrganizerCoverImage
          RdTopLeft={RdTopLeft}
          RdTopRight={RdTopRight}
          img={img}
        />
      ) : type === "user" ? (
        <UserCoverImage
          RdTopLeft={RdTopLeft}
          RdTopRight={RdTopRight}
          img={img}
        />
      ) : null}
    </>
  );
};

export default CoverImage;
