// These are constant values in the app
export const CONSTANTS = {
  phoneNumber: "+234 708 983 5333",
  emailAddress: "attendenquiries@gmail.com",
  baseURL: "https://app.attend.org.ng/",
  imgbaseurl: "https://app.attend.org.ng/storage/",
  profileImageUrl: "https://app.attend.org.ng/storage/",
  paystack_key: "pk_test_61b1dd761d9a0ce9bd46f52342fd51fe7f75c7c1",
  goggleApiKey: "AIzaSyDZ8hfjgIt40XQiOCm33G-fUvqCAm2x8M4",
  clientID:
    "107189871843-eot7i78oq1fgrt55ti4ar5afbsgrm3m0.apps.googleusercontent.com"
};
