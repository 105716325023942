import React, {memo } from "react";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { TabOption } from "./tabStyles";


export const TabMenu = ({tabName,setTabName,TabOptions,optionWidth,setViews})=>{
  
    const TabHandler = (e) => {
      let Value = e.target.innerHTML;
      setTabName(Value);
      const filter = TabOptions.findIndex((e)=>e.name === Value )
      setViews(filter)

    }
    
    ;
    const darkMode = useSelector(({persistedReducer})=>persistedReducer.theme.darkmode)
    return(
        <>
        <FlexibleDiv
        justifyContent={"flex-start"}
        gap={"22px"}
        breakPoint={"1180px"}
        breakPointgap={"25%"}
        breakPointPadding={"0px 32px"}
        borderBottom={"0.9px solid lightgrey"}
      >
        {TabOptions?.map(({ name, active,number }) => (
          <TabOption
            display={number ? 'flex' :"block"}
            justifyContent={number ? 'flex-start' :''}
            alignItems={number ? 'center' :""}
            gap={number ? '13px' :""}
            bg={name === tabName ? "" : "none"}
            linewidth={"70%"}
            key={name}
            width={optionWidth ? optionWidth:'20%'}
          >
            <Text
              cursor={"pointer"}
              onClick={(e) => {
                TabHandler(e, active);
              }}
              color={  name === tabName  ? darkMode ? colorTheme.white.white :'' : colorTheme.black.soft}
              fontSize={fontSizes.mobile_details}
              fontWeight={name === tabName  ? "bold" :"500"}
              resFontSize={"14px"}
              style={{display:'flex',gap:'8px',alignItems:'center'}}
            >
              {name}
            </Text>
            {number &&  <span style={{width:"11px",display:'flex',alignItems:"center",justifyContent:'center',height:"7px",padding:'6px 4px',borderRadius:'50%',background:colorTheme.primary_color,color:colorTheme.white.fadingWhite,}} >{number}</span>}
          </TabOption>
        ))}
      </FlexibleDiv>
        </>
    )
            }

export default memo(TabMenu)