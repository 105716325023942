import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  AddIcon,
  TickectIcon,
} from "../../../components/primaryComponents/icons/icons";
import {
  HeaderText,
  Text,
} from "../../../components/primaryComponents/typography/typography.component";
import Ticket from "../../../components/secondaryComponents/Ticket/ticket.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import "react-photo-view/dist/react-photo-view.css";
import { PhotoProvider } from "react-photo-view";
const TickectSection = ({ data }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const user_ticket = useSelector(
    (state) => state.user_ticket.user_tickets?.tickets
  );
  const tickets = user_ticket ? user_ticket : data?.[0]?.tickets;
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getUsersTickets());
  // }, []);
  return (
    <>
      <FlexibleDiv padding={"30px 0px"} alignItems={"flex-start"}>
        <FlexibleDiv
          width={"100%"}
          resPoint={"780px"}
          reswidth={"100%"}
          breakPoint={"980px"}
          breakPointwidth={"90%"}
        >
          <FlexibleDiv
            bg={
              darkMode ? colorTheme.black.fadingBlack : colorTheme.white.white
            }
            shadow={"0px 0px 18px rgba(0, 0, 0, 0.1)"}
            padding={"13px 23px"}
          >
            <Head />

            {/* Upcoming Events */}
            {tickets?.length > 0 ? (
              <UpcomingEvents tickets={tickets} />
            ) : (
              <FlexibleDiv padding={"12px 0px"}>
                <Text color={colorTheme.lightgrey} resFontSize={"14px"}>
                  You have no tickets
                </Text>
              </FlexibleDiv>
            )}
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default TickectSection;

const Head = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      {/* Title */}
      <FlexibleDiv position={"relative"} justifyContent={"flex-start"}>
        {/* Ticket Icon */}
        <FlexibleDiv
          position={"absolute"}
          top={"-30px"}
          bg={colorTheme.primary_color}
          radius={"5px"}
          width={"30px"}
          padding={"10px"}
          left={"0px"}
          zIndex={"1"}
        >
          <TickectIcon color={"white"} size={"21px"} />
        </FlexibleDiv>

        <HeaderText
          fontSize={fontSizes.mobile_body_text}
          color={darkMode ? colorTheme.white.white : "#4e4b4b"}
          fontWeight={"600"}
          margin={"1px 0px 0px 60px"}
          resFontSize={"1rem"}
        >
          Tickets
        </HeaderText>

        {/* Add Icon */}
        <FlexibleDiv
          position={"absolute"}
          top={"-30px"}
          bg={colorTheme.primary_color}
          radius={"5px"}
          width={"30px"}
          padding={"10px"}
          right={"0px"}
          zIndex={"1"}
        >
          <AddIcon color={"white"} size={"21px"} />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

const UpcomingEvents = ({ tickets }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <FlexibleDiv
      padding={"0px 30px"}
      breakPoint={"680px"}
      breakPointPadding={"0px"}
    >
      {/* <Title
        title={"Your Tickets"}
        color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
      /> */}

      {/* Ticket */}
      <PhotoProvider>
        <FlexibleDiv justifyContent={"flex-start"}>
          {/* Ticket Card */}
          {tickets?.map(({ status, ticket_code, event_ticket }) => {
            const event = event_ticket?.event;
            return (
              <Ticket
                key={ticket_code}
                ticketType={event_ticket?.name}
                eventName={event?.title}
                eventQRCode={ticket_code}
                eventDate={event?.startDate}
                eventLocation={event?.location}
                eventOrderNo={ticket_code}
                ticketStatus={status}
                eventImage={event_ticket?.cover_image}
              />
            );
          })}
        </FlexibleDiv>
      </PhotoProvider>
    </FlexibleDiv>
  );
};
