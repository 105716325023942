import { useDispatch, useSelector } from "react-redux";
import { AboutTextArea, Label } from "../../../pages/Settings/styles";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  edit_bio_handler,
  start_edit_handler,
  toggleProfileEditted
} from "../../../state management/Redux/slices/user_slice/edit_profile_slice";
import { useEffect } from "react";
import { colorTheme } from "../../../infrastructure/Theme/color";

const EditAbout = ({ formik }) => {
  const dispatch = useDispatch();
  const darkmode = useSelector(
    ({ persistedReducer }) => persistedReducer?.theme?.darkmode
  );
  const bio = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.bio
  );
  const editBio = useSelector(({ edit_profile }) => edit_profile?.bio);

  const editBioHandler = (e) => {
    const value = e.target.value;
    dispatch(edit_bio_handler(value));
    dispatch(start_edit_handler(true));
    formik?.handleChange("bio")(value);
  };
  const inputColor = darkmode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  useEffect(() => {
    if (bio === null) {
      dispatch(edit_bio_handler(""));
      formik?.handleChange("bio")("");
    } else {
      dispatch(edit_bio_handler(bio));
      formik?.handleChange("bio")(bio);
    }
  }, []);
  useEffect(() => {
    if (editBio === bio) {
      dispatch(start_edit_handler(false));
      dispatch(toggleProfileEditted(false));
    } else if (editBio !== bio) {
      dispatch(start_edit_handler(true));
      dispatch(toggleProfileEditted(true));
    }
  }, [editBio, bio, dispatch]);
  return (
    <>
      <FlexibleDiv margin={"0px 0px 20px 0px"}>
        <FlexibleDiv justifyContent={"flex-start"} padding={"7px 0px"}>
          <Label>About/Bio</Label>
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"}>
          <AboutTextArea
            onChange={(e) => {
              editBioHandler(e);
            }}
            width={"100%"}
            color={inputColor}
            value={editBio}
            padding={
              editBio.length > 200 ? "10px 5px 50px 5px" : "10px 5px 25px 5px"
            }
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default EditAbout;
