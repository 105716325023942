import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANTS } from "../utilities/constants";
import axios from "axios";
import { toast } from "react-toastify";
export const generate_payment_link = createAsyncThunk(
  "user/generate_payment_link",
  async ({ token, name, event_ticket_id, quantity }, { rejectWithValue }) => {
    try {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": true,
          Authorization: `Bearer ${token}`,
          Referer: CONSTANTS.baseURL
        }
      };
      const formData = new FormData();
      formData.append("name", name);
      formData.append("event_ticket_id", event_ticket_id);
      formData.append("quantity", quantity);
      const response = await axios.post(
        CONSTANTS.baseURL + "api/event/tickets/purchase",
        formData,
        config
      );

      if (response.status === 200) {
        const externalLink = response?.data?.payment_url;
        window.location.replace(externalLink);
      }
    } catch (error) {
      const { response } = error;
      console.error("Generate payment link error:", error);
      console.error("Generate payment link error:", response);
      toast.error(response?.data?.error);
      return rejectWithValue(response?.data?.error);
    }
  }
);
