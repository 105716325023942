import React from "react";
import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { CONSTANTS } from "../../../../utilities/constants";
import { FacebookShare, TwitterShare, WhatsappShare } from "react-share-lite";
import { Helmet } from "react-helmet";

const Share = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const title = useSelector(
    ({ event_details }) => event_details.data?.event?.title
  );
  const description = useSelector(
    ({ event_details }) => event_details?.data?.event?.description
  );
  const cover_image = useSelector(
    ({ event_details }) => event_details?.data?.event?.cover_image
  );

  const socialIcons = [
    {
      icon: (
        <FacebookShare size={23} url={window.location.href} title={title} />
      ),
    },
    {
      icon: (
        <WhatsappShare size={23} url={window.location.href} title={title} />
      ),
    },
    {
      icon: <TwitterShare size={23} url={window.location.href} title={title} />,
    },
  ];

  return (
    <>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${CONSTANTS.imgbaseurl}${cover_image}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="Your Site Name" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={`${CONSTANTS.imgbaseurl}${cover_image}`}
        />

        {/* Pinterest */}
        <meta name="pinterest-rich-pin" content="true" />
      </Helmet>

      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        width={"82%"}
        margin={"0px 0px 0px 0px"}
        lineHeight={".4rem"}
        breakPoint={"680px"}
        breakPointMargin={"40px 0px 50px 0px"}
      >
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text
            fontWeight={"900"}
            fontSize={fontSizes.mobile_body_text}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Share with friends
          </Text>
        </FlexibleDiv>
        <FlexibleDiv
          justifyContent={"flex-start"}
          gap={"20px"}
          position={"relative"}
          top={"-12px"}
        >
          {socialIcons.map(({ icon }, index) => (
            <Text
              key={index}
              cursor={"pointer"}
              fontSize={fontSizes.desktop_body_text}
              hoverColor={colorTheme.primary_color}
              fontWeight={"900"}
              color={colorTheme.textColor}
            >
              {icon}
            </Text>
          ))}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Share;
