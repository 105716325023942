import { useDispatch, useSelector } from "react-redux";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import React, { useState } from "react";
import { useFormik } from "formik";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import Input from "../../primaryComponents/input/input.component";
import Button from "../../primaryComponents/button/button.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Loader from "../loader/loader.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { editTicketSchema } from "../../../infrastructure/schemas/edit_ticket_schema";
import { editTicketHandler } from "../../../state management/Redux/slices/editEvent/edit_details_slice";
import { editTicket } from "../../../state management/Redux/slices/editEvent/edit_ticket_slice";

const Ticket_Editor = React.memo(
  ({
    isOpen,
    setIsOpen,
    setEventTickets,
    ticketName,
    price,
    quantity,
    description,
    cover_image,
    ticketId
  }) => {
    const darkMode = useSelector(
      (state) => state.persistedReducer.theme.darkmode
    );
    const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState);
    };
    const loading = useSelector(({ create_ticket }) => create_ticket?.loading);
    const dispatch = useDispatch();
    const [cover_image_state, setCoverImage] = useState(null);

    const onSubmit = (values, action) => {
      const data = {
        name: cover_image_state?.name,
        size: cover_image_state?.size,
        lastModified: cover_image_state?.lastModified,
        type: cover_image_state?.type,
        webkitRelativePath: cover_image_state?.webkitRelativePath,
        lastModifiedDate: cover_image_state?.lastModifiedDate
      };
      dispatch(
        editTicket({
          ticketId: ticketId,
          name: values.name,
          price: values.price,
          capacity: values.capacity,
          description: values.description,
          cover_image: cover_image_state,
          setIsOpen: setIsOpen,
          formAction: action,
          dispatch: dispatch,
          editTicketHandler: editTicketHandler
        })
      );
    };

    const { values, handleChange, handleSubmit, errors, touched } = useFormik({
      initialValues: {
        name: ticketName,
        price: price,
        capacity: quantity,
        description: description
      },
      validationSchema: editTicketSchema,
      onSubmit
      // enableReinitialize: true
    });
    const cancelHandler = () => {
      values.capacity = 0;
      values.description = "";
      values.name = "";
      values.price = 0;
      setCoverImage(null);
      toggleDrawer();
    };
    return (
      <>
        <Drawer
          style={{
            width: "100%",
            height: "auto",
            padding: 23,
            background: colorTheme.black.fadeMini
          }}
          open={isOpen}
          onClose={toggleDrawer}
          direction="bottom"
        >
          <FlexibleDiv justifyContent={"flex-start"}>
            <Text
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            >
              Edit Ticket
            </Text>
          </FlexibleDiv>

          <FlexibleDiv direction={"column"}>
            <FlexibleDiv
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              margin={"0px 12px 0px 0px"}
            >
              <FlexibleDiv justifyContent={"space-between"}>
                <Text
                  fontSize={fontSizes.mobile_details}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  Ticket Name
                </Text>
                {errors.name && touched.name && (
                  <Text
                    fontSize={"13px"}
                    style={{ right: 34 }}
                    resFontSize={"12px"}
                    color={colorTheme.red}
                  >
                    {errors.name}
                  </Text>
                )}
              </FlexibleDiv>

              <Input
                value={values.name}
                onChange={(e) => {
                  handleChange("name")(e.target.value);
                }}
                radius={"5px"}
                border={`1px solid ${colorTheme.lightgrey} `}
                padding={"12px 0px 12px 12px"}
                placeHolder={"eg:Regular"}
                width={"90%"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            </FlexibleDiv>

            <FlexibleDiv
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              margin={"0px 12px 0px 0px"}
            >
              <FlexibleDiv justifyContent={"space-between"}>
                <Text
                  fontSize={fontSizes.mobile_details}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  Ticket Price
                </Text>
                {errors.price && touched.price && (
                  <Text
                    fontSize={"13px"}
                    style={{ right: 34 }}
                    resFontSize={"12px"}
                    color={colorTheme.red}
                  >
                    {errors.price}
                  </Text>
                )}
              </FlexibleDiv>

              <Input
                value={values.price}
                onChange={(e) => {
                  handleChange("price")(e.target.value);
                }}
                type={"number"}
                radius={"5px"}
                border={`1px solid ${colorTheme.lightgrey} `}
                padding={"12px 0px 12px 12px"}
                placeHolder={"N1000"}
                width={"90%"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            </FlexibleDiv>

            <FlexibleDiv
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              margin={"0px 12px 0px 0px"}
            >
              <FlexibleDiv justifyContent={"space-between"}>
                <Text
                  fontSize={fontSizes.mobile_details}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  Ticket Capacity
                </Text>
                {errors.capacity && touched.capacity && (
                  <Text
                    fontSize={"13px"}
                    style={{ right: 34 }}
                    resFontSize={"12px"}
                    color={colorTheme.red}
                  >
                    {errors.capacity}
                  </Text>
                )}
              </FlexibleDiv>

              <Input
                type={"number"}
                value={values.capacity}
                onChange={(e) => {
                  handleChange("capacity")(e.target.value);
                }}
                radius={"5px"}
                border={`1px solid ${colorTheme.lightgrey} `}
                padding={"12px 0px 12px 12px"}
                placeHolder={"50"}
                width={"90%"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            </FlexibleDiv>

            <FlexibleDiv
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              margin={"0px 12px 0px 0px"}
            >
              <FlexibleDiv justifyContent={"space-between"}>
                <Text
                  fontSize={fontSizes.mobile_details}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  Ticket Description
                </Text>
                {errors.description && touched.description && (
                  <Text
                    fontSize={"13px"}
                    style={{ right: 34 }}
                    resFontSize={"12px"}
                    color={colorTheme.red}
                  >
                    {errors.description}
                  </Text>
                )}
              </FlexibleDiv>

              <Input
                radius={"5px"}
                value={values.description}
                onChange={(e) => {
                  handleChange("description")(e.target.value);
                }}
                maxLength={200}
                border={`1px solid ${colorTheme.lightgrey} `}
                padding={"12px 0px 12px 12px"}
                placeHolder={"Description"}
                width={"90%"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            </FlexibleDiv>
            <FlexibleDiv
              direction={"column"}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              margin={"0px 12px 0px 0px"}
            >
              <FlexibleDiv justifyContent={"space-between"}>
                <Text
                  fontSize={fontSizes.mobile_details}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                  style={{ textAlign: "left" }}
                >
                  {" "}
                  Ticket Image
                </Text>
                {errors.cover_image && touched.cover_image && (
                  <Text
                    fontSize={"13px"}
                    style={{ right: 34 }}
                    resFontSize={"12px"}
                    color={colorTheme.red}
                  >
                    {errors.cover_image}
                  </Text>
                )}
              </FlexibleDiv>

              <Input
                radius={"5px"}
                onChange={(e) => {
                  const [file] = e.target?.files;
                  setCoverImage(file);
                }}
                placeHolder={cover_image}
                type={"file"}
                border={`1px solid ${colorTheme.lightgrey} `}
                padding={"12px 0px 12px 12px"}
                width={"90%"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              />
            </FlexibleDiv>

            <FlexibleDiv
              margin={"34px 0px"}
              justifyContent={"flex-start"}
              gap={"23px"}
            >
              <Button
                type={"submit"}
                width={"40%"}
                radius={"3px"}
                background={
                  loading ? colorTheme.white.white : colorTheme.primary_color
                }
                onClick={handleSubmit}
                hoverBorder={colorTheme.primary_color}
                padding={"13px"}
                hoverColor={colorTheme.primary_color}
              >
                {loading ? <Loader width={"32px"} /> : "Edit Ticket"}
              </Button>
              <Button
                onClick={cancelHandler}
                radius={"3px"}
                type="button"
                width={"40%"}
                padding={"13px"}
                background={colorTheme.white.white}
                color={colorTheme.black.strong}
                hoverBorder={colorTheme.white.white}
                hoverColor={colorTheme.black.strong}
              >
                Cancel
              </Button>
            </FlexibleDiv>
          </FlexibleDiv>
        </Drawer>
      </>
    );
  }
);

export default Ticket_Editor;
