import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import FAQ from "../../components/secondaryComponents/faq/faq.component";


const FaqColumn = () => {
    return (
        <>
        <FlexibleDiv flexBasis={'60%'} breakPoint={'780px'} breakPointflexBasis={'100%'} >
            <FAQ/>
        </FlexibleDiv>
        </>
      );
}
 
export default FaqColumn;