export const colorTheme ={
    primary_color:"#FCAE1E",
    secondary_color:"#028A0F",
    sideBarBg:'#ededed75',
        test:'#d6d6d7',
    black:{
        strong:'#000',
        soft:"rgb(172, 168, 168)",
        light:'rgb(193 193 193 / 46%)',
        darkmode1:' #121212',
        darkmode2: '#00001B',
        fadingBlack:'#1A1D1F',
        fadeMini:'#1F2326'
    },
    white:{
        fadingWhite:'#FAFAFA',
        white:'#FFFF',
    },
    lightgrey:'#A1A1A1',
    grey:'#4B4B4B',
    textColor:'#6F767E',
    red:"#FC3C12",
    ash:'#4B4B4B',
    blue:'#407BFF'
}