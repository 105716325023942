// src/routes/AppRoutes.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import Page404 from "../../pages/404/404";
import LoggedInUser from "../../pages/account/loggedInUser.account";
import Catergory from "../../pages/Catergory";
import Contact from "../../pages/Contact";
import CreateEvent from "../../pages/CreateEvent/Index";
import EditEvent from "../../pages/editEvent";
import EventDetails2 from "../../pages/EventDetails/components/index3";
import Home from "../../pages/Home/Index";
import Login from "../../pages/Login-SignUp/Login";
import SignUp from "../../pages/Login-SignUp/SignUp";
import Settings from "../../pages/Settings/settings";
import { getUserInfo } from "../../state management/Redux/slices/user_slice";
import { TokenChecker } from "../../utilities/tokenChecker";
import Layout3 from "../Layout/Layout3.0";
import ProtectedRoute from "./protectedRoutes";
import Notifications from "../../pages/Notifications";
import ForgotPassword from "../../pages/ForgotPassword/forgotpassword";
import ResestPassword from "../../pages/ForgotPassword/resetPassword";
import OTP from "../../pages/ForgotPassword/otp";
import Search_Page from "../../pages/search";
import QR_SCANNER from "../../pages/qr-scanner";
import TicketPage from "../../pages/tickets/ticket_page";
import Organizers from "../../pages/organizers";
import PrivacyPolicy from "../../pages/privacy-policy";
import NavigationListener from "../../utilities/navigation-listener";
import AuthRoute from "./authRoutes";

const AppRoutes = () => {
  const email = localStorage.getItem("email");
  const isToken = TokenChecker();
  const password = localStorage.getItem("password");
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );

  useEffect(() => {
    if (isToken && pathname !== "/login" && pathname !== "signup") {
      dispatch(getUserInfo({ token }));
    }
  }, [email, password, dispatch, pathname, isToken, token]);

  const excludePaths = [
    "/login",
    "/signup",
    "/forgot-password",
    "/forgot-password/otp",
    "/forgot-password/reset-password",
  ];

  return (
    <>
      <NavigationListener excludePaths={excludePaths} />{" "}
      {/* Add NavigationListener */}
      <Routes>
        <Route
          path="/"
          element={
            <Layout3 sidebar>
              <Home />
            </Layout3>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout3>
              <Contact />
            </Layout3>
          }
        />
        <Route
          path="/event-details/:eventId/:eventName"
          element={
            <Layout3>
              <EventDetails2 />
            </Layout3>
          }
        />
        <Route
          path="/category/:slug"
          element={
            <Layout3 sidebar>
              <Catergory />
            </Layout3>
          }
        />
        <Route
          path="*"
          element={
            <Layout3>
              <Page404 />
            </Layout3>
          }
        />
        <Route
          path="/signup"
          element={
            <AuthRoute>
              <SignUp />
            </AuthRoute>
          }
        />
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout3>
              <PrivacyPolicy />
            </Layout3>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />
        <Route
          path="/forgot-password/otp"
          element={
            <AuthRoute>
              <OTP />
            </AuthRoute>
          }
        />
        <Route
          path="/forgot-password/reset-password"
          element={
            <AuthRoute>
              <ResestPassword />
            </AuthRoute>
          }
        />
        <Route
          path="/search"
          element={
            <Layout3>
              <Search_Page />
            </Layout3>
          }
        />
        <Route
          path="/organizers"
          element={
            <Layout3>
              <Organizers />
            </Layout3>
          }
        />
        <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <Layout3 sidebar>
                <LoggedInUser />
              </Layout3>
            </ProtectedRoute>
          }
        />
        <Route
          path="/create"
          element={
            <ProtectedRoute>
              <Layout3 sidebar>
                <CreateEvent />
              </Layout3>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Layout3 sidebar>
                <Settings />
              </Layout3>
            </ProtectedRoute>
          }
        />
        <Route
          path="/manage/edit/:eventId/:eventName"
          element={
            <ProtectedRoute>
              <Layout3 sidebar>
                <EditEvent />
              </Layout3>
            </ProtectedRoute>
          }
        />
        <Route
          path="/event-details/:eventId/:eventName/tickets"
          element={
            <ProtectedRoute>
              <Layout3>
                <TicketPage />
              </Layout3>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/manage/edit/:eventId/:eventName/qr-scanner/:authkey"}
          element={<QR_SCANNER />}
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
