// import React from "react";
// import styled from "styled-components";
// import { DropDownIcon } from "../../../components/primaryComponents/icons/icons";
// import { Text } from "../../../components/primaryComponents/typography/typography.component";
// import Logo from "../../../components/secondaryComponents/logo/logo.component";
// import UserImage from "../../../components/secondaryComponents/userProfileImage/UserImage";
// import { colorTheme } from "../../../infrastructure/Theme/color";
// import { fontSizes } from "../../../infrastructure/Theme/fontSize";

import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { HeaderText } from "../../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";

// const HeaderStyle = styled.header`
//   position: fixed;
//   top: 0px;
//   left: 0px;
//   right: 0px;
//   z-index: 5 !important;
//   height: 59px;
//   background-color:white;
//   border-bottom: 1px solid #eeedf2;
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
// `;
// const LogoSection = styled.div`
//   flex: 1;
//   justify-content: flex-start;
//   align-items: center;
// `;

// const UserSection = styled.div`
//   display: flex;
//   background-color: ${colorTheme.sideBarBg};
//   padding: 0px 6px 0px 6px;
//   margin-right: 23px;
//   border-radius: 25px;
//   justify-content: flex-start;
//   align-items: center;
//   gap: 50px;
//   @media screen and (max-width: 680px) {
//     gap: 13px;
//   }
// `;
// const Header = () => {
//   return (
//     <HeaderStyle>
//       <LogoSection>
//         <Logo />
//       </LogoSection>
//       <UserSection>
//         <div style={styles.div}>
//           <UserImage
//             resWidth={"25px"}
//             resborderRadius={"25px"}
//             resHeight={"25px"}
//             width={"40px"}
//             height={"40px"}
//           />
//           <Text
//             resFontSize={"10px"}
//             fontWeight={"800"}
//             fontSize={fontSizes.mobile_smallest}
//           >
//             Angel Mba
//           </Text>
//         </div>
//         <DropDownIcon size={fontSizes.mobile_body_text} />
//       </UserSection>
//     </HeaderStyle>
//   );
// };

// export default Header;

// const styles = {
//   div: {
//     display: "flex",
//     alignItems: "center",
//     gap: "7px",
//   },
// };




const Head = ({title,fontSize,resFontSize,padding,width,smallScreenFontSize,color}) => {
  return ( 
    <>
    <FlexibleDiv justifyContent={'center'} padding={padding ? padding : "0px"} >
    <FlexibleDiv justifyContent={'flex-start'} width={width ? width : '95%'}  >
        <HeaderText color={color} margin={"0px"} fontSize={fontSize ? fontSize : fontSizes.desktop_subtitle} fontWeight={"600"} resFontSize={resFontSize ? resFontSize : "20px"} smallScreenFontSize={smallScreenFontSize ? smallScreenFontSize :''} >{title}</HeaderText>
    </FlexibleDiv>
    </FlexibleDiv>
    </>
   );
}
 
export default Head;