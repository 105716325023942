const StepVerification = ({noOfSlides, slides,Component }) => {
  if (typeof Component === "object") {
    for (let index = 0; index < Component.length || noOfSlides; index++) {
      switch (slides) {
        case slides:
          return Component[slides];

        default:
          return Component[0];
      }
    }
  } else {
    console.error("ERROR: child must be an array of objects");
  }
};

export default StepVerification;


export const Prev=(slides,setSlides)=>{
  if(slides <=0){
    return slides
   }

   else{
    setSlides(slides-1)
   }
}

export const Next =(slides,setSlides,noOfSlides)=>{
  if(slides < noOfSlides){
    setSlides(prevsate=>prevsate+1)
  }
  else if(slides > noOfSlides){
      return slides;
  }
  else{
      return slides;
  }
}