import { Helmet } from "react-helmet";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import Head from "./head.component";
import { SettingsWrapper } from "./styles";
import TabView from "./tabView.component";

const Settings = () => {
  return (
    <>
      <FlexibleDiv direction={"column"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Account Settings</title>
      </Helmet>
        <SettingsWrapper>
          <Head />
          <TabView />
        </SettingsWrapper>
        <Footer/>
      </FlexibleDiv>
    </>
  );
};

export default Settings;
