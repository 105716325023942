import { DashboardIcon, UserIcon,CompassIcon } from "../../primaryComponents/icons/icons";

export const MenuLinks = [
    {
        name:'Home',
        path:'/',
        icon:<DashboardIcon size={'1.13rem'}/>
    },
    {
        name:'Profile',
        path:'/my-profile',
        icon:<UserIcon size={'1.13rem'}/>
    },
    {
        name:"Explore",
        path:"/search",
        icon:<CompassIcon size={'1.13rem'}/>
    }
    // {
    //     name:"Notifications",
    //     path:"/notifications",
    //     icon:<BellIcon size={'1.13rem'}/>
    // },
    // {
    //     name:"Tickets",
    //     path:"/account/my-profile" ,//Change this path to account path later,
    //     icon:<TickectIcon size={'1.13rem'}/>,
    //     subNav:[
    //         {
    //             subnavlink:'Upcoming',
    //             path:'/account/my-profile'
    //         },
    //         {
    //             subnavlink:'Past Events',
    //             path:'/account/my-profile'
    //         },
    //         {
    //             subnavlink:'Free Events',
    //             path:'/account/my-profile'
    //         },
    //     ]
    // },


    // {
    //     name:"Organizers",
    //     path:'/home', //change to organizers you follow,
    //     icon:<WandIcon size={'1.13rem'}  />,
    //     subNav:[
    //         {
    //            organizerName:'Daniel James',
    //            path:'/account/organizer'
    //         },
    //         {
    //            organizerName:'Arthur Pendragon',
    //            path:'/account/organizer'
    //         },
    //         {
    //            organizerName:'John Wick',
    //            path:'/account/organizer'
    //         },

    //     ]
    // },

    // {
    //     name:"Settings",
    //     path:'/settings', 
    //     icon:<SettingsIcon size={'1.13rem'}/>
    // }


]