import React from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colorTheme } from '../../../infrastructure/Theme/color';
import Button from '../../primaryComponents/button/button.component';
import { SearchIcon } from '../../primaryComponents/icons/icons';
import Input from '../../primaryComponents/input/input.component';
import { ButtonContainer } from './searchstyle';
const SearchContainer2=styled.form`
width: ${({width})=>width? width :'458px'};
background-color: ${({bg})=>bg ? bg : '#f6f6f6'};
display: flex;
align-items: center;
height: 100%;
padding: 0px;
border-radius: 5px;
`
const Search2 = ({color,width,SubmitHandler,value,onChange}) => {
    // const navigation = useNavigate();
    const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
    return ( 
        <>
        
            <SearchContainer2 width={width} onSubmit={(e)=>SubmitHandler(e)}  bg={darkMode ? '#2A2F33' :''} >
            <ButtonContainer width={"10%"}  >
            <Button radius={"5px"} height={'100%'} background={'transparent'} width={'100%'} padding={".7rem"} border={'none'} children={<SearchIcon color={colorTheme.primary_color} size={12}  />}  hoverBorder={'none'} hoverColor={'none'} hoverBackground={"none"} />
            </ButtonContainer>
            <Input value={value} onChange={onChange} width={'90%'}  color={color} padding={"12px"} bg={"transparent"} placeHolder={"Search for events, organizers and more..."} placeholderSize={'13px'} fontSize={"13.9px"}  />
            </SearchContainer2>
        </>
     );
}
 
export default Search2;