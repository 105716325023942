import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/primaryComponents/button/button.component";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../components/primaryComponents/input/input.component";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { Form, InputContainer, Label } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import {
  loginEmailHandler,
  loginPasswordHandler,
  rememberMeHandler,
} from "../../../state management/Redux/slices/auth_slice/logIn";
import { useFormik } from "formik";
import { loginUser } from "../../../state management/Redux/slices/user_slice";
import Loader from "../../../components/secondaryComponents/loader/loader.component";
import { loginSchema } from "../../../infrastructure/schemas/login_schema";
import {
  EyeCloseIcon,
  EyeOpenIcon,
  GoogleIcon,
} from "../../../components/primaryComponents/icons/icons";
import { getGoogleUrl } from "../../../services/google_services";
const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(
    ({ persistedReducer }) => persistedReducer.user?.loginLoading
  );
  const email = useSelector((state) => state.login.email);
  const password = useSelector((state) => state.login.password);
  const rememberMe = useSelector((state) => state.login.rememberMe);
  const [passwordSecurity, setPasswordSecurity] = useState(true);
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );

  const onSubmit = (values) => {
    dispatch(
      loginUser({
        email: values?.email,
        password: values?.password,
        navigate: navigate,
      })
    );
  };

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      email: email,
      password: password,
    },
    validationSchema: loginSchema,
    onSubmit,
  });
  const [url, setUrl] = useState("");
  const getUrlHandler = async () => {
    const url = await getGoogleUrl();
    setUrl(url);
  };
  useEffect(() => {
    getUrlHandler();
  }, []);
  return (
    <>
      <Form margin={"3px 0px 0px 0px"}>
        {/* Name */}
        <InputContainer margin={"12px 0px 12px 0px"}>
          <FlexibleDiv
            justifyContent={"space-between"}
            margin={"0px 0px 12px 0px "}
            gap={"12px"}
          >
            <Label
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              labelSize={"14px"}
            >
              Email
            </Label>
            {errors?.email && touched?.email && (
              <Text
                margin={"0px"}
                resFontSize={"12px"}
                color={colorTheme.red}
                fontSize={fontSizes.mobile_details}
              >
                {errors?.email}
              </Text>
            )}
          </FlexibleDiv>

          <Input
            value={values.email}
            type={"email"}
            onChange={({ target }) => {
              dispatch(loginEmailHandler(target.value));
              handleChange("email")(target.value);
            }}
            // required
            placeHolder={"davidgreat123@gmail.com"}
            placeholderSize={"13px"}
            padding={"13px"}
            border={`1.4px solid ${colorTheme.black.light}`}
            radius={"5px"}
            width={"100%"}
            fontSize={"13px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          />
        </InputContainer>
        {/* /Password */}
        <InputContainer margin={"12px 0px 12px 0px"}>
          <FlexibleDiv
            justifyContent={"space-between"}
            margin={"0px 0px 12px 0px "}
            gap={12}
          >
            <Label
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              labelSize={"14px"}
            >
              Password
            </Label>
            {errors.password && touched.password && (
              <Text
                margin={"0px"}
                color={colorTheme.red}
                fontSize={fontSizes.mobile_details}
                resFontSize={"12px"}
              >
                {errors?.password}
              </Text>
            )}
          </FlexibleDiv>
          {/* <Input
            value={values?.password}
            placeholderSize={"13px"}
            onChange={({ target }) => {
              dispatch(loginPasswordHandler(target.value));
              handleChange("password")(target.value);
            }}
            required
            type={"password"}
            placeHolder={"5+ Characters"}
            fontSize={"13px"}
            padding={"13px"}
            border={`1.4px solid ${colorTheme.black.light}`}
            radius={"5px"}
            width={"100%"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          /> */}
          <FlexibleDiv
            wrap={"nowrap"}
            justifyContent={"space-between"}
            padding={"13px"}
            radius={"5px"}
            border={`1.4px solid ${colorTheme.black.light}`}
          >
            <Input
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              width={"90%"}
              value={values?.password}
              placeholderSize={"13px"}
              onChange={({ target }) => {
                dispatch(loginPasswordHandler(target.value));
                handleChange("password")(target.value);
              }}
              required
              type={passwordSecurity ? "password" : "text"}
              placeHolder={"5+ Characters"}
              fontSize={"13px"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
            />
            <FlexibleDiv width={"10%"}>
              {passwordSecurity ? (
                <EyeCloseIcon
                  onClick={() => {
                    setPasswordSecurity(false);
                  }}
                  cursor={"pointer"}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                />
              ) : (
                <EyeOpenIcon
                  onClick={() => {
                    setPasswordSecurity(true);
                  }}
                  cursor={"pointer"}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                />
              )}
            </FlexibleDiv>
          </FlexibleDiv>
        </InputContainer>

        {/* Remember me and forgotten password */}
        <FlexibleDiv>
          <FlexibleDiv width={"50%"} justifyContent={"flex-start"} gap={"12px"}>
            <Input
              checked={rememberMe}
              onClick={() => {
                dispatch(rememberMeHandler(!rememberMe));
              }}
              type={"Checkbox"}
              readonly
            />
            <Text
              fontWeight={"600"}
              fontSize={fontSizes.mobile_smallest}
              cursor={"pointer"}
              color={
                darkMode ? colorTheme.white.white : colorTheme.black.strong
              }
              resFontSize={"12px"}
            >
              Remember me
            </Text>
          </FlexibleDiv>
          <FlexibleDiv width={"50%"} justifyContent={"flex-end"}>
            <Link to={"/forgot-password"}>
              <Text
                fontWeight={"600"}
                fontSize={fontSizes.mobile_smallest}
                cursor={"pointer"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
                resFontSize={"12px"}
              >
                Forgot Password ?
              </Text>
            </Link>
          </FlexibleDiv>
        </FlexibleDiv>

        {/* Button */}
        <FlexibleDiv
          justifyContent={"flex-start"}
          alignItems={"center"}
          margin={"25px 0px"}
        >
          <Button
            disabled={loading}
            width={"100%"}
            padding={"12px"}
            radius={"5px"}
            background={
              loading ? colorTheme.white.white : colorTheme.primary_color
            }
            hoverBorder={`0.9px solid ${colorTheme.primary_color}`}
            hoverBackground={"white"}
            hoverColor={colorTheme.primary_color}
            children={loading ? <Loader width={32} /> : "Login"}
            type={"submit"}
            fontSize={"14px"}
            onClick={handleSubmit}
          />
        </FlexibleDiv>

        {/* Sign in with Google */}
        <Link to={url} style={{ width: "100%" }}>
          <FlexibleDiv padding={"25px 0px"}>
            <FlexibleDiv
              padding={"10px 0px"}
              radius={"9px"}
              border={`0.1px solid ${colorTheme.black.light}`}
            >
              <Text
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "12px",
                }}
                color={colorTheme.grey}
                fontWeight={"800"}
                margin={"0px"}
                fontSize={"14px"}
                resFontSize={"12px"}
              >
                <GoogleIcon size="22px" /> Sign in with Google
              </Text>
            </FlexibleDiv>
          </FlexibleDiv>
        </Link>

        {/* Dont Own An Account */}
        <FlexibleDiv
          justifyContent={"center"}
          alignItems={"center"}
          // display={'none'}
          margin={"0px 0px 0px 0px"}
          breakPoint={"1105px"}
          breakPointDisplay={"flex"}
        >
          <Link to={"/signup"}>
            <Text
              margin={"0px"}
              color={colorTheme.textColor}
              fontSize={fontSizes.mobile_details}
              cursor={"pointer"}
              resFontSize={"12px"}
            >
              Don't have an account ?{" "}
              <span style={{ color: colorTheme.blue, fontWeight: "600" }}>
                Sign Up
              </span>
            </Text>
          </Link>
        </FlexibleDiv>
      </Form>
    </>
  );
};

export default LoginForm;
