import { useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { fontSizes } from "../../infrastructure/Theme/fontSize";

const Head = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv direction={"column"} lineHeight={"2.8rem"}>
        <FlexibleDiv
          justifyContent={"flex-start"}
          breakPoint={"670px"}
          breakPointPadding={"0px 20px"}
        >
          <HeaderText
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
            margin={"0px"}
            fontWeight={"600"}
            fontSize={fontSizes.mobile_header}
            resFontSize={fontSizes.mobile_subtitle}
          >
            Settings
          </HeaderText>
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"}>
          <Text
            margin={"0px"}
            fontWeight={"500"}
            color={"#A1A1A1"}
            fontSize={"14.5px"}
            resFontSize={"13px"}
          >
            Manage your events and preferences here.
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Head;
