import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { FingerPrintIcon } from "../../components/primaryComponents/icons/icons";
import { colorTheme } from "../../infrastructure/Theme/color";
import Logo from "../../components/secondaryComponents/logo/logo.component";
import {
  HeaderText,
  Text
} from "../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import {
  AuthLayout,
  ImageColumn,
  InputFieldColumn,
  Label
} from "../Login-SignUp/styles";
import LoginImage from "../../assets/images/Login.jpg";
import Input from "../../components/primaryComponents/input/input.component";
import Button from "../../components/primaryComponents/button/button.component";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { complete_Forgot_Password_Schema } from "../../infrastructure/schemas/forgotPassword";
import Loader from "../../components/secondaryComponents/loader/loader.component";
import { resetPassword } from "../../state management/Redux/slices/forgot_password_slice";
import { Helmet } from "react-helmet";
const ResestPassword = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const resettingPassword = useSelector(
    ({ forgot_password }) => forgot_password?.resettingPassword
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (value, action) => {
    dispatch(
      resetPassword({
        password: value?.password,
        navigate: navigate,
        action: action
      })
    );
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: ""
    },
    validationSchema: complete_Forgot_Password_Schema,
    onSubmit
  });
  return (
    <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Reset Password</title>
      </Helmet>
      <AuthLayout
        bg={darkMode ? colorTheme.black.darkmode1 : colorTheme.white.white}
      >
        <ImageColumn image={LoginImage}>
          <FlexibleDiv direction={"column"} padding={"0rem 0rem 5rem 0rem"}>
            <FlexibleDiv justifyContent={"flex-start"} padding={"0px 22px"}>
              <HeaderText
                lineHeight={"2.3rem"}
                fontSize={fontSizes.desktop_subtitle}
                color={"white"}
              >
                Discover awesome events
                <br /> around you
              </HeaderText>
            </FlexibleDiv>
          </FlexibleDiv>
        </ImageColumn>
        <InputFieldColumn style={{ position: "relative" }}>
          <FlexibleDiv
            position={"absolute"}
            top={"0px"}
            left={"0px"}
            right={"0px"}
            justifyContent={"space-evenly"}
            breakPoint={"780px"}
            breakPointJustifyContent={"space-between"}
            breakPointMargin={"auto"}
            breakPointwidth={"90%"}
            breakPointPadding={"43px 0px"}
            padding={"18px 0px"}
          >
            <Logo darkMode={darkMode} />
          </FlexibleDiv>
          <FlexibleDiv
            width={"100%"}
            direction={"column"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            margin={"20px 0px 0px 0px"}
          >
            {/* Title */}
            <FlexibleDiv
              width={"65%"}
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"0px 0px 12px 0px"}
              breakPoint={"680px"}
              breakPointwidth={"90%"}
              breakPointdirection={"column"}
              breakPointAlign={"flex-start"}
            >
              <FlexibleDiv
                margin={"0px 0px 25px 0px"}
                justifyContent={"center"}
                breakPoint={"680px"}
                breakPointJustifyContent={"flex-start"}
              >
                <FlexibleDiv
                  width={"35px"}
                  height={"30px"}
                  border={`1.4px solid ${colorTheme.black.light}`}
                  padding={"8px"}
                  radius={"7px"}
                >
                  <FingerPrintIcon
                    size={23}
                    color={
                      darkMode
                        ? colorTheme.white.white
                        : colorTheme.black.strong
                    }
                  />
                </FlexibleDiv>
              </FlexibleDiv>

              <HeaderText
                margin={"0px"}
                fontSize={fontSizes.mobile_title}
                resFontSize={fontSizes.mobile_title}
                smallScreenFontSize={fontSizes.mobile_title}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              >
                Reset password
              </HeaderText>
              <Text
                margin={"0px"}
                lineHeight={"43px"}
                color={colorTheme.textColor}
                fontSize={"13px"}
                fontWeight={"500"}
                resFontSize={"13px"}
              >
                Set your new password
              </Text>
            </FlexibleDiv>

            <FlexibleDiv direction={"column"}>
              <FlexibleDiv
                direction={"column"}
                width={"70%"}
                breakPoint={"780px"}
                breakPointwidth={"90%"}
                breakPointJustifyContent={"flex-start"}
              >
                <Label
                  style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    padding: "0px",
                    marginBottom: "20px"
                  }}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                >
                  New Password
                  {errors?.password && touched?.password && (
                    <span
                      style={{
                        color: colorTheme.red,
                        fontSize: 12,
                        position: "absolute",
                        right: "0px"
                      }}
                    >
                      {errors?.password}
                    </span>
                  )}
                </Label>
                <Input
                  value={values?.password}
                  onChange={(e) => {
                    handleChange("password")(e?.target?.value);
                  }}
                  placeholderSize={"13px"}
                  required
                  type={"password"}
                  placeHolder={"Enter your new password"}
                  fontSize={"13px"}
                  padding={"13px"}
                  border={`1.4px solid ${colorTheme.black.light}`}
                  radius={"5px"}
                  width={"95%"}
                  color={
                    darkMode ? colorTheme.white.white : colorTheme.black.strong
                  }
                />
                <Button
                  disabled={resettingPassword}
                  background={
                    resettingPassword
                      ? colorTheme.white.white
                      : colorTheme.primary_color
                  }
                  //   children={
                  //     resettingPassword ? (
                  //       <Loader width={"30px"} height={"30px"} />
                  //     ) : (
                  //       "Reset Password"
                  //     )
                  //   }
                  hoverBackground={
                    resettingPassword
                      ? colorTheme.white.white
                      : colorTheme.primary_color
                  }
                  hoverBorder={"none"}
                  hoverColor={"#FFF"}
                  type={"submit"}
                  onClick={handleSubmit}
                  radius={"5px"}
                  style={{ marginTop: "20px" }}
                  padding={"14px"}
                  width={"100%"}
                >
                  {resettingPassword ? (
                    <Loader width={"30px"} height={"30px"} />
                  ) : (
                    "Reset Password"
                  )}
                </Button>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </InputFieldColumn>
      </AuthLayout>
    </>
  );
};

export default ResestPassword;
