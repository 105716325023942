import { toast } from "react-toastify";
import ClientRequest from "../../../../api/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CONSTANTS } from "../../../../utilities/constants";
import { editTicketHandler } from "./edit_details_slice";

export const editTicket = createAsyncThunk(
  "user/event/edit-ticket",
  async (
    {
      ticketId,
      // editTicketHandler,
      name,
      price,
      capacity,
      cover_image,
      description,
      setIsOpen,
      formAction,
      dispatch
    },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();

      if (cover_image !== null) {
        formData.append("cover_image", cover_image);
      }
      formData.append("name", name);
      formData.append("price", price);
      formData.append("capacity", capacity);
      formData.append("description", description);
      const token = localStorage.getItem("token");
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Credentials": true,
          Authorization: `Bearer ${token}`
        }
      };
      const response = await axios.post(
        CONSTANTS.baseURL + `api/event/tickets/edit/${ticketId}`,
        formData,
        config
      );
      const updatedTicketInfo = {
        name: name,
        price: price,
        capacity: capacity,
        cover_image: cover_image,
        description: description
      };

      if (response.status === 200) {
        window.location.reload();
        dispatch(
          editTicketHandler({ id: ticketId, updatedTicket: updatedTicketInfo })
        );
        toast.success(response?.data?.success);
        setIsOpen(false);
        formAction?.resetForm();
      }
      return response.data;
    } catch (error) {
      const { response } = error;
      console.error("Edit ticket error:", error);
      console.error("Edit ticket error:", response);
      toast.error(response.data?.error);
      return rejectWithValue(response?.data?.error);
    }
  }
);
