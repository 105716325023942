import { useEffect, useState } from "react";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../infrastructure/Theme/color";
import "./style.css";
import {
  TicketValidation,
  clearTicketValidationState, // Consider using this to clear state
} from "../../state management/Redux/slices/payment_slice/ticketValidation_slice";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Modal from "../../components/primaryComponents/modal/modal";
import Loader from "../../components/secondaryComponents/loader/loader.component";
import Button from "../../components/primaryComponents/button/button.component";
import Ticket from "../../components/secondaryComponents/Ticket/ticket.component";
import { Scanner } from "@yudiel/react-qr-scanner";

const QR_SCANNER = () => {
  const dispatch = useDispatch();
  const { authkey } = useParams();
  const {
    darkmode,
    validating_ticket_success,
    validatedTicket,
    validating_ticket_error,
    errorMessage,
  } = useSelector((state) => ({
    darkmode: state.persistedReducer.theme.darkmode,
    validating_ticket_success:
      state.ticket_validation.validating_ticket_success,
    validatedTicket: state.ticket_validation.validatedTicket,
    validating_ticket_error: state.ticket_validation.validating_ticket_error,
    errorMessage: state.ticket_validation.errorMessage,
  }));

  const scanSuccessful = (result) => {
    if (result?.rawValue) {
      dispatch(
        TicketValidation({ ticket_code: result.rawValue, auth_key: authkey })
      );
    }
  };

  const reScan = () => {
    dispatch(clearTicketValidationState()); // Clear state instead of reloading
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Code Scanner</title>
      </Helmet>
      <FlexibleDiv
        height="100vh"
        bg={darkmode ? colorTheme.black.darkmode1 : colorTheme.white.white}
      >
        {validating_ticket_success && (
          <FlexibleDiv
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text
              fontSize="32px"
              resFontSize="16px"
              color={
                validatedTicket?.success ===
                "Ticket has been validated successfully"
                  ? colorTheme.secondary_color
                  : colorTheme.red
              }
            >
              {validatedTicket?.success}
            </Text>
            {errorMessage && (
              <Text
                fontSize="16px"
                resFontSize="14px"
                color={colorTheme.white.white}
              >
                {errorMessage}
              </Text>
            )}
            {validatedTicket?.event_ticket && (
              <FlexibleDiv
                width="80%"
                margin="auto"
                breakPoint="680px"
                breakPointwidth="350px"
                breakPointMargin="auto"
              >
                <Ticket
                  eventName={validatedTicket?.event_ticket?.event?.title}
                  ticketStatus={validatedTicket?.status}
                  eventLocation={validatedTicket?.event_ticket?.event?.location}
                  eventDate={validatedTicket?.event_ticket?.event?.startDate}
                  eventImage={validatedTicket?.event_ticket?.cover_image}
                  eventOrderNo={validatedTicket?.ticket_code}
                  eventQRCode={validatedTicket?.ticket_code}
                  ticketType={validatedTicket?.event_ticket?.name}
                />
              </FlexibleDiv>
            )}
            <Button
              padding="9px"
              style={{ marginTop: "20px" }}
              onClick={reScan}
            >
              Scan again
            </Button>
          </FlexibleDiv>
        )}

        {validating_ticket_error && (
          <FlexibleDiv
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="32px" resFontSize="16px" color={colorTheme.red}>
              Error
            </Text>
            <Text
              fontSize="16px"
              resFontSize="14px"
              color={colorTheme.white.white}
            >
              {errorMessage}
            </Text>
            <Button
              padding="9px"
              style={{ marginTop: "20px" }}
              onClick={reScan}
            >
              Scan again
            </Button>
          </FlexibleDiv>
        )}

        {!validating_ticket_success && !validating_ticket_error && (
          <>
            <Scanner
              onScan={(result) => scanSuccessful(result?.[0])}
              styles={{
                container: {
                  width: 330,
                  height: 300,
                  position: "relative",
                },
                video: {
                  position: "relative",
                  width: "100%",
                  height: "100%",
                },
              }}
            />
            <TicketValidationLoader />
          </>
        )}
      </FlexibleDiv>
    </>
  );
};

export default QR_SCANNER;

const TicketValidationLoader = () => {
  const {
    validating_ticket,
    validating_ticket_success,
    validating_ticket_error,
  } = useSelector((state) => ({
    validating_ticket: state.ticket_validation.validating_ticket,
    validating_ticket_success:
      state.ticket_validation.validating_ticket_success,
    validating_ticket_error: state.ticket_validation.validating_ticket_error,
  }));

  const [text, setText] = useState("Validating Ticket");

  useEffect(() => {
    if (validating_ticket) {
      setText("Validating Ticket");
    } else if (validating_ticket_success) {
      setText("Ticket Validated Successfully");
    } else if (validating_ticket_error) {
      setText("Ticket Validation Unsuccessful");
    }
  }, [validating_ticket, validating_ticket_success, validating_ticket_error]);

  return (
    <Modal show={validating_ticket}>
      <FlexibleDiv direction="column">
        <Loader width="50px" />
        <Text
          style={{
            textAlign: "center",
          }}
          color={colorTheme.primary_color}
          fontSize="14px"
        >
          {text}
        </Text>
      </FlexibleDiv>
    </Modal>
  );
};
