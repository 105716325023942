

export const TabSwitch = (view,noOfViews,Components) => {
    for (let index = 0; index < Components.length || noOfViews; index++) {
        switch (view) {
          case view:
            return Components[view];
  
          default:
            return Components[0];
        }
      }
};
