import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Modal from "../../../../components/primaryComponents/modal/modal";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import Logo from "../../../../components/secondaryComponents/logo/logo.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { GoogleIcon } from "../../../../components/primaryComponents/icons/icons";
import { useEffect, useState } from "react";
import { getGoogleUrl } from "../../../../services/google_services";
import { Link } from "react-router-dom";

const GoogleSignInModal = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user?.token
  );
  const [url, setUrl] = useState("");
  const getUrlHandler = async () => {
    const url = await getGoogleUrl();
    setUrl(url);
  };
  useEffect(() => {
    getUrlHandler();
  }, []);
  return (
    <>
      <Modal show={!token} showExitBtn={false}>
        <FlexibleDiv
          width={"40%"}
          padding={"40px"}
          direction={"column"}
          bg={
            darkMode ? colorTheme.black.fadeMini : colorTheme.white.fadingWhite
          }
        >
          <FlexibleDiv justifyContent={"center"} alignItems={"center"}>
            <Logo />
          </FlexibleDiv>
          <Link style={{ width: "100%" }} to={url}>
            <FlexibleDiv
              style={{ cursor: "pointer" }}
              justifyContent={"center"}
              margin={"23px 0px 0px 0px"}
              alignItems={"center"}
              gap={"12px"}
              radius={"9px"}
              padding={"10px 0px"}
              border={
                darkMode
                  ? `1px solid ${colorTheme.white.fadingWhite}`
                  : `1px solid ${colorTheme.black.fadeMini}`
              }
            >
              <GoogleIcon />
              <Text
                margin={"0px"}
                fontSize={"14px"}
                fontWeight={"800px"}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
              >
                Sign In with Google to continue
              </Text>
            </FlexibleDiv>
          </Link>
        </FlexibleDiv>
      </Modal>
    </>
  );
};

export default GoogleSignInModal;
