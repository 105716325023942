import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { CoverImageStyle } from "./styles";
const OrganizerCoverImage = ({ RdTopRight, RdTopLeft, img }) => {
  return (
    <>
      <FlexibleDiv>
        <CoverImageStyle
          RdTopLeft={RdTopLeft}
          RdTopRight={RdTopRight}
          image={img}
        />
      </FlexibleDiv>
    </>
  );
};

export default OrganizerCoverImage;
