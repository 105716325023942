const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  previousRoute: "",
  currentRoute: "",
  redirectRoute: "",
};

const RouteSlice = createSlice({
  name: "route-slice",
  initialState,
  reducers: {
    setPreviousRoute(state, action) {
      state.previousRoute = action.payload;
      state.currentRoute = action.payload;
    },
    setRedirectRoute: (state, { payload }) => {
      state.redirectRoute = payload;
    },
    clearRouteState: (state) => {
      state.previousRoute = "";
      state.currentRoute = "";
      state.redirectRoute = "";
    },
  },
});

export const { setPreviousRoute, setRedirectRoute, clearRouteState } =
  RouteSlice.actions;

export default RouteSlice.reducer;
