import styled from "styled-components";

export const TICKET_WRAPPER = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  
  margin: 23px 0px;justify-content: flex-start;
  background:${({bg})=>bg ? bg : '#f7f7f7'};
`;

export const TICKET_IMAGE_SECTION = styled.div`
  width: 25%;
  display: flex;
  height: 200px;
  align-items: center;
  flex: 1;
  @media only screen and (max-width: 680px) {
    width: 30%;
  }
`;

export const TICKET_DETAILS_SECTION = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 23px;
  line-height: 23px;
  @media only screen and (max-width: 680px) {
    width: 45%;
    padding: 10.4px;
  }
`;

export const QR_CODE_SECTION = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 680px) {
    width: 25%;
  }
`;

export const Line = styled.div`
  width: 5%;
  min-height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const TOP_CUT = styled.div`
  padding: 12px;
  position: absolute;
  width: 10%;
  background:${({bg})=>bg ? bg : '#ffff'};
  border-bottom-left-radius: 250px;
  border-bottom-right-radius: 250px;
  margin: auto 0;
  top: 0px;
`;

export const BOTTOM_CUT = styled.div`
  padding: 12px;
  position: absolute;
  width: 10%;
  background:${({bg})=>bg ? bg : '#ffff'};
  border-top-left-radius: 90px;
  border-top-right-radius: 90px;
  bottom: 0%;
`;

export const DashedLine = styled.hr`
  border-width: 0px;
  border-right: 1px solid black;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right-style: dashed;
  width: 88px;
  height: inherit;
  margin: auto;
  border: 1px dashed #a2a2a2;
  transform: rotate(90deg);
  position: absolute;
  @media only screen and (max-width: 680px) {
    width: 70px;
    margin: 0px;
  }
`;
