import { useSelector } from "react-redux"

export const TokenChecker=()=>{
    const token= useSelector(({persistedReducer})=>persistedReducer?.user?.token)
    if (token) {
        return true
    }
    else{
        return false
    }
}