import { useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { MailIcon } from "../../components/primaryComponents/icons/icons";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { CONSTANTS } from "../../utilities/constants";

const Email = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      <FlexibleDiv
        direction={"column"}
        alignItems={"flex-start"}
        margin={"12px 0px 0px 0px"}
      >
        <FlexibleDiv justifyContent={"flex-start"} gap={"12px"}>
          <span>
            <MailIcon color={colorTheme.primary_color} size={22} />
          </span>
          <Text
            fontSize={"16px"}
            resFontSize={"13.5px"}
            color={darkMode ? colorTheme.white.white : "#A1A1A1"}
          >
            Email us at
          </Text>
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"} gap={"12px"}>
          <Text
            margin={"0px"}
            marginLeft={"34px"}
            fontSize={"16px"}
            resFontSize={"12px"}
            color="#A1A1A1"
          >
            {CONSTANTS.emailAddress}
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Email;
