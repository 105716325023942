import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClientRequest from "../../../../api/api";
import { toast } from "react-toastify";

export const TicketValidation = createAsyncThunk(
  "user/event/ticket-validation",
  async ({ ticket_code, auth_key, reader }) => {
    try {
      const response = await ClientRequest.post("api/event/tickets/validate", {
        ticket_code: ticket_code,
        auth_key: auth_key
      });
      if (response.status === 200) {
        // toast.success("Ticket validated");
        // reader.stop();
        return response?.data;
      } else {
        toast.error(response?.data?.error);
        console.log("validation error", response?.data?.error);
      }
    } catch (error) {
      const { response } = error;
      toast.error(response);
      console.log("validation error", response);
    }
  }
);

const initialState = {
  validating_ticket: false,
  validating_ticket_success: false,
  validating_ticket_error: false,
  errorMessage: "",
  error: null,
  validatedTicket: null
};

const TicketValidation_Slice = createSlice({
  name: "ticket_validation_slice",
  initialState,
  reducers: {
    clearTicketValidationState: (state) => {
      state.validating_ticket = false;
      state.validating_ticket_error = false;
      state.validating_ticket_success = false;
      state.validatedTicket = null;
      state.errorMessage = "";
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(TicketValidation.pending, (state) => {
        state.validating_ticket = true;
        state.validating_ticket_error = false;
        state.validating_ticket_success = false;
        state.validatedTicket = null;
      })
      .addCase(TicketValidation.fulfilled, (state, { payload }) => {
        state.validating_ticket = false;
        const errorMessage = payload?.error;
        const ticketObject = payload?.ticket;
        if (
          errorMessage === "Invalid Ticket" ||
          errorMessage === "You are not allowed to access this page!"
        ) {
          state.validating_ticket_error = true;
          state.validating_ticket_success = false;
          state.errorMessage = errorMessage;
        } else {
          state.validating_ticket_success = true;
          state.validating_ticket_error = false;
          state.validatedTicket = ticketObject;
          state.errorMessage = errorMessage;
        }
      })
      .addCase(TicketValidation.rejected, (state, { payload }) => {
        state.validating_ticket = false;
        state.validating_ticket_error = true;
        state.error = payload;
      });
  }
});

export const { clearTicketValidationState } = TicketValidation_Slice.actions;
export default TicketValidation_Slice.reducer;
