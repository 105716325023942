import styled from "styled-components";

export const UserImageStyle = styled.img`
  width: ${({ width }) => (width ? width : "")};
  height: ${({ height }) => (height ? height : "")};
  object-fit: ${({ objectFit }) => (objectFit ? objectFit : "contain")};
  border-radius: 50%;
  background-color: #e6e6e6;

  @media screen and (max-width: 680px) {
    width: ${({ resWidth }) => (resWidth ? resWidth : "")};
    height: ${({ resHeight }) => (resHeight ? resHeight : "")};
    border-radius: ${({ resborderRadius }) =>
      resborderRadius ? resborderRadius : ""};
  }
`;
