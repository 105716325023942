import ClientRequest from "../../../../api/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");
export const get_list_of_organizers_following = createAsyncThunk(
  "user/list-of-organizers-following",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/user/following");
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const { response } = error;
      console.log("organizer's list", response);
      return rejectWithValue(response);
    }
  }
);
const initialsState = {
  loading: false,
  isError: false,
  data: [],
  isSuccess: false,
  error: null
};

const Organizers_Following_Slice = createSlice({
  name: "organizer_following_slice",
  initialState: initialsState,
  extraReducers: (builder) => {
    builder
      .addCase(get_list_of_organizers_following.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(
        get_list_of_organizers_following.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        }
      )
      .addCase(
        get_list_of_organizers_following.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
          state.isError = true;
        }
      );
  }
});

export default Organizers_Following_Slice.reducer;
