import FlexibleDiv from "../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../primaryComponents/image/image";
import bannerImage from "../../assets/images/homeBanner.png";
import styled from "styled-components";
import {
  HeaderText,
  Text
} from "../primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import "./styles.css"
const HomeBanner = () => {
  const BackgroundImage = styled.div`
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.361)),
      url(${bannerImage});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 396px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 680px) {
      height: 260px;
    }
  `;
  return (
    <>
      <BackgroundImage>
        <FlexibleDiv
          width={"100%"}
          alignItems={"baseline"}
          direction={"column"}
          padding={"0px 0px 0px 50px"}
          breakPoint={'780px'}
          breakPointPadding={'0px 0px 0px 40px'}
          breakPointJustifyContent={'flex-start'}
        >
          <HeaderText
            margin={0}
            color={colorTheme.white.white}
            fontSize={fontSizes.desktop_title + 2}
            resFontSize={fontSizes.mobile_subtitle}
          >
            Explore Attend Event Categories
          </HeaderText>
          <Text
          className="home_banner_text"
            style={{ lineHeight: "21.78px" }}
            color={colorTheme.white.fadingWhite}
            fontSize={fontSizes.mobile_body_text}
            resFontSize={fontSizes.mobile_details}
          >
            Attend gives you access to all trending events at your location.{" "}
            <br />
            Explore these popular event categories on Attend. Every event here
            are under the <br />
            <span
              style={{
                color: colorTheme.primary_color,
                textDecorationLine: "underline"
              }}
            >
              Attend License{" "}
            </span>
          </Text>
        </FlexibleDiv>
      </BackgroundImage>
    </>
  );
};

export default HomeBanner;
