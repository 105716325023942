import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { CONSTANTS } from "../../../../utilities/constants";
import ClientRequest from "../../../../api/api";

export const searchEventHandler = createAsyncThunk(
  "user/search",
  async ({ keyword }, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get(`api/event/search?q=${keyword}`);
      return response.data?.events;
    } catch (error) {
      console.log("search error", error);
      return rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  loading: false,
  isError: false,
  search_keyword: "",
  isSuccessful: false,
  error: null,
  search_data: []
};

const Search_Slice = createSlice({
  name: "search_slice",
  initialState,
  reducers: {
    searchkeywordHandler: (state, { payload }) => {
      state.search_keyword = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchEventHandler.pending, (state) => {
        state.loading = true;
        state.isError = false;
        state.error = null;
        state.isSuccessful = false;
      })
      .addCase(searchEventHandler.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccessful = true;
        state.search_data = payload;
      })
      .addCase(searchEventHandler.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccessful = false;
        state.error = payload;
        state.isError = true;
      });
  }
});

export const { searchkeywordHandler } = Search_Slice.actions;

export default Search_Slice.reducer;
