import styled from "styled-components";
import { TextArea } from "../../components/primaryComponents/input/input.component";
import { colorTheme } from "../../infrastructure/Theme/color";


export const SettingsWrapper = styled.div`
width: 97%;
padding:15px 0px;

`

export const LeftSideStyle  = styled.div`
display: flex;
flex: 60%;
flex-direction: column;
@media only screen and (max-width:780px){
    flex:100%
}
`
export const RightSideStyle = styled.div`
display: flex;
flex: 40%;
flex-direction: column;
@media only screen and (max-width:780px){
    flex:100%
}
`

export const Label = styled.label`
font-size: 14px;
font-weight: 500;
color: #a7a7a7;
`

export const AboutTextArea = styled(TextArea) `
border-bottom: 2.3px solid lightgrey;
`

export const InterestsTag = styled.div`
width: auto;
border:1.8px solid #a7a7a7 ;
padding:3px 15px;
border-radius:7px;
font-size:small;
text-align:center;
color: ${({color})=>color ? color : colorTheme.black.strong };
`