import styled from "styled-components";

export const HorizontalLine = styled.hr`
width: ${({width})=>width ? width :''};
height: ${({height})=>height ? height :""};
background-color: ${({bg})=>bg ? bg :""};
border: none;
@media screen and (max-width:780px) {
    width: ${({resWidth})=>resWidth ? resWidth :''};
}
`