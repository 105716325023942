import { useDispatch, useSelector } from "react-redux";
import {
  MenuItemTitle,
  NumberBox,
  SubNav,
  SubNavItem,
} from "../../../infrastructure/Layout/layoutStyles";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { TickectIcon } from "../../primaryComponents/icons/icons";
import { accountTabHandler } from "../../../state management/Redux/slices/userAccountSlice";
import { useNavigate } from "react-router-dom";

const SideBarEventInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const events = useSelector(
    ({ user_events }) => user_events?.user_events?.events
  );
  const userInfo = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo
  );
  const isOrganizer = userInfo?.organizer === 1;

  const no_of_tickets = userInfo?.tickets?.length;
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );

  const Options = [
    {
      name: "My Events",
      link: "/my-profile",
      no: events === undefined ? 0 : events?.length,
      action: "Events",
    },
    {
      name: "My Tickets",
      no: no_of_tickets,
      link: "/my-profile",
      action: "Tickets",
    },
  ];
  return (
    <>
      <div
        style={{
          marginBottom: "50px",
          width: "100%",
          top: "-10px",
          position: "relative",
        }}
      >
        <MenuItemTitle color={darkMode ? colorTheme.white.white : ""}>
          <span>{<TickectIcon />}</span>Tickets
        </MenuItemTitle>
        <SubNav>
          {isOrganizer
            ? Options.map(({ name, action, link, no }) => (
                <SubNavItem
                  key={name}
                  onClick={() => {
                    navigate(link);
                    dispatch(accountTabHandler(action));
                  }}
                  justifyContent={"space-between"}
                  color={darkMode ? colorTheme.white.white : ""}
                  hoverBg={darkMode ? colorTheme.black.fadeMini : ""}
                >
                  {name}
                  {<NumberBox>{no}</NumberBox>}
                </SubNavItem>
              ))
            : Options.filter(({ name }) => name !== "My Events").map(
                ({ name, no, action, link }) => (
                  <SubNavItem
                    onClick={() => {
                      navigate(link);
                      dispatch(accountTabHandler(action));
                    }}
                    justifyContent={"space-between"}
                    color={darkMode ? colorTheme.white.white : ""}
                    hoverBg={darkMode ? colorTheme.black.fadeMini : ""}
                  >
                    {name}
                    {<NumberBox>{no}</NumberBox>}
                  </SubNavItem>
                )
              )}
        </SubNav>
      </div>
    </>
  );
};

export default SideBarEventInfo;
