import React, { useState } from "react";
import StepVerification from "../../../components/secondaryComponents/stepVerification/stepVerification";
import { Form } from "../styles";
import Indicator from "./indicator";
import Step1 from "./step1";
import Step2 from "./step2";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { signUpSchema } from "../../../infrastructure/schemas/sign_up_schema";
import { FormProvider } from "../../../state management/context/formContext";
import { registerUser } from "../../../state management/Redux/slices/user_slice";
import { useNavigate } from "react-router-dom";
const AuthForm = () => {
  const [slides, setSlides] = useState(0);
  const [finishedStep2, setFinishedStep2] = useState(false);
  const [noOfSlides] = useState(2);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Components = [
    <Step1
      setFinishedStep2={setFinishedStep2}
      noOfSlides={noOfSlides}
      slides={slides}
      setSlides={setSlides}
    />,
    <Step2
      setFinishedStep2={setFinishedStep2}
      noOfSlides={noOfSlides}
      slides={slides}
      setSlides={setSlides}
    />,
  ];

  const onSubmit = (values, action) => {
    if (!finishedStep2) {
    } else {
      dispatch(
        registerUser({
          email: values?.email,
          password: values?.password,
          firstName: values?.firstName,
          lastName: values?.lastName,
          gender: values?.gender,
          phoneNumber: values?.phoneNumber,
          username: values?.username,
          navigate: navigate,
        })
      );
      // action?.resetForm()
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      phoneNumber: "",
      lastName: "",
      firstName: "",
      username: "",
      gender: "",
      agreeTerms: false,
      readTerms: false,
    },
    validationSchema: signUpSchema,
    onSubmit: onSubmit,
  });
  return (
    <>
      <FormProvider formik={formik}>
        <Form margin={"3px 0px 0px 0px"}>
          <Indicator slides={slides} setSlides={setSlides} />
          <StepVerification
            noOfSlides={noOfSlides}
            slides={slides}
            Component={Components}
          />
        </Form>
      </FormProvider>
    </>
  );
};

export default AuthForm;
