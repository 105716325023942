import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import TableComponent from "../table/table.component";
import { TransactionHistoryData } from "./transactionHistory";

const Options = [
  {
    option: "Amount"
  },
  {
    option: "User"
  },
  {
    option: "Status"
  },
  {
    option: "Date"
  },
  {
    option: "Ticket Order"
  }
];
const EventTransaction = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const styles = {
    textAlign: "justify",
    //  color:`${darkMode ? colorTheme.white.white : colorTheme.black.strong} !important`
    color: "white"
  };
  const attendees = useSelector(
    ({ event_details }) => event_details?.data?.event?.attendees
  );
  return (
    <>
      <FlexibleDiv display={"block"} margin={"20px 0px"}>
        <FlexibleDiv
          margin={"10px 0px"}
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"39.36px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"26px"}
            resFontSize={"18px"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            Transactions
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"15px"}
            resFontSize={"13.8px"}
            color={colorTheme.textColor}
          >
            See transactions for this event
          </Text>
        </FlexibleDiv>

        <TableComponent
          styles={styles}
          options={Options}
          components={attendees}
        />
      </FlexibleDiv>
    </>
  );
};

export default EventTransaction;
