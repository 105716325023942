import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { CalendarIcon } from "../../primaryComponents/icons/icons";
import {
  HeaderText,
  Text,
} from "../../primaryComponents/typography/typography.component";
import { dateDateFormatter } from "../../../utilities/functions/formatDate";
import Skeleton from "react-loading-skeleton";

import React, { useEffect, useState } from "react";
import "./style.css";
import Modal from "../../primaryComponents/modal/modal";
import { DateTimePicker } from "@atlaskit/datetime-picker";
import {
  endDateHandler,
  startDateHandler,
} from "../../../state management/Redux/slices/editEvent/edit_details_slice";
const EditEventSchedule = React.memo(() => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const eventStartDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.startDate
  );
  const eventEndDate = useSelector(
    ({ event_details }) => event_details?.data?.event?.endDate
  );

  const { first, isRecurring, second } = dateDateFormatter(
    eventStartDate,
    eventEndDate
  );
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const [splitFirstDate, setSplitFirstDate] = useState(
    first?.formattedDate?.split(",")[0]
  );
  const [splitSecondDate, setSplitSecondDate] = useState(
    second?.formattedDate?.split(",")[0]
  );
  const [firstDate, setFirstDate] = useState(first);
  const editStartDate = useSelector(({ edit_event }) => edit_event?.startDate);
  const editEndDate = useSelector(({ edit_event }) => edit_event?.endDate);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  return (
    <>
      <FlexibleDiv margin={"40px 0px"}>
        <FlexibleDiv
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"14.5px"}
            color={titleColor}
          >
            Schedule
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"15px"}
            resFontSize={"13.8px"}
            color={colorTheme.textColor}
          >
            This is the date and time of this event.
          </Text>
        </FlexibleDiv>

        <FlexibleDiv justifyContent={"flex-start"}>
          {loading ? (
            <Skeleton width={600} height={28} />
          ) : (
            <FlexibleDiv
              width={"850px"}
              shadow={"0px 2px 7px rgba(0, 0, 0, 0.1)"}
              direction={"column"}
              padding={"10px"}
              breakPoint={"680px"}
              breakPointwidth={"100%"}
            >
              <FlexibleDiv>
                <FlexibleDiv
                  gap={"12px"}
                  width={"60%"}
                  justifyContent={"flex-start"}
                  breakPoint={"680px"}
                  breakPointwidth={"100%"}
                >
                  <CalendarIcon color={titleColor} />
                  <Text
                    fontSize={"15px"}
                    fontWeight={"600"}
                    resFontSize={"14px"}
                    color={titleColor}
                  >
                    {firstDate.dayOfWeek}, {firstDate.formattedDate} at{" "}
                    {firstDate?.formattedTime} GMT +5{" "}
                  </Text>
                </FlexibleDiv>
                <FlexibleDiv
                  position={"relative"}
                  width={"40%"}
                  justifyContent={"flex-end"}
                  breakPoint={"680px"}
                  breakPointwidth={"100%"}
                  breakPointJustifyContent={"flex-start"}
                >
                  <Text
                    cursor={"pointer"}
                    onClick={() => {
                      setShow(true);
                    }}
                    fontSize={"14px"}
                    resFontSize={"13.8px"}
                    fontWeight={"500"}
                    color={colorTheme.primary_color}
                  >
                    Change date and time
                  </Text>
                </FlexibleDiv>
              </FlexibleDiv>

              {isRecurring ? (
                <FlexibleDiv justifyContent={"flex-start"}>
                  <Text
                    fontSize={"14px"}
                    resFontSize={"13.8px"}
                    color={titleColor}
                  >
                    <span style={{ fontWeight: "600" }}>Recurring event:</span>{" "}
                    From {splitFirstDate} to {splitSecondDate} on{" "}
                    {first?.dayOfWeek} at {first?.formattedTime} GMT +5:00{" "}
                  </Text>
                </FlexibleDiv>
              ) : null}
              {/* <FlexibleDiv justifyContent={"flex-start"}>
                <Text
                  cursor={"pointer"}
                  fontSize={"14px"}
                  resFontSize={"13.8px"}
                  color={colorTheme.primary_color}
                >
                  Customize
                </Text>
              </FlexibleDiv> */}
              <Modal show={show} setShowModal={setShow} showExitBtn>
                <FlexibleDiv
                  direction={"column"}
                  width={"50%"}
                  bg={
                    darkMode
                      ? colorTheme.black.fadingBlack
                      : colorTheme.white.white
                  }
                  padding={"23px"}
                >
                  <FlexibleDiv direction={"column"}>
                    <Text
                      fontSize="14px"
                      color={
                        darkMode
                          ? colorTheme.white.white
                          : colorTheme.black.strong
                      }
                    >
                      Start Date
                    </Text>
                    <DateTimePicker
                      timeIsEditable
                      onChange={(e) => {
                        dispatch(startDateHandler(e));
                        const { first, second } = dateDateFormatter(
                          e,
                          editEndDate
                        );
                        setFirstDate(first);
                        setSplitFirstDate(first?.formattedDate?.split(",")[0]);
                        setSplitSecondDate(
                          second?.formattedDate?.split(",")[0]
                        );
                      }}
                      value={editStartDate}
                    />
                  </FlexibleDiv>
                  <FlexibleDiv direction={"column"}>
                    <Text
                      fontSize="14px"
                      color={
                        darkMode
                          ? colorTheme.white.white
                          : colorTheme.black.strong
                      }
                    >
                      End Date
                    </Text>
                    <DateTimePicker
                      timeIsEditable
                      onChange={(e) => {
                        endDateHandler(e);
                        const { first, second } = dateDateFormatter(
                          editStartDate,
                          e
                        );
                        setFirstDate(first);
                        setSplitFirstDate(first?.formattedDate?.split(",")[0]);
                        setSplitSecondDate(
                          second?.formattedDate?.split(",")[0]
                        );
                      }}
                      value={editEndDate}
                    />
                  </FlexibleDiv>
                </FlexibleDiv>
              </Modal>
            </FlexibleDiv>
          )}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
});

export default EditEventSchedule;
