import React, { useEffect } from "react";
import Logo from "../../components/secondaryComponents/logo/logo.component";
import { AuthLayout, ImageColumn, InputFieldColumn } from "./styles";
import LoginImage from "../../assets/images/Party.jpg";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { HeaderText } from "../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../infrastructure/Theme/fontSize";
import AuthForm from "./components/signupAuth";
import { colorTheme } from "../../infrastructure/Theme/color";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
const SignUp = () => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );

  return (
    <AuthLayout
      bg={darkMode ? colorTheme.black.darkmode1 : colorTheme.white.white}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Sign Up</title>
      </Helmet>
      <ImageColumn image={LoginImage}>
        <FlexibleDiv direction={"column"} padding={"0rem 0rem 5rem 0rem"}>
          <FlexibleDiv justifyContent={"flex-start"} padding={"0px 22px"}>
            <Logo margin="0px" darkMode={darkMode} />
          </FlexibleDiv>
          <FlexibleDiv justifyContent={"flex-start"} padding={"0px 22px"}>
            <HeaderText
              lineHeight={"2.3rem"}
              fontSize={fontSizes.desktop_subtitle}
              color={colorTheme.white.white}
            >
              Experience the best events <br /> of your life
            </HeaderText>
          </FlexibleDiv>
        </FlexibleDiv>
      </ImageColumn>

      <InputFieldColumn>
        <AuthForm />
      </InputFieldColumn>
    </AuthLayout>
  );
};

export default SignUp;
