import Button from "../../../../components/primaryComponents/button/button.component";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import Loader from "../../../../assets/images/loading.svg";
import { Image } from "../../../../components/primaryComponents/image/image";
import { useCreateEvent } from "../../createEvent Context";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Create = ({ setShowModal }) => {
  const isSubmitting = useCreateEvent()?.[1];
  const loading = useSelector(({createEvent})=>createEvent.loading)
  const error = useSelector(({createEvent})=>createEvent.error)
  const handleSubmit = useCreateEvent()?.[5];
  useEffect(() => {
    if (loading=== true) {
      setShowModal(true);
    } 
    
    else if(error !==null){
      setShowModal(true)
    }
    else {
      setShowModal(false);
    }

  }, [loading,error]);
  return (
    <>
      <FlexibleDiv justifyContent={"center"} margin={"60px 0px"}>
        <Button
          type={"submit"}
          onClick={() => {
            handleSubmit();
          }}
          background={
            isSubmitting ? colorTheme.textColor : colorTheme.primary_color
          }
          width={"70%"}
          fontSize={"16px"}
          radius={"5px"}
          padding={"16px"}
          hoverColor={colorTheme.white.white}
          hoverBorder={"none"}
          hoverBackground={
            isSubmitting ? colorTheme.textColor : colorTheme.primary_color
          }
        >
          {/* {isSubmitting ? (
            <Image src={Loader} width={"33px"} />
          ) : (
            "Create Event"
          )} */}
          Create Event
        </Button>
      </FlexibleDiv>
    </>
  );
};

export default Create;
