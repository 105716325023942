import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClientRequest from "../../../../api/api";
export const getListOfOrganizers = createAsyncThunk(
  "user/get-list-of-organizers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.get("api/organizers");
      if (response.status === 200) {
        return response.data?.organizers;
      }
    } catch (error) {
      const { response } = error;
      console.error("List of organizers error", error);
      console.error("List of organizers error response", response);
      return rejectWithValue(response);
    }
  }
);
const initialState = {
  loading: false,
  isSuccessful: false,
  isError: false,
  list_of_organizers: [],
  error: null
};

const ListOfOrganizerSlice = createSlice({
  name: "list_of_organizers_slice",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getListOfOrganizers.pending, (state) => {
        state.loading = true;
        state.isSuccessful = false;
        state.isError = false;
        state.error = null;
      })
      .addCase(getListOfOrganizers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccessful = true;
        state.list_of_organizers = payload;
      })
      .addCase(getListOfOrganizers.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.error = payload;
      });
  }
});

export default ListOfOrganizerSlice.reducer;
