import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../../components/primaryComponents/typography/typography.component";
import EditAdditionalInfo from "../../../components/secondaryComponents/editAdditionalInfo/editAdditionalInfo.component";
import EditEventDescription from "../../../components/secondaryComponents/editEventDescription/editEventDescription.component";
import EditEventImages from "../../../components/secondaryComponents/editEventImages/editEventImage.component";
import EditEventLocation from "../../../components/secondaryComponents/editEventLocation/editEventLocation.component";
import EditEventPrice from "../../../components/secondaryComponents/editEventPrice/editEventPrice.component";
import EditEventSchedule from "../../../components/secondaryComponents/editEventSchedule/eventEventSchedule.component";
import EditEventTitle from "../../../components/secondaryComponents/editEventTitle/editEventTitle.component";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../../components/primaryComponents/button/button.component";
import { editEventHandler } from "../../../state management/Redux/slices/editEvent/edit_details_slice";
import Loader from "../../../components/secondaryComponents/loader/loader.component";
import { useParams } from "react-router-dom";
import { useState } from "react";

const OverView = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const token = useSelector((state) => state.persistedReducer.user.token);
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const title = useSelector(({ edit_event }) => edit_event?.title);
  const description = useSelector(({ edit_event }) => edit_event?.description);
  const visibility = useSelector(({ edit_event }) => edit_event?.visibility);
  const startDate = useSelector(({ edit_event }) => edit_event?.startDate);
  const endDate = useSelector(({ edit_event }) => edit_event?.endDate);
  const type = useSelector(({ edit_event }) => edit_event?.type);
  const tags = useSelector(({ edit_event }) => edit_event?.tags);
  const loading = useSelector(({ edit_event }) => edit_event?.loading);
  // const cover_image = useSelector(({ edit_event }) => edit_event?.cover_image);
  const location = useSelector(({ edit_event }) => edit_event?.location);
  const [editCoverImage,setEditCoverImage] = useState(null)
  const eventCategory = useSelector(
    ({ event_details }) => event_details?.data?.event?.category
  );
  const start_editing = useSelector(
    ({ edit_event }) => edit_event?.start_editing
  );
  const dispatch = useDispatch();
  const { eventId } = useParams();
  return (
    <>
      <FlexibleDiv breakPoint={"680px"} breakPointwidth={"90%"}>
        <FlexibleDiv
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"15.5px"}
            color={titleColor}
          >
            Overview
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"15px"}
            color={colorTheme.textColor}
            resFontSize={"13.8px"}
          >
            Make quick edits to this event
          </Text>
        </FlexibleDiv>
        <EditEventTitle />
        <EditEventDescription />
        <EditEventSchedule />
        <EditEventLocation />
        <EditEventPrice />
        <EditEventImages editCoverImage={editCoverImage} setEditCoverImage={setEditCoverImage} />
        <EditAdditionalInfo />

        <FlexibleDiv margin={"30px 0px"}>
          <Button
            disabled={!start_editing}
            padding={"13px"}
            background={
              loading ? colorTheme.white.white : colorTheme.primary_color
            }
            width={"90%"}
            type={"submit"}
            onClick={() => {
              dispatch(
                editEventHandler({
                  title: title,
                  description: description,
                  startDate: startDate,
                  endDate: endDate,
                  token: token,
                  id: eventId,
                  cover_image: editCoverImage,
                  type: type,
                  tags: tags,
                  location: location,
                  category: eventCategory,
                  visibility: visibility
                })
              );
            }}
          >
            {loading ? <Loader width={"32px"} /> : "Save Changes"}
          </Button>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default OverView;
