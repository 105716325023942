import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { HeaderText } from "../../primaryComponents/typography/typography.component";
import EditEventCoverImage from "./editCoverImage.component";
import eventImage from "../../../assets/images/Party.jpg";
import { Image } from "../../primaryComponents/image/image";
import Button from "../../primaryComponents/button/button.component";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Skeleton from "react-loading-skeleton";

const EditEventImages = ({ setEditCoverImage, editCoverImage }) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const btnBgColor = darkMode
    ? colorTheme.primary_color
    : colorTheme.white.white;
  const btnColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  const extra_images = useSelector(
    ({ event_details }) => event_details?.data?.event?.extra_images
  );
  const loading = useSelector(({ event_details }) => event_details?.loading);

  return (
    <>
      <FlexibleDiv direction={"column"}>
        <FlexibleDiv
          justifyContent={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"14.5px"}
            color={titleColor}
          >
            Event Pictures
          </HeaderText>
        </FlexibleDiv>
        <FlexibleDiv justifyContent={"flex-start"}>
          {loading ? (
            <Skeleton width={700} height={350} />
          ) : (
            <EditEventCoverImage
              editCoverImage={editCoverImage}
              setEditCoverImage={setEditCoverImage}
            />
          )}
        </FlexibleDiv>
        {/* <FlexibleDiv
          margin={"20px 0px"}
          justifyContent={"flex-start"}
          gap={"12px"}
        >
          {extra_images?.includes("[]")
            ? null
            : extra_images?.map(() => (
                <Image
                  width={"143px"}
                  height={"96px"}
                  src={eventImage}
                  radius={"5px"}
                />
              ))}
        </FlexibleDiv> */}
        {/* <FlexibleDiv margin={"12px 0px"} justifyContent={"flex-start"}>
          <Button
            width={"870px"}
            padding={"12px"}
            children={"+ Add More Photos/Videos"}
            background={btnBgColor}
            color={btnColor}
            hoverBackground={"#F2F3F7"}
            hoverColor={"#000"}
            hoverBorder={"none"}
          />
        </FlexibleDiv> */}
      </FlexibleDiv>
    </>
  );
};

export default EditEventImages;
