import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  // Text
} from "../../components/primaryComponents/typography/typography.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import { colorTheme } from "../../infrastructure/Theme/color";
import { useEffect } from "react";
import EventLoaderCard from "../../components/secondaryComponents/skeleton/eventCard_loader.component";
import { Helmet } from "react-helmet";
import OrganizerCard from "../../components/secondaryComponents/topOrganizers/organizerCard.component";
import { getListOfOrganizers } from "../../state management/Redux/slices/list_of_organizers";

const Organizers = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const organizers = useSelector(
    ({ list_of_organizers }) => list_of_organizers?.list_of_organizers
  );
  const loading = useSelector(
    ({ list_of_organizers }) => list_of_organizers?.loading
  );
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;
  useEffect(() => {
    dispatch(getListOfOrganizers());
  }, []);
  return (
    <>
      <FlexibleDiv display={"block"} margin={"20px 0px"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Attend - Organizers</title>
        </Helmet>
        <FlexibleDiv
          width={"90%"}
          breakPoint={"780px"}
          breakPointwidth={"90%"}
          style={{ alignSelf: "center", margin: "auto" }}
        >
          <FlexibleDiv
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <HeaderText
              fontSize="35px"
              color={textColor}
              resFontSize="23px"
              margin={"opx"}
              style={{ textAlign: "center" }}
            >
              Organizers
            </HeaderText>
            {/* <Text
                            margin={"0px"}
                            lineHeight={"28px"}
                            color={textColor}
                            resFontSize={"11.5px"}
                            fontSize={"14px"}
                            style={{ textAlign: "center" }}
                        >
                            Explore with Attend, your online hub for discovering and attending
                            captivating events that elevate your experiences.
                        </Text> */}
          </FlexibleDiv>

          <FlexibleDiv
            margin={"30px 0px"}
            justifyContent={organizers?.length === 1 ? "flex-start" : "center"}
            breakPoint={"780px"}
            breakPointJustifyContent={"center"}
            alignItems={"stretch"}
            flexWrap={"no-wrap"}
          >
            {loading ? (
              <FlexibleDiv
                justifyContent={"flex-start"}
                gap={"23px"}
                breakPoint={"780px"}
                breakPointJustifyContent={"flex-start"}
                breakPointPadding={"0px 23px"}
                alignItems={"stretch"}
                flexWrap={"no-wrap"}
              >
                {Array.from({ length: 10 }).map((index) => (
                  <EventLoaderCard width={"230px"} key={index} />
                ))}
              </FlexibleDiv>
            ) : (
              <>
                {organizers?.map(
                  ({ firstname, lastname, id, profile_image, username }) => (
                    <OrganizerCard
                      flex={"20%"}
                      key={id}
                      name={`${firstname} ${lastname}`}
                      username={username}
                      id={id}
                      profile_image={profile_image}
                    />
                  )
                )}
              </>
            )}
            <FlexibleDiv
              display={"none"}
              breakPoint={"780px"}
              breakPointDisplay={"block"}
              breakPointwidth={"35%"}
              breakPointMargin={"10px"}
              breakPointFlex={".85"}
            />
          </FlexibleDiv>
        </FlexibleDiv>
        <Footer />
      </FlexibleDiv>
    </>
  );
};

export default Organizers;
