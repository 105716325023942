import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  BOTTOM_CUT,
  DashedLine,
  Line,
  TICKET_WRAPPER,
  TOP_CUT,
} from "./styles";
import TicketDate from "./ticketDate";
import TicketDetails from "./ticketDetails";
import TicketImage from "./ticketImage";
import TicketQRCode from "./ticketQrCode";
import { PhotoProvider } from "react-photo-view";
const Ticket = ({
  eventName,
  eventLocation,
  eventDate,
  eventOrderNo,
  eventSeatNo,
  eventImage,
  eventQRCode,
  ticketType,
  ticketStatus,
}) => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );

  return (
    <>
      <FlexibleDiv>
        <TicketDate eventDate={eventDate} />
        <FlexibleDiv
          width={"90%"}
          breakPoint={"680px"}
          breakPointwidth={"100%"}
        >
          <TICKET_WRAPPER bg={darkMode ? colorTheme.black.fadeMini : "#F7F7F7"}>
            <TicketImage eventImage={eventImage} />
            <TicketDetails
              eventName={eventName}
              ticketType={ticketType}
              eventLocation={eventLocation}
              eventDate={eventDate}
              eventOrderNo={eventOrderNo}
              ticketStatus={ticketStatus}
              eventSeatNo={eventSeatNo}
            />
            <Line>
              <TOP_CUT bg={darkMode ? colorTheme.black.fadingBlack : "#FFFF"} />
              <DashedLine />
              <BOTTOM_CUT
                bg={darkMode ? colorTheme.black.fadingBlack : "#FFFF"}
              />
            </Line>
            <PhotoProvider>
              <TicketQRCode eventQRCode={eventQRCode} />
            </PhotoProvider>
          </TICKET_WRAPPER>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default Ticket;
