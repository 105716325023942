import { useDispatch, useSelector } from "react-redux";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../components/primaryComponents/typography/typography.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import { colorTheme } from "../../infrastructure/Theme/color";
import Search2 from "../../components/secondaryComponents/Search/search2.0";
import EventCard from "../../components/secondaryComponents/eventCard";
import { useEffect } from "react";
import { getEvents } from "../../state management/Redux/slices/events/get_event_slice";
import {
  searchEventHandler,
  searchkeywordHandler
} from "../../state management/Redux/slices/search/search_slice";
import EventLoaderCard from "../../components/secondaryComponents/skeleton/eventCard_loader.component";
import { Helmet } from "react-helmet";

const Search_Page = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const eventList = useSelector(({ events }) => events.event_list?.events);
  const search_keyword = useSelector(({ search }) => search?.search_keyword);
  const isSuccessful = useSelector(({ search }) => search?.isSuccessful);
  const search_data = useSelector(({ search }) => search?.search_data);
  const loading = useSelector(({ search }) => search?.loading);
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(searchEventHandler({ token: token, keyword: search_keyword }));
  };
  useEffect(() => {
    dispatch(getEvents({ token: token }));
  }, [dispatch, token]);
  useEffect(() => {
    if (search_keyword !== "") {
      dispatch(searchEventHandler({ token: token, keyword: search_keyword }));
    }
  }, [search_keyword, dispatch, token]);
  return (
    <>
      <FlexibleDiv display={"block"} margin={"20px 0px"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Attend - Search</title>
        </Helmet>
        <FlexibleDiv
          width={"60%"}
          breakPoint={"780px"}
          breakPointwidth={"90%"}
          style={{ alignSelf: "center", margin: "auto" }}
        >
          <FlexibleDiv
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <HeaderText
              fontSize="35px"
              color={textColor}
              resFontSize="23px"
              margin={"opx"}
              style={{ textAlign: "center" }}
            >
              Explore
            </HeaderText>
            <Text
              margin={"0px"}
              lineHeight={"28px"}
              color={textColor}
              resFontSize={"11.5px"}
              fontSize={"14px"}
              style={{ textAlign: "center" }}
            >
              Explore with Attend, your online hub for discovering and attending
              captivating events that elevate your experiences.
            </Text>
          </FlexibleDiv>

          <FlexibleDiv margin={"20px 0px"}>
            <Search2
              SubmitHandler={onSubmit}
              value={search_keyword}
              onChange={(e) => {
                const value = e.target.value;
                dispatch(searchkeywordHandler(value));
              }}
              color={textColor}
              width={"100%"}
            />
          </FlexibleDiv>

          <FlexibleDiv
            margin={"30px 0px"}
            gap={"23px"}
            justifyContent={eventList?.length === 1 ? "flex-start" : "center"}
            breakPoint={"780px"}
            breakPointJustifyContent={"center"}
            alignItems={"center"}
            flexWrap={"no-wrap"}
          >
            {loading && (
              <>
                <FlexibleDiv
                  gap={"23px"}
                  justifyContent={"flex-start"}
                  breakPoint={"780px"}
                  breakPointJustifyContent={"flex-start"}
                  breakPointPadding={'0px 23px'}
                  alignItems={"center"}
                  flexWrap={"no-wrap"}
                >
                  {Array.from({ length: 2 }).map((index) => (
                    <EventLoaderCard width={"230px"} key={index} />
                  ))}
                </FlexibleDiv>
              </>
            )}
            {search_keyword === "" &&
              !isSuccessful &&
              eventList?.map(({ cover_image, title, startDate, id, category, user }) => (
                <EventCard
                  link={`/event-details/${id}/${encodeURIComponent(title)}`}
                  cover_image={cover_image}
                  title={title}
                  organizer={user}
                  category={JSON.parse(category)}
                  startDate={startDate}
                  style={{ overflowX: "hidden" }}
                  breakPointwidth={'100%'}
                  breakPoint={"780px"}
                  width={"40%"}

                  key={id}
                />
              ))}
            {search_data.length > 0 &&
              isSuccessful &&
              search_data?.map(({ cover_image, title, startDate, id, location, user }, index) => (
                <EventCard
                  style={{ overflowX: "hidden" }}
                  location={location}
                  breakPointwidth={eventList?.length === 1 ? "80%" : "100%"}
                  breakPoint={"780px"}
                  width={"30%"}
                  link={`/event-details/${id}/${encodeURIComponent(title)}`}
                  key={index}
                  organizer={user}
                  cover_image={cover_image}
                  event_id={id}
                  title={title}
                  startDate={startDate}
                />
              ))}

            {!loading && search_data?.length === 0 && search_keyword !== "" && (
              <>
                <Text
                  color={
                    darkMode ? colorTheme.black.soft : colorTheme.black.strong
                  }
                  style={{
                    margin: "auto"
                  }}
                  fontSize={"13px"}
                  resFontSize={"12.5px"}
                >
                  No matching event was found
                </Text>
              </>
            )}
          </FlexibleDiv>
        </FlexibleDiv>
        <Footer />
      </FlexibleDiv>
    </>
  );
};

export default Search_Page;
