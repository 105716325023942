import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../../../components/primaryComponents/input/input.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import { useDispatch, useSelector } from "react-redux";
import { LIST_OF_CATEGORIES } from "./LIST_OF_CATEGORIES";
import {
  categoryValue,
  privacyValue,
} from "../../../../state management/Redux/slices/create_event_slice";
import { useEffect, useState } from "react";
import DropDown from "../../../../components/secondaryComponents/dropDown/dropDown.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { useCreateEvent } from "../../createEvent Context";

const FormOfEvent = () => {
  const dispatch = useDispatch();
  const InputHandler = (e, action) => {
    let value = e.target.value;
    dispatch(action(value));
  };
  return (
    <>
      <FlexibleDiv justifyContent={"space-evenly"}>
        <CHOOSE_A_CATEGORY InputHandler={InputHandler} />
        <TYPE_OF_EVENT InputHandler={InputHandler} />
      </FlexibleDiv>
    </>
  );
};

export default FormOfEvent;

const CHOOSE_A_CATEGORY = ({ InputHandler }) => {
  const category = useSelector((state) => state.createEvent.category);
  const category_list = useSelector(
    (state) => state.persistedReducer.categories.category_list
  );
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const handleChange = useCreateEvent()?.[4];
  const errors = useCreateEvent()?.[2];
  const touched = useCreateEvent()?.[3];
  const list =category_list?.map(({name,id})=>{
    return [name,id]
  })
  return (
    <FlexibleDiv
      flexBasis={"50%"}
      breakPoint={"680px"}
      breakPointflexBasis={"100%"}
    >
      <FlexibleDiv
        justifyContent={"flex-start"}
        padding={"16px 0px"}
        gap={"12px"}
        alignItems={"center"}
      >
        <Text
          margin={"0px"}
          fontSize={fontSizes.mobile_details}
          resFontSize={"14px"}
          color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
        >
          Choose event category
        </Text>
        {errors.category && touched.category && (
          <Text
            margin={"0px"}
            color={colorTheme.red}
            fontSize={fontSizes.mobile_smallest}
            resFontSize={fontSizes.mobile_smallest}
          >
            ({errors.category})
          </Text>
        )}
      </FlexibleDiv>

      <FlexibleDiv justifyContent={"flex-start"}>
        <FlexibleDiv width={"90%"}>
          <DropDown
            label={"Select"}
            components={list}
            border={`1px solid ${colorTheme.lightgrey}`}
            labelSize={"13px"}
            padding={"9px"}
            action={categoryValue}
            reduxValue={category}
            reduxControlled
            formikhandleChange={handleChange}
            formikValue={"category"}
          />
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};

const TYPE_OF_EVENT = ({ InputHandler }) => {
  const privacy = useSelector((state) => state.createEvent.privacy);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const [privateStateChecked, setPrivateStateChecked] = useState(false);
  const [publicChecked, setPublicChecked] = useState(false);
  const errors = useCreateEvent()?.[2];
  const touched = useCreateEvent()?.[3];
  const handleChange = useCreateEvent()?.[4];
  const CheckboxHandler = (e) => {
    InputHandler(e, privacyValue);
    handleChange("visibility")(e.target.value);
  };

  useEffect(() => {
    if (privacy === "Private") {
      setPrivateStateChecked(true);
      setPublicChecked(false);
    } else if (privacy === "Public") {
      setPrivateStateChecked(false);
      setPublicChecked(true);
    }
  }, [privacy, publicChecked, privateStateChecked]);
  return (
    <FlexibleDiv
      flexBasis={"50%"}
      direction={"column"}
      breakPoint={"680px"}
      breakPointflexBasis={"100%"}
    >
      <FlexibleDiv
        justifyContent={"flex-start"}
        padding={"16px 0px"}
        gap={"12px"}
      >
        <Text
          margin={"0px"}
          fontSize={fontSizes.mobile_details}
          resFontSize={"14px"}
          color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
        >
          This event will be
        </Text>
        {errors.visibility && touched.visibility && (
          <Text
            margin={"0px"}
            color={colorTheme.red}
            fontSize={fontSizes.mobile_smallest}
            resFontSize={fontSizes.mobile_smallest}
          >
            ({errors.visibility})
          </Text>
        )}
      </FlexibleDiv>

      <FlexibleDiv justifyContent={"flex-start"}>
        <FlexibleDiv
          flexBasis={"30%"}
          justifyContent={"flex-start"}
          gap={"9px"}
        >
          <Input
            checked={publicChecked}
            color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
            value={"Public"}
            type={"checkbox"}
            padding={"12px"}
            onChange={(e) => {
              CheckboxHandler(e);
            }}
          />
          <Text
            fontWeight={"500"}
            fontSize={fontSizes.mobile_details}
            resFontSize={"14px"}
            color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
          >
            Public
          </Text>
        </FlexibleDiv>
        <FlexibleDiv
          flexBasis={"30%"}
          justifyContent={"flex-start"}
          gap={"9px"}
        >
          <Input
            checked={privateStateChecked}
            value={"Private"}
            bg={"none"}
            type={"checkbox"}
            padding={"12px"}
            onChange={(e) => {
              CheckboxHandler(e);
            }}
          />
          <Text
            fontWeight={"500"}
            fontSize={fontSizes.mobile_details}
            resFontSize={"14px"}
            color={darkMode ? colorTheme.textColor : colorTheme.black.strong}
          >
            Private
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </FlexibleDiv>
  );
};
