import { Helmet } from "react-helmet";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text,
} from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import TestNav from "../../components/secondaryComponents/NavigationBar/testNav";
import Footer from "../../components/secondaryComponents/Footer/Footer";
const PrivacyPolicy = () => {
  const info_we_collect = [
    "Personal Information: When you create an account, purchase tickets, or contact us, we may collect personal information such as your name, email address, phone number, payment information, and billing address.",
    "Event Information: When you create or manage events, we collect details about the events, such as event name, location, date, and time.",
    "Usage Data: We collect information about your interactions with our services, including IP addresses, browser type, pages visited, and the dates and times of your visits.",
  ];
  const how_we_use_your_info = [
    "Provide, maintain, and improve our services.",
    "Process transactions and send related information, including purchase confirmations and invoices.",
    "Communicate with you, including sending updates, promotions, and marketing materials.",
    "Monitor and analyze trends, usage, and activities in connection with our services.",
    "Personalize and improve your experience on our website.",
    "Detect, prevent, and address technical issues and security breaches.",
  ];
  const sharing_your_info = [
    "Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, email delivery, and customer support.",
    "Event Organizers: When you purchase a ticket, your information may be shared with the event organizer for purposes related to the event.",
    "Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).",
  ];
  const data_security = [
    "We implement a variety of security measures to maintain the safety of your personal information. These measures include using encryption to protect sensitive information transmitted online and access controls to restrict access to personal information.",
  ];
  const your_rights = [
    "Access and review the personal information we hold about you.",
    "Request corrections to any inaccurate or incomplete information.",
    "Request deletion of your personal information, subject to certain legal obligations.",
    "Opt-out of receiving promotional communications from us.",
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Privacy Policy</title>
      </Helmet>
      <TestNav />
      <FlexibleDiv
        padding={"100px 23px 23px 13px"}
        display={"block"}
        breakPoint={"780px"}
        breakPointwidth={"90%"}
        breakPointMargin={"auto"}
      >
        <FlexibleDiv display={"block"}>
          <HeaderText
            fontSize="42px"
            resFontSize="18px"
            color="white"
            style={{ textAlign: "center" }}
          >
            Privacy{" "}
            <span style={{ color: colorTheme.primary_color }}>Policy</span>
          </HeaderText>
          <Text
            style={{ textAlign: "center" }}
            fontSize={"14px"}
            resFontSize={"12px"}
            lineHeight={"27px"}
            color={colorTheme.lightgrey}
          >
            Thanks for using Attend! Our Privacy Policy details how we handle
            your information.
            <br /> By using Attend, you agree to these practices.
          </Text>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
          borderBottom={`.2px solid ${colorTheme.lightgrey}`}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              Information We Collect
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              We collect information to provide better services to our users.
              The types of information we collect include:
            </Text>
          </FlexibleDiv>
          <FlexibleDiv direction={"column"}>
            {info_we_collect.map((item, index) => (
              <Text
                style={{ marginBottom: "10px", textAlign: "center" }}
                lineHeight={"25px"}
                fontSize={"14px"}
                color={colorTheme.lightgrey}
                resFontSize={"13px"}
                key={index}
              >
                {item}
              </Text>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
          borderBottom={`1px solid ${colorTheme.lightgrey}`}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              How We Use Your Information
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              We use the information we collect to:
            </Text>
          </FlexibleDiv>
          <FlexibleDiv direction={"column"}>
            {how_we_use_your_info.map((item, index) => (
              <Text
                style={{ marginBottom: "10px", textAlign: "center" }}
                lineHeight={"25px"}
                fontSize={"14px"}
                color={colorTheme.lightgrey}
                resFontSize={"13px"}
                key={index}
              >
                {item}
              </Text>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
          borderBottom={`1px solid ${colorTheme.lightgrey}`}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              Sharing Your Information
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties except as described below:
            </Text>
          </FlexibleDiv>
          <FlexibleDiv direction={"column"}>
            {sharing_your_info.map((item, index) => (
              <Text
                style={{ marginBottom: "10px", textAlign: "center" }}
                lineHeight={"25px"}
                fontSize={"14px"}
                color={colorTheme.lightgrey}
                resFontSize={"13px"}
                key={index}
              >
                {item}
              </Text>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
          borderBottom={`1px solid ${colorTheme.lightgrey}`}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              Data Security
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              We implement a variety of security measures to maintain the safety
              of your personal information:
            </Text>
          </FlexibleDiv>
          <FlexibleDiv direction={"column"}>
            {data_security.map((item, index) => (
              <Text
                style={{ marginBottom: "10px", textAlign: "center" }}
                lineHeight={"25px"}
                fontSize={"14px"}
                color={colorTheme.lightgrey}
                resFontSize={"13px"}
                key={index}
              >
                {item}
              </Text>
            ))}
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
          borderBottom={`1px solid ${colorTheme.lightgrey}`}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              Your Rights
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              You have the right to:
            </Text>
          </FlexibleDiv>
          <FlexibleDiv direction={"column"}>
            {your_rights.map((item, index) => (
              <Text
                style={{ marginBottom: "10px", textAlign: "center" }}
                lineHeight={"25px"}
                fontSize={"14px"}
                color={colorTheme.lightgrey}
                resFontSize={"13px"}
                key={index}
              >
                {item}
              </Text>
            ))}
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              To exercise these rights, please contact us at
              attendenquiries@gmail.com.
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
          borderBottom={`1px solid ${colorTheme.lightgrey}`}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              Changes to This Privacy Policy
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              lineHeight={"25px"}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>

        <FlexibleDiv
          display="block"
          margin={"53px auto"}
          width={"1000px"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          padding={"0px 0px 23px 0px"}
        >
          <FlexibleDiv>
            <Text
              fontSize={"16px"}
              color={colorTheme.primary_color}
              resFontSize={"14px"}
              margin={"0px"}
              fontWeight={"700"}
            >
              Contact Us
            </Text>
          </FlexibleDiv>
          <FlexibleDiv marginTop="3px">
            <Text
              fontSize={"14px"}
              color={colorTheme.white.fadingWhite}
              style={{ textAlign: "center" }}
              resFontSize={"13px"}
              fontWeight={"500"}
            >
              If you have any questions about this Privacy Policy, please
              contact us at:
            </Text>
          </FlexibleDiv>
          <FlexibleDiv direction={"column"}>
            <Text
              style={{ marginBottom: "10px", textAlign: "center" }}
              lineHeight={"25px"}
              fontSize={"14px"}
              color={colorTheme.lightgrey}
              resFontSize={"13px"}
            >
              Attend Services
              <br />
              No. 17 Umunkanka Street, UNN
              <br />
              Nsukka, Enugu, 400241
              <br />
              attendenquiries@gmail.com
              <br />
              09035466339
            </Text>
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
