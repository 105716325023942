import styled from "styled-components";

export const CreateEventStyle = styled.div`
width: 100%;
min-height: 100vh;
position: relative;
@media screen and (max-width:680px) {
display: flex;
}
`
export const BodyStyle = styled.div`
width: 80%;
min-height: 100vh;
/* background-color: red; */
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
margin-top: 90px;
@media screen and (max-width:680px) {
    width: 100%;
}
`

export const MainSection = styled.section`
width: 90%;
margin: 0px auto;
border-radius: 5px;
height: 100%;
padding: 23px;
box-shadow: ${({shadow})=>shadow ? shadow : '0px 1px 3px lightgray'};
background-color: ${({bg})=>bg ? bg : 'white'};
@media screen and (max-width:680px) {
    width: 100%;
}

`