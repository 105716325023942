import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { NumberBox } from "../../../../infrastructure/Layout/layoutStyles";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import {
  clearAuthServiceState,
  logOut
} from "../../../../state management/Redux/slices/user_slice";
// import { logOut } from "../../../../state management/Redux/slices/user_slice";
import Button from "../../../primaryComponents/button/button.component";
import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { LogOutIcon } from "../../../primaryComponents/icons/icons";
import { Text } from "../../../primaryComponents/typography/typography.component";
import UserImage from "../../userProfileImage/UserImage";
import { useEffect } from "react";
import { get_user_events } from "../../../../state management/Redux/slices/userAccountSlice/user_events_slice";
import { Image } from "../../../primaryComponents/image/image";
import badge from "../../../../assets/images/verifiedTick.png";
import { accountTabHandler } from "../../../../state management/Redux/slices/userAccountSlice";
import { CONSTANTS } from "../../../../utilities/constants";
import no_user from "../../../../assets/images/no_user.png";
const userLinks = [
  {
    name: "Profile",
    link: "/my-profile",
    tabName: "Profile"
  },

  {
    name: "My Events",
    link: "/my-profile",
    tabName: "Events"
  },

  {
    name: "My Tickets",
    link: "/my-profile",
    tabName: "Tickets"
  },
  {
    name: "Settings",
    link: "/settings"
  }
];

const MobileMenuProfile = ({ setToggleNavBar }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const userInfo = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo
  );
  const profileImage = useSelector(
    ({ persistedReducer }) => persistedReducer.user.userInfo?.profile_image
  );
  const events = useSelector(
    ({ user_events }) => user_events?.user_events?.events
  );
  const isOrganizer = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.organizer
  );
  const firstName = userInfo?.firstname;
  const lastName = userInfo?.lastname;
  const name = `${firstName} ${lastName}`;
  const no_of_tickets = userInfo?.tickets?.length;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ButtonHandler = () => {
    setToggleNavBar(false);
    navigate("/create");
  };
  const logOutHandler = () => {
    dispatch(logOut());
    dispatch(clearAuthServiceState());
    navigate("/login");
  };

  useEffect(() => {
    dispatch(get_user_events());
  }, []);
  return (
    <>
      <FlexibleDiv
        margin={"30px 0px 0px 0px"}
        alignItems={"flex-start"}
        direction={"column"}
      >
        {/* User Name */}
        <FlexibleDiv justifyContent={"flex-start"} gap={"12px"}>
          <UserImage
            objectFit={"cover"}
            img={
              profileImage
                ? `${CONSTANTS.profileImageUrl}${profileImage}`
                : no_user
            }
            width={"40px"}
            height={"40px"}
          />
          <Text
            fontWeight={"700"}
            color={darkMode ? colorTheme.white.white : colorTheme.black.strong}
          >
            {name}
          </Text>
          {isOrganizer === 1 ? (
            <Image
              src={badge}
              height={"16px"}
              width={"16px"}
              alt={"attend-badge"}
            />
          ) : null}
        </FlexibleDiv>

        <FlexibleDiv direction={"column"} alignItems={"flex-start"}>
          {isOrganizer === 1
            ? userLinks.map(({ name, link, count, tabName }) => (
                <Link
                  style={{ width: "100%" }}
                  onClick={() => {
                    dispatch(accountTabHandler(tabName));
                    setToggleNavBar(false);
                  }}
                  to={link}
                  key={name}
                >
                  <FlexibleDiv
                    key={name}
                    justifyContent={"flex-start"}
                    width={"100%"}
                    gap={"12px"}
                  >
                    <Text
                      fontWeight={"500"}
                      color={
                        darkMode ? colorTheme.white.fadingWhite : "#373838d6"
                      }
                    >
                      {name}
                    </Text>
                    {name === "My Events" ? (
                      <NumberBox>{events ? events?.length : 0}</NumberBox>
                    ) : name === "My Tickets" ? (
                      <NumberBox>{no_of_tickets}</NumberBox>
                    ) : null}
                  </FlexibleDiv>
                </Link>
              ))
            : userLinks
                .filter(({ name }) => name !== "My Events")
                .map(({ name, link, count, tabName }) => (
                  <Link
                    style={{ width: "100%" }}
                    onClick={() => {
                      dispatch(accountTabHandler(tabName));
                      setToggleNavBar(false);
                    }}
                    to={link}
                    key={name}
                  >
                    <FlexibleDiv
                      key={name}
                      justifyContent={"flex-start"}
                      width={"100%"}
                      gap={"12px"}
                    >
                      <Text
                        fontWeight={"500"}
                        color={
                          darkMode ? colorTheme.white.fadingWhite : "#373838d6"
                        }
                      >
                        {name}
                      </Text>
                      {name === "My Events" ? (
                        <NumberBox>{events ? events?.length : 0}</NumberBox>
                      ) : name === "My Tickets" ? (
                        <NumberBox>{no_of_tickets}</NumberBox>
                      ) : null}
                    </FlexibleDiv>
                  </Link>
                ))}
        </FlexibleDiv>
        <FlexibleDiv
          height={"0.2px"}
          bg={colorTheme.black.light}
          width={"90%"}
        ></FlexibleDiv>

        <FlexibleDiv margin={"23px 0px 23px 0px"} justifyContent={"flex-start"}>
          <Button
            onClick={() => {
              ButtonHandler();
            }}
            width={"90%"}
            background={colorTheme.primary_color}
            hoverBackground={colorTheme.primary_color}
            hoverColor={colorTheme.white.white}
            padding={"10px"}
            hoverBorder={"none"}
            radius={"5px"}
          >
            Create Event
          </Button>
        </FlexibleDiv>

        <FlexibleDiv
          margin={"0px 0px 33px 0px"}
          justifyContent={"flex-start"}
          gap={"12px"}
        >
          <LogOutIcon
            onClick={() => {
              logOutHandler();
            }}
            color={colorTheme.primary_color}
          />{" "}
          <Text
            onClick={() => {
              logOutHandler();
            }}
            fontWeight={"500"}
            color={darkMode ? colorTheme.white.fadingWhite : "#373838d6"}
          >
            Log Out
          </Text>
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default MobileMenuProfile;
