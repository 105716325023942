import { useSelector } from "react-redux";
// import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
// import { colorTheme } from "../../../../infrastructure/Theme/color";
// import { Text } from "../../../../components/primaryComponents/typography/typography.component";
// import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
// import Input from "../../../../components/primaryComponents/input/input.component";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import Modal from "../../components/primaryComponents/modal/modal";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../infrastructure/Theme/color";
import Input from "../../components/primaryComponents/input/input.component";

const Share_Event_Scanner = ({ showModal, setShowModal }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  const authkey = useSelector(
    ({ event_details }) => event_details?.data?.event?.auth_key
  );
  const [copy, setCopy] = useState(false);
  const bg = darkMode
    ? colorTheme.black.fadeMini
    : colorTheme.white.fadingWhite;
  const textColor = darkMode ? colorTheme.white.white : colorTheme.black.strong;

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);
  return (
    <>
      <Modal
        scroll={true}
        showExitBtn={true}
        show={showModal}
        setShowModal={setShowModal}
      >
        <FlexibleDiv
          display={"block"}
          width={"40%"}
          bg={bg}
          padding={"40px"}
          breakPoint={"780px"}
          breakPointwidth={"60%"}
        >
          <FlexibleDiv>
            <Text
              style={{ margin: "0px 0px 23px 0px", textAlign: "center" }}
              color={textColor}
              resFontSize={"15px"}
              fontSize={"15px"}
            >
              Share link to your crew members for ticket validation
            </Text>
          </FlexibleDiv>

          <FlexibleDiv direction={"column"} padding={"0px 0px 23px 0px"}>
            <FlexibleDiv>
              <FlexibleDiv
                width={"60%"}
                border={"1px solid lightgrey"}
                padding={"10px"}
              >
                <FlexibleDiv>
                  <Input
                    readonly={true}
                    width={"70%"}
                    color={textColor}
                    value={`${window.location.href}/qr-scanner/${authkey}`}
                  />
                  <FlexibleDiv width={"20%"}>
                    <CopyToClipboard
                      onCopy={() => {
                        setCopy(true);
                      }}
                      text={`${window.location.href}/qr-scanner/${authkey}`}
                    >
                      <BiCopy
                        style={{ cursor: "pointer" }}
                        color={
                          copy
                            ? colorTheme.secondary_color
                            : colorTheme.white.white
                        }
                        size={18}
                      />
                    </CopyToClipboard>
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </Modal>
    </>
  );
};

export default Share_Event_Scanner;
