export const separateName = (fullName) => {
  // Split the full name into words
  const nameParts = fullName.split(" ");

  // Check if there are at least two parts (first name and last name)
  if (nameParts.length >= 2) {
    // The first part is the first name
    const firstName = nameParts[0];

    // The rest are the last name
    const lastName = nameParts.slice(1).join(" ");

    return { firstName, lastName };
  } else {
    // If there's only one part, consider it as the first name
    return { firstName: fullName, lastName: "" };
  }
};
