import { useDispatch, useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Input from "../../primaryComponents/input/input.component";
import {
  HeaderText,
  Text,
} from "../../primaryComponents/typography/typography.component";
import Skeleton from "react-loading-skeleton";
import { EditIcon, RevertIcon } from "../../primaryComponents/icons/icons";
import React, {useState } from "react";
import { titleHandler } from "../../../state management/Redux/slices/editEvent/edit_details_slice";

const EditEventTitle = React.memo(() => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const titleColor = darkMode
    ? colorTheme.white.white
    : colorTheme.black.strong;
  const inputColor = darkMode
    ? colorTheme.white.fadingWhite
    : colorTheme.black.strong;
  const title = useSelector(({ edit_event }) => edit_event.title);
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const [readonly, setReadOnly] = useState(true);
  const dispatch = useDispatch();
  return (
    <>
      <FlexibleDiv>
        <FlexibleDiv
          direction={"column"}
          alignItems={"flex-start"}
          lineHeight={"25px"}
          padding={"23px 0px"}
        >
          <HeaderText
            margin={"0px"}
            fontSize={"20px"}
            fontWeight={"600"}
            resFontSize={"14.5px"}
            color={titleColor}
          >
            Event Title
          </HeaderText>
          <Text
            margin={"0px"}
            fontSize={"15px"}
            color={colorTheme.textColor}
            resFontSize={"13.8px"}
          >
            This displays the name of your event.
          </Text>
        </FlexibleDiv>

        <FlexibleDiv justifyContent={"flex-start"}>
          {loading ? (
            <Skeleton width={300} height={28} />
          ) : (
            <FlexibleDiv
              shadow={"0px 2px 7px rgba(0, 0, 0, 0.1)"}
              width={"850px"}
              left={"0px"}
              breakPoint={"880px"}
              breakPointwidth={"100%"}
            >
              <Input
                width={"80%"}
                padding={"12px"}
                readonly={readonly}
                onChange={(e) => {
                  dispatch(titleHandler(e.target.value));
                }}
                value={title}
                color={inputColor}
              />
              <FlexibleDiv width={"10%"}>
               
                <EditIcon
                  onClick={() => {
                    setReadOnly(false);
                  }}
                  color={colorTheme.primary_color}
                />
                {/* <RevertIcon
                  onClick={() => {
                    setReadOnly(false);
                  }}
                  color={colorTheme.primary_color}
                /> */}
              </FlexibleDiv>
            </FlexibleDiv>
          )}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
});

export default EditEventTitle;
