import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import CardCategory from "./cardCategory.component";
import CardImage from "./cardImage.component";
import CardLocation from "./cardLocation.component";
import CardName from "./cardName.component";
import CardTime from "./cardTime.component";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CardOrganizerInfo from "./cardOrganizerInfo.component";
const EventLink = styled(Link)`
  height: ${({ height }) => (height ? height : "")};
  padding: ${({ padding }) => (padding ? padding : "")};
  border: ${({ border }) => (border ? border : "none")};
  position: ${({ position }) => (position ? position : "")};
  display: ${({ display }) => (display ? display : "flex")};
  flex-direction: ${({ direction }) => (direction ? direction : "row")};
  flex: ${({ flex }) => (flex ? flex : "")};
  width: ${({ width }) => (width ? width : "100%")};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  right: ${({ right }) => (right ? right : "")};
  left: ${({ left }) => (left ? left : "")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-evenly"};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "")};
  top: ${({ top }) => (top ? top : "")};
  background: ${({ bg }) => (bg ? bg : "")};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "wrap")};
  flex-basis: ${({ flexBasis }) => (flexBasis ? flexBasis : "")};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : "")};
  border-radius: ${({ radius }) => (radius ? radius : "")};
  border-style: ${({ borderStyle }) => (borderStyle ? borderStyle : "")};
  margin: ${({ margin }) => (margin ? margin : "")};
  z-index: ${({ zIndex }) => (zIndex ? zIndex : "")};
  bottom: ${({ bottom }) => (bottom ? bottom : "")};
  gap: ${({ gap }) => (gap ? gap : "")};
  box-shadow: ${({ shadow }) => (shadow ? shadow : "")};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "")};
  flex-grow: ${({ flexGrow }) => (flexGrow ? flexGrow : "")};
  transition: ${({ transition }) => (transition ? transition : "")};
  @media screen and (max-width: ${({ breakPoint }) =>
      breakPoint ? breakPoint : ""}) {
    width: ${({ breakPointwidth }) => (breakPointwidth ? breakPointwidth : "")};
    height: ${({ breakPointHeight }) =>
      breakPointHeight ? breakPointHeight : ""};
    display: ${({ breakPointDisplay }) =>
      breakPointDisplay ? breakPointDisplay : ""};
    flex-basis: ${({ breakPointflexBasis }) =>
      breakPointflexBasis ? breakPointflexBasis : ""};
    flex: ${({ breakPointFlex }) => (breakPointFlex ? breakPointFlex : "")};
    justify-content: ${({ breakPointJustifyContent }) =>
      breakPointJustifyContent ? breakPointJustifyContent : ""};
    flex-wrap: ${({ breakPointWrap }) =>
      breakPointWrap ? breakPointWrap : "wrap"};
    align-items: ${({ breakPointAlign }) =>
      breakPointAlign ? breakPointAlign : "center"};
    margin: ${({ breakPointMargin }) =>
      breakPointMargin ? breakPointMargin : ""};
    gap: ${({ breakPointgap }) => (breakPointgap ? breakPointgap : "")};
    flex-direction: ${({ breakPointdirection }) =>
      breakPointdirection ? breakPointdirection : "row"};
    padding: ${({ breakPointPadding }) =>
      breakPointPadding ? breakPointPadding : ""};
    left: ${({ breakPointLeft }) => (breakPointLeft ? breakPointLeft : "")};
    bottom: ${({ breakPointbottom }) =>
      breakPointbottom ? breakPointbottom : ""};
    border: ${({ breakPointborder }) =>
      breakPointborder ? breakPointborder : "none"};
    top: ${({ breakPointtop }) => (breakPointtop ? breakPointtop : "")};
  }
`;
const EventCard = ({
  width,
  breakPoint,
  location,
  breakPointwidth,
  startDate,
  category,
  cover_image,
  title,
  event_id,
  organizer,
  tickets,
  endDate,
  status,
  style,
  link,
}) => {
  const darkmode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const loading = useSelector(({ events }) => events.fetching_events);
  return (
    <>
      <SkeletonTheme
        baseColor={colorTheme.black.fadingBlack}
        highlightColor={"#444444"}
      >
        <EventLink
          style={style}
          breakPoint={breakPoint ? breakPoint : ""}
          breakPointwidth={breakPointwidth ? breakPointwidth : ""}
          width={width ? width : "100%"}
          to={link}
        >
          <FlexibleDiv padding={"8px 0"} direction={"column"} border={"0px"}>
            <CardImage
              image={cover_image}
              status={status}
              startDate={startDate}
            />
            <FlexibleDiv
              direction={"column"}
              margin={"0px 0px 0px 0px"}
              padding={"13px 0px"}
              bg={darkmode ? colorTheme.black.fadeMini : "#FFF"}
              style={{
                borderBottomLeftRadius: "9px",
                borderBottomRightRadius: "9px",
              }}
              shadow={"0 1px 2px 0 rgb(0 0 0 / 15%)"}
            >
              {/* Events details */}
              <FlexibleDiv justifyContent={"flex-start"} flex={"1 0 auto"}>
                {/* event title and purchase type */}
                <FlexibleDiv
                  padding={"0 9px"}
                  flex={"1 1 auto"}
                  justifyContent={"flex-start"}
                  flexWrap={"wrap"}
                  alignItems={"baseline"}
                >
                  <CardName title={title} userName={organizer?.username} />
                  {/* <CardPurchaseType purchaseType={EventStatusChecker(endDate)} loading={loading} /> */}
                </FlexibleDiv>
                {/* Category | Location | Time */}
                <FlexibleDiv
                  margin={"5px 0px"}
                  padding={"0 10px"}
                  alignItems={"flex-start"}
                  direction={"column"}
                >
                  {category !== null && <CardCategory category={category} />}
                  {location && (
                    <CardLocation location={location} loading={loading} />
                  )}
                  <CardTime startDate={startDate} />
                </FlexibleDiv>
              </FlexibleDiv>
              {/* OrganizerInfo */}
              {organizer !== null && (
                <CardOrganizerInfo
                  status={status}
                  loading={loading}
                  userName={organizer?.username}
                  organizername={`${organizer?.firstname} ${organizer?.lastname}`}
                  organizer_profilepic={organizer?.profile_image}
                />
              )}
            </FlexibleDiv>
          </FlexibleDiv>
        </EventLink>
      </SkeletonTheme>
    </>
  );
};

export default EventCard;
