import ClientRequest from "../api/api";
import { CONSTANTS } from "../utilities/constants";

let gapi = window.gapi;
const API_KEY = CONSTANTS.goggleApiKey;
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
];
const CLIENT_ID = CONSTANTS.clientID;
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

export const initGoogleClient = (callback) => {
  gapi.load("client:auth2", () => {
    try {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(
          function () {
            if (typeof callback === "function") {
              callback(true);
            }
          },
          function (error) {
            console.log(error);
          }
        );
    } catch (error) {
      console.log(error);
    }
  });
};

export const signInToGoogle = async () => {
  try {
    let googleuser = await gapi.auth2
      .getAuthInstance()
      .signIn({ prompt: "consent" });
    if (googleuser) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const publishTheCalenderEvent = (event) => {
  try {
    gapi.client.load("calendar", "v3", () => {
      var request = gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
      });

      request.execute(function (event) {
        console.log("Event created: " + event.htmlLink);
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getGoogleUrl = async () => {
  try {
    const response = await fetch(`${CONSTANTS.baseURL}api/user/google/auth`, {
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include", // This ensures cookies or tokens are sent with the request
    });

    if (!response.ok) {
      // Handle non-200 responses here
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data?.url;
  } catch (error) {
    console.error("Error fetching Google URL:", error);
    throw error; // Re-throw the error so the caller can handle it
  }
};
export const googleSignCallback = async (url) => {
  const fullUrl = `${CONSTANTS.baseURL}api/user/google/auth/callback${url}`;
  console.log('Fetching URL:', fullUrl);

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Response error text:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching Google callback:', error);
    throw error;
  }
};

