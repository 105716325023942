import styled from "styled-components";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";

export const NavBar=styled(FlexibleDiv)`
top: 0px;
position: relative;
left: 0px;
right: 0px;
height: 60px;
justify-content:flex-start;
background-color: white;
`

export const LinkBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: relative;
padding:2px 10px 2px 10px;
cursor:pointer;
flex: 1;
/* width: 90%; */
transition: background 0.5s ease-in-out;
border-radius: 3px;

&:hover{
    background:${colorTheme.sideBarBg}
}
@media screen and (max-width:780px) {
   padding: 0.06rem;
}
@media screen and (max-width:680px) {
    display: none;
}
`