import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import UserImage from "../userProfileImage/UserImage";
import Info from "./info.component";
import no_user from "../../../assets/images/no_user.png"
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../utilities/constants";


const NavProfile = () => {
    const profileImage = useSelector(
        ({ persistedReducer }) => persistedReducer.user.userInfo?.profile_image
      );
    return ( 
        <>
    <FlexibleDiv>
           {/* Info */}
           <Info/>
           {/* Image */}
           <FlexibleDiv width={'30%'} >
            <UserImage objectFit={'cover'}  img={
              profileImage
                ? `${CONSTANTS.profileImageUrl}${
                    profileImage}
                  `
                : no_user
            } width={'40px'} height={'40px'} />
           </FlexibleDiv>
     </FlexibleDiv>

        </>
     );
}
 
export default NavProfile;

