const currentDate = new Date();
export const getUpcomingEvents = (events) => {
  const upcomingEvents = events?.filter((event) => {
    const eventStartDate = new Date(event.startDate);
    return eventStartDate > currentDate;
  });

  return upcomingEvents;
};

export const calculateDaysLeft=(startDate)=> {
    const eventStartDate = new Date(startDate);
    const timeDifference = eventStartDate.getTime() - currentDate.getTime();
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    if (daysLeft >1) {
        return `${daysLeft} days to go`;
    }
    else{
        return `${daysLeft} day left`;
    }
  }
  