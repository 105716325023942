import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../primaryComponents/image/image";
import { Text } from "../../primaryComponents/typography/typography.component";
import no_image from "../../../assets/images/no_user.png";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../utilities/constants";

const Users = ({ name, profile_image }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  return (
    <>
      {/* {users.map(({names})=>(
         <FlexibleDiv justifyContent={'flex-start'} gap={'12px'} >
            <Image width={'30px'} height={'30px'} radius={'50%'} src={userImage} alt={'user'}/>
          <Text fontSize={"13px"} fontWeight={"500"} color={colorTheme.ash} resFontSize={'small'}>{names}</Text>
          </FlexibleDiv>
      ))} */}
      <FlexibleDiv justifyContent={"flex-start"} gap={"12px"}>
        <Image
          width={"30px"}
          height={"30px"}
          radius={"50%"}
          src={
            profile_image ? `${CONSTANTS.imgbaseurl}${profile_image}` : no_image
          }
          alt={"user_image"}
        />
        <Text
          fontSize={"13px"}
          fontWeight={"500"}
          color={darkMode ? colorTheme.white.white : colorTheme.textColor}
          resFontSize={"small"}
        >
          {name}
        </Text>
      </FlexibleDiv>
    </>
  );
};

export default Users;
