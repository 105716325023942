import styled from "styled-components";


export const FaqQuestionContainer = styled.div`
overflow: hidden;
width: 90%;
height: ${({height})=>height ? height :'50px'};
transition: height 0.6s ease-in;
position: relative;
padding-bottom: ${({paddingBottom})=>paddingBottom ? paddingBottom :""};
border-bottom:.3px solid rgba(162, 162, 162, 0.65);

@media screen and (max-width: 680px) {
    height: ${({height})=>height ? height :'40px'};
    margin-bottom: 12px;
}
`

