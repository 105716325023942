import { Helmet } from "react-helmet";
import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import ConversationColumn from "./conversationColumn";
import FaqColumn from "./faqColumn";
import ContactHero from "./hero";

const Contact = () => {
  return (
    <FlexibleDiv direction={"column"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Attend - Contact Us</title>
      </Helmet>
      <ContactHero />
      <FlexibleDiv justifyContent={"center"} margin={"80px 0px 0px 0px"}>
        <FlexibleDiv
          width={"97.9%"}
          alignItems={"baseline"}
          breakPoint={"780px"}
          breakPointPadding={"0px 20px"}
        >
          <FaqColumn />
          <ConversationColumn />
        </FlexibleDiv>
      </FlexibleDiv>
      <Footer />
    </FlexibleDiv>
  );
};

export default Contact;
