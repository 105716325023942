import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MenuItemTitle } from "../../../infrastructure/Layout/layoutStyles";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { SettingsIcon } from "../../primaryComponents/icons/icons";

const SideBarSetting = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const organizers = useSelector(
    ({ organizers_following }) => organizers_following?.data
  );
  return (
    <>
      <div
        style={{
          marginTop: "5px",
          width: "100%",
          top: organizers?.length > 1 ? "-35px" : "-35px",
          position: "relative"
        }}
      >
        <Link to={"/settings"}>
          <MenuItemTitle color={darkMode ? colorTheme.white.white : ""}>
            {" "}
            <span>
              <SettingsIcon />
            </span>
            Settings
          </MenuItemTitle>
        </Link>
      </div>
    </>
  );
};

export default SideBarSetting;
