import { useSelector } from "react-redux";
import FlexibleDiv from "../../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../../../components/primaryComponents/typography/typography.component";
import { colorTheme } from "../../../../infrastructure/Theme/color";
import { fontSizes } from "../../../../infrastructure/Theme/fontSize";
import Head from "../../BlockCodes/header";
import TextEditor from "./textEditor.component";
import { useCreateEvent } from "../../createEvent Context";

const Description = () => {
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const errors = useCreateEvent()?.[2];
  const touched = useCreateEvent()?.[3];
  return (
    <>
      <FlexibleDiv direction={"column"} margin={"30px 0px"}>
        {/* Title */}

        <FlexibleDiv
          direction={"column"}
          lineHeight={"1.9em"}
          alignItems={"flex-start"}
          margin={"0px 0px 23px 0px"}
        >
          <FlexibleDiv>
            <FlexibleDiv
              width={errors.description && touched.description ? "15%" : "100%"}
              breakPoint={"680px"}
              breakPointwidth={"100%"}
            >
              <Head
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
                fontSize={fontSizes.desktop_body_text}
                width={"100%"}
                resFontSize={"18px"}
                title={"Description"}
              />
            </FlexibleDiv>
            <FlexibleDiv
              justifyContent={"flex-start"}
              width={"85%"}
              display={
                errors.description && touched.description ? "flex" : "none"
              }
              breakPoint={"680px"}
              breakPointwidth={"100%"}
            >
              <Text
                margin={"0px"}
                color={colorTheme.red}
                fontSize={fontSizes.mobile_smallest}
                resFontSize={fontSizes.mobile_smallest}
              >
                ({errors.description})
              </Text>
            </FlexibleDiv>
          </FlexibleDiv>
          <Text
            color={colorTheme.textColor}
            margin={"0px"}
            fontSize={fontSizes.mobile_body_text}
            fontWeight={"400"}
            resFontSize={"16px"}
          >
            Tell users more about your event.
          </Text>
        </FlexibleDiv>
        {/* Text Editor */}
        <TextEditor />
      </FlexibleDiv>
    </>
  );
};

export default Description;
