import { useSelector } from "react-redux";
import { LeftSideStyle } from "../../../../pages/Settings/styles";
import FlexibleDiv from "../../../primaryComponents/flexibleDiv/flexibleDiv.component";
import EditAbout from "../about.component";
// import DeleteAccount from "../deleteAccount.component";
// import EditInterests from "../interest.component";
// import EditLocation from "../location.component";
import EditName from "../name.component";

const LeftColumn = ({ formik }) => {
  const isOrganizer = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.organizer
  );
  return (
    <LeftSideStyle>
      <FlexibleDiv width={"85%"}>
        <EditName formik={formik} />
        {isOrganizer === 1 && <EditAbout formik={formik} />}
        {/* <EditLocation/> */}
        {/* <EditInterests/> */}
        {/* <FlexibleDiv breakPoint={'780px'} breakPointDisplay={"none"}>
                <DeleteAccount/>
            </FlexibleDiv> */}
      </FlexibleDiv>
    </LeftSideStyle>
  );
};

export default LeftColumn;
