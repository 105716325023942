import styled from "styled-components";
import { colorTheme } from "../../infrastructure/Theme/color";
import { Text } from "../../components/primaryComponents/typography/typography.component";
export const MainSection = styled.main`
  width: 95%;
  border-top: 95px;
`;

export const CoverImageStyle = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3)),
    url(${({ image }) => (image ? image : "")});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 155px;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  @media screen and (max-width: 680px) {
    border-radius: 0px;
  }
`;

export const ProfileImageView = styled.div`
  width: 104px;
  background: ${({ bg }) => (bg ? bg : "#FFF")};
  border-radius: 50%;
  height: 104px;
  position: absolute;
  left: ${({ left }) => (left ? left : "5%")};
  z-index: 1;
  top: ${(top) => (top ? top : "-90%")};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 780px) {
    width: 70px;
    height: 70px;
    top: 0px;
    position: inherit;
  }
  @media screen and (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

export const UserTypeView = styled.div`
  padding: 5px;
  position: relative;
  background: ${colorTheme.primary_color};
  position: relative;
  width: 60px;
  text-align: center;
  border-radius: 5px;
`;

export const EditButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  padding: 3px;
  background: ${colorTheme.primary_color};
  display: grid;
  place-content: center;
  top: 70%;
  left: 20px;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    left: 0px;
    width: 15px;
    height: 15px;
    top: 60%;
  }
`;

export const TabOption = styled.div`
  width: ${({ width }) => (width ? width : "5%")};
  position: relative;
  &::after {
    content: "";
    background: ${({ bg }) => (bg ? bg : colorTheme.primary_color)};
    height: 2px;
    width: ${({ linewidth }) => (linewidth ? linewidth : "45px")};
    top: 98%;
    position: absolute;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex: 1%;
  background-color: ${colorTheme.primary_color};
  padding: 32.5px 1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: auto;
`;
export const RightSide = styled.div`
  display: flex;
  align-items: baseline;
  flex: 99%;
  background: ${({ bg }) => (bg ? bg : "")};
  padding: 8px 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Badge = styled.img`
  height: 16px;
  width: 16px;
  @media screen and (max-width: 680px) {
    position: absolute;
    right: -10px;
  }
`;

export const ProfileName = styled(Text)`
  @media screen and (max-width: 680px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
  }
`;
