import styled from "styled-components";
import BgImage from "../../../assets/images/Party.jpg";
import { colorTheme } from "../../../infrastructure/Theme/color";
export const BackButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 9;
  left: 10px;
  cursor: pointer;
  background-color: white;
  display: none;
  top: 80px;
  font-size: 12px;
  color: ${colorTheme.black.soft};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 15%);
  transition: background 0.5s ease-in;

  &:hover {
    background: ${colorTheme.primary_color};
    color: white;
  }
  @media screen and (max-width: 790px) {
    display: flex;
    top: 170px;
  }
  @media screen and (max-width: 680px) {
    top: 80px;
  }
`;
export const BackgroundImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 180px; */
  /* @media screen and (max-width: 780px) {
    height: auto;
  } */
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 80%;
  padding: 52px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: linear-gradient(${({bgColor})=>bgColor ? bgColor :'rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)'}),
    url(${({ image }) => (image ? image : '')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: ${({resPoint})=>resPoint ? resPoint :'780px'}) {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
   height: ${({resHeight})=>resHeight ? resHeight : 'auto'};
  }
`;

export const BackButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  top: 23px;
  position: relative;
  gap: 2px;

  @media screen and (max-width: 780px) {
    width: 90%;
  }
`;

export const TextSection = styled.div`
  width: 100%;
  display: flex;
  padding: 2px;
  align-items: center;
  flex: 1;

  @media screen and (max-width: 780px) {
    flex-wrap: wrap;
  }
`;

export const HeroCol = styled.div`
  display: flex;
  flex-basis: ${({flexBasis})=>flexBasis ? flexBasis :'50%'};
  flex-direction: column;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
  justify-content: space-evenly;
  @media screen and (max-width: 780px) {
    padding: 20px;
  }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
  }
`;

