import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ClientRequest from "../../../../api/api";

export const deleteTicket = createAsyncThunk(
  "user/events/delete-ticket",
  async ({ id, dispatch,deleteTicketHandler }, { rejectWithValue }) => {
    try {
      const response = await ClientRequest.post(
        `api/event/tickets/delete/${id}`
      );
      if (response.status === 200) {
      dispatch(  deleteTicketHandler(id))
        toast.error(response.data?.success);
      }
    } catch (error) {
      const { response } = error;
      console.error("Edit ticket error:", error);
      console.error("Edit ticket error:", response);
      toast.error(response.data?.error);
      return rejectWithValue(response?.data?.error);
    }
  }
);
