import FlexibleDiv from "../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../components/primaryComponents/image/image";
import { Text } from "../../components/primaryComponents/typography/typography.component";
import Footer from "../../components/secondaryComponents/Footer/Footer";
import Img404 from "../../assets/images/404.svg";
import { Helmet } from "react-helmet";
const Page404 = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>404</title>
      </Helmet>
      <FlexibleDiv
        padding={"120px 0px 40px 0px"}
        direction={"column"}
        justifyContent={"center"}
      >
        <Image src={Img404} width={"220px"} />
        <Text>Page not found</Text>
      </FlexibleDiv>
      <Footer />
    </>
  );
};

export default Page404;
