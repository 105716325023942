import { createSlice } from "@reduxjs/toolkit";


const OrganizerSlice = createSlice({
    name:"organizer_page_details",
    initialState:null,
    reducers:{
        setOrganizerPage(state,action){
        state= action.payload;
        return state;
        }
    }
})


export const {setOrganizerPage} = OrganizerSlice.actions;

export default OrganizerSlice.reducer