import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";

const EventLoaderCard = ({ breakPointwidth, breakPoint, width }) => {
  const darkmode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  return (
    <>
      <SkeletonTheme
        baseColor={colorTheme.black.fadingBlack}
        highlightColor={"#444444"}
      >
        <FlexibleDiv
          padding={"8px 0"}
          width={width ? width : "100%"}
          direction={"column"}
          border={"0px"}
          breakPoint={breakPoint ? breakPoint : ""}
          breakPointwidth={breakPointwidth ? breakPointwidth : ""}
        >
          <Skeleton width={230} height={130} />
          <FlexibleDiv
            direction={"column"}
            margin={"0px 0px 0px 0px"}
            padding={"13px 0px"}
            bg={darkmode ? colorTheme.black.fadeMini : "#FFF"}
            style={{
              borderBottomLeftRadius: "9px",
              borderBottomRightRadius: "9px",
            }}
            shadow={"0 1px 2px 0 rgb(0 0 0 / 15%)"}
          >
            <FlexibleDiv justifyContent={"flex-start"} flex={"1 0 auto"}>
              <FlexibleDiv
                padding={"0 9px"}
                flex={"1 1 auto"}
                wrap={"nowrap"}
                alignItems={"baseline"}
              >
                <FlexibleDiv flexBasis={"50%"} direction={"column"}>
                  <Skeleton width={70} />
                  <Skeleton width={70} />
                </FlexibleDiv>
                <FlexibleDiv flexGrow={"30%"} justifyContent={"flex-end"}>
                  <Skeleton width={70} height={20} />
                </FlexibleDiv>
              </FlexibleDiv>

              <FlexibleDiv
                margin={"5px 0px"}
                padding={"0 10px"}
                alignItems={"flex-start"}
                direction={"column"}
              >
                <Skeleton width={70} />
                <Skeleton width={90} />
              </FlexibleDiv>
            </FlexibleDiv>

            <FlexibleDiv>
              <FlexibleDiv
                margin={"0px"}
                flex={"1 0 auto"}
                padding={"10px 0px 0px 4px"}
                width={"100%"}
              >
                <FlexibleDiv
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                >
                  <FlexibleDiv width={"15%"}>
                    <Skeleton
                      circle
                      width={25}
                      height={25}
                      containerClassName="avatar-skeleton"
                    />
                  </FlexibleDiv>

                  <FlexibleDiv
                    width={"85%"}
                    direction={"column"}
                    alignItems={"flex-start"}
                  >
                    <Skeleton width={101} />

                    <Skeleton width={101} />
                  </FlexibleDiv>
                </FlexibleDiv>
              </FlexibleDiv>
            </FlexibleDiv>
          </FlexibleDiv>
        </FlexibleDiv>
      </SkeletonTheme>
    </>
  );
};

export default EventLoaderCard;
