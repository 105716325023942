// import { PaystackButton } from "react-paystack";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../primaryComponents/button/button.component";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../utilities/constants";
import { generate_payment_link } from "../../../services/ticket_payment";
import { Image } from "../../primaryComponents/image/image";
import Loader from "../../../assets/images/loading.svg";

const Ticket_Checkout = () => {
  const dispatch = useDispatch();
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.token
  );
  const email = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.email
  );
  const firstname = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.firstname
  );
  const lastname = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.lastname
  );
  const phone = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.phone
  );
  const selected_tickets = useSelector(
    ({ buy_ticket }) => buy_ticket.selected_tickets
  );
  const selected_ticket = useSelector(
    ({ buy_ticket }) => buy_ticket.selected_ticket
  );
  const loading = useSelector(({ buy_ticket }) => buy_ticket.loading);
  const bg =
    selected_ticket === null
      ? colorTheme.lightgrey
      : loading
      ? colorTheme.white.white
      : colorTheme.primary_color;
  const total = selected_tickets?.reduce((total, ticket) => {
    return total + ticket.price * ticket.quantity;
  }, 0);
  const name = `${firstname} ${lastname}`;
  const componentProps = {
    email,
    amount: total * 100,
    metadata: {
      name,
      phone
    },
    publicKey: CONSTANTS.paystack_key,
    text: "Check out",
    onSuccess: () => {
      alert("Thanks for doing business with us!");
    },
    onClose: () => {
      alert("Wait!");
    }
  };

  return (
    <FlexibleDiv
      justifyContent={"flex-start"}
      margin={"23px 0px"}
      padding={"0px 23px"}
    >
      <Button
        disabled={selected_ticket === null}
        type={"submit"}
        onClick={() => {
          dispatch(
            generate_payment_link({
              token: token,
              event_ticket_id: selected_ticket?.id,
              name: name
            })
          );
        }}
        hoverBorder={"none"}
        color={colorTheme.white.white}
        background={bg}
        hoverBackground={bg}
        hoverColor={colorTheme.white.white}
        width={"150px"}
        radius={"5px"}
        padding={"13px"}
      >
        {loading ? <Image src={Loader} width={"33px"} /> : " Check out"}
      </Button>
      {/* <DrawerSummary /> */}
    </FlexibleDiv>
  );
};

export default Ticket_Checkout;


