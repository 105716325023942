import React, { useEffect } from "react";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import Footer from "../../../components/secondaryComponents/Footer/Footer";
import Hero from "../../../components/secondaryComponents/hero/hero.component";
import TestNav from "../../../components/secondaryComponents/NavigationBar/testNav";
import { Column, EventDetailsWrapper, MainStyle } from "../eventDetails.styles";
import AboutEvent from "./AboutEvent/aboutEvent";
import DateTime from "./DateAndTime/dateTime";
import MoreEvent from "./moreEvents/moreEvents";
import OrganizerEventDetailsBox from "./organizerEventDetailsBox";
import Share from "./Share/share";
import SimilarEvents from "./similarEvents/similarEvents";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDetailsData,
  getEventDetails
} from "../../../state management/Redux/slices/events/get_event_details_slice";
import { SkeletonTheme } from "react-loading-skeleton";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { useState } from "react";
import Tickets_Page from "../../tickets";
import Share_Modal from "./Share/share_modal.component";
import { CONSTANTS } from "../../../utilities/constants";
import { Helmet } from "react-helmet";
import GoogleSignInModal from "./modals/google-signin-modal";
const EventDetails2 = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer.user.token
  );
  const title = useSelector(
    ({ event_details }) => event_details?.data?.event?.title
  );
  const event = useSelector(({ event_details }) => event_details?.data?.event);
  const userEmail = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo?.email
  );
  const cover_image = useSelector(
    ({ event_details }) => event_details?.data?.event?.cover_image
  );
  const organizer = useSelector(
    ({ event_details }) => event_details.data?.organizer
  );
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const startDate = useSelector(
    ({ event_details }) => event_details.data?.event?.startDate
  );

  useEffect(() => {
    dispatch(getEventDetails({ token: token, event_id: eventId }));
  }, [eventId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showTicket, setShowTickets] = useState(false);
  const [shareModal, setShareModal] = useState(false);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <TestNav />
      <SkeletonTheme
        baseColor={colorTheme.black.fadingBlack}
        highlightColor={"#444444"}
      >
        <EventDetailsWrapper>
          {/* Hero  */}
          <FlexibleDiv position={"relative"}>
            <Hero
              backBtn
              loading={loading}
              typeOfHandler={"dispatch"}
              goBackFunction={clearDetailsData}
              image={loading ? null : `${CONSTANTS.imgbaseurl}${cover_image}`}
              title={title}
              height={"60vh"}
              breakPoint={"880px"}
              breakPointHeight={"auto"}
              // text={
              //   " Engineering New Annex,Engineering Faculty,UNN,Nsukka,Enugu,Nigeria"
              // }
              author={`${organizer?.username}`}
            />
            <OrganizerEventDetailsBox
              setShowTickets={setShowTickets}
              setShareModal={setShareModal}
            />
          </FlexibleDiv>
          <MainStyle>
            <FlexibleDiv
              width={"60%"}
              margin={"100px 0px 0px 0px"}
              breakPoint={"790px"}
              breakPointwidth={"100%"}
              breakPointMargin={"auto"}
            >
              <AboutEvent />
              {/* More event displays in tablet and mobile devices  under about event */}
              {/* <MoreEvent
                display={"none"}
                breakPoint={"890px"}
                breakPointDisplay={"flex"}
              /> */}
            </FlexibleDiv>

            <FlexibleDiv
              width={"40%"}
              margin={"40px 0px 0px 0px"}
              breakPoint={"790px"}
              breakPointwidth={"100%"}
              direction={"column"}
            >
              <DateTime />

              <Column>
                <Share />
                {/* More event displays in PC, desktop and wide screens  under about share */}
                {/* <MoreEvent /> */}
              </Column>
            </FlexibleDiv>
            <Tickets_Page show={showTicket} setShowModal={setShowTickets} />
            <Share_Modal showModal={shareModal} setShowModal={setShareModal} />
          </MainStyle>
          {/* <SimilarEvents /> */}
          <Footer marginTop={"0px"} />
          <GoogleSignInModal/>
        </EventDetailsWrapper>
      </SkeletonTheme>
    </>
  );
};

export default EventDetails2;
