import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";


const TicketsSold = () => {
    const darkMode = useSelector((state)=>state.persistedReducer.theme.darkmode)
    const titleColor = darkMode ? colorTheme.white.white :colorTheme.black.darkmode2
    const bgColor = darkMode ? "rgba(12, 89, 20, 0.942)" : "rgba(2, 138, 15, 0.1)"
    const revenue = useSelector(
        ({ event_details }) => event_details?.data?.event?.revenue
      );
    return ( 
        <>
        <FlexibleDiv height={'140px'}  width={'30%'} bg={bgColor} radius={"10px"} padding={"23px 23px"} display={"block"} lineHeight={'40px'}  breakPoint={"780px"} breakPointwidth={"50%"} breakPointPadding={"12px"} breakPointHeight={'auto'} >
            <FlexibleDiv justifyContent={'flex-start'} >
            <Text margin={"0px"} fontSize={"23px"} fontWeight={"600"} color={titleColor} >Tickets Sold</Text>
            </FlexibleDiv>
            <FlexibleDiv justifyContent={'flex-start'} >
            <Text margin={"0px"} fontSize={"26px"} fontWeight={"500"} color={titleColor} >N {revenue}</Text>
            </FlexibleDiv>
            {/* <FlexibleDiv justifyContent={'flex-start'} padding={"10px 0px 0px 0px "} >
            <Text margin={"0px"} fontSize={"14px"} fontWeight={"500"} color={colorTheme.textColor} >Per Ticket- 200</Text>
            </FlexibleDiv> */}
        </FlexibleDiv>
        </>
     );
}
 
export default TicketsSold;