import React from "react";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { Text } from "../../primaryComponents/typography/typography.component";
import { FooterLinks } from "./footerLinks";
import {
  FooterColumns,
  FooterContainer,
  FooterMain,
  FooterStyle,
  FooterUl,
  FooterLi,
  LowerSection
} from "./styles";

const Footer = ({ bottom, left, position, marginTop }) => {
  let Time = new Date();
  let Year = Time.getFullYear();

  return (
    <FooterContainer
      marginTop={marginTop}
      bottom={bottom}
      left={left}
      position={position}
    >
      <FooterStyle>
        {/* <FooterMain>
                {FooterLinks.map((items)=>(
                    <FooterColumns key={items.title}>
                        <Text fontSize={fontSizes.mobile_body_text} >
                            {items.title}
                        </Text>
                        <FooterUl>
                           {items.links.map(({name,icon})=>(
                             <FooterLi key={name}>
                                {icon}{name}
                             </FooterLi>
                           ))}
                        </FooterUl>
                    </FooterColumns>
                ))}
            </FooterMain> */}

        <LowerSection>
          <FlexibleDiv width={"50%"} justifyContent={"flex-start"}>
            <Text
              color={"#4B4B4B"}
              fontSize={fontSizes.mobile_details}
              resFontSize={"10px"}
            >
              @ {Year} Attend. All rights reserved.
            </Text>
          </FlexibleDiv>
          <FlexibleDiv width={"50%"} justifyContent={"flex-end"}>
            {/* <Logo/> */}
            <Text color="#A1A1A1" fontSize={"16px"}>
              Attend
            </Text>
          </FlexibleDiv>
        </LowerSection>
      </FooterStyle>
    </FooterContainer>
  );
};

export default Footer;
