import { colorTheme } from "../../../infrastructure/Theme/color"
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component"
import { Text } from "../../primaryComponents/typography/typography.component"



const TicketOrder =({ticketOrder})=>{
    return(
        <>
       <FlexibleDiv justifyContent={'flex-start'} >
          <Text fontSize={"14px"} color={colorTheme.textColor} resFontSize={'small'}>{ticketOrder}</Text>
          </FlexibleDiv>

        </>
    )
}

export  default TicketOrder