import React, { useMemo, useState } from "react";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import TabMenu from "./tabMenu.component";
import { TabSwitch } from "./tabSwitch.component";


const TabComponent = React.memo(({defualt,tabName,setTabName,Options,optionWidth,view,noOfViews,Components,setView,}) => {
    const [TabOptions, setTabOptions] = useState(Options);
   
      const ProfileTabStateHandler = () => {

          setTabOptions(() => {
            return [
                Options
            ];
          });
        };
        const Memoized = useMemo(()=><TabMenu Components={Components} optionWidth={optionWidth}noOfViews={noOfViews} setViews={setView} view={view} tabName={tabName} setTabName={setTabName} TabOptions={TabOptions} ProfileTabStateHandler={ProfileTabStateHandler} />)
    return ( 
        <>
        <FlexibleDiv>
        {Memoized}
        {TabSwitch(view,noOfViews,Components)}
        </FlexibleDiv>
        </>
     );
})
 
export default TabComponent;