import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import { HeaderText } from "../../primaryComponents/typography/typography.component";
import OrganizerCard from "./organizerCard.component";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"
import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";

const TopOrganizers = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5.5,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2.1,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.06,
              slidesToScroll: 2,
            },
          },
        ],
      };

      const darkMode = useSelector(({persistedReducer})=>persistedReducer.theme.darkmode)
    return ( 
        <>
        
        <FlexibleDiv >
            {/* Title */}
            <FlexibleDiv justifyContent={'flex-start'} >
                <HeaderText color={darkMode ?colorTheme.white.white  :'#000'} fontWeight={"700"} fontSize={'18px'} resFontSize={fontSizes.mobile_details} >Top Organizers To Follow</HeaderText>
            </FlexibleDiv>

            <FlexibleDiv>
              <Slider {...settings}>
              {[0,1,2,3,4,5,6,7,8,9].map((item)=>(
                    <OrganizerCard key={item}/>
                ))}
              </Slider>
            </FlexibleDiv>
        </FlexibleDiv>
        </>
     );
}
 
export default TopOrganizers;