import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import Img from "../../../assets/images/Comedy.jpg";
import { Text } from "../../primaryComponents/typography/typography.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { ExtractDateTime } from "../../../utilities/functions/dateExtractor";
import { CONSTANTS } from "../../../utilities/constants";
const CardImage = ({ status, image, startDate }) => {
  return (
    <FlexibleDiv height={"169px"} minHeight={"169px"} position={"relative"}>
      <>
        {status?.includes("FINISHED") && <EventStatus />}
        <EventDate startDate={startDate} />
        <img
          src={image ? `${CONSTANTS.imgbaseurl}${image}` : Img}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "100%",
            borderTopLeftRadius: "9px",
            borderTopRightRadius: "9px",
          }}
          alt={"event name"}
        />
      </>
    </FlexibleDiv>
  );
};

export default CardImage;

const EventDate = ({ startDate }) => {
  const { day, dayOfMonth } = ExtractDateTime(startDate);

  return (
    <>
      <FlexibleDiv
        direction={"column"}
        justifyContent={"center"}
        position={"absolute"}
        zIndex={"1"}
        bg={"white"}
        width={"45px"}
        radius={"2px"}
        padding={"4px"}
        right={"10px"}
        lineHeight={"1.2rem"}
        top={"19px"}
      >
        <Text
          fontSize={fontSizes.mobile_details}
          resFontSize={fontSizes.mobile_details}
          color={colorTheme.black.soft}
          fontWeight={"600"}
          margin={"0px"}
        >
          {day}
        </Text>
        <Text
          fontSize={fontSizes.mobile_body_text}
          resFontSize={fontSizes.mobile_body_text}
          fontWeight={"600"}
          margin={"0px"}
        >
          {dayOfMonth}
        </Text>
      </FlexibleDiv>
    </>
  );
};
const EventStatus = () => {
  return (
    <>
      <FlexibleDiv
        direction={"column"}
        justifyContent={"center"}
        position={"absolute"}
        zIndex={"1"}
        bg={colorTheme.red}
        width={"45px"}
        radius={"2px"}
        padding={"8px"}
        left={"10px"}
        lineHeight={"1.2rem"}
        top={"19px"}
      >
        <Text
          fontSize={fontSizes.mobile_details}
          resFontSize={fontSizes.mobile_details}
          color={colorTheme.white.fadingWhite}
          fontWeight={"600"}
          margin={"0px"}
        >
          Ended
        </Text>
        {/* <Text
          fontSize={fontSizes.mobile_body_text}
          resFontSize={fontSizes.mobile_body_text}
          fontWeight={"600"}
          margin={"0px"}
        >
          {dayOfMonth}
        </Text> */}
      </FlexibleDiv>
    </>
  );
};
