import { useSelector } from "react-redux";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { Text } from "../../primaryComponents/typography/typography.component";

const Amount = ({ amount }) => {
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );
  return (
    <>
      {/* {amount.map(({option})=>(
          <Text fontSize={"14px"} color={colorTheme.ash} resFontSize={'small'}>N{option}</Text>
      ))} */}
      <Text
        fontSize={"14px"}
        color={darkMode ? colorTheme.white.white : colorTheme.textColor}
        resFontSize={"small"}
      >
        N{amount ? amount : 0.0}
      </Text>
    </>
  );
};

export default Amount;
